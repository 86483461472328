import React from 'react';
import { AuthModal } from './AuthModal';
import { RegistrationForm, ResetPasswordForm, ResetPasswordMessage, SignInForm } from '../components';
import { Modal } from '../../../lib/components/Modal';
import { useStore } from '../../../lib/core/hooks/use-store';
import { RequireUserRepository } from '../../../lib/core/available-stores';

interface IAuthFormWindow {
  onClose: () => void;
  initialAuthStatus?: AuthStatus;
  title?: string;
  afterSubmit?: () => void;
  redirect?: string;
}

export enum AuthStatus {
  SignIn = 'SIGNIN',
  Registration = 'REGISTRATION',
  Password = 'PASSWORD',
  PasswordMessage = 'PASSWORDMESSAGE',
}

export const AuthFormWindow: React.FC<IAuthFormWindow> = ({
  initialAuthStatus = AuthStatus.SignIn,
  title,
  onClose,
  afterSubmit,
  redirect,
}: IAuthFormWindow) => {
  const [showForm, setShowForm] = React.useState(initialAuthStatus);
  const userRepository = useStore<RequireUserRepository>('UserRepository');
  const handleCloseForm = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleShowSignInForm = React.useCallback(() => {
    setShowForm(AuthStatus.SignIn);
  }, [setShowForm]);

  const handleShowResetPasswordForm = React.useCallback(() => {
    setShowForm(AuthStatus.Password);
  }, [setShowForm]);

  const handleShowResetPasswordMessage = React.useCallback(() => {
    setShowForm(AuthStatus.PasswordMessage);
  }, [setShowForm]);

  const handleShowRegistrationForm = React.useCallback(() => {
    setShowForm(AuthStatus.Registration);
  }, [setShowForm]);

  const handleAfterSubmit = React.useCallback(() => {
    afterSubmit ? afterSubmit() : userRepository.routeAfterSignIn(redirect);
  }, [redirect, afterSubmit, userRepository]);

  return (
    <>
      <AuthModal isOpened={showForm === AuthStatus.SignIn} title="Login" onClose={handleCloseForm}>
        <SignInForm
          onClickResetPassword={handleShowResetPasswordForm}
          onClickRegister={handleShowRegistrationForm}
          afterSubmit={handleAfterSubmit}
        />
      </AuthModal>
      <AuthModal
        isOpened={showForm === AuthStatus.Registration}
        title={title || 'Registrierung'}
        onClose={handleCloseForm}
        width="640px"
      >
        <RegistrationForm afterSubmit={handleAfterSubmit} onClickSignIn={handleShowSignInForm} />
      </AuthModal>
      <AuthModal isOpened={showForm === AuthStatus.Password} title="Passwort zurücksetzen" onClose={handleCloseForm}>
        <ResetPasswordForm
          onClickRegister={handleShowRegistrationForm}
          afterResetPassword={handleShowResetPasswordMessage}
        />
      </AuthModal>
      {/* TODO make this as the part of ResetPasswordForm */}
      <Modal title="" closable={false} isOpened={showForm === AuthStatus.PasswordMessage}>
        <ResetPasswordMessage onClickOk={handleCloseForm} />
      </Modal>
    </>
  );
};
