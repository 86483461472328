import React from 'react';

export const BenchTwoSeatIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="44" height="41" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.6 18H2.29999C0.999988 18 0 19 0 20.3V24.6C0 25.9 0.999988 26.9 2.29999 26.9H5L2 40.3C2 40.7 2.20004 41 2.60004 41H2.70001C3.00001 41 3.29999 40.8 3.29999 40.4L6.29999 26.9H37.6L40.6 40.4C40.6 40.7 40.9 41 41.2 41H41.3C41.7 41 41.9 40.6 41.9 40.3L38.9 26.9H41.6C42.9 26.9 43.9 25.9 43.9 24.6V20.3C43.9 19.1 42.9 18 41.6 18ZM42.6 24.7C42.6 25.3 42.1 25.7 41.6 25.7H2.29999C1.69999 25.7 1.29999 25.2 1.29999 24.7V20.4C1.29999 19.8 1.79999 19.4 2.29999 19.4H41.6C42.2 19.4 42.6 19.9 42.6 20.4V24.7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 16.5C3.5 15.1193 4.61929 14 6 14H18C19.3807 14 20.5 15.1193 20.5 16.5V18.5C20.5 18.7761 20.2761 19 20 19C19.7239 19 19.5 18.7761 19.5 18.5V16.5C19.5 15.6716 18.8284 15 18 15H6C5.17157 15 4.5 15.6716 4.5 16.5V18.5C4.5 18.7761 4.27614 19 4 19C3.72386 19 3.5 18.7761 3.5 18.5V16.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 16.5C22 15.1193 23.1193 14 24.5 14H36.5C37.8807 14 39 15.1193 39 16.5V18.5C39 18.7761 38.7761 19 38.5 19C38.2239 19 38 18.7761 38 18.5V16.5C38 15.6716 37.3284 15 36.5 15H24.5C23.6716 15 23 15.6716 23 16.5V18.5C23 18.7761 22.7761 19 22.5 19C22.2239 19 22 18.7761 22 18.5V16.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.625C1 1.62297 2.62297 0 4.625 0H38.625C40.627 0 42.25 1.62297 42.25 3.625V18.625H41V3.625C41 2.31332 39.9367 1.25 38.625 1.25H4.625C3.31332 1.25 2.25 2.31332 2.25 3.625V18.625H1V3.625Z"
      fill="currentColor"
    />
  </svg>
);
