import React from 'react';

export const WhiteboardMobileIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="32" height="55" viewBox="0 0 32 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 53.7131C7.19036 53.7131 7.75 53.1535 7.75 52.4631C7.75 51.7728 7.19036 51.2131 6.5 51.2131C5.80964 51.2131 5.25 51.7728 5.25 52.4631C5.25 53.1535 5.80964 53.7131 6.5 53.7131ZM6.5 54.9631C7.88071 54.9631 9 53.8438 9 52.4631C9 51.0824 7.88071 49.9631 6.5 49.9631C5.11929 49.9631 4 51.0824 4 52.4631C4 53.8438 5.11929 54.9631 6.5 54.9631Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 53.7131C26.1904 53.7131 26.75 53.1535 26.75 52.4631C26.75 51.7728 26.1904 51.2131 25.5 51.2131C24.8096 51.2131 24.25 51.7728 24.25 52.4631C24.25 53.1535 24.8096 53.7131 25.5 53.7131ZM25.5 54.9631C26.8807 54.9631 28 53.8438 28 52.4631C28 51.0824 26.8807 49.9631 25.5 49.9631C24.1193 49.9631 23 51.0824 23 52.4631C23 53.8438 24.1193 54.9631 25.5 54.9631Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.05841 1.17153C2.88933 1.27269 2.86466 1.36164 2.86466 1.41282V1.43162L1.00049 36.1852C1.00445 36.2359 1.03534 36.3396 1.19942 36.5003C1.23526 36.5354 1.32436 36.5903 1.47458 36.6355C1.61745 36.6786 1.77282 36.6998 1.8985 36.6998H30.1015C30.4932 36.6998 30.6947 36.604 30.8006 36.5003L30.8582 36.4439L30.9197 36.4137L30.9215 36.4117C30.9301 36.4016 30.9434 36.382 30.9573 36.3513C30.9842 36.292 30.9972 36.2277 30.9996 36.1863L29.1353 1.43162V1.41282C29.1353 1.41774 29.1353 1.41757 29.1333 1.41302C29.1259 1.3954 29.0908 1.31216 28.925 1.20396C28.7402 1.08334 28.4849 1 28.2368 1H3.76316C3.48631 1 3.22919 1.06935 3.05841 1.17153ZM2.54497 0.313402C2.89863 0.101804 3.34076 0 3.76316 0H28.2368C28.688 0 29.132 0.14486 29.4716 0.366553C29.7825 0.569505 30.1247 0.921419 30.1351 1.39075L32 36.1541V36.1729C32 36.3612 31.9544 36.5741 31.8679 36.7647C31.7924 36.9309 31.6615 37.1324 31.4472 37.2642C31.0919 37.5799 30.6161 37.6998 30.1015 37.6998H1.8985C1.67455 37.6998 1.42202 37.6641 1.18613 37.593C0.957594 37.5242 0.697068 37.4079 0.49983 37.2148C0.212357 36.9333 0 36.5844 0 36.1729V36.1541L1.86484 1.39161C1.87355 0.883464 2.19447 0.523114 2.54497 0.313402Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5634 33.9829C10.561 33.9892 10.5562 34.005 10.5562 34.0336V36.5439C10.5562 36.5726 10.561 36.5884 10.5634 36.5946C10.5657 36.6003 10.567 36.6011 10.5662 36.6003C10.567 36.6009 10.5699 36.603 10.5765 36.6055C10.586 36.6091 10.6059 36.6144 10.6389 36.6144H21.011C21.044 36.6144 21.0639 36.6091 21.0734 36.6055C21.08 36.603 21.0829 36.6009 21.0837 36.6003C21.0829 36.6011 21.0843 36.6003 21.0865 36.5946C21.0889 36.5884 21.0937 36.5726 21.0937 36.5439V34.0336C21.0937 34.005 21.0889 33.9892 21.0865 33.9829C21.0843 33.9772 21.0829 33.9765 21.0837 33.9772C21.0829 33.9766 21.08 33.9746 21.0734 33.9721C21.0639 33.9685 21.044 33.9631 21.011 33.9631H10.6389C10.6059 33.9631 10.586 33.9685 10.5765 33.9721C10.5698 33.9746 10.5669 33.9767 10.5662 33.9773C10.5658 33.9778 10.5648 33.9795 10.5634 33.9829ZM9.8666 33.2628C10.0756 33.0581 10.3536 32.9631 10.6389 32.9631H21.011C21.2963 32.9631 21.5743 33.0581 21.7833 33.2628C21.9932 33.4683 22.0937 33.7456 22.0937 34.0336V36.5439C22.0937 36.8319 21.9932 37.1092 21.7833 37.3148C21.5743 37.5194 21.2963 37.6144 21.011 37.6144H10.6389C10.3536 37.6144 10.0756 37.5194 9.8666 37.3148C9.65668 37.1092 9.55615 36.8319 9.55615 36.5439V34.0336C9.55615 33.7456 9.65668 33.4683 9.8666 33.2628Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 37.9631H5V47.9631H27V37.9631ZM4 36.9631V48.9631H28V36.9631H4Z"
      fill="currentColor"
    />
  </svg>
);
