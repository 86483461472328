import React from 'react';

export const StorageCabinetIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="38" height="52" viewBox="0 0 38 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M36.2 0.299805H18.9H1.60004C1.20004 0.299805 0.900024 0.599817 0.900024 0.999817V20.9998V40.9998V48.0998C0.900024 48.4998 1.20004 48.7998 1.60004 48.7998H3.90002V50.5998C3.90002 50.9998 4.20004 51.2998 4.60004 51.2998H9.90002C10.3 51.2998 10.6 50.9998 10.6 50.5998V48.7998H27.5V50.5998C27.5 50.9998 27.8 51.2998 28.2 51.2998H33.5C33.9 51.2998 34.2 50.9998 34.2 50.5998V48.7998H36.5C36.9 48.7998 37.2 48.4998 37.2 48.0998V40.9998V20.9998V0.999817C36.8 0.499817 36.5 0.299805 36.2 0.299805ZM2.10004 40.1998V21.4998H18.1V40.1998H2.10004ZM19.5 21.4998H35.5V40.1998H19.5V21.4998ZM35.5 20.1998H19.5V1.49982H35.5V20.1998ZM2.10004 1.59979H18.1V20.2998H2.10004V1.59979ZM9.10004 49.7998H5.10004V48.6998H9.10004V49.7998ZM32.6 49.7998H28.6V48.6998H32.6V49.7998ZM35.5 47.2998H33.2H27.9H9.70004H4.40002H2.10004V41.4998H18.8H35.5V47.2998Z"
      fill="currentColor"
    />
    <path
      d="M23.7 12.6002C24.6 12.6002 25.4 11.8002 25.4 10.9002C25.4 10.0002 24.6 9.2002 23.7 9.2002C22.8 9.2002 22 10.0002 22 10.9002C22 11.8002 22.8 12.6002 23.7 12.6002ZM23.7 10.5002C23.9 10.5002 24.1 10.7002 24.1 10.9002C24.1 11.1002 23.9 11.3002 23.7 11.3002C23.5 11.3002 23.3 11.1002 23.3 10.9002C23.3 10.7002 23.5 10.5002 23.7 10.5002Z"
      fill="currentColor"
    />
    <path
      d="M23.7 29.2002C22.8 29.2002 22 30.0002 22 30.9002C22 31.8002 22.8 32.6002 23.7 32.6002C24.6 32.6002 25.4 31.8002 25.4 30.9002C25.4 30.0002 24.6 29.2002 23.7 29.2002ZM23.7 31.3002C23.5 31.3002 23.3 31.1002 23.3 30.9002C23.3 30.7002 23.5 30.5002 23.7 30.5002C23.9 30.5002 24.1 30.7002 24.1 30.9002C24.1 31.1002 23.9 31.3002 23.7 31.3002Z"
      fill="currentColor"
    />
    <path
      d="M13.9001 9.2002C13.0001 9.2002 12.2001 10.0002 12.2001 10.9002C12.2001 11.8002 13.0001 12.6002 13.9001 12.6002C14.8001 12.6002 15.6001 11.8002 15.6001 10.9002C15.6001 10.0002 14.9001 9.2002 13.9001 9.2002ZM13.9001 11.3002C13.7001 11.3002 13.5001 11.1002 13.5001 10.9002C13.5001 10.7002 13.7001 10.5002 13.9001 10.5002C14.1001 10.5002 14.3 10.7002 14.3 10.9002C14.3 11.1002 14.1001 11.3002 13.9001 11.3002Z"
      fill="currentColor"
    />
    <path
      d="M13.9001 29.2002C13.0001 29.2002 12.2001 30.0002 12.2001 30.9002C12.2001 31.8002 13.0001 32.6002 13.9001 32.6002C14.8001 32.6002 15.6001 31.8002 15.6001 30.9002C15.6001 30.0002 14.9001 29.2002 13.9001 29.2002ZM13.9001 31.3002C13.7001 31.3002 13.5001 31.1002 13.5001 30.9002C13.5001 30.7002 13.7001 30.5002 13.9001 30.5002C14.1001 30.5002 14.3 30.7002 14.3 30.9002C14.3 31.1002 14.1001 31.3002 13.9001 31.3002Z"
      fill="currentColor"
    />
  </svg>
);
