import React from 'react';

export const TableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.4 25H5.70001C5.30001 25 5 25.3 5 25.7V31.1C5 31.5 5.30001 31.8 5.70001 31.8H7.10001V38.6V45.9V53.2C7.10001 53.6 7.40002 53.9 7.80002 53.9H28.5C28.9 53.9 29.2 53.6 29.2 53.2V45.9V38.6V31.8H55.8V53.2C55.8 53.6 56.1 53.9 56.5 53.9C56.9 53.9 57.2 53.6 57.2 53.2V31.8H58.6C59 31.8 59.3 31.5 59.3 31.1V25.7C59.1 25.2 58.8 25 58.4 25ZM27.8 37.8H8.39999V31.8H27.8V37.8ZM8.39999 39.1H27.8V45.1H8.39999V39.1ZM27.8 52.4H8.39999V46.4H27.8V52.4ZM57.8 30.3H6.39999V26.3H57.8V30.3Z"
      fill="currentColor"
    />
    <path
      d="M16.7 35.4H19.5C19.9 35.4 20.2 35.1 20.2 34.7C20.2 34.3 19.9 34 19.5 34H16.7C16.3 34 16 34.3 16 34.7C16.1 35.1 16.3 35.4 16.7 35.4Z"
      fill="currentColor"
    />
    <path
      d="M19.5 41.4004H16.7C16.3 41.4004 16 41.7004 16 42.1004C16 42.5004 16.3 42.8004 16.7 42.8004H19.5C19.9 42.8004 20.2 42.5004 20.2 42.1004C20.2 41.7004 19.9 41.4004 19.5 41.4004Z"
      fill="currentColor"
    />
    <path
      d="M16.7 50.1002H19.5C19.9 50.1002 20.2 49.8002 20.2 49.4002C20.2 49.0002 19.9 48.7002 19.5 48.7002H16.7C16.3 48.7002 16 49.0002 16 49.4002C16.1 49.8002 16.3 50.1002 16.7 50.1002Z"
      fill="currentColor"
    />
    <path
      d="M36.2 20.6H50C50.4 20.6 50.7 20.3 50.7 19.9V11.7C50.7 11.3 50.4 11 50 11H36.2C35.8 11 35.5 11.3 35.5 11.7V19.9C35.5 20.3 35.8 20.6 36.2 20.6ZM36.8 12.3H49.3V19.2H36.8V12.3Z"
      fill="currentColor"
    />
    <path
      d="M36.2 23.8004H50C50.4 23.8004 50.7 23.5004 50.7 23.1004C50.7 22.7004 50.4 22.4004 50 22.4004H36.2C35.8 22.4004 35.5 22.7004 35.5 23.1004C35.5 23.5004 35.8 23.8004 36.2 23.8004Z"
      fill="currentColor"
    />
  </svg>
);
