import React from 'react';
import * as Yup from 'yup';

import { useRegisterInvitedUser } from '../../../lib/core/repositories/register-repository';
import { useSignIn } from '../../../lib/core/repositories/auth-repository';
import { MutationRegisterArgs, User } from '../../../lib/core/api/generated';
import { PrivacyPolicy } from '../../payment/components/PrivacyPolicy';
import {
  Form,
  FormButton,
  FormCheckbox,
  FormFieldRow,
  FormFooter,
  FormInput,
  FormLayout,
} from '../../../lib/components/Form';
import { GqlExistError } from '../../../lib/core/errors/gql-error';
import { Button } from '../../../lib/components/Button';
import { h5 } from '../../../lib/themes/typography';
import { requiredString } from '../../../lib/utils/yup-reductions';
import { IRegistrationForm } from './RegistrationForm';

interface InvitedUserRegistrationFormProps {
  afterSubmit?: () => unknown;
  inviteToken?: string;
  onCancel: () => unknown;
}

export const InvitedUserRegistrationForm: React.FC<InvitedUserRegistrationFormProps> = ({
  afterSubmit,
  inviteToken,
  onCancel,
}) => {
  const [submitRegisterInvitedUser] = useRegisterInvitedUser();
  const [submitSignIn] = useSignIn();
  const [isPrivacyPolicyOpened, setPrivacyPolicyOpened] = React.useState<boolean>(false);

  // On success registration, we automatically try to login the user and redirect him to the first his accounts
  const onSubmit = React.useCallback(
    ({ firstName, lastName, password }: MutationRegisterArgs) =>
      submitRegisterInvitedUser({ firstName, lastName, inviteToken, password }).then((user: User) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        submitSignIn({ username: user.username!, password }),
      ),
    [submitRegisterInvitedUser, submitSignIn, inviteToken],
  );

  const handleOpenPrivacyPolicy = React.useCallback(() => {
    setPrivacyPolicyOpened(true);
  }, []);

  const handleClosePrivacyPolicy = React.useCallback(() => {
    setPrivacyPolicyOpened(false);
  }, []);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        afterSubmit={afterSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        errorsHR={[
          [
            GqlExistError,
            'Diese E-Mail ist bereits registriert. Verwenden Sie eine andere oder stellen Sie Ihr Passwort wieder her.',
          ],
        ]}
      >
        <FormLayout>
          <FormFieldRow>
            <FormInput name="lastName" label="Name" autoFocusOnEmpty={true} />
            <FormInput name="firstName" label="Vorname" />
          </FormFieldRow>
          <FormInput type="password" name="password" label="Passwort" />
          <FormFieldRow>
            <FormCheckbox name="agreedPrivacyPolicy">
              Ich akzeptiere{' '}
              <Button
                variant="text"
                color="bronze"
                onClick={handleOpenPrivacyPolicy}
                css={`
                  ${h5}
                `}
              >
                die AGB
              </Button>
            </FormCheckbox>
          </FormFieldRow>
          <FormFooter>
            <Button type="button" variant="outlined" color="default" onClick={onCancel}>
              Abbrechen
            </Button>
            <FormButton type="submit" variant="contained" color="bronze">
              Registrieren
            </FormButton>
          </FormFooter>
        </FormLayout>
      </Form>

      <PrivacyPolicy isOpened={isPrivacyPolicyOpened} onClose={handleClosePrivacyPolicy} />
    </>
  );
};

const initialValues: IRegistrationForm = {
  firstName: '',
  lastName: '',
  password: '',
  agreedPrivacyPolicy: false,
};

const validationSchema = Yup.object().shape({
  firstName: requiredString('Bitte geben Sie Ihren Vornamen ein.'),
  lastName: requiredString('Bitte geben Sie Ihren Nachnamen ein.'),
  password: requiredString('Dieses Feld darf nicht leer sein.')
    .min(8, 'Das Passwort sollte aus 8 oder mehr Symbolen bestehen')
    .max(32, 'Das Passwort sollte aus 32 oder weniger Symbolen bestehen'),
  agreedPrivacyPolicy: Yup.boolean().oneOf([true]).required('Dieses Feld darf nicht leer sein.'),
});
