import React from 'react';

import { PlusIcon } from '../../icons';

import { FabStyled, IFabStyledProps } from './Fab.styled';

export interface IFabProps extends Partial<IFabStyledProps> {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  refEl?: React.RefObject<any>;
  className?: string;
}

export const Fab: React.FC<IFabProps> = ({ variant = 'plus', onClick, className, refEl, ...rest }) => {
  return (
    <FabStyled type="button" variant={variant} onClick={onClick} className={className} ref={refEl} {...rest}>
      {variant === 'plus' ? <PlusIcon /> : 'i'}
    </FabStyled>
  );
};
