import React, { useRef } from 'react';
import { Page, Title, ImageStyled } from './CreatingSolutionPage.styled';
import { Slider } from '../../../../lib/components';

import product0 from '../../../../static/img/mocks/plan-for-me/productsBig/0.png';
import product1 from '../../../../static/img/mocks/plan-for-me/productsBig/1.png';
import product2 from '../../../../static/img/mocks/plan-for-me/productsBig/2.png';
import product3 from '../../../../static/img/mocks/plan-for-me/productsBig/3.png';
import product4 from '../../../../static/img/mocks/plan-for-me/productsBig/4.png';
import product5 from '../../../../static/img/mocks/plan-for-me/productsBig/5.png';
import product6 from '../../../../static/img/mocks/plan-for-me/productsBig/6.png';
import product8 from '../../../../static/img/mocks/plan-for-me/productsBig/8.png';
import product9 from '../../../../static/img/mocks/plan-for-me/productsBig/9.png';

const products: string[] = [product0, product1, product2, product3, product4, product5, product6, product8, product9];

export const CreatingSolutionPage: React.FC<any> = () => {
  React.useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const sliderRef = useRef(null);

  return (
    <Page>
      <Title>
        Gleich geht‘s weiter.
        <br />
        Wir gestalten gerade das passende Konzepte für Ihr neue Arbeitswelt.
      </Title>
      <Slider
        ref={sliderRef}
        autoplay={{ delay: 500 }}
        slidesPerView={'auto'}
        loop={true}
        loopedSlides={10}
        normalizeSlideIndex={true}
      >
        {products.map((product, index) => (
          <ImageStyled src={product} alt="" key={index} />
        ))}
      </Slider>
    </Page>
  );
};
