import { styled } from '../../themes';

interface IRootProps {
  isShowed: boolean;
}

export const Root = styled.div<IRootProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 400ms;
  opacity: ${({ isShowed }) => (isShowed ? '1' : '0')};
  animation: ${({ isShowed }) => (isShowed ? 'none' : 'hide 0.4s ease-in-out 1')};

  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
