import { styled, css, applyCssIf, colors, h3, h5 } from '../../../../../../lib/themes';
import { RoomStatus } from '../../../../../../lib/core/api/generated';
import { MoreIcon } from '../../../../../../lib/icons/MoreIcon';
import { isRoomStatusOrdered, isRoomStatusPlanned } from './RoomCard';

export interface IProgressProps {
  status: RoomStatus;
  progress: number;
}

export const Footer = styled.div`
  padding: 30px 40px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MoreInfo = styled.div`
  position: relative;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;

export const MoreIconStyled = styled(MoreIcon)`
  width: 100%;
  height: 100%;
  color: ${colors.bronze};
  transition: color 0.3s;
`;

export const ButtonMore = styled.button`
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &:hover {
    ${MoreIconStyled} {
      color: ${colors.bronzeLight};
    }
  }
`;

export const Root = styled.div`
  background-color: ${colors.white};

  &:hover {
    box-shadow: none;
  }
`;
export const StyledRoomTile = styled.div`
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  &:hover {
    box-shadow: 0 0 25px rgba(123, 130, 147, 0.55);
  }
`;

export const Header = styled.div`
  padding: 40px;
  padding-bottom: 20px;
`;

export const Title = styled.h3`
  ${h3};
  margin: 0 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Info = styled.p`
  ${h5};
  margin: 0;
  color: ${colors.bronze};
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 220px;

  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;

    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);
  }
`;

export const StatusWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ApprovalsStatus = styled.p`
  ${h5};
  color: ${colors.grayMedium};
`;

export const Status = styled.p`
  margin: 0;
  margin-bottom: 10px;

  ${h5};
  align-self: flex-start;
  color: ${colors.grayDark};

  svg {
    margin: 0 10px 0 5px;
    color: ${colors.bronze};
  }
`;

export const Progress = styled.div<IProgressProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 8px;

  background-color: ${colors.grayLight};
  border-radius: 30px;

  &::after {
    content: '';
    position: absolute;

    width: ${({ progress }) => `${progress}%`};
    height: 100%;

    background-color: ${colors.grayMedium};
    border-radius: 30px;

    animation: appear 0.4s ease-out normal;
  }

  ${({ status }) =>
    applyCssIf(isRoomStatusOrdered(status))(css`
      &::after {
        background-color: ${colors.bronze};
      }
    `)};

  ${({ status }) =>
    applyCssIf(isRoomStatusPlanned(status))(css`
      &::after {
        background-color: ${colors.bronze};
      }
    `)};

  @keyframes appear {
    0% {
      width: 0;
    }
    100% {
      width: ${({ progress }) => `${progress}%`};
    }
  }
`;
