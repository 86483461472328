import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from '../Menu';
import { MenuItem } from '../MenuItem';
import { GearIcon, LogoutIcon } from '../../icons';
import { On } from '../../core/hooks/on';
import { useCurrentAccount } from '../../core/repositories/user-repository';
import { CError } from '../Error';
import { IconButtonStyled } from './Header.styled';
import { useSignOut } from '../../core/repositories/auth-repository';

export const AuthorizedUserMenu: React.FC = () => {
  const [signOut] = useSignOut();
  const history = useHistory();

  const handleLogout = React.useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut, history]);

  return On(
    ({ id: accountId }) => (
      <Menu anchorElement={<IconButtonStyled icon={'User'} isShow={true} />}>
        <MenuItem URL={`/accounts/${accountId}/projects`}>Projekte</MenuItem>
        <MenuItem URL={`/accounts/${accountId}/orders`}>Bestellungen</MenuItem>
        <MenuItem URL={`/accounts/${accountId}/profile`}>Profil</MenuItem>
        <MenuItem URL={`/accounts/${accountId}/settings`}>
          Account Einstellungen <GearIcon />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Ausloggen <LogoutIcon />
        </MenuItem>
      </Menu>
    ),
    () => <div />,
    error => <CError error={error} />,
    useCurrentAccount(),
  );
};
