import { LinkProps } from 'react-router-dom';

import { styled, css, applyCssIf, colors, h5 } from '../../../themes';
import media from '../../../utils/media';

interface IItemProps extends LinkProps {
  active: boolean;
  counted: boolean;
  counter?: number;
  hasIcon: boolean;
  hasCounter: boolean;
  disabled: boolean;
  width?: number;
}

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.button.attrs((props: IItemProps) =>
  props.to
    ? {}
    : {
        type: 'button',
      },
)<IItemProps>`
  position: relative;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  height: 70px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ counted }) =>
    counted
      ? ''
      : css`
          flex-direction: column;
        `};
  white-space: nowrap;
  ${h5};
  text-decoration: none;
  color: ${colors.black};
  border: none;
  background: none;
  transition: all 0.5s;
  counter-increment: item;

  &:not(:last-child) {
    margin-right: 60px;
  }

  &:disabled {
    color: ${colors.grayMedium};
  }
  ${props =>
    props.disabled
      ? css`
          color: ${colors.grayMedium};
        `
      : ''}

  ${({ counted }) =>
    applyCssIf(counted)(css`
      &:before {
        content: ${`counter(item) '. '`};
      }
    `)};

  ${({ active }) =>
    applyCssIf(active)(css`
      color: ${colors.bronze};
      background: white;
    `)};

  ${({ hasIcon }) =>
    applyCssIf(hasIcon)(css`
      height: 160px;
    `)};

  ${({ hasCounter, counter }) =>
    applyCssIf(hasCounter)(css`
      ::after {
        content: ${`'${counter}'`};
        position: absolute;
        right: 50px;
        top: 10px;
        transform: translate(100%, 0%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        ${h5};
        color: ${colors.white};
        background-color: ${colors.bronze};
        border-radius: 50%;
      }
    `)};

  @media (max-width: 1350px) {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  @media (max-width: 1250px) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 1200px) {
    &:before {
      content: '';
    }
  }
`;

export const Root = styled.div`
  display: flex;
  position: relative;
`;

export const Icon = styled.div`
  color: ${colors.black};
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  position: absolute;
  width: 10px;
  height: 3px;
  bottom: 0;
  background: ${colors.bronze};
  transition: all 0.5s;
`;
