import React from 'react';

import { Button, Container } from '../../../../lib/components';
import { AuthFormWindow, AuthStatus } from '../../../auth/containers/AuthFormWindow';
import {
  ButtonWrapper,
  Content,
  Description,
  FlexContainer,
  StyledMembershipBlock,
  Title,
} from './MembershipBlock.styled';

export const MembershipBlock: React.FC = () => {
  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState<boolean>(false);

  const handleShowAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  return (
    <StyledMembershipBlock>
      <Container>
        <FlexContainer>
          <Content>
            <Title>Jetzt anmelden und die Arbeitswelt von Morgen erleben.</Title>
            <Description>
              Die Profi-Designer von nod studios sorgen für trendige Bürokonzepte und beraten digital – bis zum
              individuellen Wunschbüro.
            </Description>
          </Content>
          <ButtonWrapper>
            <Button variant="contained" onClick={handleShowAuthFormWindow}>
              Mitglied werden
            </Button>
          </ButtonWrapper>
        </FlexContainer>
      </Container>

      {showAuthFormWindow && (
        <AuthFormWindow initialAuthStatus={AuthStatus.Registration} onClose={handleCloseAuthFormWindow} />
      )}
    </StyledMembershipBlock>
  );
};
