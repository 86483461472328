import React from 'react';
import { PageHeaderNormal, Button } from '../../lib/components';

import { TrialAccountExpiresStyled, Content, Title, Description, Buttons } from './TrialAccountExpires.styled';

export const TrialAccountExpires: React.FC = () => {
  return (
    <TrialAccountExpiresStyled>
      <PageHeaderNormal />
      <Content>
        <Title>Die Probezeit ist abgelaufen</Title>
        <Description>Upgrade plan to continue creating</Description>

        <Buttons>
          <Button variant="outlined" size="large" color="bronze" href="/">
            Nicht jetzt
          </Button>
          <Button variant="contained" size="large" color="bronze" href="/purchase-room-package">
            Premium freischalten
          </Button>
        </Buttons>
      </Content>
    </TrialAccountExpiresStyled>
  );
};
