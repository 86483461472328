import React from 'react';
import { BigPlusIcon } from '../../icons';

import { Root, IconWrapper } from './AddProjectButton.styled';
import { Typography } from '../../themes/typography/Typography';

interface IAddProjectButtonProps {
  onClick: () => void;
}

export const AddProjectButton: React.FC<IAddProjectButtonProps> = ({ onClick }) => {
  return (
    <Root data-hook-name="ProjectButton" onClick={onClick}>
      <IconWrapper>
        <BigPlusIcon />
      </IconWrapper>
      <Typography tag="span" variant="h3">
        Neue Arbeitswelten entdecken:
        <br />
        Neues Projekt starten.
      </Typography>
    </Root>
  );
};
