import { styled, css, applyCssIf } from '../../../themes';
import { is } from '../../../utils/fp';

import { Button } from '../../Button';
import { ArrowIcon } from '../../../icons';
import { NavigationButtonDirection } from './NavigationButton';

interface IDirectable {
  direction: NavigationButtonDirection;
}

const isBackDirection = is<NavigationButtonDirection>(direction => direction === 'back');

const isForwardDirection = is<NavigationButtonDirection>(direction => direction === 'forward');

export const Label = styled.span`
  width: 100%;
  text-align: center;
`;

export const Icon = styled(ArrowIcon)`
  width: 10px;
  height: 17px;
`;

export const StyledNavigationButton = styled(Button)<IDirectable>`
  ${({ direction }) => css`
    ${applyCssIf(isForwardDirection(direction))(css`
      ${Label} {
        padding-right: 20px;
      }
    `)}

    ${applyCssIf(isBackDirection(direction))(css`
      flex-direction: row-reverse;

      ${Label} {
        padding-left: 20px;
      }

      ${Icon} {
        transform: scaleX(-1);
      }
    `)}
  `}
`;
