import React from 'react';

import { CMSContent } from '../../lib/components/CMSContent/CMSContent';
import { Header } from '../../lib/components/Header';
import { Container } from '../../lib/components/Container';
import { PageFooter } from '../../lib/components/Page/PageFooter';

import { TermsStyled, Content } from './Terms.styled';

export const Terms: React.FC = () => (
  <TermsStyled>
    <Header />
    <Content>
      <Container>
        <CMSContent>
          <br />
          <br />
          <br />
          <h5>ALLGEMEINE GESCHÄFTSBEDINGUNGEN DER FURNISHED GMBH</h5>
          <p>01. März 2020</p>
          <ol>
            <li>
              {' '}
              <span>ALLGEMEINES</span>
              <ol>
                <li>
                  Die hier aufgeführten allgemeinen Geschäftsbedingungen („AGB“) gelten für die Vertragsbeziehung
                  zwischen der Furnished GmbH („FURNISHED“) und dem Kunden, gemeinsam „die Parteien“.
                </li>
                <li>
                  Gegenstand der Vertragsbeziehung sind die entgeltliche Überlassung/Übertrag beziehungsweise Verkauf
                  von Büroeinrichtung, Elektronikartikeln und die Erbringung von Serviceleistungen, die mit dem Verkauf
                  in Zusammenhang stehen.
                </li>
                <li>
                  Der Vertragsabschluss erfolgt auf Grundlage dieser AGB, sofern nicht besondere individuelle Abreden
                  getroffen werden. Abweichende oder ergänzende Bedingungen des Kunden werden nicht schon
                  Vertragsinhalt, wenn FURNISHED diesen nicht gesondert widerspricht.
                </li>
                <li>
                  Für Regelungen, die nicht Bestandteil dieser AGB sind, gelten nicht die allgemeinen
                  Geschäftsbedingungen des Kunden, sondern die gesetzlichen Regelungen, sofern nicht besondere
                  individuelle Abreden getroffen werden.
                </li>
                <li>
                  Individuelle Regelungen eines separaten Vertrages zwischen den Parteien, wie auch seine Anlagen, gehen
                  diesen AGB vor. Ergänzend gelten jedoch diese AGB.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>VERTRAGSPARTNER</span>
              <ol>
                <li>
                  FURNISHED richtet das Angebot von Waren und Dienstleistungen ausschließlich an Unternehmer (B2B
                  Kunden).
                </li>
                <li>
                  Ein Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige
                  Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder
                  selbständigen beruflichen Tätigkeit handelt, sowie Freiberufler, Behörden, Körperschaften des
                  öffentlichen Rechts und Vereine.
                </li>
                <li>Natürliche Personen, die als Unternehmer handeln, müssen das 18. Lebensjahr vollendet haben.</li>
                <li>
                  Sollte FURNISHED nach dem Zustandekommen des Vertrages Kenntnis davon erlangen, dass der Kunde kein
                  Unternehmer, sondern Verbraucher ist, kann FURNISHED innerhalb einer angemessenen Frist den Rücktritt
                  vom Vertrag erklären.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>VERTRAGSABSCHLUSS</span>
              <ol>
                <li>
                  Der Vertrag zwischen FURNISHED und dem Kunden kommt durch die Angebotsannahme des Kunden zustande.
                </li>
                <li>
                  Die durch FURNISHED im Portal angebotenen Waren und Dienstleistungen stellen ein Angebot auf Abschluss
                  eines Kaufvertrages dar.
                </li>
                <li>
                  Die Durchführung der Bestellung durch den Kunden ist für FURNISHED der Abschluss eines Kaufvertrages
                  zu den in der Bestellung genannten Bedingungen.
                </li>
                <li>
                  Die Bestätigung des Eingangs der Bestellung durch FURNISHED stellt noch keine Annahme des Angebotes
                  dar. 3.5Der Vertrag kommt durch die ausdrückliche{' '}
                </li>
                <li>
                  Annahmeerklärung durch FURNISHED gegenüber dem Kunden zustande oder wenn die Ware – ohne vorherige
                  ausdrückliche Annahmeerklärung – an den Kunden versendet wird.
                </li>
                <li>
                  Bei einer Teillieferung bezieht sich der Vertragsschluss ausschließlich auf den versandten Teil der
                  Bestellung.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>ANGABEN IM PORTAL</span>
              <ol>
                <li>
                  Die Waren werden in den im Portal angegebenen Verpackungseinheiten geliefert. Technische Änderungen,
                  wie auch Änderungen bei Form, Farbe und Gewichtseinheit der Verpackungseinheit bleiben im Rahmen des
                  Zumutbaren innerhalb handelsüblicher Grenzen vorbehalten.
                </li>
                <li>
                  Sollte sich eine fehlerhafte Preisauszeichnung oder eine nicht unwesentlich fehlerhafte
                  Artikelbeschreibung der im Portal angebotenen Waren und Dienstleistungen zeigen, sind FURNISHED und
                  der Kunde ungeachtet eines eventuell bestehenden gesetzlichen Anfechtungsrechtes berechtigt, vom
                  Vertrag zurückzutreten. Dieser Rücktritt ist unverzüglich nach Kenntnis von diesem Rücktrittsgrund
                  durch die jeweilige Partei zu erklären.
                </li>
                <li>
                  Preisangaben im Katalog verstehen sich als Nettopreise zuzüglich der jeweils geltenden gesetzlichen
                  Umsatzsteuer, es sei denn, der im Katalog angegebene Preis ist ausdrücklich als Bruttopreis kenntlich
                  gemacht.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>VORBEHALT LIEFERZEITEN</span>
              <ol>
                <li>
                  Die im Portal angegebenen Lieferzeiten beruhen auf den Angaben der Zulieferer und Hersteller von
                  FURNISHED und beziehen sich auf die Werktage von Montag bis Freitag. Sie sind als voraussichtliche
                  Zeiten zu verstehen und unverbindlich.
                </li>
                <li>
                  Bei Nichtverfügbarkeit von einzelnen Waren informiert FURNISHED den Kunden hierüber unverzüglich. In
                  diesem Fall kann der Kunde für die betroffenen Waren teilweise vom Vertrag zurücktreten und ist an die
                  Bestellung der betroffenen Waren nicht mehr gebunden.
                </li>
                <li>
                  Hat FURNISHED die Nichtverfügbarkeit der betroffenen Waren nicht zu vertreten, kann FURNISHED
                  innerhalb angemessener Zeit nach Kenntnis der Nichtverfügbarkeit von dem Vertrag zurücktreten.
                  FURNISHED kann alternativ dem Kunden zunächst die Lieferung einer nach Art und Güte vergleichbaren
                  Ware anstelle der ursprünglichen Ware anbieten. Stimmt der Kunde dieser Vertragsänderung innerhalb
                  einer angemessenen Frist seit Erhalt dieses Angebotes nicht zu, kann FURNISHED innerhalb angemessener
                  Zeit vom Vertrag zurücktreten. Bereits getätigte Gegenleistungen erstattet FURNISHED im Falle eines
                  Rücktritts unverzüglich.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>LIEFERUNG UND MONTAGE</span>
              <ol>
                <li>
                  FURNISHED arbeitet mit Logistikdienstleistern, im Rahmen der Leistungserfüllung durch Dritte,
                  zusammen, um dem Kunden eine einheitliche Lieferung und Montage zu bieten. Dabei sind Teillieferungen
                  auf Wunsch bzw. in Abstimmung mit dem Kunden möglich.
                </li>
                <li>
                  Leistungsort für die Lieferverpflichtung von FURNISHED ist der Ort des letzten Versandlagers der
                  bestellten Waren. Mit der Auslieferung der Waren am Leistungsort geht die Gefahr auf den Kunden über.
                </li>
                <li>
                  Die Waren werden von FURNISHED an den mit dem Kunden vereinbarten Standort geliefert und dort, falls
                  so bestellt, montiert. Möchte der Kunde bis zur finalen Lieferung den Standort nachträglich wechseln,
                  ist FURNISHED berechtigt, den Mehraufwand bei Lieferung und/oder Montage in Rechnung zu stellen.
                </li>
                <li>
                  Die für die Versendung der Ware anfallenden Kosten variieren je nach Umfang der Bestellung und ob der
                  Kunde Teillieferungen zugesendet bekommen möchte. Lieferkosten im FURNISHED Portal können unter
                  Vorbehalt angegeben werden, darauf weist FURNISHED den Kunden explizit hin. Die genauen Angaben der
                  Lieferkosten werden im Rahmen des Angebots durch FURNISHED berechnet und dem Kunden aufgezeigt.
                </li>
                <li>
                  Unterlässt der Kunde die genauen Angaben zur Lieferadresse bei der Bestellung, trägt er die
                  tatsächlich angefallenen zusätzlichen Mehrkosten der Anlieferung, falls es Abweichungen bei der
                  Auslieferung gibt.{' '}
                </li>
                <li>
                  Der Kunde verpflichtet sich, FURNISHED bei Erfüllung der vertraglichen Pflichten im notwendigen Umfang
                  kostenfrei zu unterstützen und alle in seiner Betriebssphäre zur ordnungsgemäßen Auftragsausführung
                  erforderlichen Voraussetzungen zu schaffen.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>MÄNGEL DER WAREN</span>
              <ol>
                <li>
                  Die Waren werden dem Kunden in neuem Zustand überlassen und sind frei von Mängeln, wenn sie im
                  Zeitpunkt der Überlassung der vertraglich vereinbarten Beschaffenheit entsprechen.
                </li>
                <li>
                  Die verschuldensunabhängige Haftung von FURNISHED bei anfänglichen Mängeln ist ausgeschlossen.
                  FURNISHED haftet insoweit nur bei grober Fahrlässigkeit oder Vorsatz.
                </li>
                <li>
                  Mängel sind vom Kunden unverzüglich (innerhalb von 7 Tagen) nach Lieferung festzustellen und
                  schriftlich bei FURNISHED anzuzeigen. Für durch verspätete Mängelrüge verursachte Schäden haftet der
                  Kunde gegenüber FURNISHED.
                </li>
                <li>
                  Unterlässt der Kunde die Anzeige, so gilt die Ware als genehmigt, es sei denn, dass es sich um einen
                  Mangel handelt, der bei der Untersuchung nicht erkennbar war.
                </li>
                <li>
                  Liegt ein Mangel vor, beseitigt FURNISHED den Mangel innerhalb angemessener Zeit nach eigener Wahl
                  durch Reparatur oder durch Lieferung einer neuen Sache. Liegt ein nur unerheblicher Mangel vor, der
                  den vertragsgemäßen Gebrauch der Kaufgegenstände nicht einschränkt, besteht kein Anspruch des Kunden
                  auf Reparatur oder Lieferung einer neuen Sache. Das Recht des Kunden zur Minderung bleibt unberührt.
                </li>
                <li>
                  Stellt sich heraus, dass ein Mangel trotz Meldung des Kunden nicht vorliegt, ist FURNISHED berechtigt,
                  die mit der versuchten Beseitigung in Verbindung stehenden Aufwände zu berechnen.
                </li>
                <li>
                  Die Ansprüche des Kunden wegen Mängeln verjähren 12 Monate nach Übergabe, es sei denn, FURNISHED hat
                  den Mangel arglistig verschwiegen. Dann gelten die gesetzlichen Verjährungsfristen.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>GEWÄHRLEISTUNG</span>
              <ol>
                <li>
                  Die Gewährleistungsfrist beträgt ein Jahr ab Ablieferung der Sache. Dies gilt nicht, wenn FURNISHED
                  den Mangel arglistig verschwiegen hat.
                </li>
                <li>
                  Im Gewährleistungsfall wird FURNISHED entweder den Mangel durch Nachbesserung beseitigen oder die
                  gelieferte mangelhaften Ware gegen eine mangelfreie Ware umtauschen.
                </li>
                <li>
                  Sind zwei Nachbesserungsversuche binnen jeweils angemessener Frist gescheitert, hat der Kunde das
                  Recht, den Kaufpreis zu mindern oder vom Vertrag zurückzutreten. Weitergehende Ansprüche des Kunden
                  sind ausgeschlossen.
                </li>
                <li>
                  Der Kunde hat die Möglichkeit, sich mit dem FURNISHED Kundensupport in Verbindung zu setzen, um sein
                  Gewährleistungsrecht in Anspruch zu nehmen, die individuelle Vorgehensweise abzustimmen und eine
                  zügige Durchführung sicherzustellen. Eine Rücksendung durch den Kunden und Annahme der Ware durch
                  FURNISHED führt nicht zur automatischen Anerkennung eines Gewährleistungsanspruches.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>HAFTUNG VON FURNISHED</span>
              <ol>
                <li>
                  Für Schäden, die von FURNISHED vorsätzlich oder grob fahrlässig herbeigeführt werden, haftet FURNISHED
                  unbeschränkt.
                </li>
                <li>
                  Die Haftung im Falle von Beschaffenheitsgarantien nach dem ProdHaftG sowie für Personenschäden, d. h.
                  für Verletzungen des Lebens, des Körpers oder Gesundheit und Freiheit gilt unbeschränkt. Davon umfasst
                  ist auch eine unbeschränkte Haftung für arglistig verschwiegene Mängel.
                </li>
                <li>
                  Bei leicht fahrlässiger Verletzung von wesentlichen Vertragspflichten (Kardinalpflichten) gilt die
                  Ersatzpflicht von FURNISHED uneingeschränkt. Wesentliche Vertragspflichten sind Pflichten, deren
                  Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht und auf deren Einhaltung der
                  Kunde regelmäßig vertrauen darf. Im Übrigen ist die Haftung von FURNISHED für leicht fahrlässig
                  verursachte Schäden ausgeschlossen.
                </li>
                <li>
                  Die vorstehenden Regelungen gelten entsprechend für die persönliche Haftung der gesetzlichen Vertreter
                  oder Personen, derer sich FURNISHED zur Erfüllung ihrer Verpflichtungen bedient.
                </li>
                <li>
                  Eine Änderung der gesetzlichen Beweislastregelungen ist mit den vorstehenden Regelungen nicht
                  verbunden.
                </li>
                <li>
                  Alle Ansprüche des Kunden, die sich aus dem vorliegenden Vertragsverhältnis ergeben, insbesondere
                  Ansprüche auf Schaden- oder Aufwendungsersatz verjähren in 12 Monaten nach Entstehen des Anspruchs. Im
                  Falle von Schäden an Leben, Körper, Gesundheit oder Freiheit einer Person, bei vorsätzlichem Handeln
                  oder wenn FURNISHED Mängel arglistig verschwiegen hat, sowie bei Ansprüchen aus dem
                  Produkthaftungsgesetz gelten die gesetzlichen Verjährungsfristen.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span> EIGENTUMSVORBEHALT</span>
              <ol>
                <li>
                  Bis zur Erfüllung sämtlicher Forderungen behält sich FURNISHED das Eigentum an der gelieferten Ware
                  vor.
                </li>
                <li>
                  Der Kunde ist nicht berechtigt, die unter Eigentumsvorbehalt stehenden Waren an Dritte zu verpfänden
                  oder zur Sicherheit zu übereignen. Erwirbt ein Dritter gleichwohl Rechte an der Ware, so tritt der
                  Kunde schon jetzt sämtliche ihm hierdurch entstehenden Rechte an FURNISHED ab. FURNISHED nimmt diese
                  Abtretung an. Der Kunde ist verpflichtet, FURNISHED unverzüglich schriftlich zu informieren, falls
                  eine Pfändung, Beschlagnahme oder sonstige Verfügung seitens eines Dritten erfolgt ist.
                </li>
                <li>
                  Hat der Kunde Forderungen aus der Weiterveräußerung der von FURNISHED gelieferten oder zu liefernden
                  Artikel bereits an Dritte abgetreten, insbesondere aufgrund echten oder unechten Factorings, oder
                  sonstige Vereinbarungen getroffen, die die derzeitigen oder künftigen Sicherungsrechte von FURNISHED
                  beeinträchtigen können, hat er FURNISHED darüber unverzüglich zu informieren. Im Falle eines unechten
                  Factorings ist FURNISHED berechtigt, vom Vertrag zurückzutreten und die Herausgabe bereits gelieferter
                  Artikel zu verlangen. Gleiches gilt im Falle eines echten Factorings, wenn der Kunde nach dem Vertrag
                  mit dem Factor nicht frei über den Kaufpreis der Forderung verfügen kann.
                </li>
                <li>
                  Der Eigentumsvorbehalt wird von FURNISHED freigegeben, sobald und soweit dessen realisierbarer Wert
                  die Forderung gegen den Kunden nachhaltig um mehr als 10 % übersteigt.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>ZAHLUNGSBEDINGUNGEN</span>
              <ol>
                <li>
                  FURNISHED bietet dem Kunden mehrere Zahlungsoptionen an, die der Kunde während des Bestellprozesses
                  auswählen kann.
                </li>
                <li>
                  Die Annahme der vom Kunden gewählten Zahlungsmethode durch FURNISHED steht unter dem Vorbehalt einer
                  Bonitätsprüfung des Kunden. FURNISHED ist berechtigt, vor der Lieferung die Bonität des Kunden zu
                  überprüfen und hierzu auf Auskunfteien, wie Schufa oder Creditreform, oder andere Auskunfteien in
                  Deutschland oder in dem Staat, in dem der Kunde seinen Sitz hat, zurückzugreifen. FURNISHED ist
                  berechtigt, nach unverzüglicher Information an den Kunden, die Lieferung an den Kunden nur gegen
                  Vorkasse zu tätigen.
                </li>
                <li>
                  Zahlungsforderungen sind sofort nach Rechnungsstellung ohne Abzug fällig, es sei denn, die Parteien
                  haben schriftlich etwas anderes vereinbart.
                </li>
                <li>
                  Bei Eintritt von Zahlungsverzug oder bei Tatsachen, welche Zweifel an der Zahlungsfähigkeit oder
                  Zahlungsbereitschaft des Kunden begründen, werden sämtliche Forderungen einschließlich solcher, für
                  die FURNISHED dem Kunden Zahlungsziele eingeräumt hat, sofort zur Zahlung fällig.
                </li>
                <li>
                  Bei Zahlung des Kunden aus einem anderen Land als Deutschland hat der Kunde sämtliche Kosten zu
                  tragen, die für die Transferierung des vollständigen Betrages auf das Konto von FURNISHED entstehen.
                  Ebenso gehen Kosten zu Lasten des Kunden, die FURNISHED wegen unberechtigten Nichtausgleichs von
                  Zahlungsforderungen oder der Insolvenz des Kunden entstehen.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>GUTSCHEINE</span>
              <ol>
                <li>Pro Kunde und Bestellung kann nur ein Gutschein im FURNISHED Portal eingelöst werden.</li>
                <li>Der Gutscheinwert versteht sich inklusive der jeweils geltenden gesetzlichen Umsatzsteuer.</li>
                <li>
                  Ist der Bestellwert geringer als der Gutscheinwert, verfällt der Differenzbetrag. Der Gutscheinwert
                  wird nicht auf Versandkosten angerechnet. Der Kunde kann die Rechnungssumme nicht selbstständig um den
                  Gutscheinwert kürzen.
                </li>
                <li>
                  Gutscheine werden nur bei Onlinebestellungen akzeptiert. Eine Berücksichtigung des Gutscheinwertes
                  nach Abschluss einer Online-Bestellung ist ausgeschlossen.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>RÜCKGABE</span>
              <ol>
                <li>
                  Eine Rückgabe (Rücknahme oder Umtausch), auf die kein gesetzlicher und/oder kein vereinbarter
                  Rechtsanspruch besteht, ist ausgeschlossen. Werden bei einzelnen Waren Rückgaberechte ausgewiesen,
                  gehen diese diesen AGB vor.
                </li>
                <li>
                  Die Gefahr des Verlusts oder der Qualitätsverschlechterung der vom Kunden zurückgesandten Ware trägt
                  der Kunde bis er die Ware an einen von FURNISHED benannten Lieferpartner übergeben hat.
                </li>
                <li>
                  Die Annahme der rückgesandten Ware durch FURNISHED führt nicht zu einer Akzeptanz des
                  Rückgabeverlangens des Kunden.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>ELEKTRONISCHE ZUGANGSDATEN UND KOMMUNIKATION</span>
              <ol>
                <li>
                  Der Kunde ist damit einverstanden, dass die vertragsbezogene Kommunikation in Textform wie
                  beispielsweise per E-Mail erfolgen kann, es sei denn zwingend anzuwendende gesetzliche Vorschriften
                  erfordern eine andere Form der Kommunikation.
                </li>
                <li>
                  Der Kunde ist für die Sicherstellung der Vertraulichkeit seiner Zugangsdaten für das FURNISHED Portal
                  selbst verantwortlich.
                </li>
                <li>Der Kunde ist für die Aktivitäten innerhalb seines FURNISHED Zugangs selbst verantwortlich.</li>
                <li>
                  Der Kunde hat FURNISHED unverzüglich zu informieren, sobald der Verdacht besteht, dass ein
                  unberechtigter Dritter Kenntnis von den Zugangsdaten erlangt oder die Zugangsdaten unautorisiert
                  genutzt hat oder dies zu erwarten ist.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <span>SCHLUSSBESTIMMUNGEN</span>
              <ol>
                <li>
                  FURNISHED ist berechtigt, diese AGB mit Wirkung in die Zukunft zu ändern oder anzupassen. FURNISHED
                  wird dem Kunden die geänderten AGB vor dem geplanten Inkrafttreten in Textform übermitteln und auf die
                  Neuregelungen sowie das Datum des Inkrafttretens besonders hinweisen. Die Änderungen gelten als
                  genehmigt, wenn der Kunde diesen nicht schriftlich oder in Textform widerspricht. FURNISHED wird auf
                  diese Regelung im Mitteilungsschreiben besonders hinweisen. Der Widerspruch muss innerhalb von sechs
                  Wochen nach Zugang der Mitteilung eingegangen sein. Widerspricht der Kunde einer Änderung, kann
                  FURNISHED von einer Fortführung des Vertragsverhältnisses Abstand nehmen. Dieser Änderungsmechanismus
                  gilt nicht für Änderungen der vertraglichen Hauptleistungspflichten der Parteien.
                </li>
                <li>
                  Sollte eine Bestimmung des geschlossenen Vertrages oder dieser AGB unwirksam sein oder werden oder
                  eine Lücke enthalten, so bleibt die Rechtswirksamkeit der übrigen Bestimmungen davon unberührt.
                </li>
                <li>
                  Nebenabreden und Änderungen dieses Vertrages bedürfen der Schriftform. Dies gilt auch für die
                  Aufhebung des Schriftformerfordernisses.
                </li>
                <li>
                  Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des Deutschen
                  internationalen Privatrechtes, insbesondere seiner Kollisionsnormen und des UN-Kaufrechts (CISG).
                  Ausschließlicher Gerichtsstand für alle aus diesem Vertragsverhältnis sich ergebenden Streitigkeiten
                  ist Berlin.
                </li>
                <li>
                  FURNISHED ist ungeachtet vorstehender Regelung berechtigt, an jedem gesetzlich zulässigen allgemeinen
                  Gerichtsstand zu klagen.
                </li>
              </ol>
            </li>
          </ol>
        </CMSContent>
      </Container>
    </Content>
    <PageFooter />
  </TermsStyled>
);
