import React from 'react';

export const PhoneBoothIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="56" height="64" viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0682 6.8875C23.1336 8.00443 22.4708 10.4782 23.5877 12.4127L27.8163 19.7369C28.9332 21.6715 31.407 22.3343 33.3415 21.2174C33.5371 21.1045 33.6041 20.8543 33.4912 20.6587L31.6984 17.5535C30.4731 18.0971 29.0099 17.6393 28.3246 16.4523L26.8049 13.8201C26.1197 12.6332 26.4548 11.1371 27.5382 10.3477L25.6268 7.03719C25.5139 6.84159 25.2638 6.77457 25.0682 6.8875ZM22.7217 12.9127C21.3286 10.4999 22.1553 7.41455 24.5682 6.02147C25.2421 5.6324 26.1038 5.86329 26.4928 6.53719L28.5389 10.081C28.7365 10.4233 28.6192 10.8609 28.277 11.0585C27.4851 11.5157 27.2138 12.5283 27.671 13.3201L29.1907 15.9523C29.6479 16.7442 30.6604 17.0155 31.4523 16.5583C31.7945 16.3607 32.2322 16.478 32.4298 16.8202L34.3572 20.1587C34.7463 20.8326 34.5154 21.6943 33.8415 22.0834C31.4287 23.4765 28.3433 22.6498 26.9503 20.2369L22.7217 12.9127Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 1H6C3.23858 1 1 3.23858 1 6V58C1 60.7614 3.23858 63 6 63H50C52.7614 63 55 60.7614 55 58V6C55 3.23858 52.7614 1 50 1ZM6 0C2.68629 0 0 2.68629 0 6V58C0 61.3137 2.68629 64 6 64H50C53.3137 64 56 61.3137 56 58V6C56 2.68629 53.3137 0 50 0H6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42 0C42.2761 0 42.5 0.223858 42.5 0.5V63.5C42.5 63.7761 42.2761 64 42 64C41.7239 64 41.5 63.7761 41.5 63.5V0.5C41.5 0.223858 41.7239 0 42 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 0C14.2761 0 14.5 0.223858 14.5 0.5V63.5C14.5 63.7761 14.2761 64 14 64C13.7239 64 13.5 63.7761 13.5 63.5V0.5C13.5 0.223858 13.7239 0 14 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 38C36.5523 38 37 37.5523 37 37C37 36.4477 36.5523 36 36 36C35.4477 36 35 36.4477 35 37C35 37.5523 35.4477 38 36 38ZM36 39C37.1046 39 38 38.1046 38 37C38 35.8954 37.1046 35 36 35C34.8954 35 34 35.8954 34 37C34 38.1046 34.8954 39 36 39Z"
      fill="currentColor"
    />
  </svg>
);
