import React from 'react';

export const TableLampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="29" height="46" viewBox="0 0 29 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.1 19.8998L22.2 1.2998C22.1 0.999805 21.9 0.799805 21.6 0.799805H7.3C7 0.799805 6.79999 0.999805 6.69999 1.2998L0.799997 19.8998C0.699997 20.0998 0.799996 20.2998 0.899996 20.4998C0.999996 20.6998 1.2 20.7998 1.4 20.7998H13.8V32.8998C7.5 33.1998 2.4 38.4998 2.4 44.8998C2.4 45.2998 2.69999 45.4998 3.09999 45.4998H25.8C26.2 45.4998 26.4 45.1998 26.4 44.8998C26.4 38.4998 21.4 33.2998 15 32.8998V20.6998H27.4C27.6 20.6998 27.8 20.5998 27.9 20.3998C28.2 20.2998 28.2 20.0998 28.1 19.8998ZM25.2 44.0998H3.8C4.1 38.4998 8.79999 33.9998 14.5 33.9998C20.2 34.0998 24.9 38.4998 25.2 44.0998ZM2.4 19.3998L7.9 2.0998H21.2L26.7 19.3998H2.4Z"
      fill="currentColor"
    />
  </svg>
);
