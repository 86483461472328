import { styled, h5 } from '../../../lib/themes';

export const Content = styled.div``;

export const Message = styled.p`
  ${h5};
  margin-bottom: 30px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin: 0 auto;
`;
