import { styled } from '../../../../../../../lib/themes';

export const Root = styled.div`
  max-width: calc(((100vw - 1170px) / 2) + 1170px);
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow-x: scroll;
  padding-bottom: 50px;
  cursor: grab;
  :active {
    cursor: grabbing;
  }
`;
export const ScrollHide = styled.div`
  width: 100%;
  overflow: hidden;
  height: 180px;
  border-bottom: 1px solid #e0e0e0;
`;
