import { DocumentNode, ExecutionResult } from 'graphql';
import { GraphQLError } from 'graphql/error/GraphQLError';
import {
  ApolloQueryResult,
  MutationOptions,
  ObservableQuery,
  OperationVariables,
  QueryOptions,
  WatchQueryOptions,
} from '@apollo/client';

import { CoreError } from '../errors/core-error';
import { GqlError, ServiceUnavailable, TrialPeriodExpiredError } from '../errors/gql-error';
import { ManageableStore } from '../manageable-store';
import { NetworkError } from '../errors/network-error';
import { RequireApolloStore, RequireLog } from '../available-stores';
import { routeStore } from '../../router';

/**
 * This is some syntax sugar for all api stores
 * Basically, class is responsible for processing query/mutation results
 * and transforming it into eatable form
 */
export abstract class ApiRepository<
  TQueries,
  TMutates,
  T extends RequireApolloStore & RequireLog = RequireApolloStore & RequireLog,
> extends ManageableStore<T> {
  // cache for watching queries.
  // The first map key is Query (DocumentNode),
  // The second map key(string) is serialized variables of the same queries
  private watchQueries = new Map<DocumentNode, Map<string, ObservableQuery<any, any>>>();

  protected get apolloStore() {
    return this._mainStore.get('ApolloStore');
  }

  /**
   * Run a graphql query to API
   * @param {DocumentNode} query - query document
   * @param {keyof TQueries} resultPropertyName - name of property in result than contained real result
   * @param {TVariables} variables
   * @param options
   * @return {Promise<TResult>}
   */
  protected query<TResult, TVariables>(
    query: DocumentNode,
    resultPropertyName: keyof TQueries,
    variables?: TVariables,
    options?: Partial<QueryOptions<TVariables>>,
  ): Promise<TResult> {
    return this.apolloStore
      .query<TResult, TVariables>({
        ...options,
        query,
        variables,
      })
      .then(result => this.processQueryResult<TResult>(result, resultPropertyName))
      .catch(error => {
        this._mainStore.get('Log').error(error);
        ApiRepository.processFetchError(error);
        throw error;
      });
  }

  /**
   * This is special watch query, which is mostly helper for React hooks
   * You may use it directly in ReactComponents, but then you have to take care
   * on changes subscription, unsubscription
   * @param query
   * @param variables
   * @param options - additional apollo options
   */
  protected watchQuery<TResult, TVariables>(
    query: DocumentNode,
    variables?: TVariables,
    options?: Partial<WatchQueryOptions<TVariables>>,
  ): ObservableQuery<TResult, TVariables> {
    const cachedWatchQuery = this.getCachedWatchQuery<TResult, TVariables>(query, variables);
    if (cachedWatchQuery) {
      return cachedWatchQuery;
    }

    const watchQuery = this.apolloStore.watchQuery<TResult, TVariables>({ ...options, query, variables });
    this.setCachedWatchQuery(query, variables, watchQuery);
    return watchQuery;
  }

  /**
   * Run a graphql mutation on API
   * @param {DocumentNode} mutation
   * @param {keyof TMutates} resultPropertyName - resultPropertyName - name of property
   * in result than contained real result
   * @param {TVariables} variables
   * @param options - additional mutate options
   * @return {Promise<TResult>}
   */
  protected mutate<TResult, TVariables extends OperationVariables = OperationVariables>(
    mutation: DocumentNode,
    resultPropertyName: keyof TMutates,
    variables: TVariables,
    options?: Partial<MutationOptions<TResult, TVariables>>,
  ): Promise<TResult> {
    return this.apolloStore
      .mutate<TResult, TVariables>({
        mutation,
        variables,
        ...options,
      })
      .then(result => this.processQueryResult<TResult>(result, resultPropertyName))
      .catch(error => {
        this._mainStore.get('Log').error(error);
        ApiRepository.processFetchError(error);
        throw error;
      });
  }

  /**
   * This is helper function, not supposed to be used outside core
   * @param result
   * @param resultPropertyName
   */
  public static processQueryResultS<TResult, TQueriesMutations>(
    result: ApolloQueryResult<TResult> | ExecutionResult<TResult>,
    resultPropertyName: keyof TQueriesMutations,
  ): TResult {
    const { data, errors } = result;

    if (data) {
      if ((data as any)[resultPropertyName]) {
        return (data as any)[resultPropertyName] as TResult;
      }
      throw new CoreError(`Returned data have no ${resultPropertyName} property`);
    }

    if (errors) {
      ApiRepository.processFetchError({ graphQLErrors: errors } as any);
    }

    throw new Error('This error should never happens.');
  }

  /**
   * This function is the common entry point to process any errors that
   * happen in query or mutation
   * @param error
   */
  public static processFetchError(error: {
    readonly graphQLErrors: GraphQLError[];
    readonly networkError?: any | null;
  }) {
    const { graphQLErrors, networkError } = error;
    let errors = graphQLErrors;

    if (networkError) {
      if (networkError.result && networkError.result.errors) {
        errors = networkError.result.errors;
      } else {
        throw new NetworkError();
      }
    }

    if (errors && errors.length) {
      const firstError = errors[0];
      const code = firstError.extensions ? (firstError.extensions.code as string) : 'Unknown';

      const gqlError = GqlError.getGqlError(code, firstError.message, errors);

      if (gqlError instanceof ServiceUnavailable) {
        routeStore.history.push('/maintenance');
        return;
      }

      if (gqlError instanceof TrialPeriodExpiredError) {
        routeStore.history.push('/trial-account-expires');
        return;
      }

      throw gqlError;
    }
  }

  private processQueryResult<TResult>(
    result: ApolloQueryResult<TResult> | ExecutionResult<TResult>,
    resultPropertyName: keyof TMutates | keyof TQueries,
  ): TResult {
    return ApiRepository.processQueryResultS<TResult, TQueries & TMutates>(result, resultPropertyName);
  }

  private getCachedWatchQuery<TResult, TVariables>(
    query: DocumentNode,
    variables?: TVariables,
  ): ObservableQuery<TResult, TVariables> | undefined {
    const queryMap = this.watchQueries.get(query);
    if (!queryMap) {
      return undefined;
    }

    return queryMap.get(this.getVariablesKey(variables));
  }

  private setCachedWatchQuery(query: DocumentNode, variables: any, watchQuery: ObservableQuery<any, any>) {
    let queryMap = this.watchQueries.get(query);
    if (!queryMap) {
      queryMap = new Map<string, ObservableQuery<any, any>>();
      this.watchQueries.set(query, queryMap);
    }

    queryMap.set(this.getVariablesKey(variables), watchQuery);
  }

  /**
   * Because variables are often created on-the-fly
   * We cannot compare just objects, we need to convert them into strings
   * in order not to do deep compare each time
   * @param variables
   */
  private getVariablesKey(variables: any): string {
    return JSON.stringify(variables);
  }

  clear() {
    this.watchQueries = new Map<DocumentNode, Map<string, ObservableQuery<any, any>>>();
  }
}
