import { styled, css, applyCssIf, colors } from '../../../themes';
import { StyledButton } from '../../Button/Button.styled';
import { TitleStyled } from '../../Title/Title.styled';
import { StyledImage } from '../../Image/Image.styled';

export const FadeGradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 14.79%, rgba(0, 0, 0, 0) 43.98%);
`;

export const CoverWrapper = styled.div`
  position: relative;

  ${StyledButton} {
    display: none;
  }

  :hover {
    ${StyledButton} {
      display: inline-flex;
    }

    ${FadeGradient} {
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.56) 14.79%, rgba(0, 0, 0, 0) 43.98%);
    }
  }
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */

  &:active,
  &:checked,
  &:focus {
    & ~ ${TitleStyled} {
      color: ${colors.bronze};
    }

    & ~ ${CoverWrapper} {
      &::after {
        content: '';

        position: absolute;
        left: -13px;
        top: -13px;

        pointer-events: none;

        width: calc(100% + 20px);
        height: calc(100% + 20px);

        border: 3px solid ${colors.bronze};
      }
    }
  }
`;

export const Container = styled.label`
  position: relative;

  display: inline-flex;
  flex-direction: column;

  cursor: pointer;

  ${StyledImage} {
    width: 255px;
    height: 255px;
  }

  ${TitleStyled} {
    margin-bottom: 45px;
  }

  ${StyledButton} {
    position: absolute;
    left: 50%;
    bottom: 20px;

    width: 215px;
    min-width: 215px;

    background-color: transparent;
    transform: translateX(-50%);

    &,
    &:hover,
    &:active {
      color: ${colors.white};
      border-color: ${colors.white};
    }
  }
`;
