import React from 'react';

export const CoffeeTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="48" height="17" viewBox="0 0 48 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M45.6 0H2.29999C0.999988 0 0 1 0 2.3V5.60001C0 6.90001 0.999988 7.90001 2.29999 7.90001H5L2 16.3C2 16.7 2.20004 17 2.60004 17H2.70001C3.00001 17 3.29999 16.8 3.29999 16.4L6.29999 7.90001H41.6L44.6 16.4C44.6 16.7 44.9 17 45.2 17H45.3C45.7 17 45.9 16.6 45.9 16.3L42.9 7.90001H45.6C46.9 7.90001 47.9 6.90001 47.9 5.60001V2.3C47.9 1.1 46.9 0 45.6 0ZM46.6 5.70001C46.6 6.30001 46.1 6.70001 45.6 6.70001H2.29999C1.69999 6.70001 1.29999 6.20001 1.29999 5.70001V2.40001C1.29999 1.80001 1.79999 1.40001 2.29999 1.40001H45.6C46.2 1.40001 46.6 1.90001 46.6 2.40001V5.70001Z"
      fill="currentColor"
    />
  </svg>
);
