import { gql } from '@apollo/client';

export const gqlProjectDocs = {
  getProject: gql`
    query project($id: String!) {
      project(id: $id) {
        id
        name
        accountId
        rooms {
          id
          name
          moodboard {
            images {
              url
            }
          }
          projectId
          total {
            originalTotalPrice
            originalTotalPriceInclTax
            discountAmount
            taxAmount
            totalPrice
            totalPriceInclTax
            totalItems
          }
          planMode
          items {
            id
          }
          roomStatus
          approvals {
            approvalStatus
          }
        }
      }
    }
  `,
  createProject: gql`
    mutation project($name: String!, $accountId: String!) {
      createProject(name: $name, accountId: $accountId) {
        id
        name
        accountId
        rooms {
          id
          name
        }
      }
    }
  `,
  deleteProject: gql`
    mutation deleteProject($projectId: String!) {
      deleteProject(projectId: $projectId)
    }
  `,
};
