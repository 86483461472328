import React from 'react';

import { CrossIcon } from '../../icons';
import { StyledCard, Title, CloseButton } from './Card.styled';
import { noop } from '../../utils/placeholders';
import { ButtonColor } from '../Button';

export interface ICardProps {
  title?: string;
  onClose?: () => unknown;
  hasCloseButton?: boolean;
  children?: React.ReactNode | null;
  className?: string;
  colorButton?: ButtonColor;
  width?: string;
  height?: string;
  noPadding?: boolean;
}

export const Card: React.FC<ICardProps> = props => {
  const {
    children,
    title = '',
    hasCloseButton = true,
    onClose = noop,
    className = '',
    colorButton = 'bronze',
    width = '600px',
    height = 'auto',
    noPadding = false,
  } = props;

  return (
    <StyledCard className={className} width={width} height={height} noPadding={noPadding}>
      {title && <Title hasCloseButton={hasCloseButton}>{title}</Title>}
      {hasCloseButton && (
        <CloseButton onClick={onClose} color={colorButton}>
          <CrossIcon />
        </CloseButton>
      )}
      {children}
    </StyledCard>
  );
};
