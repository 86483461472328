import React from 'react';
import { useField, useFormikContext } from 'formik';

import { Checkbox } from '../../Checkbox';

interface IFormCheckboxProps {
  name: string;
  disabled?: boolean;
  className?: string;
}

export const FormCheckbox: React.FC<IFormCheckboxProps> = props => {
  const [formikProps, meta] = useField<boolean>(props.name);
  const joinedProps = { ...props, ...formikProps };
  const isError = Boolean(meta.error) && meta.touched;

  const { setFieldValue } = useFormikContext<any>();

  const handleChange = React.useCallback(
    (isChecked: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(props.name, isChecked);
    },
    [props.name, setFieldValue],
  );

  return <Checkbox {...joinedProps} onChange={handleChange} error={isError ? meta.error : ''} />;
};
