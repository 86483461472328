import React, { useCallback, useEffect, useState } from 'react';

import { Solution } from '../../hooks/products-hooks';
import { On } from '../../../../../../../lib/core/hooks/on';
import { CError } from '../../../../../../../lib/components/Error';
import { useSearchProducts } from '../../../../../../../lib/core/repositories/search-products-repository';
import { ProductConfiguration, ProductPage } from '../../../../../../../lib/core/api/generated';
import { Pagination } from '../../../../../../../lib/components/Pagination';
import { CatalogProductList } from './CatalogProductList';

import { PaginationWrapper, Wrapper } from './CatalogProducts.styled';

interface ICatalogProductsProps {
  solution: Solution;
  roomProducts: string[];
  onSelect: (productConfiguration: ProductConfiguration, amount: number) => unknown;
}

export const CatalogProducts: React.FC<ICatalogProductsProps> = ({ solution, onSelect, roomProducts }) => {
  const [pageNumber, setPage] = useState(1);
  const itemsPerPage = 9;

  const handleSetPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [solution]);

  return On(
    ({ result: productConfigurations, page, pageSize, total, count, hasNext }: ProductPage) => {
      return (
        <Wrapper>
          <CatalogProductList
            productConfigurations={productConfigurations}
            onSelect={onSelect}
            roomProducts={roomProducts}
          />

          <PaginationWrapper>
            <Pagination initialValue={pageNumber} pages={Math.ceil(total / itemsPerPage)} onChange={handleSetPage} />
          </PaginationWrapper>
        </Wrapper>
      );
    },
    () => {
      return <div>Loading...</div>;
    },
    error => <CError error={error} />,

    useSearchProducts(solution.id, pageNumber, itemsPerPage),
  );
};
