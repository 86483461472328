import React from 'react';

export const NightStandIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.9 11.9996H25.2C25.3 11.7996 25.3 11.5996 25.1 11.3996L23.9 9.6996C24.5 8.8996 24.9 7.9996 24.9 6.8996C24.9 6.4996 24.8 6.19961 24.8 5.79961C24.9 5.89961 25.1 5.99961 25.3 5.99961C25.5 5.99961 25.6 5.89961 25.8 5.79961C26.9 4.69961 26.9 2.89961 25.8 1.79961C24.7 0.699609 22.9 0.699609 21.8 1.79961C21.6 1.99961 21.6 2.3996 21.8 2.6996C21.5 2.5996 21.1 2.49961 20.7 2.49961C20.3 2.49961 20 2.5996 19.6 2.6996C19.8 2.3996 19.8 2.09961 19.6 1.79961C18.5 0.699609 16.7 0.699609 15.6 1.79961C14.5 2.89961 14.5 4.69961 15.6 5.79961C15.7 5.89961 15.9 5.99961 16.1 5.99961C16.3 5.99961 16.4 5.89961 16.6 5.79961C16.5 6.09961 16.5 6.4996 16.5 6.8996C16.5 7.9996 16.9 8.8996 17.5 9.6996L16.3 11.3996C16.2 11.5996 16.2 11.7996 16.2 11.9996H1.49999C1.09999 11.9996 0.899994 12.2996 0.899994 12.5996V27.9996V43.3996C0.899994 43.7996 1.19999 43.9996 1.49999 43.9996H3.99999V47.0996C3.99999 47.4996 4.29999 47.6996 4.59999 47.6996H36.9C37.3 47.6996 37.5 47.3996 37.5 47.0996V43.9996H40C40.4 43.9996 40.6 43.6996 40.6 43.3996V27.9996V12.5996C40.5 12.2996 40.2 11.9996 39.9 11.9996ZM24.7 2.79961C25.1 3.19961 25.3 3.8996 25.1 4.3996L23.1 2.3996C23.7 2.1996 24.3 2.39961 24.7 2.79961ZM23.6 6.99961C23.6 8.69961 22.2 10.0996 20.5 10.0996C18.8 10.0996 17.4 8.69961 17.4 6.99961C17.4 5.29961 18.8 3.8996 20.5 3.8996C22.2 3.8996 23.6 5.29961 23.6 6.99961ZM15.9 4.3996C15.7 3.8996 15.8 3.19961 16.3 2.79961C16.6 2.49961 17 2.29961 17.4 2.29961C17.6 2.29961 17.8 2.2996 17.9 2.3996L15.9 4.3996ZM18.2 10.5996C18.9 10.9996 19.7 11.2996 20.6 11.2996C21.5 11.2996 22.3 10.9996 23 10.5996L24 11.9996H17.3L18.2 10.5996ZM1.89999 13.2996H39.3V27.3996H1.89999V13.2996ZM36 46.5996H4.99999V44.0996H36V46.5996ZM39.2 42.8996H36.7H4.39999H1.89999V28.7996H39.3V42.8996H39.2Z"
      fill="currentColor"
    />
    <path
      d="M20.5 16.8994C19.3 16.8994 18.3 17.8994 18.3 19.0994C18.3 20.2994 19.3 21.2994 20.5 21.2994C21.7 21.2994 22.7 20.2994 22.7 19.0994C22.7 17.8994 21.7 16.8994 20.5 16.8994ZM20.5 19.8994C20 19.8994 19.6 19.4994 19.6 18.9994C19.6 18.4994 20 18.0994 20.5 18.0994C21 18.0994 21.4 18.4994 21.4 18.9994C21.4 19.5994 21 19.8994 20.5 19.8994Z"
      fill="currentColor"
    />
    <path
      d="M20.5 36.6998C21.7 36.6998 22.7 35.6998 22.7 34.4998C22.7 33.2998 21.7 32.2998 20.5 32.2998C19.3 32.2998 18.3 33.2998 18.3 34.4998C18.4 35.6998 19.3 36.6998 20.5 36.6998ZM20.5 33.5998C21 33.5998 21.4 33.9998 21.4 34.4998C21.4 34.9998 21 35.3998 20.5 35.3998C20 35.3998 19.6 34.9998 19.6 34.4998C19.7 33.9998 20 33.5998 20.5 33.5998Z"
      fill="currentColor"
    />
  </svg>
);
