import React from 'react';

export const OneSeatSofaIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="45" height="42" viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M44.6 31.9002V11.5002C44.6 11.1002 44.3 10.9002 43.9 10.9002H36.9V1.3002C36.9 0.900196 36.6 0.700195 36.2 0.700195H9.30002C8.90002 0.700195 8.60004 1.0002 8.60004 1.3002V10.9002H1.60004C1.20004 10.9002 0.900024 11.2002 0.900024 11.5002V31.9002C0.900024 32.3002 1.20004 32.5002 1.60004 32.5002H7.90002L1.10004 40.5002C0.900037 40.8002 0.900043 41.2002 1.20004 41.4002C1.30004 41.5002 1.50004 41.6002 1.60004 41.6002C1.80004 41.6002 2.00004 41.5002 2.10004 41.4002L9.60004 32.6002H36L43.5 41.4002C43.6 41.6002 43.8 41.6002 44 41.6002C44.1 41.6002 44.3 41.5002 44.4 41.4002C44.7 41.2002 44.7 40.8002 44.5 40.5002L37.7 32.5002H44C44.3 32.5002 44.6 32.2002 44.6 31.9002ZM10 1.9002H35.6V22.9002H10V1.9002ZM43.3 31.2002H2.40002V12.2002H8.80002V23.7002C8.80002 24.1002 9.10003 24.3002 9.50003 24.3002H36.4C36.8 24.3002 37.1 24.0002 37.1 23.7002V12.2002H43.5V31.2002H43.3Z"
      fill="currentColor"
    />
  </svg>
);
