import { colors, styled } from '../../../../lib/themes';
import { Link } from '../../../../lib/router/link';

export const Root = styled.header`
  height: 80px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  z-index: 101;
`;

export const HeaderUserWrapper = styled.div`
  position: absolute;
  height: 80px;
  top: 0;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackHomeLink = styled(Link)`
  margin-right: 40px;
  line-height: 1;
  color: ${colors.bronze};
`;
