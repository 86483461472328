import React from 'react';

export const EnvelopeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="400" height="293" viewBox="0 0 400 293" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M400 0H0V293H400V0Z" fill="url(#paint0_radial)" />
    <rect x="64" y="86.475" width="272" height="206.432" fill="url(#pattern0)" />
    <path
      d="M292 148.844C271.76 163.627 231.187 193.34 216 204.474C210.067 192.488 204.334 180.501 196.907 169.407C186.14 153.396 172.093 139.85 155.694 129.666C140.451 120.151 123.948 112.817 106.667 107.877C105.52 107.558 84.4404 103.083 84.4404 103.376V247.598C84.4404 253.024 86.5981 258.227 90.4389 262.063C94.2796 265.9 99.4888 268.055 104.92 268.055H295.147C297.837 268.055 300.5 267.526 302.984 266.498C305.469 265.47 307.727 263.963 309.629 262.063C311.53 260.164 313.039 257.909 314.068 255.427C315.097 252.945 315.627 250.285 315.627 247.598V117.44C315.627 131.517 302.28 141.346 292 148.844Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M185.853 29.6197L84.373 103.402V230.471H315.626V117.52C315.625 113.621 314.711 109.776 312.956 106.293C311.201 102.81 308.654 99.7861 305.52 97.4625L214.146 29.6197C210.056 26.5839 205.096 24.9447 200 24.9447C194.904 24.9447 189.943 26.5839 185.853 29.6197Z"
      fill="#FFDBD5"
    />
    <path
      d="M263.854 257.906H136.16C132.27 257.906 128.54 256.363 125.789 253.616C123.039 250.868 121.493 247.142 121.493 243.256L121.493 58.0272C121.493 55.1591 122.634 52.4084 124.665 50.3803C126.695 48.3522 129.449 47.2129 132.32 47.2129L267.68 47.2129C270.552 47.2129 273.305 48.3522 275.336 50.3803C277.366 52.4084 278.507 55.1591 278.507 58.0272V243.256C278.507 247.142 276.962 250.868 274.211 253.616C271.461 256.363 267.73 257.906 263.84 257.906H263.854Z"
      fill="white"
    />
    <path
      d="M215.84 158.486H133.24C132.88 158.486 132.573 158.02 132.573 157.448V154.065C132.573 153.492 132.88 153.026 133.24 153.026H215.84C216.2 153.026 216.493 153.492 216.493 154.065V157.448C216.493 157.967 216.2 158.486 215.84 158.486Z"
      fill="#E7EAEC"
    />
    <path
      d="M223.32 169.354H133.534C133.27 169.34 133.021 169.224 132.842 169.03C132.663 168.836 132.566 168.579 132.574 168.315V164.932C132.566 164.668 132.663 164.412 132.842 164.218C133.021 164.024 133.27 163.907 133.534 163.894H223.32C223.452 163.899 223.581 163.93 223.7 163.985C223.819 164.04 223.927 164.118 224.016 164.214C224.105 164.311 224.174 164.424 224.22 164.547C224.265 164.67 224.286 164.801 224.28 164.932V168.315C224.286 168.446 224.265 168.577 224.22 168.701C224.174 168.824 224.105 168.937 224.016 169.033C223.927 169.13 223.819 169.208 223.7 169.263C223.581 169.318 223.452 169.349 223.32 169.354Z"
      fill="#E7EAEC"
    />
    <path
      d="M198.44 180.288H133.653C133.373 180.292 133.102 180.185 132.9 179.99C132.698 179.796 132.58 179.53 132.573 179.249V175.867C132.575 175.727 132.604 175.589 132.66 175.46C132.715 175.331 132.795 175.215 132.895 175.117C132.995 175.019 133.114 174.942 133.244 174.891C133.374 174.839 133.513 174.813 133.653 174.815H198.44C198.58 174.813 198.719 174.839 198.849 174.891C198.979 174.942 199.098 175.019 199.198 175.117C199.298 175.215 199.378 175.331 199.434 175.46C199.489 175.589 199.518 175.727 199.52 175.867V179.249C199.518 179.389 199.489 179.526 199.433 179.654C199.378 179.782 199.298 179.898 199.197 179.994C199.097 180.091 198.978 180.167 198.848 180.217C198.718 180.268 198.579 180.292 198.44 180.288Z"
      fill="#E7EAEC"
    />
    <path
      d="M264.893 150.948H249.48C248.088 150.948 246.96 152.075 246.96 153.465V168.861C246.96 170.251 248.088 171.378 249.48 171.378H264.893C266.285 171.378 267.413 170.251 267.413 168.861V153.465C267.413 152.075 266.285 150.948 264.893 150.948Z"
      fill="#FFCCC4"
    />
    <path
      d="M315.627 117.44C315.627 131.517 302.293 141.346 292.013 148.844C267.453 166.797 212.88 206.751 210.16 208.776V268.082H295.147C300.578 268.082 305.788 265.926 309.628 262.09C313.469 258.254 315.627 253.05 315.627 247.625V117.44Z"
      fill="#EA7F7D"
    />
    <path
      d="M214.52 112.712H131.92C131.56 112.712 131.253 112.246 131.253 111.673V108.29C131.253 107.717 131.56 107.251 131.92 107.251H214.52C214.88 107.251 215.173 107.717 215.173 108.29V111.673C215.173 112.246 214.88 112.712 214.52 112.712Z"
      fill="#E7EAEC"
    />
    <path
      d="M222 123.633H132.214C132.082 123.628 131.953 123.597 131.834 123.542C131.715 123.487 131.607 123.409 131.518 123.312C131.429 123.216 131.36 123.103 131.314 122.979C131.269 122.856 131.248 122.725 131.254 122.594V119.211C131.248 119.08 131.269 118.949 131.314 118.826C131.36 118.702 131.429 118.589 131.518 118.493C131.607 118.396 131.715 118.318 131.834 118.263C131.953 118.208 132.082 118.177 132.214 118.172H222C222.132 118.177 222.261 118.208 222.38 118.263C222.5 118.318 222.607 118.396 222.696 118.493C222.785 118.589 222.854 118.702 222.9 118.826C222.945 118.949 222.966 119.08 222.96 119.211V122.594C222.966 122.725 222.945 122.856 222.9 122.979C222.854 123.103 222.785 123.216 222.696 123.312C222.607 123.409 222.5 123.487 222.38 123.542C222.261 123.597 222.132 123.628 222 123.633Z"
      fill="#E7EAEC"
    />
    <path
      d="M84.373 103.402C84.373 103.109 105.453 107.584 106.6 107.904C123.898 112.831 140.418 120.156 155.68 129.666C172.079 139.85 186.126 153.396 196.893 169.407C208.68 186.987 216.2 207.058 227.093 225.21C240.56 247.678 262.04 268.055 289.84 268.055H104.853C102.164 268.055 99.5004 267.526 97.0157 266.498C94.5309 265.47 92.2732 263.963 90.3715 262.063C88.4698 260.164 86.9612 257.909 85.932 255.427C84.9028 252.945 84.373 250.285 84.373 247.598V103.402Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M263.573 105.227H248.16C246.768 105.227 245.64 106.354 245.64 107.744V123.14C245.64 124.53 246.768 125.657 248.16 125.657H263.573C264.965 125.657 266.093 124.53 266.093 123.14V107.744C266.093 106.354 264.965 105.227 263.573 105.227Z"
      fill="#FFCCC4"
    />
    <path
      d="M214.52 66.0982H131.92C131.56 66.0982 131.253 65.632 131.253 65.0593V61.6765C131.253 61.1038 131.56 60.6377 131.92 60.6377H214.52C214.88 60.6377 215.173 61.1038 215.173 61.6765V65.0593C215.173 65.632 214.88 66.0982 214.52 66.0982Z"
      fill="#E7EAEC"
    />
    <path
      d="M222 77.0191H132.214C132.082 77.0139 131.953 76.9829 131.834 76.9279C131.715 76.8729 131.607 76.795 131.518 76.6985C131.429 76.602 131.36 76.4889 131.314 76.3656C131.269 76.2424 131.248 76.1114 131.254 75.9802V72.5974C131.248 72.4662 131.269 72.3352 131.314 72.212C131.36 72.0888 131.429 71.9757 131.518 71.8792C131.607 71.7827 131.715 71.7047 131.834 71.6497C131.953 71.5947 132.082 71.5637 132.214 71.5586H222C222.132 71.5637 222.261 71.5947 222.38 71.6497C222.5 71.7047 222.607 71.7827 222.696 71.8792C222.785 71.9757 222.854 72.0888 222.9 72.212C222.945 72.3352 222.966 72.4662 222.96 72.5974V75.9802C222.966 76.1114 222.945 76.2424 222.9 76.3656C222.854 76.4889 222.785 76.602 222.696 76.6985C222.607 76.795 222.5 76.8729 222.38 76.9279C222.261 76.9829 222.132 77.0139 222 77.0191Z"
      fill="#E7EAEC"
    />
    <path
      d="M197.12 87.9H132.333C132.193 87.9035 132.055 87.8793 131.925 87.8289C131.795 87.7784 131.676 87.7027 131.576 87.606C131.475 87.5094 131.395 87.3938 131.339 87.2659C131.284 87.138 131.255 87.0005 131.253 86.8611V83.5183C131.255 83.379 131.284 83.2414 131.339 83.1135C131.395 82.9856 131.475 82.87 131.576 82.7734C131.676 82.6767 131.795 82.601 131.925 82.5505C132.055 82.5001 132.193 82.4759 132.333 82.4795H197.12C197.4 82.4759 197.671 82.5829 197.873 82.7774C198.075 82.9718 198.193 83.238 198.2 83.5183V86.9011C198.183 87.1743 198.061 87.4304 197.86 87.6164C197.659 87.8024 197.394 87.9039 197.12 87.9Z"
      fill="#E7EAEC"
    />
    <path
      d="M263.254 58.6133H247.84C246.449 58.6133 245.32 59.7402 245.32 61.1304V76.5262C245.32 77.9164 246.449 79.0434 247.84 79.0434H263.254C264.645 79.0434 265.774 77.9164 265.774 76.5262V61.1304C265.774 59.7402 264.645 58.6133 263.254 58.6133Z"
      fill="#C0F7E0"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(200 146.174) scale(167.902 191.295)"
      >
        <stop stopColor="#FFEEED" />
        <stop offset="0.08" stopColor="#FFEFEE" stopOpacity="0.95" />
        <stop offset="0.24" stopColor="#FFF1F0" stopOpacity="0.83" />
        <stop offset="0.44" stopColor="#FFF4F4" stopOpacity="0.62" />
        <stop offset="0.69" stopColor="#FFF9F9" stopOpacity="0.34" />
        <stop offset="0.96" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint1_linear" x1="200" y1="268.055" x2="200" y2="103.389" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FAA076" />
        <stop offset="0.28" stopColor="#FAA177" stopOpacity="0.99" />
        <stop offset="0.44" stopColor="#F8A37B" stopOpacity="0.96" />
        <stop offset="0.58" stopColor="#F5A681" stopOpacity="0.91" />
        <stop offset="0.7" stopColor="#F1AB8A" stopOpacity="0.84" />
        <stop offset="0.81" stopColor="#ECB196" stopOpacity="0.74" />
        <stop offset="0.91" stopColor="#E6B9A4" stopOpacity="0.63" />
        <stop offset="1" stopColor="#DFC1B3" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="187.106"
        y1="268.055"
        x2="187.106"
        y2="103.389"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAB696" />
        <stop offset="0.28" stopColor="#FAB697" stopOpacity="0.99" />
        <stop offset="0.44" stopColor="#F8B799" stopOpacity="0.96" />
        <stop offset="0.58" stopColor="#F5B99D" stopOpacity="0.91" />
        <stop offset="0.7" stopColor="#F1BCA3" stopOpacity="0.84" />
        <stop offset="0.81" stopColor="#ECBFAB" stopOpacity="0.74" />
        <stop offset="0.91" stopColor="#E6C3B4" stopOpacity="0.63" />
        <stop offset="1" stopColor="#DFC8BE" stopOpacity="0.5" />
      </linearGradient>
    </defs>
  </svg>
);
