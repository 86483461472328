import { styled } from '../../themes';

export const RadioGroupStyled = styled.div`
  display: flex;

  & > * {
    flex: 0 0 auto;

    &:not(:first-child) {
      margin-left: 100px;
    }
  }
`;
