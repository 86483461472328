import { createUploadLink } from 'apollo-upload-client';

import {
  ApolloClient,
  ApolloLink,
  ApolloQueryResult,
  FetchResult,
  from,
  InMemoryCache,
  MutationOptions,
  ObservableQuery,
  OperationVariables,
  QueryOptions,
  WatchQueryOptions,
} from '@apollo/client';

import { CoreError } from '../errors/core-error';
import { MainStore } from '../main-store';
import { ManageableStore } from '../manageable-store';
import { RequireAuthRepository } from '../available-stores';
import { gqlDocsAuthRegister } from '../gql-documents/gql-auth-register';

/**
 * This store is basically needed to provide apollo client and that's all
 */
export class ApolloStore extends ManageableStore<RequireAuthRepository> {
  private _client: ApolloClient<any>;
  private _uri: string; // basically for debug reasons

  /**
   * Create a new apollo client and remembers it
   * @param mainStore
   * @param {string} uri
   */
  constructor(mainStore: MainStore<RequireAuthRepository>, uri: string) {
    super(mainStore, 'ApolloStore');
    if (!uri) {
      throw new CoreError(`No API URI provided`);
    }

    this._uri = uri;

    const authorizationLink = new ApolloLink((operation, forward) => {
      const authRepository = this.getStore('AuthRepository');
      operation.setContext(({ headers }) => ({
        headers: {
          ...headers,
          authorization: authRepository.hasAuth ? `Bearer ${authRepository.accessToken}` : '',
        },
      }));
      return forward(operation);
    });

    this._client = new ApolloClient({
      link: from([authorizationLink, createUploadLink({ uri, fetch: this.customFetch })]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });
  }

  // This is special handler, which intercepts rotten token reply and initiates its refresh
  private customFetch = (uri: string, options: any): Promise<Response> => {
    return fetch(uri, options)
      .then(response => {
        const responseClone = response.clone();
        return responseClone.json().then(json => [json, response]);
      })
      .then(([json, response]) => {
        // This weird check is special case when we have refresh token, but accessToken has rotten
        if (json?.errors?.[0]?.message === 'invalid authentication') {
          // This is a small optimize, not to slow every request with getting authRepository again
          const authRepository = this.getStore('AuthRepository');
          if (authRepository.refreshToken) {
            // We have to make custom request here
            const refreshRequest = JSON.stringify({
              operationName: 'refresh',
              query: gqlDocsAuthRegister.refresh,
              variables: { refreshToken: authRepository.refreshToken },
            });

            return fetch(this._uri, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Sec-Fetch-Mode': 'cors',
                'Sec-Fetch-Site': 'cross-site',
              },
              body: refreshRequest,
            })
              .then(result => {
                const responseClone = result.clone();
                return responseClone.json().then(json => [json, result]);
              })
              .then(([refreshData, refreshResponse]) => {
                if (refreshData?.data?.refresh) {
                  // Alles ist gut!
                  authRepository.consumeRefreshData(refreshData.data.refresh);
                  // Repeat original query with new accessToken
                  return fetch(uri, {
                    ...options,
                    headers: {
                      ...options.headers,
                      authorization: authRepository.hasAuth ? `Bearer ${authRepository.accessToken}` : '',
                    },
                  });
                } else if (json?.errors?.[0]?.message === 'invalid authentication') {
                  // This means refresh token is not valid, we need to clear grant data
                  authRepository.clearAuth();
                }
                return refreshResponse;
              });
          } else {
            // if we have no refresh token or any grantData, just clear-u anything and take appropriate steps
            authRepository.clearAuth();
          }
          return response;
        }
        return response;
      });
  };

  getClient() {
    return this._client;
  }

  /**
   * internals for mocking API in tests
   * @param {MutationOptions<T, TVariables>} options
   * @return {Promise<FetchResult<T>>}
   * @inner
   */
  mutate<TData, TVariables>(options: MutationOptions<TData, TVariables>): Promise<FetchResult<TData>> {
    return this._client.mutate(options as any);
  }

  /**
   * Internals for mocking API in tests
   * @param options
   * @inner
   */
  query<TData, TVariables extends OperationVariables>(
    options: QueryOptions<TVariables>,
  ): Promise<ApolloQueryResult<TData>> {
    return this._client.query(options);
  }

  /**
   * Internals for mocking API in tests
   * @param options
   */
  watchQuery<TData, TVariables extends OperationVariables>(
    options: WatchQueryOptions<TVariables>,
  ): ObservableQuery<TData, TVariables> {
    return this._client.watchQuery<TData, TVariables>(options);
  }

  clear() {
    // No need to wait for it
    this._client.cache.reset();
  }
}
