import React from 'react';

export const SquareRackIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="39" height="57" viewBox="0 0 39 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.5 0H19.2H0.899979C0.499979 0 0.299988 0.3 0.299988 0.6V19V37.3V55.6C0.299988 56 0.599979 56.2 0.899979 56.2H19.2H37.5C37.9 56.2 38.1 55.9 38.1 55.6V37.3V19V0.6C38.1 0.3 37.9 0 37.5 0ZM1.49998 19.6H18.5V36.6H1.49998V19.6ZM19.8 19.6H36.8V36.6H19.8V19.6ZM36.8 18.3H32.2C32.2 18.2 32.2 18 32.1 17.9L27.5 10C27.3 9.7 26.9 9.6 26.6 9.8C26.3 10 26.2 10.4 26.4 10.7L30.9 18.3H23.7V18.2V9.1C23.7 8.7 23.4 8.5 23.1 8.5C22.7 8.5 22.5 8.8 22.5 9.1V18.2V18.3H19.9V1.3H36.9V18.3H36.8ZM1.49998 1.3H18.5V18.3H13.2V18.2V9.1C13.2 8.7 12.9 8.5 12.6 8.5C12.3 8.5 12 8.7 12 9.1V18.2V18.3H9.39998V18.2V9.1C9.39998 8.7 9.09999 8.5 8.79999 8.5C8.49999 8.5 8.19998 8.8 8.19998 9.1V18.2V18.3H5.59999V18.2V9.1C5.59999 8.7 5.29998 8.5 4.99998 8.5C4.69998 8.5 4.39998 8.8 4.39998 9.1V18.2V18.3H1.79999V1.3H1.49998ZM1.49998 37.9H18.5V54.9H1.49998V37.9ZM36.8 54.9H32.5C32.5 54.8 32.5 54.8 32.5 54.7L28.3 46.1C28.1 45.8 27.8 45.6 27.4 45.8C27.1 46 26.9 46.3 27.1 46.7L31.1 54.9H27.7C27.7 54.8 27.7 54.8 27.7 54.7L23.5 46.1C23.3 45.8 23 45.6 22.6 45.8C22.3 46 22.1 46.3 22.3 46.7L26.3 54.9H19.9V37.9H36.9V54.9H36.8Z"
      fill="currentColor"
    />
  </svg>
);
