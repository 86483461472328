import { styled, h4, body, colors } from '../../lib/themes';
import config from 'config';
import { LogoIcon } from '../../lib/icons';

const { staticServer } = config;

const BgMobileStub = `${staticServer}/cms/site/assets/files/2304/mobile-stub.jpg`;

export const MobileStubStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(${BgMobileStub});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const Header = styled.div`
  position: relative;
  padding-left: 26px;
  display: flex;
  align-items: center;
  height: 80px;
`;

export const Logo = styled(LogoIcon)`
  width: 170px;
  height: auto;
  color: ${colors.bronze};
`;

export const Content = styled.div`
  padding-top: 20px;
  padding-left: 26px;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h1`
  ${h4};
  font-weight: bold;
  margin-bottom: 20px;
  color: ${colors.black};
`;

export const Description = styled.p`
  ${body};
  line-height: 21px;
  color: ${colors.black};
`;
