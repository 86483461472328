import { styled, colors, h5 } from '../../themes';

export const StyledMenuItem = styled.div`
  width: 100%;
  height: 70px;
  padding: 20px 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${h5};

  :hover {
    color: ${colors.bronze};
    cursor: pointer;
  }
`;
