import React from 'react';

import { StyledCheckbox, InputWrapper, Input, Icon, Label } from './Checkbox.styled';

export interface ICheckboxProps {
  name?: string;
  value?: boolean;
  disabled?: boolean;
  error?: string;
  onChange: (isChecked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  children,
  disabled,
  value = false,
  onChange,
  error,
  className,
  ...props
}) => {
  const isValid = !Boolean(error);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.checked, event);
  };

  return (
    <StyledCheckbox disabled={disabled} className={className}>
      <InputWrapper isValid={isValid}>
        <Input {...props} onChange={handleChange} checked={value} disabled={disabled} />
        {value ? <Icon /> : null}
      </InputWrapper>
      <Label>{children}</Label>
    </StyledCheckbox>
  );
};
