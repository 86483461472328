import React from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { Root } from './ConfiguratorPageStyled.styled';
import { On } from '../../../../lib/core/hooks/on';
import { CError } from '../../../../lib/components/Error';
import { useGetRoom } from '../../../../lib/core/repositories/room-repository';
import { isRoomItemsStep, isRoomParamsStep, pageToStep, RoomItemsStep, RoomParamsStep } from '../../typesUtils';
import { ConfiguratorParamsRootStep } from '../ConfiguratorParamsRootStep/ConfiguratorParamsRootStep';
import { ConfiguratorItemsRootStep } from '../ConfiguratorItemsRootStep/ConfiguratorItemsRootStep';
import { Loader } from '../../../../lib/components/Loader';

export interface IConfiguratorPageParams {
  accountId: string;
  projectId: string;
  roomId: string;
  page?: string;
}

/**
 * This component it a root for editing/viewing room
 * It is responsible for checking if such room exists, and if current :page is available for the room
 * RoomId is encoded and held in route url ':roomId' param
 * Current phase is also determined by url in ':page' param
 * e.g. /accounts/:accountId/projects/:projectId/rooms/:roomId/:page
 */
export const ConfiguratorPage: React.FC<RouteComponentProps<IConfiguratorPageParams>> = () => {
  const { accountId, projectId, roomId, page } = useParams<IConfiguratorPageParams>();

  // Extract current step from route
  const step = pageToStep(page);
  const history = useHistory();

  const changeStep = React.useCallback(
    (newStep: RoomParamsStep | RoomItemsStep) => {
      history.push(`/accounts/${accountId}/projects/${projectId}/rooms/${roomId}/${newStep}`);
    },
    [history, accountId, projectId, roomId],
  );

  return On(
    room => (
      <Root>
        {isRoomParamsStep(step) ? (
          <ConfiguratorParamsRootStep step={step.step} room={room} changeStep={changeStep} />
        ) : isRoomItemsStep(step) ? (
          <ConfiguratorItemsRootStep step={(step as any).step} room={room} changeStep={changeStep} />
        ) : (
          <div>Bitte wählen Sie einen Schritt in der Kopfzeile</div>
        )}
      </Root>
    ),
    () => <Loader />,
    errorState => <CError error={errorState} />,
    useGetRoom({ id: roomId }),
  );
};
