import { styled, colors } from '../../themes';
import { CloseButton } from '../../../lib/components/Card/Card.styled';

interface IContainerProps {
  isShow: boolean;
}

export const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled.div<IContainerProps>`
  opacity: ${({ isShow }) => (isShow ? '1' : '0')};
  animation: ${({ isShow }) => (isShow ? 'show 0.4s ease-in-out' : 'hide 0.4s ease-in-out 1')};

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes hide {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
`;
