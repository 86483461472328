import { body, h3, styled } from '../../themes';

export const FieldSet = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;

  &:not(:last-child) {
    margin-bottom: 90px;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: 43px;
    }
  }
`;

export const Legend = styled.legend`
  ${h3};
`;

export const SubLegend = styled.span`
  ${body};
  display: block;
  margin-top: 8px;
`;
