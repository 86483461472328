import { h4, styled } from '../../../../lib/themes';

import { DownloadIcon } from '../../../../lib/icons';
import { FlexContainer, Button } from '../../../../lib/components';
import { IStyledButtonProps } from '../../../../lib/components/Button/Button.styled';

export const ProductTotalStyled = styled.div``;

export const ProductTotalTitle = styled.p`
  ${h4};
  margin-bottom: 17px;
`;

export const TotalFlexContainer = styled(FlexContainer)`
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Subscription = styled.div``;

export const CheckboxWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
