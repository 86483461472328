import { colors, css, h5, styled } from '../../../../lib/themes';
import { CheckIcon } from '../../../../lib/icons';
import { FlexContainer } from '../../../../lib/components/FlexContainer';

export const Root = styled.div`
  background: ${colors.white};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  padding: 40px;
`;

export const TotalSum = styled.div``;

export const Table = styled.table`
  font-size: 25px;
  line-height: 1.2;
`;

export const FlexContainerStyled = styled(FlexContainer)`
  align-items: center;
`;

export const CheckIconStyled = styled(CheckIcon)`
  color: ${colors.bronze};
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td`
  padding-right: 50px;

  &:last-child {
    white-space: nowrap;
  }
`;

export const TableRow = styled.tr`
  &:not(:last-child) {
    ${TableCell} {
      padding-bottom: 35px;
    }
  }

  &:last-child {
    font-size: 30px;
    line-height: 1.17;
    font-weight: 700;
  }
`;

interface IButtonWrapperProps {
  isShow: boolean;
}

export const ButtonWrapper = styled.div<IButtonWrapperProps>`
  visibility: hidden;
  user-select: none;
  opacity: 0;
  transform: translate(0, -140px);
  transition: ease 0.5s;
  margin-top: 40px;
  height: 0;
  ${({ isShow }) =>
    isShow
      ? css`
          visibility: visible;
          height: auto;
          user-select: auto;
          opacity: 1;
          transform: translate(0, 0);
        `
      : ''}
`;

export const PromoCodeView = styled.span`
  ${h5};
  color: ${colors.grayMedium};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  & * {
    color: ${colors.grayMedium};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-top: 25px;
`;
