import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthFormWindow } from '../../../modules/auth/containers/AuthFormWindow';
import { MenuItem } from '../MenuItem';
import { LoginIcon } from '../../icons';
import { Menu } from '../Menu';
import { AuthorizedUserMenu } from './AuthorizedUserMenu';

import { IconButtonStyled } from './Header.styled';
import { useHasAuth } from '../../core/repositories/auth-repository';

export const HeaderUserMenu: React.FC = () => {
  const hasAuth = useHasAuth();
  const location = useLocation();
  const [redirect, setRedirect] = useState<string>();
  const history = useHistory();

  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('showLogin') === 'true') {
      setRedirect(params.get('redirect') || undefined);
      setShowAuthFormWindow(true);
      // remove `showLogin` search parameter from address string
      history.replace(location.pathname);
    }
  }, [history, location]);

  const handleShowAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  return hasAuth ? (
    <AuthorizedUserMenu />
  ) : (
    <>
      <Menu anchorElement={<IconButtonStyled icon="User" isShow={true} />}>
        <MenuItem onClick={handleShowAuthFormWindow}>
          Einloggen <LoginIcon />
        </MenuItem>
      </Menu>
      {showAuthFormWindow && <AuthFormWindow onClose={handleCloseAuthFormWindow} redirect={redirect} />}
    </>
  );
};
