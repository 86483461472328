import * as React from 'react';
import * as Yup from 'yup';

import { On } from '../../../lib/core/hooks/on';
import { useCreateProject } from '../../../lib/core/repositories/project-repository';
import { useMe } from '../../../lib/core/repositories/user-repository';

import { Form, FormInput, FormButton, FormFooter, CError } from '../../../lib/components';

import { FormLayout } from '../../../lib/components/Form';
import { Project } from '../../../lib/core/api/generated';
import { requiredString } from '../../../lib/utils/yup-reductions';

interface INewProjectFormProps {
  afterCreateProject?: (result: Project) => unknown;
  accountId: string;
}

interface INewProjectForm {
  name: string;
}

export const NewProjectForm: React.FC<INewProjectFormProps> = ({ afterCreateProject, accountId }) => {
  const [createProject] = useCreateProject();

  const initialValues = { accountId, name: '' };

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createProject}
      afterSubmit={afterCreateProject}
    >
      <FormLayout>
        <FormInput name="name" label="Projektname" autoFocusOnEmpty={true} />
        <FormFooter>
          <FormButton type="submit" color="bronze" variant="contained">
            Jetzt loslegen
          </FormButton>
        </FormFooter>
      </FormLayout>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  name: requiredString('Bitte geben Sie einen Projektnamen ein.')
    .min(2, 'Projektname ist zu kurz')
    .max(18, 'Projektname ist zu lang'),
});
