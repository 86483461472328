import React from 'react';

export const RoundedSingleBedIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.1 0.5H11.2C5.40001 0.5 0.700012 5.2 0.700012 11V14.4V14.6V25C0.700012 25.4 1.00002 25.7 1.30002 25.7H3.90001V29C3.90001 29.4 4.20002 29.7 4.50002 29.7H7.70001C8.10001 29.7 8.30002 29.4 8.30002 29V25.7H31.6V29C31.6 29.4 31.9 29.7 32.2 29.7H35.4C35.8 29.7 36 29.4 36 29V25.7H38.6C39 25.7 39.2 25.4 39.2 25V14.6V14.4V11C39.7 5.2 34.9 0.5 29.1 0.5ZM11.2 1.79999H29.1C34.2 1.79999 38.3 5.9 38.3 11V13.7H31V13.6C31 10.3 28.3 7.70001 25.1 7.70001H15.2C11.9 7.70001 9.30002 10.4 9.30002 13.6V13.7H2.00002V11C2.00002 5.9 6.20001 1.79999 11.2 1.79999ZM29.8 13.8H10.5V13.7C10.5 11.1 12.6 9.10001 15.1 9.10001H25C27.7 9.00001 29.8 11.1 29.8 13.8C29.8 13.7 29.8 13.7 29.8 13.8ZM7.20001 28.3H5.30002V25.6H7.20001V28.3ZM35.1 28.3H33.2V25.6H35.1V28.3ZM38.4 24.4H2.00002V15.1H38.3V24.4H38.4Z"
      fill="currentColor"
    />
  </svg>
);
