import { styled, colors, h2 } from '../../../../lib/themes';

import { Button } from '../../../../lib/components/Button';
import { StyledButton } from '../../../../lib/components/Button/Button.styled';
import { ComputerIcon, DownloadIcon } from '../../../../lib/icons';

export const Title = styled.h1`
  ${h2}
  white-space: pre-line;
  color: ${colors.black};
`;

export const Text = styled.p`
  ${h2}
  color: ${colors.bronze};
`;

export const PlanButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;
  gap: 28px;
  margin-bottom: 65px;
`;
