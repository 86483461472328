import { styled, colors, h3, body } from '../../themes';

export const Title = styled.h3`
  ${h3};
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 19px;
`;

export const Root = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: ${colors.grayLight};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 120px;

  svg {
    color: ${colors.grayMedium};
  }
`;

export const Subtitle = styled.h4`
  ${body};
  margin-top: 33px;
  padding: 0 30px;
  text-align: center;
  color: ${colors.grayDark};
`;
