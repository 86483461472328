import { useState, useLayoutEffect } from 'react';
import { noop } from '../utils/placeholders';

export function useDelayToDetach(
  isOpen: boolean,
  isOpenDefault = false,
  delay = 400,
  onDetach = noop,
): [boolean, boolean] {
  const [isShowed, setShowed] = useState(isOpenDefault);
  const [isAttached, setAttached] = useState(isOpenDefault);

  useLayoutEffect(() => {
    setShowed(isOpen);
    let timeout;
    if (isOpen) {
      setAttached(true);
    } else {
      timeout = setTimeout(() => {
        setAttached(false);
        onDetach();
      }, delay);
    }
    return () => {
      timeout && window.clearTimeout(timeout);
    };
  }, [delay, isOpen, onDetach]);

  return [isShowed, isAttached];
}
