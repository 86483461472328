import React from 'react';
import { Link } from 'react-router-dom';
import { GeneralCard, Menu, MenuItem } from '../../components';
import { TrashIcon } from '../../icons';

import { Content, ImageStyled, MenuButton, Root, SubTitle, Title, MoreIconStyled } from './ProjectCard.styled';
import { useIsInvitedUser } from '../../core/hooks/useIsInvitedUser';

interface IProjectCardProps {
  image?: string;
  title: string;
  subtitle: string;
  to: string;
  hasOrderedRooms?: boolean;
  onRemove: () => void;
  onOrder: () => void;
  isInvitedUser?: boolean;
}

export const ProjectCard: React.FC<IProjectCardProps> = ({
  image,
  title,
  subtitle,
  to,
  hasOrderedRooms = false,
  onRemove,
  onOrder,
  isInvitedUser,
}) => {
  return (
    <Root data-hook-name={'ProjectCard'}>
      <Link to={to}>
        {image && <ImageStyled src={image} alt={title} />}
        <Content>
          <Title data-hook-name={'ProjectTitle'}>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </Content>
      </Link>

      {isInvitedUser ? null : (
        <Menu
          anchorElement={
            <MenuButton data-hook-button="MenuCard" type="button">
              <MoreIconStyled />
            </MenuButton>
          }
        >
          <MenuItem onClick={onOrder}>Projekt bestellen</MenuItem>
          {!hasOrderedRooms && (
            <MenuItem onClick={onRemove}>
              Projekt löschen <TrashIcon />
            </MenuItem>
          )}
        </Menu>
      )}
    </Root>
  );
};
