import { applyCssIf, css, styled, colors, h1, h2, h3, h4, h5 } from '../../themes';
import { TitleAlign, TitleType } from './Title.types';

const isH1 = (type: TitleType): boolean => type === 'h1';

const isH2 = (type: TitleType): boolean => type === 'h2';

const isH3 = (type: TitleType): boolean => type === 'h3';

const isH4 = (type: TitleType): boolean => type === 'h4';

const isH5 = (type: TitleType): boolean => type === 'h5';

export interface ITitleStyledProps {
  /**
   * Title type.
   * Available options: h1, h2, h3
   */
  type: TitleType;

  /**
   * Title align.
   * Available options: center, right
   */
  align: TitleAlign;
}

export const TitleStyled = styled.h1<ITitleStyledProps>`
  display: block;

  color: ${colors.dark};
  text-align: ${({ align }) => align};

  ${({ type }) =>
    applyCssIf(isH1(type))(css`
      ${h1};
      min-height: 45px;
    `)};

  ${({ type }) =>
    applyCssIf(isH2(type))(css`
      ${h2};
      min-height: 45px;
    `)};

  ${({ type }) =>
    applyCssIf(isH3(type))(css`
      ${h3};
      //min-height: 35px;
    `)};

  ${({ type }) =>
    applyCssIf(isH4(type))(css`
      ${h4};
      //min-height: 30px;
    `)};

  ${({ type }) =>
    applyCssIf(isH5(type))(css`
      ${h5};
      //min-height: 25px;
    `)};
`;
