import { styled, css, colors } from '../../themes';
import { ArrowIcon } from '../../icons';

interface ISliderNavigateButton {
  direction: string;
}

export const SliderNavigateButtonStyled = styled.button<ISliderNavigateButton>`
  width: 36px;
  height: 36px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${props => {
    switch (props.direction) {
      case 'left':
        return css`
          transform: rotate(180deg);
        `;
      case 'right':
        return css`
          transform: rotate(0);
        `;
      default:
        return css``;
    }
  }};
`;

export const NavigateButtonIcon = styled(ArrowIcon)`
  width: 100%;
  height: 100%;
  color: ${colors.black};
`;
