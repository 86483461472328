import React from 'react';

export const LightingIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="58" height="72" viewBox="0 0 58 72" fill="none">
    <path
      d="M6.95117 67.3496C6.95117 61.8268 11.4283 57.3496 16.9512 57.3496H20.7549C26.2778 57.3496 30.7549 61.8268 30.7549 67.3496V70.7392H6.95117V67.3496Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.1758 9.14192L33.0566 7.05338C30.7156 4.74643 26.9203 4.74643 24.5794 7.05338V7.05338C22.2385 9.36032 22.2385 13.1006 24.5794 15.4076L26.6987 17.4961"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.3546 28.7962V28.7962C42.6955 31.1032 46.4908 31.1032 48.8317 28.7962V28.7962C51.1726 26.4893 51.1726 22.749 48.8317 20.442V20.442"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9751 33.1266C22.0414 29.25 22.0414 22.9648 25.9751 19.0882L38.2211 7.01981C42.1548 3.1432 48.5325 3.1432 52.4662 7.01981L56.9997 11.4876L30.5086 37.5944L25.9751 33.1266Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18.853 57.3492L6.95117 35.0332" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="5.4632" cy="30.5706" r="4.4632" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.92676 27.5938L27.7796 17.1797" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
