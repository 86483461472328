import { colors, styled } from '../../../../lib/themes';
import { Title } from '../../../../lib/components/Title';

export const Root = styled.div``;

export const Menu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  user-select: none;
`;

export const MenuItem = styled.li`
  margin: 10px;
  padding: 10px;
  background-color: ${colors.grayLightest};

  &:hover {
    cursor: pointer;
    background-color: ${colors.grayLighter};
  }
`;

export const Divider = styled.hr`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const MoodboardTitle = styled(Title)`
  margin-bottom: 50px;
`;
