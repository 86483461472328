import React from 'react';

export const ThreeSeatSofaIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="38" viewBox="0 0 65 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M64.2 8.7H62.2C62.2 8.6 62.2 8.6 62.2 8.5V5.7C62.2 2.6 59.6 0 56.5 0H8.39996C5.29996 0 2.70001 2.6 2.70001 5.7V8.5C2.70001 8.6 2.70001 8.6 2.70001 8.7H0.700012C0.300012 8.7 0 9 0 9.3V22.2V32.6C0 33 0.300012 33.2 0.700012 33.2H3.20001V36.8C3.20001 37.2 3.49996 37.4 3.89996 37.4H60.9C61.3 37.4 61.6 37.1 61.6 36.8V33.2H64.1C64.5 33.2 64.8 32.9 64.8 32.6V22.2V9.4C64.9 9 64.6 8.7 64.2 8.7ZM63.6 21.7H59.1C59.4 21.7 59.7 21.4 59.7 21.1V10H63.6V21.7ZM4.09998 8.5V5.7C4.09998 3.3 6.1 1.3 8.5 1.3H56.6C59 1.3 61 3.3 61 5.7V8.5C61 8.6 61 8.6 61 8.7H59C58.6 8.7 58.3 9 58.3 9.3V21C58.3 21.3 58.6 21.6 58.9 21.6H6.09998C6.39998 21.6 6.70001 21.3 6.70001 21V9.4C6.70001 9 6.4 8.8 6 8.8H4C4.1 8.7 4.09998 8.6 4.09998 8.5ZM5.39996 10V21C5.39996 21.3 5.7 21.6 6 21.6H1.5V10H5.39996ZM60.4 36.2H4.70001V33.3H60.4V36.2ZM63.6 32H1.59998V23H63.6V32Z"
      fill="currentColor"
    />
  </svg>
);
