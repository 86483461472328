import React from 'react';

import { CMSContent } from '../../lib/components/CMSContent/CMSContent';
import { Header } from '../../lib/components/Header';
import { Container } from '../../lib/components/Container';
import { PageFooter } from '../../lib/components/Page/PageFooter';

import { PrivacyPolicyStyled, Content } from './PrivacyPolicy.styled';

export const PrivacyPolicy: React.FC = () => (
  <PrivacyPolicyStyled>
    <Header />
    <Content>
      <Container>
        <CMSContent>
          <br />
          <br />
          <br />
          <h1>DATENSCHUTZ</h1>
          <br />
          <br />
          <h5>§ 1 Allgemeines</h5>
          <br />
          <p>
            Ihre personenbezogenen Daten (z.B. Anrede, Name, Anschrift, E-Mail-Adresse, Telefonnummer) werden von uns
            nur gemäß den Bestimmungen des deutschen Datenschutzrechts und des Datenschutzrechts der Europäischen Union
            (EU) verarbeitet. Die nachfolgenden Vorschriften informieren Sie neben den Verarbeitungszwecken,
            Rechtsgrundlagen, Empfängern, Speicherfristen auch über Ihre Rechte und den Verantwortlichen für Ihre
            Datenverarbeitung. Diese Datenschutzerklärung bezieht sich nur auf unsere Websites. Falls Sie über Links auf
            unseren Seiten auf andere Seiten weitergeleitet werden, informieren Sie sich bitte dort über den jeweiligen
            Umgang mit Ihren Daten. Sofern vorhanden, weisen wir unseren Datenschutzbeauftragten im Impressum aus.
          </p>
          <br />
          <h5>§ 2 Aufruf der Webseite</h5>
          <br />
          <p>
            <strong>(1) Verarbeitungszweck</strong>
            <br />
            Bei Aufruf und Nutzung unserer Webseite erheben wir die personenbezogenen Daten, die Ihr Browser automatisch
            an unseren Server übermittelt. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Wenn
            Sie unsere Webseite nutzen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um
            Ihnen unsere Webseite anzuzeigen und die Stabilität und Sicherheit zu gewährleisten: Die IP-Adresse des
            Nutzers, das Datum und die Uhrzeit des Zugriffes, den Name und die URL der abgerufenen Datei, die URL der
            Website (von der aus der Zugriff erfolgt), den verwendeter Browser, das Betriebssystem des zugreifenden
            Rechners und den Namen Ihres Providers.
          </p>
          <br />
          <p>
            <strong>(2) Rechtsgrundlagen</strong>
            <br />
            a) Sollten Sie uns eine ausdrückliche Einwilligung zur Verarbeitung Ihrer Daten gegeben haben, ist Art. 6
            Abs. 1a) DSGVO die Rechtsgrundlage für diese Verarbeitung. b) Sollten wir Ihre Daten zur Durchführung
            vorvertraglicher Maßnahmen verarbeiten, ist Art. 6 Abs. 1b) DSGVO die Rechtsgrundlage. c) In allen anderen
            Fällen ist Art. 6 Abs. 1f) DSGVO die Rechtsgrundlage. WIDERSPRUCHSRECHT: Sie haben das Recht, der
            Datenverarbeitung die auf der Grundlage des Art. 6 Abs. 1 f) DSGVO erfolgt und nicht der Direktwerbung dient
            aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit zu widersprechen. Im Falle der
            Direktwerbung können Sie der Verarbeitung hingegen ohne Angabe von Gründen jederzeit widersprechen.
          </p>
          <br />
          <p>
            <strong>(3) Berechtigtes Interesse</strong>
            <br />
            Unser berechtigtes Interesse an der Verarbeitung besteht darin, dass die Verarbeitung der genannten Daten
            für die Bereitstellung einer Webseite erforderlich sind und somit der Wahrung eines berechtigten Interesses
            unseres Unternehmens dienen.
          </p>
          <br />
          <p>
            <strong>(4) Speicherdauer</strong>
            <br />
            Ihre Daten werden gelöscht, sobald die Daten zum Anzeigen der Webseite nicht mehr erforderlich sind. Wenn
            gesetzlich vorgeschrieben, kann im Einzelfall eine weitergehende Speicherung erfolgen.
          </p>
          <br />
          <p>
            <strong>(5) Widerrufsrecht</strong>
            <br />
            Die Erfassung und Speicherung der genannten Daten ist für die Bereitstellung und den Betrieb der Webseite
            zwingend erforderlich. Daher besteht seitens des Nutzers keine Widerspruchsmöglichkeit.
          </p>
          <br />

          <h5>§ 3 Kontaktaufnahme</h5>
          <br />
          <p>
            <strong>(1) Verarbeitungszweck</strong>
            <br />
            Ihre personenbezogenen Daten, die Sie uns per E-Mail, Kontaktformular etc. zur Verfügung stellen,
            verarbeiten wir zur Beantwortung und Erledigung Ihrer Anfragen. Im Rahmen des Absendevorgangs Ihrer Anfrage,
            über das Kontaktformular, wird zur Einholung Ihrer Einwilligung auf diese Datenschutzerklärung verwiesen.
            Sie sind nicht verpflichtet, uns Ihre personenbezogenen Daten bereitzustellen. Aber ohne Mitteilung Ihrer
            E-Mail-Adresse können wir Ihnen auch nicht per E-Mail antworten.
          </p>
          <br />
          <p>
            <strong>(2) Rechtsgrundlagen</strong>
            <br />
            a) Sollten Sie uns eine ausdrückliche Einwilligung zur Verarbeitung Ihrer Daten gegeben haben, ist Art. 6
            Abs. 1a) DSGVO die Rechtsgrundlage für diese Verarbeitung. b) Sollten wir Ihre Daten zur Durchführung
            vorvertraglicher Maßnahmen verarbeiten, ist Art. 6 Abs. 1b) DSGVO die Rechtsgrundlage. c) In allen anderen
            Fällen (insbesondere bei Nutzung eines Kontaktformulars) ist Art. 6 Abs. 1f) DSGVO die Rechtsgrundlage.
            WIDERSPRUCHSRECHT: Sie haben das Recht, der Datenverarbeitung die auf der Grundlage des Art. 6 Abs. 1 f)
            DSGVO erfolgt und nicht der Direktwerbung dient aus Gründen, die sich aus Ihrer besonderen Situation
            ergeben, jederzeit zu widersprechen. Im Falle der Direktwerbung können Sie der Verarbeitung hingegen ohne
            Angabe von Gründen jederzeit widersprechen.
          </p>
          <br />
          <p>
            <strong>(3) Berechtigtes Interesse</strong>
            <br />
            Unser berechtigtes Interesse an der Verarbeitung besteht darin, mit Ihnen auf schnellem Wege zu
            kommunizieren und Ihre Anfragen kostengünstig zu beantworten. Wenn Sie uns Ihre Anschrift mitteilen,
            behalten wir uns vor, diese für postalische Direktwerbung zu verwenden. Ihr Interesse am Datenschutz können
            Sie durch eine sparsame Datenweitergabe (z.B. Verwendung eines Pseudonyms) wahren.
          </p>
          <br />
          <p>
            <strong>(4) Empfängerkategorien</strong>
            <br />
            Hostinganbieter, Versanddienstleister bei Direktwerbung
          </p>
          <br />
          <p>
            <strong>(5) Speicherdauer</strong>
            <br />
            Ihre Daten werden gelöscht, wenn sich aus den Umständen entnehmen lässt, dass Ihre Anfrage bzw. der
            betroffene Sachverhalt abschließend geklärt ist. Falls es jedoch zu einem Vertragsschluss kommt, werden die
            nach Handels- und Steuerrecht erforderlichen Daten von uns für die gesetzlich bestimmten Zeiträume
            aufbewahrt, also regelmäßig zehn Jahre (vgl. § 257 HGB, § 147 AO).
          </p>
          <br />
          <p>
            <strong>(6) Widerrufsrecht</strong>
            <br />
            Sie haben im Fall der Verarbeitung aufgrund Ihrer Einwilligung das Recht, Ihre Einwilligung jederzeit mit
            einer eMail an contact(at)furnished.de zu widerrufen.
          </p>
          <br />
          <h5>§ 4 Kommentare</h5>
          <br />
          <p>
            <strong>(1) Verarbeitungszweck</strong>
            <br />
            Es besteht die Möglichkeit einen Kommentar zu verfassen. Ihre Daten (z.B. Name/Pseudonym, E-Mail-Adresse,
            Website) werden dann nur für den Zweck der Veröffentlichung Ihres Kommentars verarbeitet.
          </p>
          <br />
          <p>
            <strong>(2) Rechtsgrundlage</strong>
            <br />
            Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 f) DSGVO.
          </p>
          <br />
          <p>
            <strong>(3) Berechtigtes Interesse</strong>
            <br />
            Unser berechtigtes Interesse ist der öffentliche Austausch von Nutzermeinungen zu bestimmten Themen und
            Produkten. Die Veröffentlichung dient u.a. der Transparenz und Meinungsbildung. Ihr Interesse am Datenschutz
            bleibt gewahrt, da Sie Ihren Kommentar unter einem Pseudonym veröffentlichen können.
          </p>
          <br />
          <p>
            <strong>(4) Speicherdauer</strong>
            <br />
            Eine bestimmte Speicherdauer ist nicht vorgesehen. Sie können jederzeit mit einer eMail an
            contact(at)furnished.de die Löschung Ihres Kommentars verlangen.
          </p>
          <br />
          <p>
            <strong>(5) Widerrufsrecht</strong>
            <br />
            Sie haben das Recht, der Datenverarbeitung die auf der Grundlage des Art. 6 Abs. 1 f) DSGVO erfolgt und
            nicht der Direktwerbung dient aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit zu
            widersprechen. Im Falle der Direktwerbung können Sie der Verarbeitung hingegen ohne Angabe von Gründen
            jederzeit widersprechen.
          </p>
          <br />
          <h5>§ 5 Blogs und Publikationsmedien</h5>
          <br />
          <p>
            Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und Publikation (nachfolgend
            „Publikationsmedium“). Die Daten der Leser werden für die Zwecke des Publikationsmediums nur insoweit
            verarbeitet, als es für dessen Darstellung und die Kommunikation zwischen Autoren und Lesern oder aus
            Gründen der Sicherheit erforderlich ist. Im Übrigen verweisen wir auf die Informationen zur Verarbeitung der
            Besucher unseres Publikationsmediums im Rahmen dieser Datenschutzhinweise. Kommentare und Beiträge: Wenn
            Nutzer Kommentare oder sonstige Beiträge hinterlassen, können ihre IP-Adressen auf Grundlage unserer
            berechtigten Interessen gespeichert werden. Das erfolgt zu unserer Sicherheit, falls jemand in Kommentaren
            und Beiträgen widerrechtliche Inhalte hinterlässt (Beleidigungen, verbotene politische Propaganda etc.). In
            diesem Fall können wir selbst für den Kommentar oder Beitrag belangt werden und sind daher an der Identität
            des Verfassers interessiert. Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten
            Interessen die Angaben der Nutzer zwecks Spamerkennung zu verarbeiten. Auf derselben Rechtsgrundlage
            behalten wir uns vor, im Fall von Umfragen die IP-Adressen der Nutzer für deren Dauer zu speichern und
            Cookies zu verwenden, um Mehrfachabstimmungen zu vermeiden. Die im Rahmen der Kommentare und Beiträge
            mitgeteilten Informationen zur Person, etwaige Kontakt- sowie Webseiteninformationen als auch die
            inhaltlichen Angaben werden von uns bis zum Widerspruch der Nutzer dauerhaft gespeichert. Mit Hilfe von
            Plugins werden Kommentare echter Menschen von Spam-Kommentaren unterschieden. Dazu werden alle
            Kommentarangaben an einen Server in den USA verschickt, wo sie analysiert und für Vergleichszwecke vier Tage
            lang gespeichert werden. Ist ein Kommentar als Spam eingestuft worden, werden die Daten über diese Zeit
            hinaus gespeichert. Zu diesen Angaben gehören der eingegebene Name, die E-Mailadresse, die IP-Adresse, der
            Kommentarinhalt, der Referrer, Angaben zum verwendeten Browser sowie zum Computersystem und die Zeit des
            Eintrags. Die Nutzer können gerne Pseudonyme nutzen oder auf die Eingabe des Namens oder der Emailadresse
            verzichten. Sie können die Übertragung der Daten komplett verhindern, indem sie unser Kommentarsystem nicht
            nutzen. Das wäre schade, aber leider sehen wir keine Alternativen, die ebenso effektiv funktionieren.
          </p>
          <br />
          <h5>§ 6 Bewerbungen und im Bewerbungsverfahren</h5>
          <br />
          <p>
            Der für die Verarbeitung Verantwortliche erhebt und verarbeitet die personenbezogenen Daten von Bewerbern
            zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege
            erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem
            elektronischen Wege, beispielsweise per E-Mail oder über ein auf der Internetseite befindliches Webformular,
            an den für die Verarbeitung Verantwortlichen übermittelt. Schließt der für die Verarbeitung Verantwortliche
            einen Anstellungsvertrag mit einem Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des
            Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert. Wird von dem für die
            Verarbeitung Verantwortlichen kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die
            Bewerbungsunterlagen zwei Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern einer
            Löschung keine sonstigen berechtigten Interessen des für die Verarbeitung Verantwortlichen entgegenstehen.
            Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in einem Verfahren
            nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).
          </p>
          <br />
          <h5>§ 7 Weitergabe von Daten</h5>
          <br />
          <p>
            Wir geben Ihre persönlichen Daten nur in folgenden Fällen an Dritte weiter: Wenn Sie hierzu Ihre
            ausdrückliche Einwilligung nach Art. 6 Abs. 1a) DSGVO erteilt haben. Wenn dies gesetzlich zulässig und nach
            Art. 6 Abs. 1b) DSGVO zur Erfüllung eines Vertragsverhältnisses mit Ihnen erforderlich ist. Wenn nach Art. 6
            Abs. 1c) DSGVO für die Weitergabe eine gesetzliche Verpflichtung besteht. Wenn die Weitergabe nach Art. 6
            Abs. 1f) DSGVO zur Wahrung berechtigter Unternehmensinteressen, sowie zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
            überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
          </p>
          <br />
          <h5>§ 8 Cookies</h5>
          <br />
          <p>
            <strong>(1) Verarbeitungszweck</strong>
            <br />
            Auf dieser Webseite werden technisch notwendige Cookies eingesetzt. Cookies sind kleine Textdateien, die im
            Rahmen Ihres Besuchs unserer Webseite von uns an den Browser Ihres Endgeräts gesendet und dort gespeichert
            werden. Sie enthalten Informationen, die bei einem Zugriff auf unsere Webseiteninhalte abgerufen werden
            können und so eine effizientere und bessere Nutzung unserer Angebote ermöglichen. Einige Funktionen unserer
            Webseite können ohne den Einsatz technisch notwendiger Cookies nicht angeboten werden. Wir setzen permanente
            und sogenannte Session Cookies ein. Session Cookies werden automatisch gelöscht, sobald Sie Ihren Browser
            schließen. Permanente Cookies werden einen längeren Zeitraum in Ihrem Browser gespeichert, bis sie zur
            Erreichung ihres Zwecks nicht mehr erforderlisind und gelöscht werden. Die Cookies dienen einer Verbesserung
            unserer Dienste und sind beispielsweise in der Lage, den von Ihnen verwendeten Browser bei einem erneuten
            Besuch unserer Webseite wiederzuerkennen und verschiedene Informationen an uns zu übermitteln. Mithilfe von
            Cookies können wir unter anderem unser Internetangebot für Sie nutzerfreundlicher und effektiver gestalten,
            indem wir etwa Ihre Nutzung unserer Webseite nachvollziehen und Ihre bevorzugten Einstellungen (bspw.
            Länder- und Spracheneinstellungen) feststellen. Cookies können nicht verwendet werden, um Programme zu
            starten oder Viren auf einen Computer zu übertragen. In keinem Fall werden die von uns erfassten Daten an
            Dritte weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.
          </p>
          <br />
          <p>
            <strong>(2) Rechtsgrundlage</strong>
            <br />
            Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs.1 f) DSGVO.
          </p>
          <br />
          <p>
            <strong>(3) Berechtigtes Interesse</strong>
            <br />
            Unser berechtigtes Interesse ist die Funktionsfähigkeit unserer Webseite. Die durch technisch notwendige
            Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet. Dadurch wird Ihr
            Interesse am Datenschutz gewahrt.
          </p>
          <br />
          <p>
            <strong>(4) Speicherdauer</strong>
            <br />
            Die technisch notwendigen Cookies werden im Regelfall mit dem Schließen des Browsers gelöscht. Dauerhaft
            gespeicherte Cookies haben eine unterschiedlich lange Lebensdauer von einigen Minuten bis zu mehreren
            Jahren. Die über Cookies an uns übermittelten Daten werden gelöscht, sobald die Informationen für die
            Erreichung der oben genannten Zwecke nicht mehr erforderlich sind.
          </p>
          <br />
          <p>
            <strong>(5) Widerspruchsrecht</strong>
            <br />
            Falls Sie die Speicherung dieser Cookies nicht wünschen, deaktivieren Sie bitte die Annahme dieser Cookies
            in Ihrem Internetbrowser. Dies kann aber eine Funktionseinschränkung unserer Webseite zur Folge haben.
            Gleichfalls können die bereits gesetzte Cookies jederzeit über Ihren jeweiligen Browser oder andere
            Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich. Weiterhin ist es
            möglich, Ihren Browser so einzustellen, dass er Sie benachrichtigt, bevor Cookies gespeichert werden.
          </p>
          <br />
          <h5>§ 9 Social Media</h5>
          <br />
          <p>
            Wir verwenden auf unserer Webseite Icons der sozialen Netzwerke „Facebook”, „Twitter” und „Google+”. Diese
            Dienste werden von den Unternehmen Inc., Google Inc. und Twitter Inc. (Anbieter) angeboten. Um den Schutz
            Ihrer Daten beim Besuch unserer Website zu erhöhen, handelt es sich bei den Weiterleitungen um statische
            Links die mittels sogenannter „Shariff“ – Projekt eingebaut sind. Dadurch wird verhindert, dass Ihre Daten
            bereits beim Besuch unserer Website an die sozialen Netzwerke geschickt werden. Ein Kontakt zwischen Ihnen
            und dem sozialen Netzwerk wird erst hergestellt, wenn Sie aktiv auf den Button klicken. Weiter Informationen
            dazu finden Sie hier:
            https://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html
          </p>
          <br />
          <h5>§ 10 Google Maps</h5>
          <br />
          <p>
            Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA. Zur Nutzung der Funktionen von Google Maps ist es
            notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google
            in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
            Datenübertragung.
          </p>
          <br />
          <p>
            Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und
            an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden
            Sie in der Datenschutzerklärung von Google: https://www.google.de/intl/de/policies/privacy/. Opt-Out:
            https://adssettings.google.com/authenticated.
          </p>
          <br />
          <h5>§ 11 Tracking- und Analysetools</h5>
          <br />
          <p>
            Verarbeitungszweck, Rechtsgrundlage und berechtigtes Interesse
            <br />
            Wir nutzen Tracking- und Analysetools um eine fortlaufende Optimierung und bedarfsgerechte Gestaltung
            unserer Webseite sicherzustellen. Mit Hilfe von Tracking-Maßnahmen ist es uns auch möglich, die Nutzung
            unserer Webseite durch Besucher statistisch zu erfassen und unser Onlinepräsenz mit Hilfe der dadurch
            gewonnenen Erkenntnisse für Sie weiterzuentwickeln. Aufgrund dieser Interessen ist der Einsatz der
            nachfolgend beschriebenen Tracking- und Analysetools gemäß Art. 6 Abs. 1f) DSGVO gerechtfertigt. Die
            Tracking- und Analysetools können Sie in den Cookie-Einstellungen jederzeit ändern und diese so aktivieren
            oder deaktivieren.
          </p>
          <br />
          <br />
          <h2>EINGESETZTE TRACKING- UND ANALYSETOOLS</h2>
          <br />
          <p>
            <strong>1. Google Analytics</strong>
            <br />
            Auf dieser Webseite wird Google Analytics, ein Web-Analysedienst der Google Inc., 1600 Amphitheatre Parkway,
            Mountainview, CA 94043, USA, genutzt. Google mit Sitz in den USA ist für das us-europäische
            Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden
            Datenschutzniveaus gewährleistet. Web-Analyse ist die Erhebung, Sammlung und Auswertung von Daten über das
            Verhalten von Besuchern von Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten darüber, von
            welcher Internetseite eine betroffene Person auf eine Internetseite gekommen ist (sogenannte Referrer), auf
            welche Unterseiten der Internetseite zugegriffen oder wie oft und für welche Verweildauer eine Unterseite
            betrachtet wurde. Eine Web-Analyse wird überwiegend zur Optimierung einer Internetseite und zur
            Kosten-Nutzen-Analyse von Internetwerbung eingesetzt. Der für die Verarbeitung Verantwortliche verwendet für
            die Web-Analyse über Google Analytics den Zusatz “_gat._anonymizeIp”. Mittels dieses Zusatzes wird die
            IP-Adresse des Internetanschlusses der betroffenen Person von Google gekürzt und anonymisiert, wenn der
            Zugriff auf unsere Internetseiten aus einem Mitgliedstaat der Europäischen Union oder aus einem anderen
            Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum erfolgt. Der Zweck der
            Google-Analytics-Komponente ist die Analyse der Besucherströme auf unserer Internetseite. Google nutzt die
            gewonnenen Daten und Informationen unter anderem dazu, die Nutzung unserer Internetseite auszuwerten, um für
            uns Online-Reports, welche die Aktivitäten auf unseren Internetseiten aufzeigen, zusammenzustellen, und um
            weitere mit der Nutzung unserer Internetseite in Verbindung stehende Dienstleistungen zu erbringen. Google
            Analytics setzt ein Cookie auf dem informationstechnologischen System der betroffenen Person. Was Cookies
            sind, wurde oben bereits erläutert. Mit Setzung des Cookies wird Google eine Analyse der Benutzung unserer
            Internetseite ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für
            die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine Google-Analytics-Komponente integriert
            wurde, wird der Internetbrowser auf dem informationstechnologischen System der betroffenen Person
            automatisch durch die jeweilige Google-Analytics-Komponente veranlasst, Daten zum Zwecke der Online-Analyse
            an Google zu übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google Kenntnis über
            personenbezogene Daten, wie der IP-Adresse der betroffenen Person, die Google unter anderem dazu dienen, die
            Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge Provisionsabrechnungen zu ermöglichen.
            Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der Ort, von
            welchem ein Zugriff ausging und die Häufigkeit der Besuche unserer Internetseite durch die betroffene
            Person, gespeichert. Bei jedem Besuch unserer Internetseiten werden diese personenbezogenen Daten,
            einschließlich der IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an Google in den
            Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den
            Vereinigten Staaten von Amerika gespeichert. Google gibt diese über das technische Verfahren erhobenen
            personenbezogenen Daten unter Umständen an Dritte weiter. Die betroffene Person kann die Setzung von Cookies
            durch unsere Internetseite, wie oben bereits dargestellt, jederzeit mittels einer entsprechenden Einstellung
            des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine
            solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
            informationstechnologischen System der betroffenen Person setzt. Zudem kann ein von Google Analytics bereits
            gesetzter Cookie jederzeit über den Internetbrowser oder andere Softwareprogramme gelöscht werden. Ferner
            besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch Google Analytics erzeugten, auf
            eine Nutzung dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser Daten durch Google zu
            widersprechen und eine solche zu verhindern. Hierzu muss die betroffene Person ein Browser-Add-On unter dem
            Link https://tools.google.com/dlpage/gaoptout herunterladen und installieren. Dieses Browser-Add-On teilt
            Google Analytics über JavaScript mit, dass keine Daten und Informationen zu den Besuchen von Internetseiten
            an Google Analytics übermittelt werden dürfen. Die Installation des Browser-Add-Ons wird von Google als
            Widerspruch gewertet. Wird das informationstechnologische System der betroffenen Person zu einem späteren
            Zeitpunkt gelöscht, formatiert oder neu installiert, muss durch die betroffene Person eine erneute
            Installation des Browser-Add-Ons erfolgen, um Google Analytics zu deaktivieren. Sofern das Browser-Add-On
            durch die betroffene Person oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist, deinstalliert
            oder deaktiviert wird, besteht die Möglichkeit der Neuinstallation oder der erneuten Aktivierung des
            Browser-Add-Ons. Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
            https://www.google.de/intl/de/policies/privacy/ und unter http://www.google.com/analytics/terms/de.html
            abgerufen werden. Google Analytics wird unter diesem Link https://www.google.com/intl/de_de/analytics/
            genauer erläutert.
          </p>
          <br />
          <p>
            <strong>2. Google AdWords</strong>
            <br />
            Auf dieser Webseite wird Google AdWords, ein Analysedienst der Google Inc., 1600 Amphitheatre Parkway,
            Mountainview, CA 94043, USA, genutzt. Google mit Sitz in den USA ist für das us-europäische
            Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden
            Datenschutzniveaus gewährleistet. Google AdWords ist ein Dienst zur Internetwerbung, der es Werbetreibenden
            gestattet, sowohl Anzeigen in den Suchmaschinenergebnissen von Google als auch im Google-Werbenetzwerk zu
            schalten. Google AdWords ermöglicht es einem Werbetreibenden, vorab bestimmte Schlüsselwörter festzulegen,
            mittels derer eine Anzeige in den Suchmaschinenergebnissen von Google ausschließlich dann angezeigt wird,
            wenn der Nutzer mit der Suchmaschine ein schlüsselwortrelevantes Suchergebnis abruft. Im
            Google-Werbenetzwerk werden die Anzeigen mittels eines automatischen Algorithmus und unter Beachtung der
            zuvor festgelegten Schlüsselwörter auf themenrelevanten Internetseiten verteilt. Der Zweck von Google
            AdWords ist die Bewerbung unserer Internetseite durch die Einblendung von interessenrelevanter Werbung auf
            den Internetseiten von Drittunternehmen und in den Suchmaschinenergebnissen der Suchmaschine Google und eine
            Einblendung von Fremdwerbung auf unserer Internetseite. Gelangt eine betroffene Person über eine
            Google-Anzeige auf unsere Internetseite, wird auf dem informationstechnologischen System der betroffenen
            Person durch Google ein sogenannter Conversion-Cookie abgelegt. Was Cookies sind, wurde oben bereits
            erläutert. Ein Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und dient nicht zur
            Identifikation der betroffenen Person. Über den Conversion-Cookie wird, sofern das Cookie noch nicht
            abgelaufen ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der Warenkorb von einem
            Online-Shop-System, auf unserer Internetseite aufgerufen wurden. Durch den Conversion-Cookie können sowohl
            wir als auch Google nachvollziehen, ob eine betroffene Person, die über eine AdWords-Anzeige auf unsere
            Internetseite gelangt ist, einen Umsatz generierte, also einen Warenkauf vollzogen oder abgebrochen hat. Die
            durch die Nutzung des Conversion-Cookies erhobenen Daten und Informationen werden von Google verwendet, um
            Besuchsstatistiken für unsere Internetseite zu erstellen. Diese Besuchsstatistiken werden durch uns wiederum
            genutzt, um die Gesamtanzahl der Nutzer zu ermitteln, welche über AdWords-Anzeigen an uns vermittelt wurden,
            also um den Erfolg oder Misserfolg der jeweiligen AdWords-Anzeige zu ermitteln und um unsere
            AdWords-Anzeigen für die Zukunft zu optimieren. Weder unser Unternehmen noch andere Werbekunden von
            Google-AdWords erhalten Informationen von Google, mittels derer die betroffene Person identifiziert werden
            könnte. Mittels des Conversion-Cookies werden personenbezogene Informationen, beispielsweise die durch die
            betroffene Person besuchten Internetseiten, gespeichert. Bei jedem Besuch unserer Internetseiten werden
            demnach personenbezogene Daten, einschließlich der IP-Adresse des von der betroffenen Person genutzten
            Internetanschlusses, an Google in den Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen
            Daten werden durch Google in den Vereinigten Staaten von Amerika gespeichert. Google gibt diese über das
            technische Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte weiter. Die betroffene
            Person kann die Setzung von Cookies durch unsere Internetseite, wie oben bereits dargestellt, jederzeit
            mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von
            Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten Internetbrowsers würde auch
            verhindern, dass Google einen Conversion-Cookie auf dem informationstechnologischen System der betroffenen
            Person setzt. Zudem kann ein von Google AdWords bereits gesetztes Cookie jederzeit über den Internetbrowser
            oder andere Softwareprogramme gelöscht werden. Ferner besteht für die betroffene Person die Möglichkeit, der
            interessenbezogenen Werbung durch Google zu widersprechen. Hierzu muss die betroffene Person von jedem der
            von ihr genutzten Internetbrowser aus den Link www.google.de/settings/ads aufrufen und dort die gewünschten
            Einstellungen vornehmen. Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können
            unter https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
          </p>
          <br />
          <p>
            <strong>3. Facebook</strong>
            <br />
            Um nutzergruppengesteuertes Marketing in sozialen Netzwerken zu ermöglichen, ist auf dieser Webseite ein
            Tracking des Social Media Dienstes Facebook Inc. mittels eines Pixels eingebunden. Betreibergesellschaft von
            Facebook Pixel ist die Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, USA. Für die Verarbeitung
            personenbezogener Daten Verantwortlicher ist, wenn eine betroffene Person außerhalb der USA oder Kanada
            lebt, die Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland. Beim Besuch
            unserer Webseite wird das Pixel von Ihrem Webbrowser geladen. Dabei werden Informationen an Facebook
            gesendet. Dies betrifft unter anderem die Information, ob in Ihrem Browser Facebook-Cookies gesetzt sind.
            Anhand dieser Information wird die Browsersitzung einer Person zugeordnet. Diese Zuordnung erfolgt
            anonymisiert nur anhand einer Facebook ID, sodass für uns kein Personenbezug herstellbar ist. Es besteht die
            Möglichkeit unter http://optout.aboutads.info/?c=2#!/ verhaltensbasierter Werbung zu widersprechen. Wenn Sie
            hier ein Häkchen setzen, dann wird in Ihrem Browser ein Opt-Out Cookie gesetzt. Zusätzliche Einstellungen
            zur Werbung auf Facebook können Sie dort in Ihrem Nutzerprofil vornehmen. Die von Facebook veröffentlichte
            Datenrichtlinie, die unter https://de-de.facebook.com/about/privacy/ abrufbar ist, gibt Aufschluss über die
            Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch Facebook. Ferner wird dort erläutert,
            welche Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre der betroffenen Person bietet. Zudem
            sind unterschiedliche Applikationen erhältlich, die es ermöglichen, eine Datenübermittlung an Facebook zu
            unterdrücken. Solche Applikationen können durch die betroffene Person genutzt werden, um eine
            Datenübermittlung an Facebook zu unterdrücken.
          </p>
          <br />
          <p>
            <strong>4. Instagram</strong>
            <br />
            Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten und
            durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem
            Instagram-Account eingeloggt sind können Sie durch Anklicken des Instagram-Buttons die Inhalte unserer
            Seiten mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem
            Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
            der übermittelten Daten sowie deren Nutzung durch Instagram erhalten. Weitere Informationen hierzu finden
            Sie in der Datenschutzerklärung von Instagram: http://instagram.com/about/legal/privacy/.
          </p>
          <br />
          <p>
            <strong>5.Xing</strong>
            <br />
            Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des Dienstes Xing, angeboten durch die XING
            AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland, eingebunden werden. Hierzu können z.B. Inhalte wie
            Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes
            innerhalb von Xing teilen können. Sofern die Nutzer Mitglieder der Plattform Xing sind, kann Xing den Aufruf
            der o.g. Inhalte und Funktionen den dortigen Profilen der Nutzer zuordnen. Datenschutzerklärung von Xing:
            https://www.xing.com/app/share?op=data_protection.
          </p>
          <br />
          <p>
            <strong>6. LinkedIn</strong>
            <br />
            Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des Dienstes LinkedIn, angeboten durch die
            LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland, eingebunden werden. Hierzu können z.B.
            Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses
            Onlineangebotes innerhalb von LinkedIn teilen können. Sofern die Nutzer Mitglieder der Plattform LinkedIn
            sind, kann LinkedIn den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der Nutzer zuordnen.
            Datenschutzerklärung von LinkedIn: https://www.linkedin.com/legal/privacy-policy. LinkedIn ist unter dem
            Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten (https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active).
            Datenschutzerklärung: https://www.linkedin.com/legal/privacy-policy, Opt-Out:
            https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
          </p>
          <br />
          <p>
            <strong>7. Youtube</strong>
            <br />
            Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA, ein. Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out:
            https://adssettings.google.com/authenticated. Google Fonts: Wir binden die Schriftarten („Google Fonts“) des
            Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung:
            https://www.google.com/policies/privacy/, Opt-Out: https://adssettings.google.com/authenticated.
          </p>
          <br />
          <h5>§ 12 Newsletter</h5>
          <br />
          <p>
            <strong>(1) Verarbeitungszweck</strong>
            <br />
            Bei Anmeldung zum Newsletter wird Ihre E-Mail-Adresse für Werbezwecke genutzt, d.h. im Rahmen des
            Newsletters informieren wir Sie insbesondere über Dienstleistungen und Produkte aus unserem Sortiment. Zu
            statistischen Zwecken können wir auswerten, welche Links im Newsletter geklickt werden. Dabei ist für uns
            nicht erkennbar, welche konkrete Person geklickt hat. Die nachstehende Einwilligung haben Sie gesondert oder
            ggf. im Verlauf des Bestellprozesses ausdrücklich erteilt: Newsletter abonnieren
          </p>
          <br />
          <p>
            <strong>(2) Rechtsgrundlage</strong>
            <br />
            Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 a) DSGVO.
          </p>
          <br />
          <p>
            <strong>(3) Empfängerkategorien</strong>
            <br />
            ggf. Newsletterversandanbieter
          </p>
          <br />
          <p>
            <strong>(4) Speicherdauer</strong>
            <br />
            Ihre E-Mail-Adresse wird für den Newsletter-Versand nur für die Dauer der gewünschten Anmeldung gespeichert.
          </p>
          <br />
          <p>
            <strong>(5) Widerrufsrecht</strong>
            <br />
            Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Wenn Sie den Newsletter nicht
            weiter beziehen wollen, dann können Sie sich folgendermaßen abmelden: Per E-Mail
          </p>
          <br />
          <h5>§ 13 Rechte der betroffenen Person</h5>
          <br />
          <p>
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen
            folgende Rechte uns gegenüber zu:
          </p>
          <br />
          <p>
            <strong>1. Auskunftsrecht</strong>
            <br />
            Sie können von uns eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
            verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von uns über folgende Informationen
            Auskunft verlangen: <br />
            (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
            <br />
            (2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
            <br />
            (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen
            Daten offengelegt wurden oder noch offengelegt werden;
            <br />
            (4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete
            Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
            <br />
            (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten,
            eines Rechts auf Einschränkung der Verarbeitung durch uns oder eines Widerspruchsrechts gegen diese
            Verarbeitung;
            <br />
            (6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
            <br />
            (7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei
            der betroffenen Person erhoben werden;
            <br />
            (8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1
            und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik
            sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene
            Person.
            <br />
            Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in
            ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie
            verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung
            unterrichtet zu werden.
          </p>
          <br />
          <p>
            <strong>2. Recht auf Berichtigung</strong>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung uns gegenüber, sofern die verarbeiteten
            personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Wir müssen die Berichtigung
            unverzüglich vornehmen.
          </p>
          <br />

          <p>
            <strong>3. Recht auf Einschränkung der Verarbeitung</strong>
            <br />
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden
            personenbezogenen Daten verlangen: <br />
            (1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten für eine Dauer bestreiten, die es
            uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
            <br />
            (2) wenn die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
            stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
            <br />
            (3) wenn wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie diese
            jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
            <br />
            (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht
            feststeht, ob die uns zustehenden berechtigten Gründe gegenüber Ihren Gründen überwiegen.
            <br />
            Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von
            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
            Wurde die Einschränkung der Verarbeitung nach den oben genannten Voraussetzungen eingeschränkt, werden Sie
            von uns unterrichtet, bevor die Einschränkung aufgehoben wird.
          </p>
          <br />
          <p>
            <strong>4. Recht auf Löschung</strong>
            <br />
            a) Löschungspflicht
            <br />
            Sie können von uns verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht
            werden, und wir sind verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe
            zutrifft:
            <br />
            (1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige
            Weise verarbeitet wurden, nicht mehr notwendig.
            <br />
            (2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9
            Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
            <br />
            (3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine
            vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
            Widerspruch gegen die Verarbeitung ein.
            <br />
            (4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
            <br />
            (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
            Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
            <br />
            (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
            Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
            <br />
            b) Information an Dritte Haben wir die Sie betreffenden personenbezogenen Daten öffentlich gemacht und sind
            wir gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so treffen wir unter Berücksichtigung der
            verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um die
            für die Datenverarbeitung Verantwortlichen, die die personenbezogenen Daten verarbeiten, darüber zu
            informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen
            Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
            <br />
            c) Ausnahmen
            <br />
            Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
            <br />
            (1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
            <br />
            (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der
            Mitgliedstaaten, dem wir unterliegen, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen
            Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde;
            <br />
            (3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit.
            h und i sowie Art. 9 Abs. 3 DSGVO;
            <br />
            (4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke
            oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht
            voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
            beeinträchtigt, oder
            <br />
            (5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>
          <br />
          <p>
            <strong>5. Recht auf Unterrichtung</strong>
            <br />
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung uns gegenüber geltend
            gemacht, sind wir verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten
            offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung
            mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
            verbunden.
            <br />
            Ihnen steht uns gegenüber das Recht zu, über diese Empfänger unterrichtet zu werden.
          </p>
          <br />
          <p>
            <strong>6. Recht auf Datenübertragbarkeit</strong>
            <br />
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in
            einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese
            Daten einem anderen Verantwortlichen ohne Behinderung zu übermitteln, sofern
            <br />
            (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO
            oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
            <br />
            (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
            <br />
            In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
            personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies
            technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die
            Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die uns übertragen wurde.
          </p>
          <br />
          <p>
            <strong>7. Widerspruchsrecht</strong>
            <br />
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
            DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Wir verarbeiten die Sie betreffenden personenbezogenen Daten dann nicht mehr, es sei denn, wir können
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
            überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie
            das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
            Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung
            in Verbindung steht. Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
            betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet. Sie haben die Möglichkeit, im
            Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
            2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische
            Spezifikationen verwendet werden.
          </p>
          <br />
          <p>
            <strong>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</strong>
            <br />
            Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den
            Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
            Verarbeitung nicht berührt.
          </p>
          <br />
          <p>
            <strong>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</strong>
            <br />
            Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung - einschließlich
            Profiling - beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
            oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
            <br />
            (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist,
            <br />
            (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen wir unterliegen, zulässig ist
            und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer
            berechtigten Interessen enthalten oder
            <br />
            (3) mit Ihrer ausdrücklichen Einwilligung erfolgt.
            <br />
            Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9
            Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der
            Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden. Hinsichtlich der in (1) und (3)
            genannten Fälle treffen wir angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten
            Interessen zu wahren.
          </p>
          <br />
          <p>
            <strong>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</strong>
            <br />
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das
            Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts,
            Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
            <br />
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den
            Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
            nach Art. 78 DSGVO.
          </p>
          <br />
          <h5>§ 14 Schlusshinweise</h5>
          <br />
          <p>
            Verantwortliche für die Datenverarbeitung: Siehe Impressum auf dieser Webseite:
            https://furnished.de/impressum/
          </p>
          <p>
            Für Fehler im Text oder falsche Links wird keine Haftung übernommen. Für die Inhalte externer Links sind die
            jeweiligen Anbieter verantwortlich.
          </p>
        </CMSContent>
      </Container>
    </Content>
    <PageFooter />
  </PrivacyPolicyStyled>
);
