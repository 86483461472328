import React from 'react';

export const OfficeTablesIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="79" height="42" viewBox="0 0 79 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.0156 6.20124H2.21907M25.0156 6.20124V15.8607M25.0156 6.20124H77.9231V1H1V6.20124H2.21907M2.21907 6.20124V15.8607M2.21907 15.8607H25.0156M2.21907 15.8607V26.1393M25.0156 15.8607V26.1393M2.21907 26.1393H25.0156M2.21907 26.1393V36.1703M25.0156 26.1393V36.1703M25.0156 36.1703V41H2.21907V36.1703M25.0156 36.1703H2.21907M11.484 30.226H16.1164M11.484 19.8235H16.1164M11.484 10.1641H16.1164"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M76.3848 5.61523V40.2902" stroke="black" />
  </svg>
);
