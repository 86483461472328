import React from 'react';

export const TwoStorageCabinetIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.9 0.200195H21.2H1.50003C1.10003 0.200195 0.900024 0.500208 0.900024 0.900208V25.9002C0.900024 26.3002 1.20003 26.6002 1.50003 26.6002H4.80003L3.20003 30.3002C3.10003 30.6002 3.20003 31.0002 3.50003 31.2002C3.60003 31.2002 3.70003 31.3002 3.80003 31.3002C4.10003 31.3002 4.30002 31.2002 4.40002 30.9002L6.20003 26.7002H21.1H36L37.8 30.9002C37.9 31.1002 38.1 31.3002 38.4 31.3002C38.5 31.3002 38.6 31.3002 38.7 31.2002C39 31.1002 39.2 30.7002 39 30.3002L37.4 26.6002H40.7C41.1 26.6002 41.3 26.3002 41.3 25.9002V0.900208C41.5 0.500208 41.2 0.200195 40.9 0.200195ZM2.10004 1.50018H20.5V25.2002H5.80003H2.10004V1.50018ZM40.2 25.2002H36.5H21.8V1.50018H40.2V25.2002Z"
      fill="currentColor"
    />
    <path
      d="M15.4 15.1C16.4 15.1 17.2 14.3 17.2 13.3C17.2 12.3 16.4 11.5 15.4 11.5C14.4 11.5 13.6 12.3 13.6 13.3C13.7 14.3 14.4 15.1 15.4 15.1ZM15.4 12.9C15.7 12.9 15.9 13.1 15.9 13.4C15.9 13.7 15.7 13.9 15.4 13.9C15.1 13.9 14.9 13.7 14.9 13.4C15 13.1 15.2 12.9 15.4 12.9Z"
      fill="currentColor"
    />
    <path
      d="M26.9 15.1C27.9 15.1 28.7 14.3 28.7 13.3C28.7 12.3 27.9 11.5 26.9 11.5C25.9 11.5 25.1 12.3 25.1 13.3C25.2 14.3 26 15.1 26.9 15.1ZM26.9 12.9C27.2 12.9 27.4 13.1 27.4 13.4C27.4 13.7 27.2 13.9 26.9 13.9C26.6 13.9 26.4 13.7 26.4 13.4C26.5 13.1 26.7 12.9 26.9 12.9Z"
      fill="currentColor"
    />
  </svg>
);
