import { h5, styled } from '../../themes';

import { Title } from '../Title';
import { Item as MaterialPickerItem } from '../MaterialPicker/MaterialPicker.styled';

export const ProductOptionStyled = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: 40px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  ${MaterialPickerItem} {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const OptionTitle = styled.legend`
  margin-bottom: 30px;
  ${h5};
`;
