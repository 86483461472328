import { styled, colors } from '../../../../lib/themes';
import { Image } from '../../../../lib/components/Image';
import { Button } from '../../../../lib/components/Button';
import { TrashIcon } from '../../../../lib/icons';

export const TitleWrapper = styled.div`
  margin-bottom: 60px;
`;

export const ProductsTableStyled = styled.table`
  margin-bottom: 100px;
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  line-height: 1.67;
`;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
  padding-right: 40px;
  padding-bottom: 20px;

  &:not(:nth-child(2)) {
    white-space: nowrap;
  }

  &:nth-child(5) {
    font-size: 20px;
    line-height: 1.5;
  }

  &:last-child {
    padding-right: 10px;
  }
`;

export const TableCellCurrency = styled(TableCell)`
  text-align: right;
`;

export const TableBody = styled.tbody`
  ${TableRow} {
    &:last-child {
      ${TableCell} {
        padding-bottom: 30px;
      }
    }
  }
`;

export const TableFoot = styled.tfoot`
  border-top: 1px solid ${colors.grayLighter};

  ${TableCell} {
    padding-top: 46px;

    &:last-child {
      font-size: 25px;
      line-height: 1.2;
    }
  }
`;

export const ProductImage = styled(Image)`
  width: 65px;
  height: 60px;
`;

export const TrashIconStyled = styled(TrashIcon)``;

export const RemoveButton = styled(Button).attrs(() => ({ variant: 'text' }))`
  width: auto;
  min-width: auto;
  height: auto;
  padding: 0;

  & > * {
    &:first-child {
      margin-right: 0;
    }
  }

  &,
  &:focus {
    color: ${colors.grayMedium};
  }
`;

export const TotalAmount = styled.div`
  width: 160px;
  text-align: center;
`;
