import { styled, colors, css } from '../../themes';
import media from '../../utils/media';

export interface ISliderStyledProps {
  bulletsColor: 'bronze' | 'white' | 'black';
  navigationColor: 'bronze' | 'white';
  shadow?: boolean;
}

export const SliderStyled = styled.div<ISliderStyledProps>`
  height: 100%;

  .swiper-slide {
    width: auto;
  }

  .swiper {
    height: 100%;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 30px;

    ${media.phone`
      text-align: left;
      width: auto;
    `};
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 6px;
    background-color: transparent;
    border: 1px solid ${({ bulletsColor }) => colors[bulletsColor]};
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: ${({ bulletsColor }) => colors[bulletsColor]};
    }
  }

  .swiper-button-next {
    right: 58px;
  }

  .swiper-button-prev {
    left: 58px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: none;
    color: ${({ navigationColor }: ISliderStyledProps) => colors[navigationColor]};
  }

  &.styles-slider {
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 60px;
    }
  }
`;
