import React from 'react';
import { RoomParamsStep } from '../../typesUtils';
import { ConfiguratorPageHeader } from '../../components/ConfiguratorPageHeader';
import { PlanTypePage } from './PlanTypePage';
import { RoomStylePage } from './RoomStylePage';
import { RoomParamsPage } from './RoomParamsPage';
import { IConfiguratorParamsPagesProps } from './ConfiguratorParamsPagesProps';

/**
 * This component is responsible for showing updateRoom form,
 * which basically consists of 3 screens: /plan-type /room-style /room-params
 * Current screen is controlled by route in upper component,
 * available screen controlled by common rules and this component itself
 * @param step - step from route URL
 * @param room - already reloaded room
 * f@param changeStep - degelation of current step change
 * @fconstructor
 */

export const ConfiguratorParamsRootStep: React.FC<IConfiguratorParamsPagesProps> = ({ step, room, changeStep }) => {
  const renderPage = () => {
    switch (step) {
      default:
      // case RoomParamsStep.PlanType:
      //   return <PlanTypePage room={room} step={step} changeStep={changeStep} />;
      case RoomParamsStep.RoomStyle:
        return <RoomStylePage room={room} step={step} changeStep={changeStep} />;
      case RoomParamsStep.RoomParams:
        return <RoomParamsPage room={room} step={step} changeStep={changeStep} />;
    }
  };

  return (
    <>
      <ConfiguratorPageHeader room={room} step={step} changeStep={changeStep} />
      {renderPage()}
    </>
  );
};
