import { h1, h5, styled } from '../../../../lib/themes';

export const StyledDescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleRow = styled.div`
  display: flex;
`;

export const DescriptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
`;

export const Title = styled.h2`
  ${h1};
`;

export const Description = styled.p`
  width: 540px;
  ${h5};
`;

export const Tags = styled.div`
  width: 420px;
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  height: 42px;

  padding: 0 35px;
  margin: 0 30px 30px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 39px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;
