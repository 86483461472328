import React from 'react';

export const BankersLampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="24" height="44" viewBox="0 0 24 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23 18.0996L18.1 0.599609C18 0.299609 17.8 0.0996094 17.5 0.0996094H5.99998C5.69998 0.0996094 5.50001 0.299609 5.40001 0.599609L0.499984 18.0996C0.399984 18.2996 0.499959 18.4996 0.599959 18.6996C0.699959 18.8996 0.899959 18.9996 1.09996 18.9996H7.40001V25.2996C6.80001 25.5996 6.29997 26.1996 6.29997 26.8996C6.29997 27.8996 7.09996 28.6996 8.09996 28.6996C9.09996 28.6996 9.90001 27.8996 9.90001 26.8996C9.90001 26.1996 9.39997 25.4996 8.79997 25.2996V18.9996H11.2V36.6996H9.99998C8.59998 36.6996 7.29997 37.2996 6.29997 38.2996L4.99998 39.5996C4.59998 40.0996 4.29997 40.6996 4.29997 41.2996V42.7996C4.29997 43.1996 4.59998 43.3996 4.99998 43.3996H18.4C18.8 43.3996 19.1 43.0996 19.1 42.7996V41.2996C19.1 40.6996 18.9 40.0996 18.4 39.5996L17.1 38.2996C16.1 37.2996 14.8 36.6996 13.4 36.6996H12.3V18.9996H22.3C22.5 18.9996 22.7 18.8996 22.8 18.6996C23 18.4996 23.1 18.2996 23 18.0996ZM16.2 39.0996L17.5 40.3996C17.7 40.5996 17.8 40.8996 17.8 41.1996V42.0996H5.7V41.1996C5.7 40.8996 5.79998 40.5996 5.99998 40.3996L7.29997 39.0996C7.99997 38.3996 8.99998 37.8996 9.99998 37.8996H13.4C14.5 37.8996 15.5 38.3996 16.2 39.0996ZM6.49998 1.3996H17L20.8 14.8996H2.7L6.49998 1.3996ZM1.99998 17.5996L2.40001 16.1996H21.2L21.6 17.5996H1.99998Z"
      fill="currentColor"
    />
    <path
      d="M6.40002 13.4995H6.5C6.8 13.4995 7.09998 13.2995 7.09998 12.9995L9.09998 4.19951C9.19998 3.79951 8.99998 3.49951 8.59998 3.39951C8.29998 3.29951 7.89999 3.49951 7.79999 3.89951L5.79999 12.6995C5.79999 12.9995 6.00002 13.3995 6.40002 13.4995Z"
      fill="currentColor"
    />
  </svg>
);
