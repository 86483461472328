import { styled, colors, h4, body } from '../../themes';
import { Fab } from '../Fab';

export const Label = styled.label`
  ${body};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Input = styled.input.attrs({ type: 'number' })`
  width: 60px;
  margin-top: 5px;
  background: transparent;
  z-index: 1;

  ${h4};

  border: none;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export const Meters = styled.div<{ units: string }>`
  align-self: flex-end;
  position: absolute;
  left: 0;
  span {
    ${h4};
    opacity: 0;
  }
  &::after {
    content: ${({ units }) => `' ${units}'`};
    ${h4};
  }
`;

export const InfoButton = styled(Fab).attrs({ variant: 'info' })`
  width: 40px;
  height: 40px;
  margin-left: auto;
`;

export const DragPin = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;

  width: 32px;
  height: 32px;

  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);

  cursor: pointer;

  z-index: 1;
`;

export const RangeInput = styled.input.attrs({ type: 'range' })<{ progress: number }>`
  appearance: none; /* Hides the slider so that custom slider can be made */
  position: relative;

  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */

  height: 4px;
  background: ${colors.gray};

  margin-top: 20px;

  ::after {
    content: '';
    position: absolute;

    width: ${({ progress }) => `${progress}%`};
    height: 4px;

    background: ${colors.bronze};
  }

  ::-webkit-slider-thumb {
    appearance: none;
    position: relative;

    width: 32px;
    height: 32px;

    border-radius: 50%;
    background: ${colors.white};
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);

    cursor: pointer;
    z-index: 1;
  }
`;

export const StyledSliderEdit = styled.div`
  display: inline-flex;
  flex-direction: column;

  width: 285px;
`;
