import React from 'react';

export const SquareBedIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="49" height="35" viewBox="0 0 49 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M48.1 18.7H45.2C45.3 18.6 45.4 18.4 45.4 18.2V13.8C45.4 13.4 45.1 13.2 44.7 13.2H43V0.600006C43 0.200006 42.7 0 42.3 0H7.40002C7.00002 0 6.70004 0.300006 6.70004 0.600006V13.2H5.00003C4.60003 13.2 4.30002 13.5 4.30002 13.8V18.2C4.30002 18.4 4.40003 18.6 4.50003 18.7H1.60004C1.20004 18.7 0.900024 19 0.900024 19.3V30C0.900024 30.4 1.20004 30.7 1.60004 30.7H4.30002V33.9C4.30002 34.3 4.60003 34.6 5.00003 34.6H13.7C14.1 34.6 14.4 34.3 14.4 33.9V30.7H34.8V33.9C34.8 34.3 35.1 34.6 35.5 34.6H44.2C44.6 34.6 44.9 34.3 44.9 33.9V30.7H48.2C48.6 30.7 48.9 30.4 48.9 30V19.3C48.7 19 48.4 18.7 48.1 18.7ZM8.10004 1.39999H41.7V13.3H35.9V6.5C35.9 6.1 35.6 5.89999 35.2 5.89999H14.4C14 5.89999 13.7 6.2 13.7 6.5V13.3H7.90002V1.39999H8.10004ZM15.2 13.3V7.20001H34.7V13.3H15.2ZM5.70004 18.3V14.6H44.1V18.3C44.1 18.5 44.2 18.7 44.3 18.8H5.50003C5.60003 18.6 5.70004 18.5 5.70004 18.3ZM13.1 33.3H5.70004V30.7H13.1V33.3ZM43.5 33.3H36.1V30.7H43.5V33.3ZM47.4 29.4H2.30002V20H47.4V29.4Z"
      fill="currentColor"
    />
  </svg>
);
