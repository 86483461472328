import React from 'react';
import { useFormikContext } from 'formik';

import { Button, IButtonProps } from '../Button';

interface IFormButton extends IButtonProps {
  isIgnoreValidation?: boolean;
}
/**
 * Use this button in Forms to auto-disable when form is not valid, or validating,or submitting
 * @param {IButtonProps} props
 * @constructor
 */
export const FormButton: React.FC<IFormButton> = props => {
  const { isValid, isValidating, isSubmitting } = useFormikContext();
  const { disabled = false, size = 'medium', isIgnoreValidation = false, ...rest } = props;

  const disable = disabled || (isIgnoreValidation ? false : !isValid);
  const loading = isValidating || isSubmitting;

  return <Button {...rest} size={size} disabled={disable} data-hook-button={disable} isLoading={loading} />;
};
