import { styled, css, applyCssIf, colors, h4, button } from '../../themes';

export interface IStyledToggleButtonProps {
  variant?: string;
  isSelected: boolean;
  isDisabled: boolean;
}

export const StyledToggleButton = styled.button<IStyledToggleButtonProps>`
  all: unset;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 190px;
  height: 72px;

  ${h4};
  color: ${colors.black};

  background-color: ${colors.white};

  border: 1px solid ${colors.gray};
  border-radius: 10px;

  cursor: pointer;

  svg {
    margin-right: 20px;
  }

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  }

  ${({ variant }) =>
    applyCssIf(variant === 'option')(css`
      display: block;
      user-select: none;

      width: 255px;
      height: 42px;
      margin-bottom: 19px;

      ${button};
      text-align: center;

      border: 2px solid ${colors.bronze};
      border-radius: 2px;
      box-shadow: none;

      &:hover {
        color: ${colors.bronzeLight};
        border-color: ${colors.bronzeLight};
        box-shadow: none;
      }
    `)};

  ${({ isSelected }) =>
    applyCssIf(isSelected)(css`
      border-color: ${colors.bronzeLight};

      &:hover {
        color: ${colors.bronze};
      }
    `)};

  ${({ variant, isDisabled }) =>
    applyCssIf(variant === 'option' && isDisabled)(css`
      border: 1px solid ${colors.gray};
      cursor: default;
      color: ${colors.grayMedium};

      &:hover {
        border-color: ${colors.gray};
        color: ${colors.grayMedium};
      }
    `)};
`;
