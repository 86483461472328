import React from 'react';

export const UserIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="14" cy="7.99951" r="7" stroke="currentColor" strokeWidth="2" />
    <path
      d="M26 27.9995C26 20.8198 20.6274 14.9995 14 14.9995C7.37258 14.9995 2 20.8198 2 27.9995"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
