import React from 'react';

export const GearIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.8128 2.88451C12.9112 1.43596 15.0888 1.43596 16.1872 2.88451L16.7801 3.66642C17.3794 4.4568 18.3585 4.86236 19.3411 4.72725L20.3133 4.59359C22.1142 4.34597 23.654 5.8858 23.4064 7.68674L23.2728 8.65886C23.1376 9.64152 23.5432 10.6206 24.3336 11.2199L25.1155 11.8128C26.564 12.9112 26.564 15.0888 25.1155 16.1872L24.3336 16.7801C23.5432 17.3794 23.1376 18.3585 23.2727 19.3411L23.4064 20.3133C23.654 22.1142 22.1142 23.654 20.3133 23.4064L19.3411 23.2728C18.3585 23.1376 17.3794 23.5432 16.7801 24.3336L16.1872 25.1155C15.0888 26.564 12.9112 26.564 11.8128 25.1155L11.2199 24.3336C10.6206 23.5432 9.64152 23.1376 8.65886 23.2727L7.68674 23.4064C5.8858 23.654 4.34597 22.1142 4.59359 20.3133L4.72725 19.3411C4.86236 18.3585 4.4568 17.3794 3.66642 16.7801L2.88451 16.1872C1.43596 15.0888 1.43596 12.9112 2.88451 11.8128L3.66642 11.2199C4.4568 10.6206 4.86236 9.64152 4.72725 8.65886L4.59359 7.68674C4.34597 5.8858 5.8858 4.34597 7.68674 4.59359L8.65886 4.72725C9.64152 4.86236 10.6206 4.4568 11.2199 3.66642L11.8128 2.88451Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <circle cx="14" cy="14" r="4" stroke="currentColor" strokeWidth="2" />
  </svg>
);
