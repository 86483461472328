import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireLog, RequireWorkSettingRepository } from '../available-stores';
import { gqlDocsRoom } from '../gql-documents/gql-room';
import { Mutation, Query, WorkSetting } from '../api/generated';
import { useStore, UseQueryResult, useQuery } from '../hooks/use-store';

export class WorkSettingRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'WorkSettingRepository');
  }

  /**
   * Request and return workSettings list
   */
  getList = (): Promise<WorkSetting[]> => {
    return this.query(gqlDocsRoom.workSettings, 'workSettings');
  };
}

/**
 * Syntax sugar to obtain workSettings list
 */
export function useWorkSettingsList(): UseQueryResult<void, WorkSetting[]> {
  return useQuery(useStore<RequireWorkSettingRepository>('WorkSettingRepository').getList, undefined);
}
