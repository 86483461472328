import React from 'react';

import { SmallLogoIcon } from '../../../../lib/icons';
import { HeaderUserMenu } from '../../../../lib/components/Header/HeaderUserMenu';
import { HeaderUserWrapper, Root, BackHomeLink } from './Header.styled';

export const Header: React.FC = ({ children }) => {
  return (
    <Root>
      {children}
      <HeaderUserWrapper>
        <BackHomeLink to="/">
          <SmallLogoIcon />
        </BackHomeLink>
        <HeaderUserMenu />
      </HeaderUserWrapper>
    </Root>
  );
};
