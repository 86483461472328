import { styled } from '../../../../../../../lib/themes';

export const Root = styled.div`
  [data-id='root'] {
    max-width: fit-content;
  }
  [data-id='wrapper'] {
    justify-content: flex-start;
  }
  > div {
    border: none;
  }
`;

export const Wrapper = styled.div`
  background: #f8f8f8;
  padding-left: 50px;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  button {
    margin-right: 5px !important;
  }
`;
