import { styled, colors, css, applyCssIf, button } from '../../themes';
import { IOpenable, ISelectable } from '../common-types';

export const StyledSelect = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
`;

export const SelectButton = styled.button.attrs({ type: 'button' })<{ isValid: boolean }>`
  position: relative;

  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${button};
  text-align: center;

  color: ${colors.bronze};
  background-color: ${colors.white};

  border: 2px solid ${colors.bronze};
  border-radius: 2px;

  cursor: pointer;
  z-index: 1;

  ${({ isValid }) =>
    applyCssIf(!isValid)(css`
      border: 2px solid ${colors.red};
    `)};
`;

export const Icon = styled.div<IOpenable>`
  ${({ opened }) => css`
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);

    ${applyCssIf(opened)(css`
      transform: translateY(-50%) scaleY(-1);
    `)};
  `};
`;

export const Items = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% - 2px));

  width: 100%;

  display: flex;
  flex-direction: column;

  border: 2px solid ${colors.bronze};
  border-radius: 2px;
  border-top: none;
`;

export const Item = styled.button.attrs({ type: 'button' })<ISelectable>`
  ${({ selected }) => css`
    appearance: none;
    cursor: pointer;

    ${button};
    text-align: center;

    background-color: ${colors.white};
    border: none;

    :hover,
    :focus {
      color: ${colors.white};
      background-color: ${colors.bronze};
    }

    ${applyCssIf(selected)(css`
      color: ${colors.bronze};
    `)}
  `};
`;
