import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { StyleUrn } from '../types';

import { SliderBlock } from './SliderBlock';
import { DescriptionBlock } from './DescriptionBlock';
import { FurnitureBlock } from './FurnitureBlock';
import { MembershipBlock } from './MembershipBlock';

import { Page, PageSection, PageFooter, Container, Header, Tabs } from '../../../lib/components';
import { IRoomDesign, roomDesigns } from '../../fixtures';
import { TabsWrapper } from '../../home-page/components/WhatStylesDoWeOffer/WhatStylesDoWeOffer.styled';
import { Typography } from '../../../lib/themes/typography/Typography';

interface IStyleExamplePageParams {
  styleUrn: StyleUrn;
}

const items = roomDesigns.map(item => ({ name: item.title, value: item.urn, to: `/styles/${item.urn}` }));

export const StyleExamplePage: React.FC = () => {
  const history = useHistory();
  const { styleUrn } = useParams<IStyleExamplePageParams>();

  const [roomDesign, setRoomDesign] = React.useState<IRoomDesign>(roomDesigns[0]);

  React.useEffect(() => {
    const roomDesign = roomDesigns.find(({ urn }) => urn === styleUrn);
    if (roomDesign) {
      setRoomDesign(roomDesign);
    } else {
      history.push(`/styles/${roomDesigns[0].urn}`);
    }
  }, [history, styleUrn]);

  const handleChangeRoomDesign = useCallback(value => {
    const _roomDesign = roomDesigns.find(roomDesign => roomDesign.urn === value);
    setRoomDesign(_roomDesign ?? roomDesigns[0]);
  }, []);

  return (
    <Page>
      <Header backButtonTitle={'Zurück'} backHref={'/'} />
      <PageSection>
        <Container>
          <Typography
            tag="h1"
            variant="h1"
            css={`
              margin-bottom: 60px;
            `}
          >
            Design-Stil Beispiele
          </Typography>
          <TabsWrapper>
            <Tabs items={items} onChange={handleChangeRoomDesign} value={roomDesign.urn} />
          </TabsWrapper>
        </Container>

        <SliderBlock roomDesign={roomDesign} />

        <Container>
          <DescriptionBlock roomDesign={roomDesign} />
        </Container>
      </PageSection>

      {/*<PageSection>*/}
      {/*  <FurnitureBlock roomDesign={roomDesign} />*/}
      {/*</PageSection>*/}

      <MembershipBlock />

      <PageFooter />
    </Page>
  );
};
