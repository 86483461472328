import React, { useCallback } from 'react';
import { Root, Item } from './SubHeader.styled';

interface IItem {
  text: string;
  disabled?: boolean;
}
interface SubHeaderProps {
  items: IItem[];
  onChange: (itemId: number) => unknown;
  selectedId: number;
}

export const SubHeader: React.FC<SubHeaderProps> = ({ items, selectedId, onChange }) => {
  const handleChange = useCallback(
    (itemId: number) => () => {
      onChange(itemId);
    },
    [onChange],
  );

  return (
    <Root>
      {items.map((item, index) => (
        <Item
          key={index}
          isSelected={index + 1 === selectedId}
          onClick={handleChange(index + 1)}
          disabled={item.disabled}
        >
          {item.text}
        </Item>
      ))}
    </Root>
  );
};
