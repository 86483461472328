import { styled, h4 } from '../../../../lib/themes';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  text-align: center;
`;

export const Message = styled.div`
  margin-top: 50px;
  max-width: 740px;
`;

export const Paragraph = styled.p`
  ${h4};
  margin-top: 50px;
`;
