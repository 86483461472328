import React from 'react';

import { Container } from '../../../../../lib/components/Container';
import { FormError } from '../../../../../lib/components/Form/FormError';
import { SliderField } from '../containers/SliderField';
import { CheckboxField } from '../containers/CheckboxField';
import { WorkUnitsField } from '../containers/WorkUnitsField';
import { WorkSettingField } from '../containers/WorkSettingField';
import { FurnitureTypesField } from '../containers/FurnitureTypesField';
import { SmartTipDialog } from '../../../../../lib/components/SmartTipDialog';
import { ConfiguratorPageFooter } from '../../../components/ConfiguratorPageFooter';
import { RoomItemsStep, RoomParamsStep } from '../../../typesUtils';
import { SideSheetTip } from '../../../../../lib/components/SideSheetTip/SideSheetTip';
import { noop } from '../../../../../lib/utils/placeholders';

import { otherParams } from './OtherParamsScreen.mock';

import { ParametersRow, ParametersWrapper, Description, Content, Image, Typography } from './RoomParamsPage.styled';

interface OtherParamsScreenProps {
  step: RoomParamsStep | RoomItemsStep;
}

export const OtherParamsScreen: React.FC<OtherParamsScreenProps> = ({ step }) => {
  const [isTipOpened, setIsTipOpened] = React.useState(false);
  const [tipAnchorRef, setTipAnchorRef] = React.useState<any>();
  const [tipContent, setTipContent] = React.useState<any>();
  const [isBigTip, setIsBigTip] = React.useState(false);

  const handleTipOpen = React.useCallback(
    (refEl: React.RefObject<any>, smartTipContent: React.ReactNode, isBig = false) => {
      if (!smartTipContent) return;
      setIsBigTip(isBig);
      setTipAnchorRef(refEl);
      setTipContent(smartTipContent);
      setIsTipOpened(true);
    },
    [],
  );

  const handleBigTipOpen = React.useCallback(
    (refEl: React.RefObject<any>, smartTipContent: React.ReactNode) => {
      handleTipOpen(refEl, smartTipContent, true);
    },
    [handleTipOpen],
  );

  const handleTipClose = React.useCallback(() => {
    setIsTipOpened(false);
  }, []);

  const setDescription = name => <Description dangerouslySetInnerHTML={{ __html: otherParams[name].description }} />;

  return (
    <>
      <Container
        css={`
          margin-top: 80px;
        `}
      >
        <FormError />
        <ParametersWrapper>
          <ParametersRow>
            {/*
            <SliderField
              onInfoButtonClick={handleTipOpen}
              name="size"
              label="Raumgröße"
              units="m²"
              min={20}
              max={1000}
              step={10}
              smartTipContent={
                <>
                  <Typography tag="h1" variant="button">
                    {otherParams['size'].title}
                  </Typography>
                  {setDescription('size')}
                </>
              }
            />
            */}
            <SliderField
              onInfoButtonClick={handleTipOpen}
              name="staticWorkstations"
              label="Feste Arbeitsplätze"
              min={0}
              max={100}
              step={1}
              smartTipContent={
                <>
                  <Typography tag="h1" variant="button">
                    {otherParams['staticWorkstations'].title}
                  </Typography>
                  {setDescription('staticWorkstations')}
                </>
              }
            />
            {/*
            <SliderField
              onInfoButtonClick={handleTipOpen}
              name="flexibleWorkstations"
              label="Flexible Arbeitsplätze"
              min={0}
              max={100}
              step={1}
              smartTipContent={
                <>
                  <Typography tag="h1" variant="button">
                    {otherParams['flexibleWorkstations'].title}
                  </Typography>
                  {setDescription('flexibleWorkstations')}
                </>
              }
            />
            </ParametersRow>
            <ParametersRow>
            */}
            <CheckboxField
              onInfoButtonClick={handleBigTipOpen}
              name="soundproofingRequired"
              label="Schallschutz"
              smartTipContent={
                <>
                  <Content>
                    <Typography tag="h1" variant="h3">
                      {otherParams['soundproofingRequired'].title}
                    </Typography>
                    {setDescription('soundproofingRequired')}
                  </Content>

                  <Content>
                    {otherParams['soundproofingRequired'].images.map((item, index) => (
                      <Image src={item} key={index} alt={item} />
                    ))}
                  </Content>
                </>
              }
            />

            <CheckboxField
              onInfoButtonClick={handleBigTipOpen}
              name="storageRequired"
              label="Stauraum"
              smartTipContent={
                <>
                  <Content>
                    <Typography tag="h1" variant="h3">
                      {otherParams['storageRequired'].title}
                    </Typography>
                    {setDescription('storageRequired')}
                  </Content>

                  <Content>
                    {otherParams['storageRequired'].images.map((item, index) => (
                      <Image src={item} key={index} alt={item} />
                    ))}
                  </Content>
                </>
              }
            />

            {/*<CheckboxField*/}
            {/*  onInfoButtonClick={handleBigTipOpen}*/}
            {/*  name="spaceRequired"*/}
            {/*  label="Zubehör"*/}
            {/*  smartTipContent={*/}
            {/*    <>*/}
            {/*      <Content>*/}
            {/*        <Typography tag="h1" variant="h3">*/}
            {/*          {otherParams['spaceRequired'].title}*/}
            {/*        </Typography>*/}
            {/*        {setDescription('spaceRequired')}*/}
            {/*      </Content>*/}

            {/*      <Content>*/}
            {/*        {otherParams['spaceRequired'].images.map((item, index) => (*/}
            {/*          <Image src={item} key={index} alt={item} />*/}
            {/*        ))}*/}
            {/*      </Content>*/}
            {/*    </>*/}
            {/*  }*/}
            {/*/>*/}

            {/*
            <CheckboxField
              onInfoButtonClick={handleBigTipOpen}
              name="loungeRequired"
              label="Lounge"
              smartTipContent={
                <>
                  <Content>
                    <Typography tag="h1" variant="h3">
                      {otherParams['loungeRequired'].title}
                    </Typography>
                    {setDescription('loungeRequired')}
                  </Content>

                  <Content>
                    {otherParams['loungeRequired'].images.map((item, index) => (
                      <Image src={item} key={index} alt={item} />
                    ))}
                  </Content>
                </>
              }
            />
          */}
          </ParametersRow>
          {/*
          <ParametersRow>
            <WorkUnitsField name="workUnitId" label="Arbeitseinheiten" />
            <WorkSettingField name="workSettingId" label="Arbeitsweise" />
            <FurnitureTypesField
              onInfoButtonClick={handleBigTipOpen}
              name="furnitureTypeId"
              label="Möbelart"
              smartTipContent={
                <>
                  <Content>
                    <Typography tag="h1" variant="h3">
                      {otherParams['furnitureTypeId'].title}
                    </Typography>
                    {setDescription('furnitureTypeId')}
                  </Content>

                  <Content>
                    {otherParams['furnitureTypeId'].images.map((item, index) => (
                      <Image src={item} key={index} alt={item} />
                    ))}
                  </Content>
                </>
              }
            />
          </ParametersRow>
          */}

          {tipAnchorRef &&
            (isBigTip ? (
              <SideSheetTip isOpened={isTipOpened} onClose={handleTipClose}>
                {tipContent}
              </SideSheetTip>
            ) : (
              <SmartTipDialog anchorRef={tipAnchorRef} isOpened={isTipOpened} onClose={handleTipClose}>
                {tipContent}
              </SmartTipDialog>
            ))}
        </ParametersWrapper>
      </Container>
      <ConfiguratorPageFooter
        step={step}
        changeStep={noop}
        // prevStep={AllRoomSteps.RoomStyle}
        nextTitle="Planung starten"
      />
    </>
  );
};
