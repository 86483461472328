import React from 'react';

export const CounterStoolIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="26" height="48" viewBox="0 0 26 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.2 23.7004H22.7L23.7 1.6004C23.7 1.4004 23.6 1.3004 23.5 1.1004C23.4 1.0004 23.2 0.900391 23 0.900391H20C19.7 0.900391 19.4 1.2004 19.4 1.5004L19.3 3.20041C19.2 3.20041 19.1 3.1004 19 3.1004H7.39999C7.29999 3.1004 7.20001 3.10041 7.10001 3.20041L7 1.5004C7 1.2004 6.69999 0.900391 6.39999 0.900391H3.39999C3.19999 0.900391 3.09999 1.0004 2.89999 1.1004C2.79999 1.2004 2.70001 1.4004 2.70001 1.6004L3.70001 23.7004H2.20001C1.20001 23.7004 0.299988 24.5004 0.299988 25.6004V28.7004C0.299988 29.1004 0.599994 29.4004 0.899994 29.4004H2.60001L1.60001 46.5004C1.60001 46.7004 1.69999 46.9004 1.79999 47.0004C1.89999 47.1004 2.09999 47.2004 2.29999 47.2004H5.29999C5.59999 47.2004 5.89999 46.9004 5.89999 46.6004L6.60001 35.0004H19.6L20.3 46.6004C20.3 46.9004 20.6 47.2004 20.9 47.2004H23.9C24.1 47.2004 24.2 47.1004 24.4 47.0004C24.5 46.9004 24.6 46.7004 24.6 46.5004L23.6 29.4004H25.3C25.7 29.4004 25.9 29.1004 25.9 28.7004V25.6004C26.1 24.5004 25.3 23.7004 24.2 23.7004ZM20.7 2.20041H22.4L21.5 23.7004H19.8L20.7 2.20041ZM8.10001 23.7004L7.89999 19.5004H18.6L18.4 23.7004H8.10001ZM7.70001 14.5004H18.8L18.6 18.2004H7.79999L7.70001 14.5004ZM18.9 13.2004H7.60001L7.39999 9.5004H19L18.9 13.2004ZM7.5 4.5004H19.1C19.2 4.5004 19.3 4.5004 19.3 4.5004L19.1 8.30038C19.1 8.30038 19.1 8.30038 19 8.30038H7.39999C7.39999 8.30038 7.39999 8.30038 7.29999 8.30038L7.10001 4.5004C7.30001 4.5004 7.4 4.5004 7.5 4.5004ZM4.10001 2.20041H5.79999L6.70001 23.7004H5L4.10001 2.20041ZM4.89999 45.8004H3.20001L4.10001 29.3004H5.79999L4.89999 45.8004ZM6.79999 33.5004L7 29.3004H19.4L19.6 33.5004H6.79999ZM23.4 45.8004H21.7L20.8 29.3004H22.5L23.4 45.8004ZM24.8 28.0004H23.1H20.1H6.5H3.5H1.79999V25.5004C1.79999 25.2004 1.99999 25.0004 2.39999 25.0004H4.60001H7.60001H19.2H22.2H24.4C24.7 25.0004 25 25.2004 25 25.5004V28.0004H24.8Z"
      fill="currentColor"
    />
  </svg>
);
