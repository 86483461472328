import { styled, css, colors, h4 } from '../../themes';
import media from '../../utils/media';

export const Card = styled.div<{ isOpened: boolean }>`
  position: absolute;
  right: 0;
  width: 832px;
  height: 100%;
  background-color: ${colors.white};
  ${({ isOpened }) =>
    isOpened
      ? css`
          animation: slideIn 0.4s ease-out;
        `
      : css`
          animation: slideOut 0.4s ease-out;
        `};

  &:before {
    content: '!';
    position: absolute;
    top: 10px;
    right: 808px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${h4};
    text-align: center;
    color: ${colors.white};
    width: 48px;
    height: 48px;
    background-color: ${colors.bronze};
    border-radius: 50%;
    z-index: 1;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 66px 52px;
  overflow-y: scroll;
  & > div:first-child {
    margin-right: 90px;
  }
`;

export const CloseButton = styled.button`
  appearance: none;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  color: ${colors.gray};

  border: none;
  background: transparent;
`;

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  z-index: 1000;
`;
