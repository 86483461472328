import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireFurnitureTypeRepository, RequireLog } from '../available-stores';
import { gqlDocsRoom } from '../gql-documents/gql-room';
import { FurnitureType, Mutation, Query } from '../api/generated';
import { useStore, UseQueryResult, useQuery } from '../hooks/use-store';

export class FurnitureTypeRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'FurnitureTypeRepository');
  }

  /**
   * Request and return furniture types list
   */
  getList = (): Promise<FurnitureType[]> => {
    return this.query(gqlDocsRoom.furnitureTypes, 'furnitureTypes');
  };
}

/**
 * Syntax sugar to obtain furniture types list
 */
export function useFurnitureTypesList(): UseQueryResult<void, FurnitureType[]> {
  return useQuery(useStore<RequireFurnitureTypeRepository>('FurnitureTypeRepository').getList, undefined);
}
