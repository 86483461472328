import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

interface IPaymentPayPalSuccessProps {
  token: string;
  PayerID: string;
}

/*
 * This page is for redirection from PayPal
 * The purpose is to catch parameters and pass them to the initial payment page
 * (Example: https://furnished.de/payment/paypal/action/success?token=EC-49195534V0804912G&PayerID=JETCTFW7TKAPW)
 * */
export const PaymentPayPalSuccess: React.FC = () => {
  const { search } = useLocation<IPaymentPayPalSuccessProps>();

  const { token, PayerID: payerId } = queryString.parse(search) as {
    [key: string]: string;
  };

  useLayoutEffect(() => {
    window.opener?.postMessage({ token, payerId }, '*');
    window.close();
  }, [payerId, token]);

  return null;
};
