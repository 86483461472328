import React, { useState, ReactNode, useCallback, useContext } from 'react';
import { ConfirmationModal } from './ConfirmationModal';
import { ConfirmationModalsState, ModalParams, ModalType } from './types';

const ConfirmationModalContext = React.createContext<any>(undefined);
const { Provider } = ConfirmationModalContext;

const ConfirmationModalWrapper: React.FC<ModalType> = ({ title, message, id, onAgree, onCancel, component, size }) => {
  const [isOpened, setIsOpened] = useState(true);
  const Context = useContext<ConfirmationModalsState>(ConfirmationModalContext);

  const handleAgree = useCallback(() => {
    onAgree && onAgree();
    setIsOpened(false);
  }, [onAgree]);

  const handleChancel = useCallback(() => {
    onCancel && onCancel();
    setIsOpened(false);
  }, [onCancel]);

  const handleDetach = useCallback(() => {
    Context.remove(id);
  }, [Context, id]);

  return (
    <ConfirmationModal
      message={message}
      title={title}
      isOpened={isOpened}
      onAgree={handleAgree}
      onCancel={handleChancel}
      onDetach={handleDetach}
      component={component}
      css={`
        width: ${size?.width};
        height: ${size?.height};
      `}
    />
  );
};

const ConfirmationModalProvider = (props: { children?: ReactNode }) => {
  const add = ({ title, message, onAgree, onCancel, component, size }: ModalParams): number => {
    let uniqueId;

    setState((curState: ConfirmationModalsState) => {
      if (curState.modals.find(other => other.message === message && other.title === title)) {
        return curState;
      }

      uniqueId = curState.idCounter + 1;

      const modal: ModalType = {
        title,
        size,
        message,
        component,
        onAgree,
        onCancel,
        id: uniqueId,
      };

      return {
        ...curState,
        idCounter: uniqueId,
        modals: [...curState.modals, modal],
      };
    });

    return uniqueId;
  };

  const remove = (id: number) => {
    setState(curState => {
      //remove the toast message using unique id
      const modals = curState.modals.filter((modal: ModalType) => modal.id !== id);
      return {
        ...curState,
        modals,
      };
    });
  };

  const removeAll = () => {
    setState(curState => {
      return {
        ...curState,
        modals: [],
      };
    });
  };

  const [state, setState] = useState<ConfirmationModalsState>({
    modals: [],
    add,
    remove,
    removeAll,
    idCounter: 0,
  });

  return (
    <Provider value={state}>
      {props.children}
      {state.modals.map(props => (
        <ConfirmationModalWrapper key={props.id} {...props} />
      ))}
    </Provider>
  );
};

export { ConfirmationModalContext, ConfirmationModalProvider };
