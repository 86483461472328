import React, { useCallback, useState } from 'react';
import { useFormikContext, useField } from 'formik';

import { ImageCardsScreen } from '../containers/ImageCardScreen';
import { ImageCardDefault } from '../containers/ImageCardScreen/components/ImageCardDefault';
import { SideSheet } from '../../../../../lib/components/SideSheet';
import { RoomDesignExample } from '../containers/RoomDesignExample';
import { Button } from '../../../../../lib/components/Button';

import { IRoomDesign, roomDesigns, roomTypes } from '../../../../fixtures';
import { AlertIcon } from '../../../../../lib/icons';

interface RoomDesignScreenProps {
  onBack: () => any;
}

export const RoomDesignScreen: React.FC<RoomDesignScreenProps> = ({ onBack }) => {
  const { submitForm } = useFormikContext();
  const [roomTypeField] = useField<string>('roomTypeId');

  const [roomDesign, setRoomDesign] = useState<IRoomDesign | null>(null);
  const [isShowRoomDesignExample, setIsShowRoomDesignExample] = useState(false);

  const handleOnShowExampleClick = useCallback(
    (roomDesign: IRoomDesign) => e => {
      e.stopPropagation();
      setRoomDesign(roomDesign);
      setIsShowRoomDesignExample(true);
    },
    [],
  );

  const handleOnCloseExample = useCallback(() => {
    setIsShowRoomDesignExample(false);
  }, []);

  const imageCardsGenerator = useCallback(
    () =>
      roomDesigns.map(roomDesign => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const roomTypeUrn = roomTypes.find(({ id }) => roomTypeField.value === id)!.urn;

        const { id, chooseStyleCover, title } = roomDesign;
        return {
          value: id,
          component: ({ onClick, isActive }) => (
            <ImageCardDefault name={title} cover={chooseStyleCover[roomTypeUrn]} onClick={onClick} isActive={isActive}>
              <div
                css={`
                  opacity: 0;
                  margin-top: -150px;
                  margin-bottom: 90px;
                `}
              >
                <Button
                  variant="outlined"
                  color="white"
                  onClick={handleOnShowExampleClick(roomDesign)}
                  css={`
                    background-color: transparent;
                  `}
                  iconEnd={<AlertIcon />}
                >
                  Design-Beispiele
                </Button>
              </div>
            </ImageCardDefault>
          ),
        };
      }),
    [handleOnShowExampleClick, roomTypeField.value],
  );

  return (
    <>
      <ImageCardsScreen
        title="1.2 Wie soll Ihr Raum aussehen?"
        fieldName="roomDesignId"
        imageCardsGenerator={imageCardsGenerator}
        onChange={submitForm}
        onBack={onBack}
      />
      <SideSheet onClose={handleOnCloseExample} isOpened={isShowRoomDesignExample}>
        {roomDesign && <RoomDesignExample roomDesign={roomDesign} />}
      </SideSheet>
    </>
  );
};
