import React, { useEffect } from 'react';

import { MobileStubStyled, Content, Title, Description, Logo, Header } from './MobileStub.styled';

export const MobileStub: React.FC = () => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return (
    <MobileStubStyled>
      <Header>
        <Logo />
      </Header>
      <Content>
        <Title>
          Bitte benutzen Sie die
          <br />
          Desktop-Version
        </Title>
        <Description>
          Derzeit ist die mobile Version nicht
          <br />
          verfügbar, aber wir arbeiten daran.
        </Description>
      </Content>
    </MobileStubStyled>
  );
};
