// TODO: Translations for the errors text

import { CoreError } from '../../core/errors/core-error';
import { NetworkError } from '../../core/errors/network-error';
import { GqlError, GqlErrors } from '../../core/errors/gql-error';
import { ErrorTranslation } from '../Form';
import React, { ReactElement } from 'react';

/**
 * Type which determines CoreError class constructor,
 * You need to pass the class itself, not one of its instances
 * see defaultApiErrorsHR
 */
export type CoreErrorConstructor = new (...args: any[]) => CoreError;

/**
 * Fallback HR text for unknown or useless error for a user.
 * "Something went wrong, please try again."
 */
const unknownErrorHr = 'Etwas ist schief gelaufen. Bitte versuche es erneut.';

/**
 * These are default CoreErrors interpretations used in Form
 * to show errors to user
 * @type {any[]}
 */
export const defaultApiErrorsHR: [CoreErrorConstructor, ErrorTranslation][] = [
  // Have no access to the resource
  [GqlErrors.UNAUTHORIZED, 'Sie haben keinen Zugriff auf eine Ressource.'],

  // Please note, all actions, concerning handling unauthenticated errors
  // and redirects are handled in ApolloStore & AuthRepository
  [GqlErrors.UNAUTHENTICATED, 'Bitte einloggen.'],

  // Could not connect to servers, please try again.
  [NetworkError, 'Es konnte keine Verbindung zu Servern hergestellt werden. Bitte versuchen Sie es erneut.'],

  // Something wrong happened with server, please try again.
  [GqlError, 'Es ist ein Fehler mit dem Server aufgetreten. Bitte versuchen Sie es erneut.'],

  // Something went wrong, please try again.
  [CoreError, unknownErrorHr],
];

/**
 * Merges errorsMap with defaultApiErrorsHR and find the first (in array/map order) matching error translation.
 * Provided errorsMap translations have higher priority to the defaultApiErrorsHR.
 * @param error
 * @param errorsMap
 */
export function translateErrortoHR(
  error: CoreError | unknown,
  errorsMap?: Map<CoreErrorConstructor, ErrorTranslation> | [CoreErrorConstructor, ErrorTranslation][],
): string | ReactElement {
  const errorsFullMap = Array.from(new Map<CoreErrorConstructor, ErrorTranslation>(errorsMap || []).entries()).concat(
    defaultApiErrorsHR,
  );

  if (error instanceof CoreError) {
    const firstErrorMatch = errorsFullMap.find((hr: [CoreErrorConstructor, string]) => error instanceof hr[0]);
    if (firstErrorMatch) {
      const hrValue = firstErrorMatch[1];
      return typeof hrValue === 'function' ? hrValue(error) : hrValue;
    }
  }

  return unknownErrorHr;
}
