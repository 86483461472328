import React, { useEffect, useState } from 'react';

import { Slider } from '../../../../lib//components';

import { slides } from './IntoductionWindow.mock';

import { Slide, Image, Title, Description, ModalStyled, ArrowIcon, Button } from './IntroductionWindow.styled';

export const IntroductionWindow: React.FC = () => {
  const swiperRef = React.useRef<any>(null);

  const [isOpenModal, setOpenModal] = useState<boolean>(true);
  const [indexSlide, setIndexSlide] = useState<number>(0);

  const slideChangeHandler = () => {
    swiperRef.current?.swiper && setIndexSlide(swiperRef.current.swiper.activeIndex);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleNext = () => {
    swiperRef.current.swiper.slideNext(500);
  };

  const handleEndClick = () => {
    handleCloseModal();
  };

  return (
    <ModalStyled
      isOpened={isOpenModal}
      onClose={handleCloseModal}
      noPadding={true}
      width="1038px"
      height="656px"
      colorButton="default"
    >
      <Slider
        ref={swiperRef}
        slidesPerView={1}
        on={{
          slideChange: slideChangeHandler,
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        simulateTouch={false}
        speed={800}
        bulletsColor="black"
        className="styles-slider"
      >
        {slides.map((item, index) => (
          <Slide key={index}>
            <Image src={item.image} alt={item.image} />
            <Title>{item.title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: item.description }} />
          </Slide>
        ))}
      </Slider>
      {indexSlide === slides.length - 1 ? (
        <Button variant="contained" size="large" color="bronze" onClick={handleEndClick}>
          schließen
          <ArrowIcon />
        </Button>
      ) : (
        <Button variant="contained" size="large" color="bronze" onClick={handleNext}>
          Weiter
          <ArrowIcon />
        </Button>
      )}
    </ModalStyled>
  );
};
