import { gql } from '@apollo/client';

export const accountGql = `{
  id
  name
  roomLimit
  companyImageUrl
  expiresAt
  projects {
    id
    name
    accountId
    rooms {
      id
      name
      roomStatus
      total {
        originalTotalPrice
        originalTotalPriceInclTax
        discountAmount
        taxAmount
        totalPrice
        totalPriceInclTax
        totalItems
      }
    }
  }
}`;

export const gqlDocsAccount = {
  getAccount: gql`
    query account($id: String!) {
      account(id: $id) ${accountGql}
    }
  `,
};
