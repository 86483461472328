import { h3, styled } from '../../../../../../lib/themes';
import { FlexContainer } from '../../../../../../lib/components/FlexContainer';
import media from '../../../../../../lib/utils/media';

export const Options = styled.div``;

export const OptionsContainer = styled(FlexContainer)`
  ${media.tablet`
    flex-wrap: wrap;
  `}
`;

export const OptionsTitle = styled.h3`
  ${h3};
  margin-bottom: 45px;
`;
