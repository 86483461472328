import { styled, colors } from '../../../themes';
import { LogoIcon } from '../../../icons';

export const Logo = styled(LogoIcon)`
  width: 170px;
  height: auto;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  color: ${colors.bronze};
`;
