import { colors, styled } from '../../themes';

export const Root = styled.button`
  all: unset;
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
  color: ${colors.black};
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    color: ${colors.bronze};
  }

  &:active {
    opacity: 0.2;
  }
`;
