import React, { useCallback } from 'react';

import { CrossIcon } from '../../icons';
import { SmartTipStyled, CloseButton } from './SmartTip.styled';

export interface ISmartTipProps {
  fill: boolean;
  closable: boolean;
  isDialog: boolean;
  children: React.ReactNode;
  onClose: () => unknown;
}

const defaultProps: ISmartTipProps = {
  children: '',
  fill: false,
  closable: false,
  isDialog: false,
  onClose: () => {},
};

export const SmartTip: React.FC<Partial<ISmartTipProps>> = props => {
  const { children, fill, closable, isDialog, onClose }: ISmartTipProps = { ...defaultProps, ...props };

  const handleClose = useCallback(
    e => {
      e.preventDefault();
      onClose();
    },
    [onClose],
  );

  return (
    <SmartTipStyled fill={fill} isDialog={isDialog}>
      {children}
      {closable && (
        <CloseButton onClick={handleClose}>
          <CrossIcon />
        </CloseButton>
      )}
    </SmartTipStyled>
  );
};
