import React from 'react';

export const ReceptionIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="82" height="43" viewBox="0 0 82 43" fill="none">
    <path
      d="M36.3083 7.06546V4.45541C36.3083 3.05 37.9231 1 40.8027 1C43.6824 1 45.2972 2.74356 45.2972 5.45928C45.2972 8.175 42.6351 7.86856 39.7848 9.87629C39.3776 10.2778 39.174 10.6794 39.174 11.0809V15.4979C38.9704 15.8995 38.5632 15.8995 38.156 16.1003L3.54493 33.7683C1.50899 34.7722 0.694608 36.5791 1.1018 38.3861C1.50899 40.3938 3.74853 42 6.39526 42H75.6174C78.2642 42 80.3001 40.5946 80.9109 38.3861C81.3181 36.5791 80.3001 34.7722 78.4678 33.7683L43.6531 16.1003"
      stroke="#282826"
      strokeMiterlimit="10"
    />
  </svg>
);
