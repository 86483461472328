import { gql } from '@apollo/client';
import { accountGql } from './gql-account';

const user = `{
  id
  username
  email
  firstName
  lastName
  locale
  emailConfirmStatus
  memberships {
    account {
      id
      name
      roomLimit
      companyImageUrl
      expiresAt
    }
    role
  }
  addresses {
    firstName
    lastName
    street
    city
    postcode
    countryId
    telephone
    company
  }
  vatId
  position
}`;

export const gqlDocsUser = {
  me: gql`
    query me {
      me {
        id
        username
        email
        firstName
        lastName
        locale
        emailConfirmStatus
        memberships {
          account ${accountGql}
          role
        }
        addresses {
          firstName
          lastName
          street
          city
          postcode
          countryId
          telephone
          company
        }
        vatId
        position
      }
    }
  `,
  updateAddress: gql`
    mutation updateAddress(
      $firstName: String!
      $lastName: String!
      $street: String!
      $city: String!
      $postcode: String!
      $countryId: String!
      $telephone: String!
      $company: String
    ) {
      updateAddress(
        firstName: $firstName
        lastName: $lastName
        street: $street
        city: $city
        countryId: $countryId
        postcode: $postcode
        telephone: $telephone
        company: $company
      ) ${user}
    }
  `,
  updateUser: gql`
    mutation updateUser(
      $username: String
      $email: String
      $firstName: String
      $lastName: String
      $vatId: String
      $position: String
      $subscribeToNewsletter: Boolean
    ) {
      updateUser(
        username: $username
        email: $email
        firstName: $firstName
        lastName: $lastName
        vatId: $vatId
        position: $position
        subscribeToNewsletter: $subscribeToNewsletter
      ) ${user}
    }
  `,
  updateEmail: gql`
    mutation updateEmail($email: String!) {
      updateEmail(email: $email) {
        email
      }
    }
  `,
  updateAccount: gql`
    mutation updateAccount($id: String!, $name: String!, $companyImage: Upload) {
      updateAccount(id: $id, name: $name, companyImage: $companyImage) {
        name
        companyImageUrl
      }
    }
  `,

  updatePassword: gql`
    mutation updatePassword($pass: String!, $oldPass: String!) {
      updatePassword(pass: $pass, oldPass: $oldPass)
    }
  `,
};
