import React from 'react';

import { RadioGroupField } from './RadioGroupField';

export const workSettings = [
  { value: '-1', label: 'Sitzend' },
  { value: '-2', label: 'Stehend' },
  { value: '-3', label: 'Gemischt' },
];

interface IWorkSettingFieldProps {
  name: string;
  label: string;
}

export const WorkSettingField: React.FC<IWorkSettingFieldProps> = ({ name, label }) => {
  return <RadioGroupField name={name} title={label} items={workSettings} />;
};
