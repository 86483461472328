import React from 'react';

export const MeetingTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="63" height="26" viewBox="0 0 63 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H61.5769C61.8531 0 62.0769 0.223858 62.0769 0.5V3.95362C62.0769 4.22976 61.8531 4.45362 61.5769 4.45362C44.2687 4.45362 0.917069 4.45362 0.5 4.45362C0.223858 4.45362 0 4.22977 0 3.95362V0.5ZM1 3.45362V1H61.0769V3.45362L1 3.45362Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M60 25.5V3.5H61V25.5H60Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1 25.5V3.5H2V25.5H1Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.99999 14L19.1181 3.53952L19.6181 4.40555L1.49999 14.866L0.99999 14Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.1181 14.866L42 4.40555L42.5 3.53952L60.6181 14L60.1181 14.866Z"
      fill="currentColor"
    />
  </svg>
);
