import { styled, colors } from '../../../../lib/themes';

export const Container = styled.div`
  position: relative;
`;

export const Counter = styled.span`
  position: absolute;
  left: -50px;
  transform: translateX(-100%);

  @media (max-width: 1399px) {
    position: relative;
    left: 0;
    margin-right: 40px;
    transform: translateX(0);
  }

  &.substep-counter {
    position: static;
    left: 0;
    margin-right: 50px;
    transform: none;
  }
`;

export const Gray = styled.span`
  color: ${colors.gray};
`;
