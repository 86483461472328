import { useEffect } from 'react';

/**
 * Hook that handles clicks outside of the passed ref
 */
export function useEscape(callback: () => any) {
  useEffect(() => {
    function handleEscape(event) {
      if (event.key === 'Escape') {
        callback();
      }
    }

    window.document.addEventListener('keydown', handleEscape);

    return () => {
      window.document.removeEventListener('keydown', handleEscape);
    };
  }, [callback]);
}
