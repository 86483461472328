import React from 'react';
import { IRoomDesign } from '../../../../../fixtures';

import { Content, Title, TopContent, Description, Images, Image } from './RoomDesignExample.styled';

export interface IRoomDesignExample {
  roomDesign: IRoomDesign;
}

export const RoomDesignExample = ({ roomDesign }: IRoomDesignExample) => {
  const {
    tip: { title, description, images },
  } = roomDesign;

  const firstImages = images.slice(0, 2);
  const secondImages = images.slice(2);

  return (
    <Content>
      <Title>{title}</Title>
      <TopContent>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Images>
          {firstImages.map((image, index) => (
            <Image src={image} key={index} />
          ))}
        </Images>
      </TopContent>
      {secondImages.map((image, index) => (
        <Image src={image} key={index} />
      ))}
    </Content>
  );
};
