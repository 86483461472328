import React from 'react';

export const DrawersWardrobeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="39" height="54" viewBox="0 0 39 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.7 27.7002C14.3 27.7002 14.1 28.0002 14.1 28.4002V30.6002C14.1 31.0002 14.4 31.3002 14.7 31.3002C15.1 31.3002 15.3 31.0002 15.3 30.6002V28.4002C15.3 28.0002 15 27.7002 14.7 27.7002Z"
      fill="currentColor"
    />
    <path
      d="M11.6 44.5996H9.39999C8.99999 44.5996 8.79999 44.8996 8.79999 45.2996C8.79999 45.6996 9.09999 45.9996 9.39999 45.9996H11.6C12 45.9996 12.2 45.6996 12.2 45.2996C12.2 44.8996 11.9 44.5996 11.6 44.5996Z"
      fill="currentColor"
    />
    <path
      d="M27.5 45.9H29.7C30.1 45.9 30.3 45.6 30.3 45.2C30.3 44.8 30 44.5 29.7 44.5H27.5C27.1 44.5 26.9 44.8 26.9 45.2C26.9 45.6 27.1 45.9 27.5 45.9Z"
      fill="currentColor"
    />
    <path
      d="M24.4 27.7002C24 27.7002 23.8 28.0002 23.8 28.4002V30.6002C23.8 31.0002 24.1 31.3002 24.4 31.3002C24.8 31.3002 25.1 31.0002 25.1 30.6002V28.4002C25 28.0002 24.7 27.7002 24.4 27.7002Z"
      fill="currentColor"
    />
    <path
      d="M14.7 5.2998C14.3 5.2998 14.1 5.59982 14.1 5.99982V8.19983C14.1 8.59983 14.4 8.89981 14.7 8.89981C15.1 8.89981 15.3 8.59983 15.3 8.19983V5.99982C15.3 5.59982 15 5.2998 14.7 5.2998Z"
      fill="currentColor"
    />
    <path
      d="M24.4 8.80017C24.8 8.80017 25.1 8.50019 25.1 8.10019V5.90018C25.1 5.50018 24.8 5.2002 24.4 5.2002C24 5.2002 23.8 5.50018 23.8 5.90018V8.10019C23.7 8.50019 24 8.80017 24.4 8.80017Z"
      fill="currentColor"
    />
    <path
      d="M37.6 0.799805H19.5H1.39999C0.999986 0.799805 0.799988 1.09982 0.799988 1.49982V12.6998V41.4998V49.2998C0.799988 49.6998 1.09999 49.9998 1.39999 49.9998H3.79999V52.8998C3.79999 53.2998 4.09999 53.5998 4.39999 53.5998C4.79999 53.5998 4.99998 53.2998 4.99998 52.8998V49.9998H19.4H33.8V52.8998C33.8 53.2998 34.1 53.5998 34.4 53.5998C34.7 53.5998 35 53.2998 35 52.8998V49.9998H37.4C37.8 49.9998 38 49.6998 38 49.2998V41.4998V12.6998V1.49982C38.3 1.09982 38 0.799805 37.6 0.799805ZM2.09998 13.1998H18.9V40.6998H2.09998V13.1998ZM20.2 13.1998H37V40.6998H20.2V13.1998ZM37 11.8998H20.2V1.99982H37V11.8998ZM2.09998 2.09982H18.9V11.9998H2.09998V2.09982ZM2.09998 41.9998H18.9V48.4998H2.09998V41.9998ZM37 48.4998H20.2V41.9998H37V48.4998Z"
      fill="currentColor"
    />
  </svg>
);
