import { colors, styled } from '../../../../lib/themes';
import { Fab } from '../../../../lib/components/Fab';
import React from 'react';

export const RoomPicture = styled.img`
  width: 100%;
  height: auto;
  display: block;
  transition: height 0.5s;
  background: ${colors.gray};
`;

export const RoomPictureWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IFabStyled {
  positionX: number;
  positionY: number;
}

export const FabStyled = styled(Fab)<IFabStyled & React.HTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  top: calc(${props => props.positionY}% - 25px);
  left: calc(${props => props.positionX}% - 25px);
`;
