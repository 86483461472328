import { colors, css, h5, styled } from '../../../../lib/themes';
import React from 'react';

export const Img = styled.img`
  border-radius: 50%;
  overflow: hidden;
  width: 275px;
  height: 275px;
  border: 2px solid ${colors.grayLighter};
`;

export const Info = styled.div`
  margin-bottom: 40px;
`;

type UploadTextProp = {
  isInvalid: boolean;
};

export const Text = styled.p<UploadTextProp>`
  ${h5};
  color: ${colors.grayMedium};

  ${p =>
    p.isInvalid &&
    css`
      color: ${colors.red};
    `}
`;

export const Buttons = styled.div``;

export const FileInput = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 130px;
  justify-content: center;
`;
