import React, { Component, ReactNode } from 'react';

import { Autoplay, EffectFade, Keyboard, Lazy, Navigation, Pagination, Swiper } from 'swiper';
import { LazyOptions, SwiperOptions } from 'swiper/types';

// import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/keyboard';
import 'swiper/css/lazy';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { ISliderStyledProps, SliderStyled } from './Slider.styled';

export interface ISliderProps extends SwiperOptions, LazyOptions, Partial<ISliderStyledProps> {
  children?: ReactNode;
  forwardedRef?: any;
  className?: any;
  thumbs?: any;
  pagination?: any;
  isNavigation?: boolean;
}

class SliderInner extends Component<ISliderProps> {
  private swiper: Swiper;

  componentDidMount(): void {
    window.requestAnimationFrame(this.init);
  }

  componentWillUnmount(): void {
    this.destroy();
  }

  private init = () => {
    const { children, forwardedRef, className, thumbs, isNavigation, ...rest } = this.props;

    const navigation = isNavigation
      ? {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }
      : {};

    const newThumbs = thumbs ? { swiper: thumbs.current.swiper } : {};
    this.swiper = new Swiper(forwardedRef.current, {
      modules: [Autoplay, Keyboard, Pagination, Navigation, EffectFade, Lazy],
      ...rest,
      navigation,
      thumbs: newThumbs,
    });
  };

  private destroy = () => {
    this.swiper?.destroy(true, true);
  };

  renderSlide = (element, id) => {
    return (
      <div className="swiper-slide" key={id} data-swiper-autoplay={element.props['data-swiper-autoplay']}>
        {element}
      </div>
    );
  };

  render(): JSX.Element {
    const {
      forwardedRef,
      children,
      bulletsColor = 'bronze',
      className,
      isNavigation,
      navigationColor = 'white',
    } = this.props;
    return (
      <SliderStyled bulletsColor={bulletsColor} navigationColor={navigationColor} className={className}>
        <div ref={forwardedRef} className="swiper">
          <div className="swiper-wrapper">{React.Children.map(children, this.renderSlide)}</div>
          {isNavigation && (
            <>
              <div className="swiper-button-prev" />
              <div className="swiper-button-next" />
            </>
          )}
          <div className="swiper-pagination" />
        </div>
      </SliderStyled>
    );
  }
}

export const Slider = React.forwardRef<any, ISliderProps>((props, ref) => {
  return <SliderInner {...props} forwardedRef={ref} />;
});
