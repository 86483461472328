import React from 'react';
import { useField, useFormikContext } from 'formik';

import { useIsTablet } from '../../../../../../lib/misc/device';
import { Address, RoomDelivery } from '../../../../../../lib/core/api/generated';
import { Container, FormLayout, FormFieldRow, FormCheckbox, FormInput } from '../../../../../../lib/components';
import { FieldSet, Legend, SubLegend } from '../../../../../../lib/components/Form/FieldSet';
import { formatCurrency } from '../../../../../../lib/utils/format-number';
import { FormRadioGroup } from '../../../../../../lib/components/Form/FormFields/FormRadioGroup';
import { Typography } from '../../../../../../lib/themes/typography/Typography';

import {
  BillingAddress,
  DeliveryStyled,
  DeliveryTitle,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from './Delivery.styled';
import { useIsInvitedUser } from '../../../../../../lib/core/hooks/useIsInvitedUser';

export interface IDeliveryProps {
  billingAddress: Address | null;
  deliveries: RoomDelivery[];
}

const deliveryZoneTypeSelect = [
  {
    value: '5576',
    label: 'Ladezone',
  },
  {
    value: '5577',
    label: 'normaler Büroeingang',
  },
];

const elevatorSelect = [
  {
    value: '5582',
    label: 'Personenaufzug',
  },
  {
    value: '5583',
    label: 'Lastenaufzug',
  },
];

export const Delivery: React.FC<IDeliveryProps> = ({ billingAddress, deliveries }) => {
  const isTablet = useIsTablet();
  const { setFieldValue } = useFormikContext<any>();
  const isInvitedUser = useIsInvitedUser();
  const [{ value: isTheSameAddress }] = useField('isTheSameAddress');
  const [{ value: isRestrictedParking }] = useField('isRestrictedParking');

  const isBillingAddressEmpty: boolean = billingAddress === null || billingAddress.street.length === 0;

  const reInitAddressFields = React.useCallback(() => {
    if (!billingAddress) return;

    const fields = ['lastName', 'firstName', 'street', 'postcode', 'city', 'company', 'telephone'];

    fields.forEach(field => setFieldValue(field, billingAddress[field]));
  }, [billingAddress, setFieldValue]);

  React.useEffect(() => {
    if (isTheSameAddress && !isBillingAddressEmpty) {
      reInitAddressFields();
    }
  }, [isTheSameAddress, isBillingAddressEmpty, reInitAddressFields]);

  return (
    <Container>
      <DeliveryStyled>
        <Container size={isTablet ? 'tablet' : 'desktop'}>
          <FormLayout>
            <DeliveryTitle>Lieferung</DeliveryTitle>
            <FieldSet>
              <Typography variant="h4" tag="p">
                Wir fragen für Sie in Ihrer Region ein individuelles Angebot für Lieferung, Montage und
                Verpackungsentsorgung an. Bitte übermitteln Sie nachfolgend alle dafür relevanten Informationen an uns.
              </Typography>

              {!isBillingAddressEmpty && billingAddress && (
                <FormCheckbox name="isTheSameAddress" disabled={isInvitedUser}>
                  Lieferadresse entspricht der Rechnungsadresse
                  <BillingAddress>
                    {`(${billingAddress.street}, ${billingAddress.postcode}, ${billingAddress.city})`}
                  </BillingAddress>
                </FormCheckbox>
              )}
              {!isTheSameAddress && (
                <>
                  <FormFieldRow>
                    <FormInput name="lastName" label="Name" disabled={isInvitedUser} />
                    <FormInput name="firstName" label="Vorname" disabled={isInvitedUser} />
                  </FormFieldRow>
                  <FormInput name="street" label="Straße und Hausnummer" disabled={isInvitedUser} />
                  <FormFieldRow>
                    <FormInput name="postcode" label="PLZ" disabled={isInvitedUser} />
                    <FormInput name="city" label="Stadt" disabled={isInvitedUser} />
                  </FormFieldRow>
                  <FormFieldRow>
                    <FormInput name="company" label="Firma" disabled={isInvitedUser} />
                    <FormInput name="telephone" label="Telefonnummer" disabled={isInvitedUser} />
                  </FormFieldRow>
                </>
              )}
            </FieldSet>

            <FieldSet>
              <Legend>Lieferzone</Legend>
              <FormRadioGroup name="deliveryZoneTypeSelect" items={deliveryZoneTypeSelect} disabled={isInvitedUser} />
              <FormInput
                name="floor"
                placeholder="Auf welcher Etage befinden sich Ihre Räumlichkeiten"
                disabled={isInvitedUser}
              />
              <FormFieldRow>
                <FormCheckbox name="isRestrictedParking" disabled={isInvitedUser}>
                  Der Büroeingang befindet sich in einer Halteverbotszone
                </FormCheckbox>
              </FormFieldRow>
              <FormInput
                name="restrictedParkingComment"
                placeholder="Uhrzeiten für Halteverbot (z.B. 8-12 Uhr)"
                disabled={!isRestrictedParking || isInvitedUser}
              />

              <FormCheckbox name="isTruckParkingAvailable" disabled={isInvitedUser}>
                Parkmöglichkeiten für LKW
              </FormCheckbox>
            </FieldSet>

            <FieldSet>
              <Legend>
                Aufzug
                <SubLegend>
                  Die Speditionsfirma sowie Furnished übernehmen keine Haftung für nicht ausgekleidete Aufzüge
                </SubLegend>
              </Legend>
              <FormRadioGroup name="elevatorSelect" items={elevatorSelect} disabled={isInvitedUser} />
              <FormInput name="elevatorMeasurements" label="Angabe der Maße" disabled={isInvitedUser} />
              <FormInput name="additionalComments" label="Anmerkungen" disabled={isInvitedUser} />
            </FieldSet>
          </FormLayout>
        </Container>
      </DeliveryStyled>
    </Container>
  );
};
