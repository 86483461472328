import { colors, styled, h3, h5 } from '../../themes';
import { Image } from '../index';
import { MoreIcon } from '../../icons/MoreIcon';

export const ImageStyled = styled(Image)`
  width: 446px;
  height: 300px;

  img {
    height: 100%;
    width: auto;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.43) 16.75%, rgba(255, 255, 255, 0) 70.63%);
    opacity: 0;
    transition: all 0.4s;
  }
`;

export const MoreIconStyled = styled(MoreIcon)`
  width: 100%;
  height: 100%;
  color: ${colors.bronze};
  transition: color 0.3s;
`;

export const Root = styled.div`
  position: relative;
  a {
    all: unset;
    background: ${colors.white};
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 300px;
    cursor: pointer;
    transition: all 0.4s;
    display: flex;
    margin-bottom: 60px;

    &:hover {
      box-shadow: 0 0 25px rgba(123, 130, 147, 0.55);
      ${ImageStyled} {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: auto;
  flex-grow: 1;
  padding: 50px 65px;
`;

export const Title = styled.h3`
  ${h3};
  margin-top: 0;
  color: ${colors.black};
  margin-bottom: 30px;
`;

export const SubTitle = styled.strong`
  ${h5};
  color: ${colors.bronze};
`;

export const MenuButton = styled.button`
  all: unset;
  position: absolute;
  top: 50px;
  right: 50px;
  padding: 0;
  width: 28px;
  height: 28px;
  background: transparent;
  cursor: pointer;
  border: none;

  &:hover {
    ${MoreIconStyled} {
      color: ${colors.bronzeLight};
    }
  }
`;
