import { ApiError } from './api-error';
import { GraphQLError } from 'graphql/error/GraphQLError';

export class GqlError extends ApiError {
  /**
   * In normal cases use GqlError.getGqlError helper
   * Use the constructor directly for very special cases, like making fake error for testing reasons.
   * @param {string} code
   * @param {string} message
   * @param graphQLErrors
   */
  constructor(
    public readonly code: string,
    public readonly message: string,
    public readonly graphQLErrors: GraphQLError[],
  ) {
    super(200, `${code} ${message}`);
  }

  static getGqlError(code: string, message: string, graphQLErrors: GraphQLError[]): GqlError {
    if (GqlErrors[code]) {
      return new GqlErrors[code](code, message, graphQLErrors);
    }
    return new GqlError(code, message, graphQLErrors);
  }
}

export enum FieldErrors {
  EXISTS = 'EXISTS',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
}

/**
 * This error suppose to happens on API
 */
export class GqlValidationErrors extends GqlError {
  constructor(
    public readonly code: string,
    public readonly message: string,
    public readonly graphQLErrors: GraphQLError[],
    public readonly fieldName?: string,
  ) {
    super(code, message, graphQLErrors);

    // Here we're supposed to unwind common VALIDATION_ERROR, but the way they provided from api is Ъуъ
    // We don't know right now what errors might happen on API and what they could mean.
    // So, let this code be here for now, refactor it when something better appears on horizon.
    // Or refactor when you meet any new kind of error from API.
    if (this.constructor === GqlValidationErrors) {
      const firstError = graphQLErrors[0];
      if (
        firstError &&
        firstError.extensions &&
        firstError.extensions.exception &&
        (firstError.extensions.exception as any).context
      ) {
        const { context } = firstError.extensions.exception as any;
        const fields = Object.keys(context);
        const firstField = fields[0];
        if (firstField && context[firstField]) {
          switch (context[firstField][0]) {
            case FieldErrors.EXISTS:
              return new GqlExistError(code, message, graphQLErrors, firstField);
            case FieldErrors.INVALID:
              return new GqlInvalidFieldError(code, message, graphQLErrors, firstField);
            case FieldErrors.UNKNOWN:
              return new GqlUnknownFieldError(code, message, graphQLErrors, firstField);
          }
        }
        // Otherwise, just create general GqlValidationErrors
      }
    }
  }
}

export class InternalServerError extends GqlError {
  constructor(
    public readonly code: string,
    public readonly message: string,
    public readonly graphQLErrors: GraphQLError[],
  ) {
    super(code, message, graphQLErrors);
  }
}

export class GqlUnauthenticatedError extends GqlError {
  constructor(
    public readonly code: string,
    public readonly message: string,
    public readonly graphQLErrors: GraphQLError[],
  ) {
    super(code, message, graphQLErrors);

    if (this.constructor === GqlUnauthenticatedError) {
      const firstError = graphQLErrors[0];
      if (firstError && firstError.message === 'bad credentials') {
        return new GqlBadCredentialsError(code, message, graphQLErrors);
      }
      // TODO rework after https://elje-group.atlassian.net/browse/LGF-716
      if (
        firstError &&
        firstError.message === 'invalid authentication' &&
        (firstError.extensions?.exception as any)?.stacktrace[0]?.startsWith('TrialPeriodExpiredError')
      ) {
        return new TrialPeriodExpiredError(code, 'The trial period for your membership has expired', graphQLErrors);
      }
    }
  }
}

export class GqlExistError extends GqlValidationErrors {}

export class GqlInvalidFieldError extends GqlValidationErrors {}

export class GqlUnknownFieldError extends GqlValidationErrors {}

export class GqlBadCredentialsError extends GqlUnauthenticatedError {}

export class TrialPeriodExpiredError extends GqlUnauthenticatedError {}

export class ServiceUnavailable extends GqlError {}

export const GqlErrors = {
  UNAUTHORIZED: class GqlUnauthorizedError extends GqlError {}, // Access denied error
  UNAUTHENTICATED: GqlUnauthenticatedError,
  INTERNAL_SERVER_ERROR: InternalServerError,
  VALIDATION_ERROR: GqlValidationErrors,
  BAD_REQUEST: class BadRequest extends GqlError {},
  NO_APPROVAL: class NoApproval extends GqlError {},
  MAIL_TOKEN_NOT_FOUND: class MailTokenNotFound extends GqlError {},
  MAIL_TOKEN_EXPIRED: class MailTokenExpired extends GqlError {},
  MAIL_ALREADY_CONFIRMED: class MailAlreadyConfirmed extends GqlError {},
  NOT_FOUND: class NotFound extends GqlError {},
  INVALID_CSV: class InvalidCSV extends GqlError {},
  ACCOUNT_ROOM_LIMIT_REACHED: class AccountRoomLimitReached extends GqlError {},
  SERVICE_UNAVAILABLE: ServiceUnavailable,
};
