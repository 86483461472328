import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { GlobalStyle } from './GlobalStyle';
import { routePrefix } from '../../lib/router/route-prefix';
import { eventPath } from '../../lib/utils/event-path';
import { analytics } from '../../lib/misc/analytics';
import { Loader } from '../../lib/components/Loader';
import { HomePage } from '../home-page/containers';
import { AboutPage } from '../about-page/containers';
import { AccountPage } from '../account/containers/AccountPage';
import { RoomsPage } from '../account/containers/RoomsPage';
import { routeStore } from '../../lib/router';
import { ConfiguratorPage } from '../configurator/containers';
import { PaymentPage } from '../payment/containers/PaymentPage';
import { StyleExamplePage } from '../styles';
import { PageInDevelopment } from '../page-in-development/PageInDevelopment';
import { Imprint } from '../imprint/Imprint';
import { Terms } from '../terms/Terms';
import { PrivacyPolicy } from '../privacy-policy/PrivacyPolicy';
import { Redirect } from '../../lib/router/redirect';
import { EmailConfirmationPage } from '../auth/containers';
import { CompletePasswordResetPage } from '../auth/containers/CompleteResetPasswordPage';
import { SuccessOrderPage } from '../configurator/containers/SuccessOrderPage/SuccessOrderPage';
import { PaymentPayPalSuccess } from '../payment/containers/PaymentPayPalSuccess';
import { PurchaseRoomPackagePage } from '../payment/containers/PurchaseRoomPackagePage';
import { RoomParamsStep } from '../configurator/typesUtils';
import { ConfiguratorFinalParamsRootStep } from '../configurator/containers/ConfiguratorFinalParamsRootStep/ConfiguratorFinalParamsRootStep';
import { Moodboards } from '../moodboards/containers/Moodboards';
import { SnackbarProvider } from '../../lib/components/SnackBar';
import { ScrollToTop } from '../configurator/components/ScrollToTop/ScrollToTop';
import { NotFound } from '../not-found/NotFound';
import { MobileStub } from '../mobile-stub/MobileStub';
import { ConfirmationModalProvider } from '../../lib/components/ConfirmationModal/ConfirmationModalProvider';
import { MaintenanceLazy } from '../maintenance/MaintenanceLazy';
import { TrialAccountExpires } from '../trial-account-expires/TrialAccountExpires';
import { useIsTabletSmall } from '../../lib/misc/device';

const inDevelopmentPages = '(ideas|products|blog|community|projects|newsletter)';

const history = createBrowserHistory();

history.listen(({ pathname }) => {
  analytics.view(pathname);
});

routeStore.setHistory(history);

class Application extends React.Component<{ isTabletSmall: boolean }> {
  componentDidMount(): void {
    const appLoader = document.getElementById('js-app-loader');
    if (appLoader) {
      appLoader.parentNode && appLoader.parentNode.removeChild(appLoader);
    }

    document.addEventListener(
      'click',
      (event: MouseEvent) => {
        const path = eventPath(event);
        for (let i = 0; i < path.length; i += 1) {
          if (path[i].dataset) {
            const {
              gaCategory: category,
              gaAction: action = 'click',
              gaLabel: label,
              gaValue: value,
            } = path[i].dataset;
            if (category) {
              analytics.event({ category, action, label, value });
              break;
            }
          }
        }
      },
      false,
    );
  }

  private LayoutRoute: React.FC<any> = () => (
    <React.Suspense fallback={<Loader />}>
      <SnackbarProvider>
        <ConfirmationModalProvider>
          <Switch>
            <Route exact={true} path={`${routePrefix}/`} component={HomePage} />
            <Route path={`${routePrefix}/invite`} component={HomePage} />
            {/*<Route path={`${routePrefix}/about`} component={AboutPage} />*/}
            <Route path={`${routePrefix}/auth/confirm/:token`} component={EmailConfirmationPage} />
            <Route path={`${routePrefix}/auth/reset-password/:token`} component={CompletePasswordResetPage} />
            <Route
              path={`${routePrefix}/accounts/:accountId/projects/:projectId/rooms/:roomId/${RoomParamsStep.RoomParams}`}
              component={ConfiguratorFinalParamsRootStep}
            />
            <Route
              path={`${routePrefix}/accounts/:accountId/projects/:projectId/rooms/:roomId/:page`}
              component={ConfiguratorPage}
            />
            <Route path={`${routePrefix}/accounts/:accountId/projects/:projectId/rooms`} component={RoomsPage} />
            <Route path={`${routePrefix}/accounts/:accountId/projects/:projectId`} component={RoomsPage} />
            <Route path={`${routePrefix}/payment/paypal/action/success`} component={PaymentPayPalSuccess} />
            <Route path={`${routePrefix}/payment/:package`} component={PaymentPage} />
            <Route path={`${routePrefix}/accounts/:accountId`} component={AccountPage} />
            <Route path={`${routePrefix}/styles/:styleUrn`} component={StyleExamplePage} />

            <Route path={`${routePrefix}/${inDevelopmentPages}`} component={PageInDevelopment} />
            <Route path={`${routePrefix}/imprint`} component={Imprint} />
            <Route path={`${routePrefix}/terms`} component={Terms} />
            <Route path={`${routePrefix}/privacy-policy`} component={PrivacyPolicy} />
            <Route path={`${routePrefix}/purchase-room-package`} component={PurchaseRoomPackagePage} />
            <Route path={`${routePrefix}/success-order/:accountId/:projectId`} component={SuccessOrderPage} />
            <Route path={`${routePrefix}/moodboards`} component={Moodboards} />
            <Route path={`${routePrefix}/maintenance`} component={MaintenanceLazy} />
            <Route path={`${routePrefix}/trial-account-expires`} component={TrialAccountExpires} />
            <Redirect to={'/404'} />
          </Switch>
        </ConfirmationModalProvider>
      </SnackbarProvider>
    </React.Suspense>
  );

  public render() {
    if (this.props.isTabletSmall) {
      return <MobileStub />;
    }

    return (
      <React.StrictMode>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            <Route path={`${routePrefix}/404`} component={NotFound} />
            <Route component={this.LayoutRoute} />
          </Switch>
        </Router>
        <GlobalStyle />
      </React.StrictMode>
    );
  }
}

export function App() {
  const isTabletSmall = useIsTabletSmall();
  return <Application isTabletSmall={isTabletSmall} />;
}
