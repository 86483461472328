import { styled } from '../../../../../../../lib/themes';

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  padding-bottom: 60px;
`;
