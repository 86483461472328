import { ManageableStore } from '../manageable-store';
import { RequireAuthRepository } from '../available-stores';
import { MainStore } from '../main-store';

/**
 * Use this object to store and get persistent data separately for each user
 * By default it uses LocalStorage
 * This store required AuthStore for functioning
 */
export class UserStorageStore<StoredOptions> extends ManageableStore<RequireAuthRepository> {
  public readonly storeName: string = '';

  constructor(mainStore: MainStore<RequireAuthRepository>) {
    super(mainStore, 'UserStorageStore');
  }

  /**
   * This function persistently sets some data for usage between sessions
   * It stores data for each user separately, if you need set some data for all user,
   * use CommonStorageStore
   * @param {string} key
   * @param {T} value
   * @return {any}
   */
  set<T>(key: keyof StoredOptions, value: T) {
    return localStorage.setItem(this.getKey(key), JSON.stringify(value));
  }

  /**
   * Returns any value from persistent storage specific for current user,
   * for global data use CommonStorageStore
   * Does not throws, except when used out of Auth context (user didn't logged in)
   * @param {string} key
   * @param {T} value
   * @return {T | undefined}
   */
  get<T>(key: keyof StoredOptions): T | undefined {
    const realKey = this.getKey(key);
    const item = localStorage.getItem(realKey);
    try {
      return item && item[realKey] ? JSON.parse(item[realKey]) : undefined;
    } finally {
    }
    return undefined;
  }

  private getKey(key: keyof StoredOptions): string {
    const authStore = this._mainStore.get('AuthRepository');
    return `${key}_${authStore.getUniqKey()}`;
  }

  clear() {
    // Do nothing, cause storage supposed to be persistent
  }
}
