import { colors, css, styled, h5 } from '../../themes';
import { IUnitable } from '../common-types';

export const InputContainer = styled.div<IUnitable>`
  ${({ units }) => css`
    position: relative;
    width: 100px;

    ::after {
      position: absolute;
      top: 0;
      right: 0;

      ${h5};
      color: ${colors.gray};

      content: ${`'${units}'`};
    }
  `};
`;

export const Input = styled.input.attrs({ type: 'number' })`
  position: relative;
  width: 100%;

  ${h5};

  border: none;
  border-bottom: 1px solid ${colors.grayMedium};

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;
