import * as React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';
import { Item, StyledPagination } from './Pagination.styled';
import { noop } from '../../utils/placeholders';
import { PaginatorButtonType, PaginatorPiece, usePagination } from './usePagination';

export interface IPaginationProps {
  pages: number;
  maxItems: number;
  initialValue: number;
  onChange: (page: number) => unknown;
}

export const Pagination: React.FC<Partial<IPaginationProps>> = ({
  pages = 1,
  initialValue = 1,
  onChange = noop,
  maxItems = 10,
}) => {
  const { activePage, hasPrevious, hasNext, visiblePieces, goToPage } = usePagination({
    initialPage: initialValue,
    maxButtons: maxItems,
    numberOfPages: pages,
  });

  const renderItem = (page: PaginatorPiece, index) => {
    const handleClick = () => {
      if (page.pageNumber) {
        goToPage(page.pageNumber);
        onChange(page.pageNumber);
      }
    };

    if (page.type === PaginatorButtonType.previous) {
      return hasPrevious ? (
        <Item key={index} onClick={handleClick} disabled={page.isDisabled}>
          <ChevronLeftIcon />
        </Item>
      ) : null;
    }

    if (page.type === PaginatorButtonType.next) {
      return hasNext ? (
        <Item key={index} onClick={handleClick} disabled={page.isDisabled}>
          <ChevronRightIcon />
        </Item>
      ) : null;
    }

    return (
      <Item key={index} selected={activePage === page.pageNumber} onClick={handleClick} disabled={page.isDisabled}>
        {page.type === PaginatorButtonType.ellipsis ? '...' : page.pageNumber}
      </Item>
    );
  };

  return <StyledPagination>{visiblePieces.map(renderItem)}</StyledPagination>;
};
