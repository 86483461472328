import React, { useCallback, useEffect, useState } from 'react';

import { Summary, Root, Content, SummaryContent, ToggleIconWrapper } from './ExpansionPanel.styled';
import { ExpandIcon } from '../../icons/ExpandIcon';

interface IExpansionPanelProps {
  summary?: any;
  show?: boolean;
  onChange?: (show: boolean) => void;
}

export const ExpansionPanel: React.FC<IExpansionPanelProps> = ({ summary, children, show = false, onChange }) => {
  const [isShow, setShow] = useState(show);

  const handleChange = useCallback(() => {
    setShow(!isShow);
    onChange && onChange(!isShow);
  }, [isShow, onChange]);

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <Root>
      <Summary onClick={handleChange}>
        <SummaryContent>
          {summary}
          <ToggleIconWrapper isShow={isShow}>
            <ExpandIcon />
          </ToggleIconWrapper>
        </SummaryContent>
      </Summary>
      <Content isShow={isShow}>{children}</Content>
    </Root>
  );
};
