import { colors, styled, h5, body, helper } from '../../themes';

import { Button, Image, Fab } from '../../components';
import { ITypographyProps, Typography } from '../../themes/typography/Typography';

export const FooterButtons = styled.div`
  position: absolute;

  bottom: 0;
  transform: translateY(100%);

  width: 100%;
  padding-top: 30px;

  display: flex;
  flex-direction: column;

  z-index: 1;
`;
// width: 350px;
// height: 606px;

export const Background = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${colors.white};

  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  }
`;

export const Root = styled.div`
  position: relative;

  //width: 350px;
  //height: 606px;
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  :not(:hover) {
    ${FooterButtons} {
      display: none;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const ButtonDetails = styled(Button)`
  width: 50%;
  min-width: 50%;
  margin: auto;
`;

export const StyledImage = styled(Image)`
  width: 248px;
  height: 229px;
  margin: 0 auto;
`;

export const Descriptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
  padding: 0 0 25px;

  flex-grow: 1;
`;

export const Name = styled.p`
  ${body}
  display: flex;
  align-items: center;

  text-align: center;
  color: ${colors.dark};
`;

export const ConfigurationName = styled.p`
  ${helper}
  display: flex;
  align-items: center;

  text-align: center;
  color: ${colors.grayDark};
  padding-bottom: 20px;
`;

export const Price = styled.strong`
  ${h5};
  text-align: center;
  color: ${colors.dark};
`;

export const Total = styled(Typography)<ITypographyProps>`
  color: ${colors.grayDark};
`;

export const FabStyled = styled(Fab)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
