import React from 'react';

export const RollContainerIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="47" height="38" viewBox="0 0 47 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43 0.0996094H3C1.4 0.0996094 0.200012 1.3996 0.200012 2.8996V11.3996V22.0996V32.9996C0.200012 33.3996 0.499994 33.6996 0.899994 33.6996H4.20001V37.2996C4.20001 37.6996 4.49999 37.9996 4.89999 37.9996C5.29999 37.9996 5.60001 37.6996 5.60001 37.2996V33.6996H40.9V37.2996C40.9 37.6996 41.2 37.9996 41.6 37.9996C42 37.9996 42.3 37.6996 42.3 37.2996V33.6996H45.6C46 33.6996 46.3 33.3996 46.3 32.9996V22.2996V11.5996V3.09961C45.8 1.39961 44.5 0.0996094 43 0.0996094ZM1.39999 12.0996H44.5V21.4996H1.39999V12.0996ZM1.39999 2.8996C1.39999 1.9996 2.09999 1.3996 2.89999 1.3996H42.9C43.8 1.3996 44.4 2.0996 44.4 2.8996V10.7996H1.29999V2.8996H1.39999ZM44.5 32.2996H1.39999V22.8996H44.5V32.2996Z"
      fill="currentColor"
    />
    <path
      d="M23 4.39941C22.1 4.39941 21.3 5.19943 21.3 6.09943C21.3 6.99943 22.1 7.79941 23 7.79941C23.9 7.79941 24.7 6.99943 24.7 6.09943C24.7 5.19943 23.9 4.39941 23 4.39941ZM23 6.49942C22.8 6.49942 22.6 6.29943 22.6 6.09943C22.6 5.89943 22.8 5.69943 23 5.69943C23.2 5.69943 23.4 5.89943 23.4 6.09943C23.4 6.29943 23.2 6.49942 23 6.49942Z"
      fill="currentColor"
    />
    <path
      d="M23 15.0996C22.1 15.0996 21.3 15.8996 21.3 16.7996C21.3 17.6996 22.1 18.4996 23 18.4996C23.9 18.4996 24.7 17.6996 24.7 16.7996C24.7 15.8996 23.9 15.0996 23 15.0996ZM23 17.2996C22.8 17.2996 22.6 17.0996 22.6 16.8996C22.6 16.6996 22.8 16.4996 23 16.4996C23.2 16.4996 23.4 16.6996 23.4 16.8996C23.4 17.0996 23.2 17.2996 23 17.2996Z"
      fill="currentColor"
    />
    <path
      d="M23 29.2994C23.9 29.2994 24.7 28.4994 24.7 27.5994C24.7 26.6994 23.9 25.8994 23 25.8994C22.1 25.8994 21.3 26.6994 21.3 27.5994C21.2 28.4994 22 29.2994 23 29.2994ZM23 27.1994C23.2 27.1994 23.4 27.3994 23.4 27.5994C23.4 27.7994 23.2 27.9994 23 27.9994C22.8 27.9994 22.6 27.7994 22.6 27.5994C22.5 27.3994 22.7 27.1994 23 27.1994Z"
      fill="currentColor"
    />
  </svg>
);
