export const pluralizeRooms = (amount: number) => {
  switch (amount) {
    case 0:
      return 'kein Raum';
    case 1:
      return '1 Raum';
    default:
      return `${amount} Räume`;
  }
};
