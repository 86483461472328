import React, { useCallback, useEffect, useState } from 'react';
import { Portal } from '../Portal';
import { Backdrop } from '../Backdrop';
import { noop } from '../../utils/placeholders';
import { Container, Card, CloseButton, CardContent } from './SideSheetTip.styled';
import { CrossIcon } from '../../icons';

interface SideSheetTipProps {
  isOpened?: boolean;
  onClose?: () => unknown;
}

export const SideSheetTip: React.FC<SideSheetTipProps> = ({ isOpened = false, onClose = noop, children }) => {
  const [attached, setAttached] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    setOpened(isOpened);
    let timeout;
    if (isOpened) {
      setAttached(true);
    } else {
      timeout = setTimeout(() => {
        setAttached(false);
        onClose && onClose();
      }, 400);
    }
    return () => {
      timeout && window.clearTimeout(timeout);
    };
  }, [isOpened, onClose]);

  const handleClose = useCallback(
    e => {
      e.preventDefault();
      onClose && onClose();
    },
    [onClose],
  );

  const stopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  return attached ? (
    <Portal>
      <Container onClick={stopPropagation}>
        <Backdrop isShow={opened} onClick={handleClose} />
        <Card isOpened={opened}>
          <CloseButton onClick={handleClose}>
            <CrossIcon />
          </CloseButton>
          <CardContent>{children}</CardContent>
        </Card>
      </Container>
    </Portal>
  ) : null;
};
