import React, { useState } from 'react';
import { Solution, solutionAll, useSolutions } from '../../hooks/products-hooks';
import { CatalogProducts } from '../CatalogProducts';
import { ProductConfiguration } from '../../../../../../../lib/core/api/generated';
import { On } from '../../../../../../../lib/core/hooks/on';
import { CatalogHeader } from '../CatalogHeader/CatalogHeader';
import { CError } from '../../../../../../../lib/components/Error';

interface ICatalogProps {
  roomProducts: string[];
  onSelect: (productConfiguration: ProductConfiguration, amount: number) => unknown;
}

export const Catalog: React.FC<ICatalogProps> = ({ roomProducts, onSelect }) => {
  const [selectedSolution, selectSolution] = useState<Solution | null>(null);

  return On(
    allSolutions => {
      const solutions = allSolutions.filter(solution => solution.id !== solutionAll.id);
      return (
        <>
          <CatalogHeader items={solutions} value={selectedSolution || solutions[0]} onChange={selectSolution} />
          <CatalogProducts
            solution={selectedSolution || solutions[0]}
            onSelect={onSelect}
            roomProducts={roomProducts}
          />
        </>
      );
    },
    () => <div>Loading...</div>,
    error => <CError error={error} />,
    useSolutions(),
  );
};
