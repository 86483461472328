import React from 'react';

export const CabinetStoragesIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="43" height="36" viewBox="0 0 43 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.7 0.299805H21.2H0.699966C0.299966 0.299805 0.0999756 0.599796 0.0999756 0.899796V31.9998C0.0999756 32.3998 0.399966 32.5998 0.699966 32.5998H2.99997V34.4998C2.99997 34.8998 3.29998 35.0998 3.59998 35.0998H38.7C39.1 35.0998 39.3 34.7998 39.3 34.4998V32.5998H41.6C42 32.5998 42.2 32.2998 42.2 31.9998V24.1998V16.3998V8.59981V0.799805C42.3 0.599805 42 0.299805 41.7 0.299805ZM1.39996 31.3998V1.59981H20.6V8.6998V16.4998V24.2998V31.3998H3.69997H1.39996ZM38.1 33.9998H4.29997V32.6998H21.2H38.1V33.9998ZM41 31.3998H38.7H21.8V24.8998H41V31.3998ZM41 23.5998H21.8V17.0998H41V23.5998ZM41 15.7998H21.8V9.2998H41V15.7998ZM41 8.09981H21.8V1.59981H41V8.09981Z"
      fill="currentColor"
    />
    <path
      d="M15.5 14.5C14.4 14.5 13.5 15.4 13.5 16.5C13.5 17.6 14.4 18.5 15.5 18.5C16.6 18.5 17.5 17.6 17.5 16.5C17.5 15.4 16.6 14.5 15.5 14.5ZM15.5 17.2C15.1 17.2 14.8 16.9 14.8 16.5C14.8 16.1 15.1 15.8 15.5 15.8C15.9 15.8 16.2 16.1 16.2 16.5C16.2 16.9 15.8 17.2 15.5 17.2Z"
      fill="currentColor"
    />
    <path
      d="M30.5 6.2H32.4C32.8 6.2 33 5.90001 33 5.60001C33 5.20001 32.7 5 32.4 5H30.5C30.1 5 29.9 5.30001 29.9 5.60001C29.8 5.90001 30.1 6.2 30.5 6.2Z"
      fill="currentColor"
    />
    <path
      d="M30.5 13.9998H32.4C32.8 13.9998 33 13.6998 33 13.3998C33 13.0998 32.7 12.7998 32.4 12.7998H30.5C30.1 12.7998 29.9 13.0998 29.9 13.3998C29.9 13.6998 30.1 13.9998 30.5 13.9998Z"
      fill="currentColor"
    />
    <path
      d="M30.5 21.7H32.4C32.8 21.7 33 21.4 33 21.1C33 20.7 32.7 20.5 32.4 20.5H30.5C30.1 20.5 29.9 20.8 29.9 21.1C29.8 21.4 30.1 21.7 30.5 21.7Z"
      fill="currentColor"
    />
    <path
      d="M30.5 29.4998H32.4C32.8 29.4998 33 29.1998 33 28.8998C33 28.4998 32.7 28.2998 32.4 28.2998H30.5C30.1 28.2998 29.9 28.5998 29.9 28.8998C29.8 29.1998 30.1 29.4998 30.5 29.4998Z"
      fill="currentColor"
    />
  </svg>
);
