import React from 'react';

export const BenchIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.5 9.29999H53.4V2.5C53.4 1.1 52.3 0 50.9 0H8.39999C6.99999 0 5.89999 1.1 5.89999 2.5V9.29999H1C0.6 9.29999 0.299988 9.59999 0.299988 9.89999V24.2C0.299988 24.6 0.6 24.8 1 24.8H7V28.7C7 29.1 7.30001 29.3 7.70001 29.3H14.5C14.9 29.3 15.2 29 15.2 28.7V24.8H44.3V28.7C44.3 29.1 44.6 29.3 45 29.3H51.8C52.2 29.3 52.5 29 52.5 28.7V24.8H58.7C59.1 24.8 59.4 24.5 59.4 24.2V10C59.2 9.6 58.9 9.29999 58.5 9.29999ZM7.29999 2.60001C7.29999 1.90001 7.8 1.39999 8.5 1.39999H51C51.7 1.39999 52.2 1.90001 52.2 2.60001V18.1H7.29999V2.60001ZM13.9 28.2H8.39999V24.9H13.9V28.2ZM51.1 28.2H45.6V24.9H51.1V28.2ZM57.9 23.6H51.7H44.9H14.5H7.70001H1.70001V10.6H5.89999V18.7C5.89999 19.1 6.20001 19.3 6.60001 19.3H52.8C53.2 19.3 53.5 19 53.5 18.7V10.6H57.9V23.6Z"
      fill="currentColor"
    />
  </svg>
);
