import React from 'react';

import { RoomPackage } from '../../core/api/generated';
import { formatCurrencyWithoutZero } from '../../utils/format-number';
import { pluralizeRooms } from '../../utils/pluralizeRooms';
import {
  PackageCardWrap,
  PackageCardStyled,
  Info,
  Title,
  Price,
  PreviousPrice,
  CallToAction,
} from './PackageCard.styled';

export interface IPackageCardProps {
  designPackage: RoomPackage;
  onSelect: (designPackage: any) => unknown;
}

export const PackageCard: React.FC<IPackageCardProps> = props => {
  const { designPackage, onSelect } = props;
  const { roomCount = 1, price } = designPackage;

  const handleSelectPackage = React.useCallback(() => {
    onSelect(designPackage);
  }, [designPackage, onSelect]);

  return (
    <PackageCardWrap onClick={handleSelectPackage}>
      <PackageCardStyled>
        <Info>
          <Title>{pluralizeRooms(roomCount)}</Title>
          <Price>{formatCurrencyWithoutZero(price)}</Price>
          {roomCount !== 1 && <PreviousPrice>{formatCurrencyWithoutZero(roomCount * 89)}</PreviousPrice>}
        </Info>
        <CallToAction>Auswählen, einrichten, begeistern</CallToAction>
      </PackageCardStyled>
    </PackageCardWrap>
  );
};
