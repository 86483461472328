import { styled, colors, body } from '../../themes';
import { StyledImage } from '../Image/Image.styled';

interface IItem {
  active: boolean;
}

export const Items = styled.div`
  display: flex;
  justify-content: flex-start;
`;

interface IStyledColor {
  color: string;
}

export const StyledColor = styled.div<IStyledColor>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 3px solid #ffffff;
  background-color: ${props => props.color};
`;

export const Item = styled.label<IItem>`
  display: block;
  position: relative;
  margin-right: 25px;
  cursor: pointer;
  width: 40px;
  height: 40px;

  :last-child {
    margin-right: 0;
  }

  input {
    opacity: 0;
  }

  input:checked + ${StyledImage}, input:checked + ${StyledColor} {
    box-shadow: 0 0 0 2px ${colors.bronze};
  }

  input:checked:focus
    + ${StyledImage},
    input:checked:hover
    + ${StyledImage},
    input:checked:focus
    + ${StyledColor},
    input:checked:hover
    + ${StyledColor} {
    box-shadow: 0 0 25px rgba(123, 130, 147, 0.55), 0 0 0 2px ${colors.bronze};
  }

  input:focus + ${StyledImage},input:hover + ${StyledImage}, input:focus + ${StyledColor},input:hover + ${StyledColor} {
    box-shadow: 0 0 25px rgba(123, 130, 147, 0.55);
  }

  ${StyledImage} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 3px solid transparent;
  }

  ${StyledImage}, ${StyledColor} {
    opacity: ${props => (props.active ? 1 : 0.5)};
  }
`;

export const StyledMaterialPicker = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  legend {
    margin-bottom: 28px;
    ${body}
  }
`;
