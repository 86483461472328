import { h1, styled, h5 } from '../../../../lib/themes';
import { FlexContainer } from '../../../../lib/components/FlexContainer';

export const TitleWrapper = styled.div`
  margin: 67px 0;
`;

export const Title = styled.h1`
  ${h1};
  display: inline-flex;
`;

export const SubTitle = styled.strong`
  ${h5};
  display: inline-flex;
  margin-left: 20px;
`;

export const ContainerStyled = styled(FlexContainer)`
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 100px;
`;

export const CardWrapper = styled.div`
  width: 445px;
  height: 555px;
  margin-bottom: 60px;
`;
