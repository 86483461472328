import { styled } from '../../../../../../../lib/themes';

export const Root = styled.div`
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  padding: 85px 40px;
  flex-wrap: wrap;
  grid-template: '0 0 0';
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  width: 1170px;
  margin: 0 auto;
  @media (max-width: 1110px) {
    grid-template: '0 0';
  }
  & > * {
    min-width: 350px;
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SmartTipWrapper = styled.div`
  width: 1170px;
  margin: 100px auto;
`;
