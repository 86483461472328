import React from 'react';

export const ShelvesCabinetIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="44" height="57" viewBox="0 0 44 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.4 44.3C16.8 44.3 17.1 44 17.1 43.7V40.1C17.1 39.7 16.8 39.5 16.4 39.5C16 39.5 15.7 39.8 15.7 40.1V43.7C15.8 44 16 44.3 16.4 44.3Z"
      fill="currentColor"
    />
    <path
      d="M27.5 44.3C27.9 44.3 28.2 44 28.2 43.7V40.1C28.2 39.7 27.9 39.5 27.5 39.5C27.1 39.5 26.8 39.8 26.8 40.1V43.7C26.9 44 27.1 44.3 27.5 44.3Z"
      fill="currentColor"
    />
    <path
      d="M42.6 0.299805H1.20001C0.800012 0.299805 0.5 0.599796 0.5 0.899796V10.8998V20.8998V30.8998V52.6998C0.5 53.0998 0.800012 53.2998 1.20001 53.2998H4.10001V56.3998C4.10001 56.7998 4.39999 56.9998 4.79999 56.9998C5.19999 56.9998 5.5 56.6998 5.5 56.3998V53.2998H22H38.5V56.3998C38.5 56.7998 38.8 56.9998 39.2 56.9998C39.6 56.9998 39.9 56.6998 39.9 56.3998V53.2998H42.8C43.2 53.2998 43.5 52.9998 43.5 52.6998V30.9998V20.9998V10.9998V0.999802C43.3 0.599802 43 0.299805 42.6 0.299805ZM22 30.2998H2V21.5998H42V30.2998H22ZM42 11.5998V20.2998H1.89999V11.5998H42ZM1.89999 1.59981H42V10.2998H18.5L15.8 5.1998C15.6 4.8998 15.2 4.7998 14.9 4.8998C14.6 5.0998 14.5 5.4998 14.6 5.7998L17 10.2998H12.1V4.7998C12.1 4.3998 11.8 4.1998 11.4 4.1998C11 4.1998 10.7 4.4998 10.7 4.7998V10.2998H8.60001V4.7998C8.60001 4.3998 8.29999 4.1998 7.89999 4.1998C7.49999 4.1998 7.20001 4.4998 7.20001 4.7998V10.2998H5.10001V4.7998C5.10001 4.3998 4.79999 4.1998 4.39999 4.1998C3.99999 4.1998 3.70001 4.4998 3.70001 4.7998V10.2998H1.5V1.59981H1.89999ZM1.89999 31.5998H21.3V52.0998H1.89999V31.5998ZM42 52.0998H22.6V31.5998H42V52.0998Z"
      fill="currentColor"
    />
    <path
      d="M37.1 27.0996H30.4C30 27.0996 29.7 27.3996 29.7 27.6996C29.7 28.0996 30 28.2996 30.4 28.2996H37.1C37.5 28.2996 37.8 27.9996 37.8 27.6996C37.8 27.3996 37.5 27.0996 37.1 27.0996Z"
      fill="currentColor"
    />
    <path
      d="M37.1 23.8994H30.4C30 23.8994 29.7 24.1994 29.7 24.4994C29.7 24.8994 30 25.0994 30.4 25.0994H37.1C37.5 25.0994 37.8 24.7994 37.8 24.4994C37.8 24.1994 37.5 23.8994 37.1 23.8994Z"
      fill="currentColor"
    />
  </svg>
);
