import { styled, h5, body, h1 } from '../../lib/themes';

export const TermsStyled = styled.div``;

export const Content = styled.div`
  padding-top: 70px;
  padding-bottom: 154px;

  h1 {
    ${h1};
  }

  h5 {
    ${h5};
  }

  p {
    ${body};
    margin-top: 15px;
  }

  ol {
    position: relative;
    margin-top: 15px;
    padding-left: 0;
    counter-reset: item;
  }

  ol > li > span {
    padding-left: 60px;
  }

  li {
    display: block;

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  li:before {
    position: absolute;
    left: 30px;
    content: counters(item, '.') '.';
    counter-increment: item;
  }

  ol > li > ol {
    padding-left: 50px;
  }
  ol > li > ol > li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  ol > li > ol > li:before {
    left: 0;
    content: counters(item, '.') ' ';
  }
`;
