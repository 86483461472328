import React from 'react';
import { ImageCardDefault, ImageCardDefaultProps } from './ImageCardDefault';
import { ColorPaletteStyled } from './ImageCardWithColors.styled';

interface ImageCardWithColorsProps extends ImageCardDefaultProps {
  colors: string[];
  isActive: boolean;
}

export const ImageCardWithColors: React.FC<ImageCardWithColorsProps> = ({
  colors,
  cover,
  isActive,
  children,
  name,
  onClick,
}) => {
  return (
    <ImageCardDefault cover={cover} name={name} onClick={onClick} isActive={isActive}>
      {children}
      <ColorPaletteStyled colors={colors} />
    </ImageCardDefault>
  );
};
