import React from 'react';

export const TableNarrowStorageIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="43" height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H41.5769C41.8531 0 42.0769 0.223858 42.0769 0.5V3.95362C42.0769 4.22976 41.8531 4.45362 41.5769 4.45362H10.9464V27.06C10.9464 27.3361 10.7225 27.56 10.4464 27.56H1.30946C1.03332 27.56 0.80946 27.3361 0.80946 27.06V4.45362H0.5C0.223858 4.45362 0 4.22977 0 3.95362V0.5ZM1.80946 4.45362V9.86749H9.94636V4.45362H1.80946ZM9.94636 10.8675H1.80946V16.6925H9.94636V10.8675ZM9.94636 17.6925H1.80946V23.3531H9.94636V17.6925ZM9.94636 24.3531H1.80946V26.56H9.94636V24.3531ZM1 3.45362V1H41.0769V3.45362L1 3.45362ZM3.96136 6.58495C3.96136 6.30881 4.18521 6.08495 4.46136 6.08495H7.5373C7.81345 6.08495 8.0373 6.30881 8.0373 6.58495C8.0373 6.86109 7.81345 7.08495 7.5373 7.08495H4.46136C4.18521 7.08495 3.96136 6.86109 3.96136 6.58495ZM3.96136 12.9988C3.96136 12.7227 4.18521 12.4988 4.46136 12.4988H7.5373C7.81345 12.4988 8.0373 12.7227 8.0373 12.9988C8.0373 13.275 7.81345 13.4988 7.5373 13.4988H4.46136C4.18521 13.4988 3.96136 13.275 3.96136 12.9988ZM3.96136 19.9061C3.96136 19.6299 4.18521 19.4061 4.46136 19.4061H7.5373C7.81345 19.4061 8.0373 19.6299 8.0373 19.9061C8.0373 20.1822 7.81345 20.4061 7.5373 20.4061H4.46136C4.18521 20.4061 3.96136 20.1822 3.96136 19.9061Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.0554 26.5888V3.56461H41.0554V26.5888H40.0554Z"
      fill="currentColor"
    />
  </svg>
);
