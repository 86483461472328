import React from 'react';

import { Button } from '../../../../../../lib/components/Button';
import { Typography } from '../../../../../../lib/themes/typography/Typography';

import { Root } from './NoMoreAvailableRooms.styled';

export const NoMoreAvailableRooms: React.FC = () => {
  return (
    <Root>
      <Typography tag="h3" variant="h3" css={{ marginBottom: 180 }}>
        Keine Räume mehr vorhanden. Buchen Sie jetzt weitere Projekträume.
      </Typography>
      <Button href="/purchase-room-package" color="bronze">
        Raum anlegen und gestalten
      </Button>
    </Root>
  );
};
