import React, { useEffect, useState } from 'react';

import { ProductCard } from '../../../../../../../lib/components/ProductCard';
import { SideSheet } from '../../../../../../../lib/components/SideSheet';

import { ProductDetails } from '../../../../ProductDetails';

import { ProductConfiguration } from '../../../../../../../lib/core/api/generated';

import { ProductsWrapper } from './CatalogProductList.styled';
import { useIsInvitedUser } from '../../../../../../../lib/core/hooks/useIsInvitedUser';

interface IProduct {
  // it is sku of original product from catalog. Later is it possible sku !== productConfiguration.sku when
  // we have chosen another product configuration. But we have to use the same 'slot'
  sku: string;
  productConfiguration: ProductConfiguration;
  productConfigurations: ProductConfiguration[];
  amount: number;
}

interface ICatalogProductListProps {
  productConfigurations: ProductConfiguration[];
  onSelect: (productConfiguration: ProductConfiguration, amount: number) => unknown;
  roomProducts: string[];
}

export const CatalogProductList: React.FC<ICatalogProductListProps> = ({
  productConfigurations,
  onSelect,
  roomProducts,
}) => {
  // Show SideSheet with products details, where user can change size, color, or similarProduct
  const [productToDetail, setProductToDetail] = useState<IProduct | null>(null);
  const isInvitedUser = useIsInvitedUser();
  const [products, setProducts] = useState<{ [productSku: string]: IProduct }>({});

  useEffect(() => {
    setProducts(
      productConfigurations.reduce((acc, productConfiguration) => {
        acc[productConfiguration.sku] = {
          sku: productConfiguration.sku,
          productConfiguration,
          productConfigurations: productConfiguration.configurableProduct.productConfigurations,
          amount: 1,
        };
        return acc;
      }, {}),
    );
  }, [productConfigurations]);

  const handleOpenDetails = (product: IProduct) => () => {
    setProductToDetail(product);
  };

  const handleOnCloseProductDetail = () => {
    setProductToDetail(null);
  };

  const handleClickToggleInListButton =
    ({ productConfiguration, amount }: IProduct) =>
    () => {
      return onSelect(productConfiguration, amount);
    };

  const handleOnProductDetailDone =
    (product: IProduct) =>
    (productConfiguration: ProductConfiguration, amount: number): Promise<unknown> => {
      setProducts({
        ...products,
        [product.sku]: {
          sku: product.sku,
          productConfiguration,
          productConfigurations: product.productConfigurations,
          amount,
        },
      });
      // Close the SideSheet with ProductToDetail
      setProductToDetail(null);

      return Promise.resolve();
    };

  const handleChangeAmount = (product: IProduct) => (amount: number) => {
    setProducts({
      ...products,
      [product.sku]: {
        sku: product.sku,
        productConfiguration: product.productConfiguration,
        productConfigurations: product.productConfigurations,
        amount,
      },
    });
  };

  return (
    <>
      <ProductsWrapper>
        {Object.keys(products).map(key => {
          const product = products[key];
          return (
            <ProductCard
              productConfiguration={product.productConfiguration}
              amount={product.amount}
              isInList={false}
              isEditable={!roomProducts.includes(product.productConfiguration.sku)}
              onChangeAmount={handleChangeAmount(product)}
              onClickDetailsButton={handleOpenDetails(product)}
              onClickToggleInListButton={handleClickToggleInListButton(product)}
              key={product.productConfiguration.sku}
            />
          );
        })}
      </ProductsWrapper>

      <SideSheet isOpened={!!productToDetail} onClose={handleOnCloseProductDetail}>
        {productToDetail && (
          <ProductDetails
            productConfiguration={productToDetail.productConfiguration}
            productConfigurations={productToDetail.productConfigurations}
            productSetId={null}
            isEditable={true}
            amount={productToDetail.amount}
            onDone={handleOnProductDetailDone(productToDetail)}
            isInvitedUser={isInvitedUser}
          />
        )}
      </SideSheet>
    </>
  );
};
