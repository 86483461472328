import { useMe } from '../repositories/user-repository';
import { useParams } from 'react-router-dom';

export enum UserRole {
  Executive = 'ROLE_EXECUTIVE',
  Trial = 'ROLE_TRIAL',
}

export const useIsInvitedUser = (): boolean => {
  const { accountId } = useParams<{ accountId: string }>();
  const [user] = useMe();

  return (
    user?.memberships.find(membership => membership.account.id === accountId)?.role === UserRole.Executive || false
  );
};
