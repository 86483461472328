import { Address, Room, RoomShippingAddress } from '../../../../../lib/core/api/generated';
import { ISummaryForm } from './LieferungScreen';

function isTheSameAddress(userAddress: Address | null, shipAddress: RoomShippingAddress): boolean {
  return Boolean(
    (userAddress && userAddress.street === shipAddress.street && userAddress.postcode === shipAddress.postcode) ||
      (userAddress && userAddress.street && !shipAddress.street),
  );
}

export function toSummaryForm(room: Room, billingAddress: Address | null): ISummaryForm {
  const shippingAddress: RoomShippingAddress = room.shippingAddress ?? {
    firstName: billingAddress?.firstName || '',
    lastName: billingAddress?.lastName || '',
    company: billingAddress?.company || '',
    street: billingAddress?.street || '',
    postcode: billingAddress?.postcode || '',
    city: billingAddress?.city || '',
    countryId: billingAddress?.countryId || 'DE',
    telephone: billingAddress?.telephone || '',
    floor: billingAddress?.floor || '',

    deliveryZoneTypeSelect: { value: '5576', options: [] },

    restrictedParkingSelect: { value: '5579', options: [] },
    // TODO not sure about restrictedParkingComment in this address
    restrictedParkingComment: billingAddress?.restrictedParkingComment || '',

    truckParkingAvailableSelect: { value: '5580', options: [] },

    elevatorSelect: { value: '5582', options: [] },
    // TODO not sure about elevatorMeasurements and additionalComments in this address
    elevatorMeasurements: billingAddress?.elevatorMeasurements || '',

    additionalComments: billingAddress?.additionalComments || '',
  };

  return {
    fastShipping: room.fastShipping ?? false,

    isTheSameAddress: isTheSameAddress(billingAddress, shippingAddress),

    firstName: shippingAddress.firstName,
    lastName: shippingAddress.lastName,
    company: shippingAddress.company || '',
    street: shippingAddress.street,
    postcode: shippingAddress.postcode,
    city: shippingAddress.city,
    countryId: shippingAddress.countryId,
    telephone: shippingAddress.telephone,
    floor: shippingAddress.floor,

    deliveryZoneTypeSelect: shippingAddress.deliveryZoneTypeSelect.value,

    isRestrictedParking: shippingAddress.restrictedParkingSelect.value === '5578',
    restrictedParkingComment: shippingAddress.restrictedParkingComment,

    isTruckParkingAvailable: shippingAddress.truckParkingAvailableSelect.value === '5580',

    elevatorSelect: shippingAddress.elevatorSelect.value,
    elevatorMeasurements: shippingAddress.elevatorMeasurements,

    additionalComments: shippingAddress.additionalComments,

    subscribeToNewsletter: false,
    contactMe: room.contactMe ?? false,
    acceptTermsAndConditions: room.acceptTermsAndConditions ?? false,
  };
}
