import React from 'react';

export const TextilIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="46" height="47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.291 22.926l1.605-1.611M20.08 21.314l1.606 1.612M23.291 24.537l1.605 1.611M20.08 26.148l1.606-1.61M22.648 45.593c-4.924 0-9.955-.43-14.878-1.396-.643.322-1.499.43-2.355.43-1.285 0-2.57-.323-3.104-.86-.857-.644-1.392-3.867-.321-5.908a80.103 80.103 0 010-28.575c-1.07-2.148-.429-5.263.32-5.908.964-.86 3.64-1.074 5.352-.43 9.848-1.933 19.91-1.933 29.757-.107a6.211 6.211 0 012.033-.322c1.285 0 2.462.322 3.105.859.856.645 1.284 3.223.642 5.156a83.55 83.55 0 010 30.079c.642 1.934.214 4.512-.642 5.156-.643.537-1.82.86-3.105.86-.856 0-1.605-.108-2.247-.43-4.817.967-9.74 1.396-14.557 1.396z"
      stroke="#282826"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
