import { ObservableQuery } from '@apollo/client';

import { ApiRepository } from './api-repository';
import { Account, Mutation, Query, QueryAccountArgs } from '../api/generated';
import { MainStore } from '../main-store';
import { RequireAccountRepository, RequireApolloStore, RequireLog, RequireUserRepository } from '../available-stores';
import { UseQueryResult, useStore, useWatchQuery } from '../hooks/use-store';
import { gqlDocsAccount } from '../gql-documents/gql-account';

export class AccountRepository extends ApiRepository<
  Query,
  Mutation,
  RequireApolloStore & RequireLog & RequireUserRepository
> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog & RequireUserRepository>) {
    super(mainStore, 'AccountRepository');
  }

  getAccount = (variables: QueryAccountArgs): ObservableQuery<Query['account'], QueryAccountArgs> => {
    return this.watchQuery<Query['account'], QueryAccountArgs>(gqlDocsAccount.getAccount, variables);
  };

  /**
   * Due to weird relations to items, we often have to refresh current account
   */
  async refreshCurrentAccount(): Promise<unknown> {
    const account = await this.getStore('UserRepository').currentAccount();
    return account && this.getAccount({ id: account.id }).result();
  }
}

export function useGetAccount(variables: QueryAccountArgs): UseQueryResult<QueryAccountArgs, Account> {
  return useWatchQuery(useStore<RequireAccountRepository>('AccountRepository').getAccount, 'account', variables);
}
