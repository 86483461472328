import React from 'react';

export const OneSeatCouchIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.9 8.7H38.9C38.9 8.6 38.9 8.6 38.9 8.5V5.7C38.9 2.6 36.3 0 33.2 0H9.09998C5.99998 0 3.40002 2.6 3.40002 5.7V8.5C3.40002 8.6 3.40002 8.6 3.40002 8.7H1.40002C1.00002 8.7 0.700012 9 0.700012 9.3V22.2V32.6C0.700012 33 1.00002 33.2 1.40002 33.2H3.90002V36.8C3.90002 37.2 4.19998 37.4 4.59998 37.4H37.6C38 37.4 38.3 37.1 38.3 36.8V33.2H40.8C41.2 33.2 41.5 32.9 41.5 32.6V22.2V9.4C41.6 9 41.3 8.7 40.9 8.7ZM40.3 21.7H35.8C36.1 21.7 36.4 21.4 36.4 21.1V10H40.3V21.7ZM4.79999 8.5V5.7C4.79999 3.3 6.80001 1.3 9.20001 1.3H33.3C35.7 1.3 37.7 3.3 37.7 5.7V8.5C37.7 8.6 37.7 8.6 37.7 8.7H35.7C35.3 8.7 35 9 35 9.3V21C35 21.3 35.3 21.6 35.6 21.6H6.79999C7.09999 21.6 7.40002 21.3 7.40002 21V9.4C7.40002 9 7.10001 8.8 6.70001 8.8H4.70001C4.80001 8.7 4.79999 8.6 4.79999 8.5ZM6.09998 10V21C6.09998 21.3 6.40001 21.6 6.70001 21.6H2.20001V10H6.09998ZM37.1 36.2H5.40002V33.3H37.1V36.2ZM40.3 32H2.29999V23H40.3V32Z"
      fill="currentColor"
    />
  </svg>
);
