import React from 'react';

export const WallCoveringIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.14645 11.1465C8.24021 11.0527 8.36739 11.0001 8.5 11.0001H17.5C17.7761 11.0001 18 11.2239 18 11.5001C18 11.7762 17.7761 12.0001 17.5 12.0001H9V56.0001H53V47.5001C53 47.2239 53.2239 47.0001 53.5 47.0001C53.7761 47.0001 54 47.2239 54 47.5001V56.5001C54 56.7762 53.7761 57.0001 53.5 57.0001H8.5C8.22386 57.0001 8 56.7762 8 56.5001V11.5001C8 11.3674 8.05268 11.2403 8.14645 11.1465Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 11.0001C13.7761 11.0001 14 11.2239 14 11.5001V32.0001C14 34.4853 11.9853 36.5001 9.5 36.5001H8.5C8.22386 36.5001 8 36.2762 8 36.0001C8 35.7239 8.22386 35.5001 8.5 35.5001H9.5C11.433 35.5001 13 33.9331 13 32.0001V11.5001C13 11.2239 13.2239 11.0001 13.5 11.0001ZM31 46.5001C31.2761 46.5001 31.5 46.7239 31.5 47.0001V52.0001H53.5C53.7761 52.0001 54 52.2239 54 52.5001C54 52.7762 53.7761 53.0001 53.5 53.0001H23.5C21.567 53.0001 20 54.5671 20 56.5001H19C19 54.0148 21.0147 52.0001 23.5 52.0001H30.5V47.0001C30.5 46.7239 30.7239 46.5001 31 46.5001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 0.500053C19 0.223911 19.2239 5.34041e-05 19.5 5.34041e-05H64.5C64.7761 5.34041e-05 65 0.223911 65 0.500053V45.5001C65 45.7762 64.7761 46.0001 64.5 46.0001H19.5C19.2239 46.0001 19 45.7762 19 45.5001V0.500053ZM20 1.00005V45.0001H64V1.00005H20Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.916 0.222703C20.0692 0.452467 20.0071 0.762902 19.7773 0.916078L18.5 1.76765V46.5001H63.2324L64.084 45.2227C64.2372 44.9929 64.5476 44.9309 64.7774 45.084C65.0071 45.2372 65.0692 45.5476 64.916 45.7774L63.916 47.2774C63.8233 47.4165 63.6672 47.5001 63.5 47.5001H18C17.7239 47.5001 17.5 47.2762 17.5 47.0001V1.50005C17.5 1.33288 17.5836 1.17676 17.7227 1.08403L19.2227 0.084028C19.4524 -0.0691482 19.7628 -0.00706133 19.916 0.222703Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.5 56.0001C53.7761 56.0001 54 56.2239 54 56.5001V64.5001C54 64.7762 53.7761 65.0001 53.5 65.0001C53.2239 65.0001 53 64.7762 53 64.5001V56.5001C53 56.2239 53.2239 56.0001 53.5 56.0001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 56.0001C8.77614 56.0001 9 56.2239 9 56.5001V64.5001C9 64.7762 8.77614 65.0001 8.5 65.0001C8.22386 65.0001 8 64.7762 8 64.5001V56.5001C8 56.2239 8.22386 56.0001 8.5 56.0001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 3.00005C8.77614 3.00005 9 3.22391 9 3.50005V11.5001C9 11.7762 8.77614 12.0001 8.5 12.0001C8.22386 12.0001 8 11.7762 8 11.5001V3.50005C8 3.22391 8.22386 3.00005 8.5 3.00005Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 11.5001C1 11.2239 1.22386 11.0001 1.5 11.0001H9.5C9.77614 11.0001 10 11.2239 10 11.5001C10 11.7762 9.77614 12.0001 9.5 12.0001H1.5C1.22386 12.0001 1 11.7762 1 11.5001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53 56.5001C53 56.2239 53.2239 56.0001 53.5 56.0001H61.5C61.7761 56.0001 62 56.2239 62 56.5001C62 56.7762 61.7761 57.0001 61.5 57.0001H53.5C53.2239 57.0001 53 56.7762 53 56.5001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 56.5001C-1.20706e-08 56.2239 0.223858 56.0001 0.5 56.0001H8.5C8.77614 56.0001 9 56.2239 9 56.5001C9 56.7762 8.77614 57.0001 8.5 57.0001H0.5C0.223858 57.0001 1.20706e-08 56.7762 0 56.5001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6464 46.6465L19.1464 45.1465L19.8535 45.8536L18.3535 47.3536L17.6464 46.6465Z"
      fill="currentColor"
    />
  </svg>
);
