import { CoreError } from './core-error';

/**
 * This class represents internal error, when developer try to use service (store) that required user being signed-in
 * This is not API 403 error. It has other semantics: 403 is a user error, but this is developer store wrong usage error
 */
export class NotSignedInError extends CoreError {
  constructor() {
    super('The method could be called only in context of signed-in user');
  }
}
