import React from 'react';

import {
  Description,
  DescriptionRow,
  StyledDescriptionBlock,
  Tag,
  Tags,
  Title,
  TitleRow,
} from './DescriptionBlock.styled';

import { IRoomDesign } from '../../../fixtures';

interface IDescriptionBlockProps {
  roomDesign: IRoomDesign;
}

export const DescriptionBlock: React.FC<IDescriptionBlockProps> = ({ roomDesign }) => {
  const { title, description, tags } = roomDesign;
  return (
    <StyledDescriptionBlock>
      <TitleRow>{<Title>{title}</Title>}</TitleRow>
      <DescriptionRow>
        <Description>{description.full}</Description>
        <Tags>
          {tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Tags>
      </DescriptionRow>
    </StyledDescriptionBlock>
  );
};
