import { UseQueryResult } from './use-store';
import { LoadState } from '../load-state';

/**
 * Simplified use hooks result, that has only two items:
 * result and load state. So you cannot re-run request manually
 */
export type SimpleQueryResult<TResult> = [TResult, LoadState];

/**
 * Merges queries and return result when all of them are done.
 * LoadState is None if any of them are None
 * Pending if any of them are Pending
 * Done if all of them are Done
 * In case of any error, the first errorSTate is returned
 * @param queries
 */
export function mergeQueries<T1, R1, T2, R2>(
  queries: [UseQueryResult<T1, R1>, UseQueryResult<T2, R2>],
): SimpleQueryResult<[R1, R2]>;
export function mergeQueries<T1, R1, T2, R2, T3, R3>(
  queries: [UseQueryResult<T1, R1>, UseQueryResult<T2, R2>, UseQueryResult<T3, R3>],
): SimpleQueryResult<[R1, R2, R3]>;
export function mergeQueries<T1, R1, T2, R2, T3, R3, T4, R4>(
  queries: [UseQueryResult<T1, R1>, UseQueryResult<T2, R2>, UseQueryResult<T3, R3>, UseQueryResult<T4, R4>],
): SimpleQueryResult<[R1, R2, R3, R4]>;
export function mergeQueries(queries) {
  let mergedLoadState: LoadState = LoadState.done();

  /*
    This cycle here is basically for small optimization
   */
  for (let i = 0; i < queries.length; i++) {
    if (queries[i][1].isError()) {
      mergedLoadState = queries[i][1];
      break;
    }
    if (queries[i][1].isNone()) {
      mergedLoadState = LoadState.none();
      break;
    }
    if (queries[i][1].isPending()) {
      mergedLoadState = LoadState.pending();
    }
  }
  return [
    [
      queries[0] && queries[0][0],
      queries[1] && queries[1][0],
      queries[2] && queries[2][0],
      queries[3] && queries[3][0],
    ],
    mergedLoadState,
  ];
}
