import { styled, colors, body } from '../../../themes';

import { Container, Link, Socials } from '../../../components';
import { LogoIcon } from '../../../icons';

export const StyledPageFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.grayLight};
`;

export const MainSection = styled.div`
  min-height: 155px;
  padding: 40px 0;

  display: flex;
  justify-content: space-between;
`;

export const BottomSection = styled.div`
  width: 100%;
  height: 46px;

  display: flex;
  align-items: center;

  background-color: ${colors.grayDark};

  ${Link} {
    color: ${colors.white};
  }
`;

export const FlexContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled(LogoIcon)`
  width: 170px;
  height: 24px;
`;

export const Links = styled.div`
  width: 343px;
  display: flex;
  justify-content: space-between;
`;

export const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 42px;
  }
`;

export const RightLinks = styled.div`
  margin-left: auto;

  * {
    margin-left: 30px;
  }
`;

export const ButtonNews = styled.button`
  ${body};
  color: ${colors.black};
  text-decoration: none;
  border: none;
  background-color: transparent;

  &:hover,
  &:visited {
    color: ${colors.black};
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: none;
  }
`;
