import config from 'config';
const { staticServer } = config;

export interface ICMSMoodboard {
  sku: string;
  children: {
    list: {
      name: string;
      children: {
        list: {
          name: string;
          buttonCoordinateX: number;
          buttonCoordinateY: number;
          contentType: {
            id: number;
          };
          contentHtml: string;
          contentText: string;
          style: string;
        }[];
      };
    }[];
  };
}

export async function fetchMoodboards(): Promise<ICMSMoodboard[]> {
  const graphql = JSON.stringify({
    query: `
      query {
        moodboard {
          list {
            sku
            children {
              list {
                name
                image {
                  url
                  width
                  height
                }
                children {
                  list {
                    name
                    buttonCoordinateX
                    buttonCoordinateY
                    contentType {
                      id
                    }
                    contentHtml
                    contentText
                    style
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {},
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: graphql,
  };

  return fetch('https://cms-dev.furnished.de/graphql', requestOptions)
    .then(response => response.text())
    .then(response =>
      response.replace(/\\\/site\\\/assets\\\/files\\\//g, `${staticServer}\/cms\/site\/assets\/files\/`),
    )
    .then(response => JSON.parse(response).data.moodboard.list);
}
