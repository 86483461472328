import React, { ChangeEvent } from 'react';
import { Radio } from '../Radio';

import { RadioGroupStyled } from './RadioGroup.styled';

interface IRadioGroupProps {
  name: string;
  value: string;
  items: { value: string; label: string }[];
  onChange: (value: string | null) => unknown;
  isRound?: boolean;
  className?: string;
  disabled?: boolean;
}

export const RadioGroup: React.FC<IRadioGroupProps> = ({
  name,
  disabled,
  value,
  items,
  onChange,
  className,
  isRound = true,
}) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <RadioGroupStyled className={className}>
      {items.map(item => (
        <Radio
          name={name}
          value={item.value}
          checked={item.value === value}
          isRound={isRound}
          onChange={handleOnChange}
          key={item.value}
          disabled={disabled}
        >
          {item.label}
        </Radio>
      ))}
    </RadioGroupStyled>
  );
};
