import React from 'react';

export const ArmchairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43.4992 46.9C49.1992 43.2 52.9992 36.7 52.9992 29.3C52.7992 27.7 51.6992 24.8 48.9992 24.6C48.8992 16.5 42.8992 12 31.9992 12C21.0992 12 15.0992 16.5 14.9992 24.6C12.2992 24.7 11.1992 27.7 10.9992 29.3C10.9992 36.6 14.7992 43.1 20.4992 46.8L16.5992 52.7C16.3992 53 16.4992 53.4 16.7992 53.6C16.8992 53.7 16.9992 53.7 17.1992 53.7C17.3992 53.7 17.5992 53.6 17.6992 53.4L21.5992 47.5C24.5992 49.2 28.1992 50.2 31.8992 50.2C35.6992 50.2 39.1992 49.2 42.1992 47.5L46.0992 53.5C46.1992 53.7 46.3992 53.8 46.5992 53.8C46.6992 53.8 46.7992 53.8 46.9992 53.7C47.2992 53.5 47.3992 53.1 47.1992 52.8L43.4992 46.9ZM32.0992 13.3C39.1992 13.3 47.6992 15.3 47.7992 24.7C44.9992 25.3 44.1992 27.9 44.0992 29.3C44.0992 30.2 43.9992 31.1 43.7992 32H20.4992C20.2992 31.1 20.1992 30.2 20.1992 29.3C20.0992 27.8 19.2992 25.2 16.4992 24.7C16.4992 15.3 24.9992 13.3 32.0992 13.3ZM43.3992 33.3C41.7992 37.9 37.2992 41.3 32.0992 41.3C26.8992 41.3 22.4992 38 20.7992 33.3H43.3992ZM12.4992 29.4C12.4992 29.4 12.8992 25.9 15.3992 25.9C18.5992 25.9 18.8992 29 18.8992 29.3C18.8992 30.4 19.0992 31.5 19.2992 32.6C19.2992 32.6 19.2992 32.6 19.2992 32.7C19.2992 32.8 19.3992 33 19.3992 33.1C20.9992 38.6 26.0992 42.7 32.0992 42.7C38.0992 42.7 43.1992 38.6 44.7992 33.1C44.8992 33 44.8992 32.9 44.8992 32.7C44.8992 32.7 44.8992 32.7 44.8992 32.6C45.1992 31.6 45.2992 30.5 45.2992 29.4C45.2992 29 45.5992 25.9 48.7992 25.9C51.1992 25.9 51.6992 29.3 51.6992 29.3C51.6992 40.1 42.8992 48.9 32.0992 48.9C21.2992 49 12.4992 40.2 12.4992 29.4Z"
      fill="currentColor"
    />
  </svg>
);
