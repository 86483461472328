import React from 'react';

import { RadioGroupField } from './RadioGroupField';

export const workUnits = [
  { value: '-1', label: 'Einzel' },
  { value: '-2', label: 'Bench' },
];

interface IWorkUnitsFieldProps {
  name: string;
  label: string;
}

export const WorkUnitsField: React.FC<IWorkUnitsFieldProps> = ({ name, label }) => {
  return <RadioGroupField title={label} name={name} items={workUnits} />;
};
