import React, { useLayoutEffect } from 'react';

// TODO: Refactor (don't use components from other modules)
import { ChooseRoomPackage } from '../../../home-page/components/ChooseRoomPackage';
import { Container, Page, PageHeaderNormal, PageSection } from '../../../../lib/components';

export const PurchaseRoomPackagePage = () => {
  return (
    <Page>
      <PageHeaderNormal />
      <PageSection noBottomMargin={true}>
        <Container>
          <ChooseRoomPackage />
        </Container>
      </PageSection>
    </Page>
  );
};
