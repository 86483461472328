import React from 'react';

export const ComputerTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="48" height="38" viewBox="0 0 48 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M46.8 18.2H45.3V0.599998C45.3 0.199998 45 0 44.6 0H15.5H2.99997C2.59997 0 2.29996 0.299998 2.29996 0.599998V18.1H0.799957C0.399957 18.1 0.0999756 18.4 0.0999756 18.7C0.0999756 19.1 0.399957 19.3 0.799957 19.3H2.29996V36.7C2.29996 37.1 2.59997 37.3 2.99997 37.3H15.6C16 37.3 16.3 37 16.3 36.7V19.3H31.5V24.7V30.7V36.7C31.5 37.1 31.8 37.3 32.2 37.3H44.8C45.2 37.3 45.5 37 45.5 36.7V30.7V24.7V19.3H47C47.4 19.3 47.7 19 47.7 18.7C47.4 18.4 47.1 18.2 46.8 18.2ZM32.7 25.5H44V30.2H32.7V25.5ZM16.2 18.2V1.3H44V18.2H32.1H16.2ZM14.9 10.2H3.59998V6.4H14.9V10.2ZM3.59998 11.5H14.9V18.2H3.59998V11.5ZM14.9 1.3V5.1H3.59998V1.3H14.9ZM14.9 36.2H3.59998V19.4H14.9V36.2ZM44 19.5V24.2H32.7V19.5H44ZM44 36.2H32.7V31.5H44V36.2Z"
      fill="currentColor"
    />
    <path
      d="M11.8 29.3996C12.6 29.3996 13.2 28.7996 13.2 27.9996C13.2 27.1996 12.6 26.5996 11.8 26.5996C11 26.5996 10.4 27.1996 10.4 27.9996C10.4 28.7996 11 29.3996 11.8 29.3996ZM11.8 27.8996C11.8 27.8996 11.9 27.9996 11.8 27.8996C11.9 28.0996 11.7 28.0996 11.8 27.8996C11.7 27.9996 11.8 27.8996 11.8 27.8996Z"
      fill="currentColor"
    />
    <path
      d="M38.4 23.8C39.2 23.8 39.8 23.2 39.8 22.4C39.8 21.6 39.2 21 38.4 21C37.6 21 37 21.6 37 22.4C37 23.2 37.6 23.8 38.4 23.8ZM38.4 22.3C38.4 22.3 38.4 22.4 38.4 22.3C38.4 22.5 38.3 22.5 38.4 22.3C38.3 22.4 38.3 22.3 38.4 22.3Z"
      fill="currentColor"
    />
    <path
      d="M38.4 27C37.6 27 37 27.6 37 28.4C37 29.2 37.6 29.8 38.4 29.8C39.2 29.8 39.8 29.2 39.8 28.4C39.8 27.6 39.1 27 38.4 27ZM38.3 28.4C38.3 28.4 38.3 28.3 38.3 28.4C38.4 28.3 38.5 28.4 38.5 28.4C38.4 28.5 38.3 28.5 38.3 28.4Z"
      fill="currentColor"
    />
    <path
      d="M38.4 35.8C39.2 35.8 39.8 35.2 39.8 34.4C39.8 33.6 39.2 33 38.4 33C37.6 33 37 33.6 37 34.4C37 35.2 37.6 35.8 38.4 35.8ZM38.4 34.3C38.4 34.3 38.4 34.4 38.4 34.3C38.4 34.5 38.3 34.5 38.4 34.3C38.3 34.4 38.3 34.3 38.4 34.3Z"
      fill="currentColor"
    />
    <path
      d="M29.7999 7.09961H19.8999C19.4999 7.09961 19.2 7.39961 19.2 7.79961V13.6996C19.2 14.0996 19.4999 14.2996 19.8999 14.2996H29.7999C30.1999 14.2996 30.4999 13.9996 30.4999 13.6996V7.79961C30.4999 7.39961 30.1999 7.09961 29.7999 7.09961ZM29.2 13.0996H20.5999V8.49961H29.2V13.0996Z"
      fill="currentColor"
    />
    <path
      d="M29.7999 15.4004H19.8999C19.4999 15.4004 19.2 15.7004 19.2 16.0004C19.2 16.4004 19.4999 16.6004 19.8999 16.6004H29.7999C30.1999 16.6004 30.4999 16.3004 30.4999 16.0004C30.4999 15.7004 30.1999 15.4004 29.7999 15.4004Z"
      fill="currentColor"
    />
  </svg>
);
