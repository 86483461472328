import { styled, colors, body } from '../../themes';
import { Link as SmartLink } from '../../router/link';

export const Link = styled(SmartLink)`
  ${body};
  color: ${colors.black};
  text-decoration: none;

  &:hover,
  &:visited {
    color: ${colors.black};
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: none;
  }
`;
