import { ObservableQuery } from '@apollo/client';

import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireLog, RequireRoomRepository } from '../available-stores';
import {
  Mutation,
  MutationCreateRoomArgs,
  MutationDeleteRoomArgs,
  MutationUpdateRoomArgs,
  MutationGenerateRoomPdfArgs,
  FileResponse,
  Query,
  QueryRoomArgs,
  Room,
  MutationAddRoomItemArgs,
  MutationUpdateRoomItemArgs,
  MutationRemoveRoomItemArgs,
  RoomItem,
  QueryProductSetArgs,
  ProductSet,
  MutationSaveRoomArgs,
  MutationInviteExecutiveForRoomArgs,
  MutationCreateRoomItemArgs,
  MutationAddAllRoomItemsArgs,
  MutationDropRoomItemArgs,
  MutationApproveRoomArgs,
} from '../api/generated';

import { gqlDocsRoom } from '../gql-documents/gql-room';
import { UseMutationResult, useStore, useMutation, UseQueryResult, useWatchQuery, useQuery } from '../hooks/use-store';

export class RoomRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'RoomRepository');
  }

  /**
   * Returns room with all properties
   */
  getRoom = (args: QueryRoomArgs): ObservableQuery<Query['room'], QueryRoomArgs> => {
    return this.watchQuery<Query['room'], QueryRoomArgs>(gqlDocsRoom.getRoom, args);
  };

  /**
   * Returns room with all properties
   */
  getNonWatchRoom = (args: QueryRoomArgs): Promise<Room> => {
    // TODO move fetchPolicy to params because it not for all usage is needed
    return this.query<Query['room'], QueryRoomArgs>(gqlDocsRoom.getRoom, 'room', args, { fetchPolicy: 'network-only' });
  };

  /**
   * Create a room
   * @param variables
   */
  createRoom = (variables: MutationCreateRoomArgs): Promise<Room> => {
    return this.mutate(gqlDocsRoom.createRoom, 'createRoom', variables, { refetchQueries: ['project'] });
  };

  /**
   * Delete a room
   * @param id
   */
  deleteRoom = (variables: MutationDeleteRoomArgs): Promise<boolean> => {
    return this.mutate<boolean>(gqlDocsRoom.deleteRoom, 'deleteRoom', variables, { refetchQueries: ['project'] });
  };

  updateRoom = (variables: MutationUpdateRoomArgs): Promise<Room> => {
    return this.mutate(gqlDocsRoom.updateRoom, 'updateRoom', variables);
  };

  planForMe = (roomId: Room['id']): Promise<boolean> => {
    return this.mutate<boolean>(gqlDocsRoom.planForMe, 'planForMe', { roomId });
  };

  letMePlan = (roomId: Room['id']): Promise<boolean> => {
    return this.mutate<boolean>(gqlDocsRoom.letMePlan, 'letMePlan', { roomId });
  };

  generateRoomPDF = (variables: MutationGenerateRoomPdfArgs): Promise<FileResponse> => {
    return this.mutate(gqlDocsRoom.generateRoomPDF, 'generateRoomPDF', variables);
  };

  saveRoom = (variables: MutationSaveRoomArgs): Promise<boolean> => {
    return this.mutate<boolean>(gqlDocsRoom.saveRoom, 'saveRoom', variables);
  };

  orderRoom = (id: Room['id']): Promise<boolean> => {
    return this.mutate<boolean>(gqlDocsRoom.orderRoom, 'orderRoom', { id }, { refetchQueries: ['room'] });
  };

  approveRoom = (variables: MutationApproveRoomArgs): Promise<Room> => {
    return this.mutate<Room>(gqlDocsRoom.approveRoom, 'approveRoom', variables, { refetchQueries: ['room'] });
  };

  /**
   * Add all initial items to a room
   */
  addAllRoomItems = ({ roomId }: MutationAddAllRoomItemsArgs): Promise<RoomItem[]> => {
    return this.mutate(gqlDocsRoom.addAllRoomItems, 'addAllRoomItems', { roomId }, { refetchQueries: ['room'] });
  };

  /**
   * Add roomItem
   * @param variables
   */
  addRoomItem = (variables: MutationAddRoomItemArgs): Promise<RoomItem> => {
    return this.mutate(gqlDocsRoom.addRoomItem, 'addRoomItem', variables, { refetchQueries: ['room'] });
  };

  /**
   * Add roomItem
   * @param variables
   */
  dropRoomItem = (variables: MutationDropRoomItemArgs): Promise<RoomItem> => {
    return this.mutate(gqlDocsRoom.dropRoomItem, 'dropRoomItem', variables, { refetchQueries: ['room'] });
  };

  /**
   * Create roomItem
   * @param variables
   */
  createRoomItem = (variables: MutationCreateRoomItemArgs): Promise<RoomItem> => {
    // TODO: refetch queries is temporal, until we write cache mangling
    return this.mutate(gqlDocsRoom.createRoomItem, 'createRoomItem', variables, { refetchQueries: ['room'] });
  };
  /**
   * Add roomItem
   * @param variables
   */
  updateRoomItem = (variables: MutationUpdateRoomItemArgs): Promise<RoomItem> => {
    return this.mutate(gqlDocsRoom.updateRoomItem, 'updateRoomItem', variables, { refetchQueries: ['room'] });
  };
  /**
   * Remove roomItem
   * @param variables
   */
  removeRoomItem = (variables: MutationRemoveRoomItemArgs): Promise<boolean> => {
    // TODO: refetch queries are temporal, until we write cache mangling
    return this.mutate(gqlDocsRoom.removeRoomItem, 'removeRoomItem', variables, { refetchQueries: ['room'] });
  };

  /**
   * Returns product set by id
   */
  getProductSet = (args: QueryProductSetArgs): ObservableQuery<Query['productSet'], QueryProductSetArgs> => {
    return this.watchQuery<Query['productSet'], QueryRoomArgs>(gqlDocsRoom.getProductSet, args);
  };

  /**
   * Invite an executive for the room
   * @param variables
   */
  inviteExecutiveForRoom = (variables: MutationInviteExecutiveForRoomArgs): Promise<boolean> => {
    return this.mutate(gqlDocsRoom.inviteExecutiveForRoom, 'inviteExecutiveForRoom', variables);
  };
}

/**
 * Watching query for a room, which causes component re-render on data update
 * @param roomId
 */
export function useGetRoom(args: QueryRoomArgs): UseQueryResult<QueryRoomArgs, Room> {
  return useWatchQuery(useStore<RequireRoomRepository>('RoomRepository').getRoom, 'room', args);
}

export function useNonWatchGetRoom(args: QueryRoomArgs): UseQueryResult<QueryRoomArgs, Room> {
  return useQuery(useStore<RequireRoomRepository>('RoomRepository').getNonWatchRoom, args);
}

/**
 * Syntax sugar to create a room
 */
export function useCreateRoom(): UseMutationResult<MutationCreateRoomArgs, Room> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').createRoom);
}

/**
 * Remove a room
 */
export function useDeleteRoom(): UseMutationResult<MutationDeleteRoomArgs, boolean> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').deleteRoom);
}

/**
 * Update a room
 */
export function useUpdateRoom(): UseMutationResult<MutationUpdateRoomArgs, Room> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').updateRoom);
}

/**
 * Plan for me
 */
export function usePlanForMe(): UseMutationResult<Room['id'], boolean> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').planForMe);
}

/**
 * Start LetMePlan process. Need to make a poling of Room.status to determinate a finish
 */
export function useLetMePlan(): UseMutationResult<Room['id'], boolean> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').letMePlan);
}

/**
 * Generate a room PDF
 */
export function useGenerateRoomPDF(): UseMutationResult<MutationGenerateRoomPdfArgs, FileResponse> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').generateRoomPDF);
}

export function useAddAllRoomItems(): UseMutationResult<MutationAddAllRoomItemsArgs, RoomItem[]> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').addAllRoomItems);
}

export function useSaveRoom(): UseMutationResult<MutationSaveRoomArgs, boolean> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').saveRoom);
}

export function useOrderRoom(): UseMutationResult<Room['id'], boolean> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').orderRoom);
}

export function useApproveRoom(): UseMutationResult<MutationApproveRoomArgs, Room> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').approveRoom);
}

/**
 * Syntax sugar to add a roomItem
 */
export function useAddRoomItem(): UseMutationResult<MutationAddRoomItemArgs, RoomItem> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').addRoomItem);
}

/**
 * Syntax sugar to drop a roomItem
 */
export function useDropRoomItem(): UseMutationResult<MutationDropRoomItemArgs, RoomItem> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').dropRoomItem);
}

/**
 * Syntax sugar to create a roomItem
 */
export function useCreateRoomItem(): UseMutationResult<MutationCreateRoomItemArgs, RoomItem> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').createRoomItem);
}

/**
 * Syntax sugar to update a roomItem
 */
export function useUpdateRoomItem(): UseMutationResult<MutationUpdateRoomItemArgs, RoomItem> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').updateRoomItem);
}

/**
 * Syntax sugar to remove a roomItem
 */
export function useRemoveRoomItem(): UseMutationResult<MutationRemoveRoomItemArgs, boolean> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').removeRoomItem);
}

export function useGetProductSet(args: QueryProductSetArgs): UseQueryResult<QueryProductSetArgs, ProductSet> {
  return useWatchQuery(useStore<RequireRoomRepository>('RoomRepository').getProductSet, 'productSet', args);
}

export function useInviteExecutiveForRoom(): UseMutationResult<MutationInviteExecutiveForRoomArgs, boolean> {
  return useMutation(useStore<RequireRoomRepository>('RoomRepository').inviteExecutiveForRoom);
}
