import React from 'react';
import { RoomItemsStep } from '../../typesUtils';
import { ConfiguratorPageHeader } from '../../components/ConfiguratorPageHeader';
import { FurnitureEditorPage } from './FurnitureEditorPage';
import { IConfiguratorItemsPagesProps } from './ConfiguratorItemsPagesProps';
import { FurnitureViewerPage } from './FurnitureViewerPage';
import { SummaryPage } from './SummaryPage';

/**
 * This component is responsible for rendering only configurator "Items" steps, like editor, viewer and summary
 * @param step
 * @param room
 * @param changeStep
 * @constructor
 */
export const ConfiguratorItemsRootStep: React.FC<IConfiguratorItemsPagesProps> = props => {
  const { step } = props;

  return (
    <>
      <ConfiguratorPageHeader {...props} />
      {step === RoomItemsStep.RoomItemsEditor ? (
        <FurnitureEditorPage {...props} />
      ) : step === RoomItemsStep.RoomItemsViewer ? (
        <FurnitureViewerPage {...props} />
      ) : (
        <SummaryPage {...props} />
      )}
    </>
  );
};
