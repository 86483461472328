import React from 'react';

import { ProductConfiguration } from '../../../../../../lib/core/api/generated';
import { ProductOption } from '../../../../../../lib/components/ProductOption';
import { IOption, ToggledButtons } from './components/ToggledButtons';
import { ProductConfigurationSimple } from '../../ProductDetails';

import { Options, OptionsContainer, OptionsTitle } from './ProductOptions.styled';

interface IProductOptionsProps {
  title: string;
  productConfiguration: ProductConfigurationSimple;
  productConfigurations: ProductConfigurationSimple[];
  onChange: (productConfiguration: ProductConfigurationSimple) => unknown;
}

function sizeDescription(productConfiguration: ProductConfiguration): string {
  const {
    width = null,
    depth = null,
    height = null,
    heightMax = null,
    heightMin = null,
    heightAdjustable = null,
  } = productConfiguration;

  const heightDescription =
    heightAdjustable && heightMin !== null && heightMax !== null
      ? `H: (${heightMin / 10}–${heightMax / 10}) cm`
      : height !== null
      ? `H: ${height / 10} cm`
      : '';

  const widthDescription = width ? `B: ${width / 10} cm` : '';

  const depthDescription = depth ? `T: ${depth / 10} cm` : '';

  return [widthDescription, heightDescription, depthDescription].filter(item => item).join(', ') || 'Ungewißheit';
}

export const ProductOptions: React.FC<IProductOptionsProps> = props => {
  const { title, productConfiguration, productConfigurations, onChange } = props;

  const options: IOption[] = productConfigurations.map((_productConfiguration: ProductConfiguration) => ({
    label: sizeDescription(_productConfiguration),
    value: _productConfiguration.sku,
  }));

  const handleOnChange = (sku: string) => {
    const newProductConfiguration = productConfigurations.find(
      _productConfiguration => _productConfiguration.sku === sku,
    );

    if (newProductConfiguration && productConfiguration.sku !== newProductConfiguration.sku) {
      onChange(newProductConfiguration);
    }
  };

  return (
    <>
      <Options>
        <OptionsTitle>{title}</OptionsTitle>
        <OptionsContainer>
          <ProductOption title="Größen">
            <ToggledButtons value={productConfiguration.sku} options={options} onChange={handleOnChange} />
          </ProductOption>
        </OptionsContainer>
      </Options>
    </>
  );
};
