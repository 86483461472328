import React, { Component } from 'react';
import { createPortal } from 'react-dom';

export class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  el: HTMLDivElement;

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}
