import I18n from 'i18n-js';

const scopeSeparator = '<===>';

interface TParams {
  count?: number;
  [key: string]: any;
}

export type TFunction = (phrase: string, params?: TParams) => string;

/**
 * Initialize global I18n with translations
 * @param i18n
 */
export function initializeI18n(translations: { en: any; ru: any; de: any }, defaultLocale: string) {
  I18n.fallbacks = true;
  I18n.translations = translations;
  I18n.defaultSeparator = scopeSeparator;
  I18n.defaultLocale = defaultLocale;
  I18n.locale = defaultLocale; // Remove this line if you want to auto-detect locale
}

/**
 * Initialize with scope and get t() function
 * This function should be called in every file you need translator like this:
 * const t = getT('scope');
 * @param scope
 */
export function getT(scope: string): TFunction {
  return function (phrase: string, params?: TParams) {
    const translatorParams = { defaultValue: `${phrase}'` };

    if (params) {
      Object.assign(translatorParams, params);
    }

    return I18n.translate(`${scope}${scopeSeparator}${phrase}`, translatorParams);
  };
}
