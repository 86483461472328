import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireColorSchemeRepository, RequireLog } from '../available-stores';
import { gqlDocsRoom } from '../gql-documents/gql-room';
import { useQuery, UseQueryResult, useStore } from '../hooks/use-store';
import { ColorScheme, Mutation, Query } from '../api/generated';

export class ColorSchemeRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'ColorSchemeRepository');
  }

  /**
   * Request and return room design list
   */
  getList = (): Promise<ColorScheme[]> => {
    return this.query(gqlDocsRoom.colorSchemeList, 'colorSchemes');
  };
}

/**
 * Syntax sugar to obtain FloorType list
 */
export function useColorSchemeList(): UseQueryResult<void, ColorScheme[]> {
  return useQuery(useStore<RequireColorSchemeRepository>('ColorSchemeRepository').getList, undefined);
}
