import React from 'react';

export const DressingTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="55" height="50" viewBox="0 0 55 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M53.3 22.4H49.6H42.5V13.7C42.5 11.1 40.4 9.1 37.9 9.1H34.8C34.5 4.1 31.2 0 27.1 0C23 0 19.6 4 19.4 9.1H15.9C13.3 9.1 11.3 11.2 11.3 13.7V22.4H4.49997H0.799957C0.399957 22.4 0.0999756 22.7 0.0999756 23.1C0.0999756 23.5 0.399957 23.7 0.799957 23.7H3.79996V29.7V48.9C3.79996 49.3 4.09997 49.5 4.49997 49.5C4.89997 49.5 5.19998 49.2 5.19998 48.9V30.4H27.2H49.2V48.9C49.2 49.3 49.5 49.5 49.9 49.5C50.3 49.5 50.6 49.2 50.6 48.9V23.7H53.6C54 23.7 54.3 23.4 54.3 23.1C54 22.7 53.7 22.4 53.3 22.4ZM27 1.3C30.6 1.3 33.5 5.1 33.5 9.7C33.5 14.3 30.6 18.1 27 18.1C23.4 18.1 20.5 14.3 20.5 9.7C20.5 5.1 23.4 1.3 27 1.3ZM12.4 13.7C12.4 11.9 13.9 10.4 15.7 10.4H19.2C19.5 15.4 22.8 19.5 26.9 19.5C31 19.5 34.4 15.5 34.6 10.4H37.7C39.5 10.4 41 11.9 41 13.7V22.4H26.8H12.2V13.7H12.4ZM4.89996 23.7H26.3V29.1H4.89996V23.7ZM49 29.1H27.6V23.7H49V29.1Z"
      fill="currentColor"
    />
    <path
      d="M17.1 25.7002H14.1C13.7 25.7002 13.4 26.0002 13.4 26.3002C13.4 26.7002 13.7 26.9002 14.1 26.9002H17.1C17.5 26.9002 17.8 26.6002 17.8 26.3002C17.8 26.0002 17.5 25.7002 17.1 25.7002Z"
      fill="currentColor"
    />
    <path
      d="M39.8 25.7002H36.8C36.4 25.7002 36.1 26.0002 36.1 26.3002C36.1 26.7002 36.4 26.9002 36.8 26.9002H39.8C40.2 26.9002 40.5 26.6002 40.5 26.3002C40.4 26.0002 40.1 25.7002 39.8 25.7002Z"
      fill="currentColor"
    />
    <path
      d="M23.5 9.00039C23.7 9.00039 23.8 8.90039 24 8.80039L27.5 5.40039C27.8 5.10039 27.8 4.70039 27.5 4.50039C27.2 4.20039 26.8 4.20039 26.6 4.50039L23.1 7.90039C22.8 8.20039 22.8 8.60039 23.1 8.80039C23.1 8.90039 23.3 9.00039 23.5 9.00039Z"
      fill="currentColor"
    />
    <path
      d="M24 12.4996C24.1 12.5996 24.3 12.6996 24.5 12.6996C24.7 12.6996 24.8 12.5996 25 12.4996L31 6.59961C31.3 6.29961 31.3 5.89961 31 5.69961C30.7 5.39961 30.3 5.39961 30.1 5.69961L24.1 11.5996C23.7 11.7996 23.7 12.1996 24 12.4996Z"
      fill="currentColor"
    />
  </svg>
);
