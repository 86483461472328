import { colors, styled } from '../../../../lib/themes';
import { StyledNavigationButton } from '../../../../lib/components/Navigation/NavigationButton/NavigationButton.styled';

export const StyledWhatStyledDoWeOffer = styled.div``;

export const PackageInfo = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  width: 445px;
  min-height: 480px;
  padding: 65px 45px 65px 65px;

  background-color: ${colors.white};

  ${StyledNavigationButton} {
    margin-top: auto;
    justify-self: flex-end;
  }
`;

export const TabsWrapper = styled.div`
  border-bottom: 1px solid ${colors.grayLighter};
  margin-bottom: 30px;
`;

export const PackageWrapper = styled.div`
  position: relative;
`;
