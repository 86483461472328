import React from 'react';

import { ToggleButtonContent, ToggleButtonGroup, ToggleButtonWrapper } from './ToggledButtons.styled';

export interface IOption {
  label: string;
  value: string;
}
interface IToggledButtonsProps {
  options: IOption[];
  value: string;
  onChange: (value: string) => void;
}

export const ToggledButtons: React.FC<IToggledButtonsProps> = ({ onChange, options, value }) => {
  const handleChange = _value => () => {
    onChange(_value);
  };

  const renderToggleButton = ({ label, value: _value }: IOption, index) => {
    return (
      <ToggleButtonWrapper key={index}>
        <input type="checkbox" value={value} checked={_value === value} onChange={handleChange(_value)} />
        <ToggleButtonContent>{label}</ToggleButtonContent>
      </ToggleButtonWrapper>
    );
  };

  return <ToggleButtonGroup>{options.map(renderToggleButton)}</ToggleButtonGroup>;
};
