import React from 'react';

export const WritingDeskWideIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="53" height="28" viewBox="0 0 53 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H51.5769C51.8531 0 52.0769 0.223858 52.0769 0.5V3.95362C52.0769 4.22976 51.8531 4.45362 51.5769 4.45362C34.2687 4.45362 0.917069 4.45362 0.5 4.45362C0.223858 4.45362 0 4.22977 0 3.95362V0.5ZM1 3.45362V1H51.0769V3.45362L1 3.45362Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M50 25.5V3.5H51V25.5H50Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M49 16V4H50V16H49Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 16V4H3V16H2Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1 25.5V3.5H2V25.5H1Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1464 23.1465C24.3417 22.9512 24.6583 22.9512 24.8536 23.1465L26 24.2929L26 11.2071L24.8536 12.3536C24.6583 12.5488 24.3417 12.5488 24.1464 12.3536C23.9512 12.1583 23.9512 11.8417 24.1464 11.6465L26.1464 9.64645C26.3417 9.45119 26.6583 9.45119 26.8536 9.64645L28.8536 11.6465C29.0488 11.8417 29.0488 12.1583 28.8536 12.3536C28.6583 12.5488 28.3417 12.5488 28.1464 12.3536L27 11.2071L27 24.2929L28.1464 23.1465C28.3417 22.9512 28.6583 22.9512 28.8536 23.1465C29.0488 23.3417 29.0488 23.6583 28.8536 23.8536L26.8536 25.8536C26.6583 26.0488 26.3417 26.0488 26.1464 25.8536L24.1464 23.8536C23.9512 23.6583 23.9512 23.3417 24.1464 23.1465Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 25.5H50V26.5H51V25.5ZM50 24.5C49.4477 24.5 49 24.9477 49 25.5V26.5C49 27.0523 49.4477 27.5 50 27.5H51C51.5523 27.5 52 27.0523 52 26.5V25.5C52 24.9477 51.5523 24.5 51 24.5H50Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 4.5H41V6.5H46V4.5ZM41 3.5C40.4477 3.5 40 3.94772 40 4.5V6.5C40 7.05228 40.4477 7.5 41 7.5H46C46.5523 7.5 47 7.05228 47 6.5V4.5C47 3.94772 46.5523 3.5 46 3.5H41Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 25.5H1L1 26.5H2V25.5ZM1 24.5C0.447715 24.5 0 24.9477 0 25.5V26.5C0 27.0523 0.447715 27.5 1 27.5H2C2.55228 27.5 3 27.0523 3 26.5V25.5C3 24.9477 2.55228 24.5 2 24.5H1Z"
      fill="currentColor"
    />
  </svg>
);
