import React from 'react';

export const CabinetsTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="60" height="28" viewBox="0 0 60 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.5 0.400391H55.3H40.1H19.5H4.29999H1.39999C0.999994 0.400391 0.700012 0.700342 0.700012 1.10034C0.700012 1.50034 0.999994 1.80035 1.39999 1.80035H3.70001V21.2004V23.4004V26.4004C3.70001 26.8004 3.99999 27.1003 4.39999 27.1003C4.79999 27.1003 5.10001 26.8004 5.10001 26.4004V24.0004H19V26.4004C19 26.8004 19.3 27.1003 19.7 27.1003C20.1 27.1003 20.4 26.8004 20.4 26.4004V23.4004V21.2004V5.60034H39.7V21.2004V23.4004V26.4004C39.7 26.8004 40 27.1003 40.4 27.1003C40.8 27.1003 41.1 26.8004 41.1 26.4004V24.0004H55V26.4004C55 26.8004 55.3 27.1003 55.7 27.1003C56.1 27.1003 56.4 26.8004 56.4 26.4004V23.4004V21.2004V1.80035H58.9C59.3 1.80035 59.6 1.50034 59.6 1.10034C59.1 0.700342 58.8 0.400391 58.5 0.400391ZM5 22.6003V21.0004V1.60034H18.9V21.0004V22.6003H5ZM20.2 4.10034V1.60034H39.5V4.10034H20.2ZM40.8 22.6003V21.0004V12.7004H54.7V21.0004V22.6003H40.8ZM54.6 11.5004H40.7V1.70038H54.6V11.5004Z"
      fill="currentColor"
    />
    <path
      d="M15 10.6006C14.2 10.6006 13.5 11.3006 13.5 12.1006C13.5 12.9006 14.2 13.6006 15 13.6006C15.8 13.6006 16.5 12.9006 16.5 12.1006C16.6 11.3006 15.9 10.6006 15 10.6006ZM14.8 12.1006C14.8 12.0006 14.9 11.9006 15 11.9006C15.1 11.9006 15.2 12.0006 15.2 12.1006C15.3 12.4006 14.8 12.4006 14.8 12.1006Z"
      fill="currentColor"
    />
    <path
      d="M47.7 9.80078C48.5 9.80078 49.2 9.10078 49.2 8.30078C49.2 7.50078 48.5 6.80078 47.7 6.80078C46.9 6.80078 46.2 7.50078 46.2 8.30078C46.2 9.10078 46.9 9.80078 47.7 9.80078ZM47.7 8.00079C47.8 8.00079 47.9 8.10081 47.9 8.20081C47.9 8.50081 47.4 8.50081 47.4 8.20081C47.5 8.10081 47.6 8.00079 47.7 8.00079Z"
      fill="currentColor"
    />
    <path
      d="M47.7 17.8008C46.9 17.8008 46.2 18.5008 46.2 19.3008C46.2 20.1008 46.9 20.8008 47.7 20.8008C48.5 20.8008 49.2 20.1008 49.2 19.3008C49.2 18.5008 48.5 17.8008 47.7 17.8008ZM47.5 19.4008C47.5 19.3008 47.6 19.2008 47.7 19.2008C47.8 19.2008 47.9 19.3008 47.9 19.4008C47.9 19.6008 47.5 19.6008 47.5 19.4008Z"
      fill="currentColor"
    />
  </svg>
);
