import React from 'react';

import {
  BarstoolIcon,
  BulbFloorLampIcon,
  CabinetStoragesIcon,
  CeilingLightIcon,
  ChairIcon,
  ComputerChairIcon,
  DeskIcon,
  DeskLampIcon,
  HelpIcon,
  HighboardIcon,
  LampIcon,
  NightStandIcon,
  NoLegsSofaIcon,
  OfficeTablesIcon,
  OneSeatSofaIcon,
  RackIcon,
  ReceptionIcon,
  SeatingFurnitureIcon,
  SideboardIcon,
  SlidingWardrobeIcon,
  SmallLogoIcon,
  TextilIcon,
  ThreeSeatSofaIcon,
  WardrobeIcon,
  WhiteboardIcon,
  PhoneBoothIcon,
  AcousticsIcon,
  BenchTwoSeatWideIcon,
  BenchTwoSeatIcon,
  BenchPlusWideIcon,
  BenchPlusIcon,
  WritingDeskWideIcon,
  WritingDeskIcon,
  AcousticFurnitureIcon,
  WallCoveringIcon,
  TwoSeatSofaIcon,
  CeilingLampIcon,
  TableSeparatorIcon,
  MeetingHubIcon,
  SimpleTableIcon,
  CoffeeTableIcon,
  CarpetIcon,
  BenchFourSeatIcon,
  WallIcon,
  MeetingTableIcon,
  WhiteboardWallIcon,
  HighTableIcon,
  PhotoSetsIcon,
  TableNarrowStorageIcon,
  ModularSofaIcon,
  SimpleBenchIcon,
} from '../../lib/icons';

export interface ICategoryIcon {
  name: string;
  parentId?: string | null;
  icon: JSX.Element;
}

// snippet for generate categoryIcons map
// JSON.stringify(
//   window.flattenCategories.reduce((acc, { id, name, parentId }) => {
//     acc[id] = { name, parentId, icon: '<HelpIcon />' };
//     return acc;
//   }, {})
// );

export const categoryIcons: { [categoryId: string]: ICategoryIcon } = {
  unknown: { name: 'unknown', icon: <HelpIcon /> },
  all: { name: 'Alles', icon: <SmallLogoIcon /> },
  '720': { name: 'Bürotische', parentId: null, icon: <DeskIcon /> },
  '721': { name: 'Sitzmöbel', parentId: null, icon: <OneSeatSofaIcon /> },
  '722': { name: 'Stauraum', parentId: null, icon: <SlidingWardrobeIcon /> },
  '723': { name: 'Whiteboard', parentId: null, icon: <WhiteboardIcon /> },
  '724': { name: 'Telefonkabine', parentId: null, icon: <PhoneBoothIcon /> },
  '725': { name: 'Accessories', parentId: null, icon: <TextilIcon /> },
  '726': { name: 'Akustik', parentId: null, icon: <AcousticsIcon /> },
  '727': { name: 'Beleuchtung', parentId: null, icon: <BulbFloorLampIcon /> },
  '728': { name: 'Bench 2er x 160', parentId: '720', icon: <BenchTwoSeatWideIcon /> },
  '729': { name: 'Schreibtisch 160', parentId: '720', icon: <OfficeTablesIcon /> },
  '730': { name: 'Bench 2er x 140', parentId: '720', icon: <BenchTwoSeatIcon /> },
  '731': { name: 'Erweiterungsmodul Bench 2er x 160', parentId: '720', icon: <BenchPlusWideIcon /> },
  '732': { name: 'Erweiterungsmodul Bench 2er x 140', parentId: '720', icon: <BenchPlusIcon /> },
  '733': { name: 'Schreibtisch 160/elekt.', parentId: '720', icon: <WritingDeskWideIcon /> },
  '734': { name: 'Schreibtisch 140/elekt.', parentId: '720', icon: <WritingDeskIcon /> },
  '735': { name: 'Bürostuhl', parentId: '721', icon: <SeatingFurnitureIcon /> },
  '736': { name: 'Rollcontainer', parentId: '722', icon: <HighboardIcon /> },
  '737': { name: 'Sideboard', parentId: '722', icon: <SideboardIcon /> },
  '738': { name: 'Whiteboard Mobil', parentId: '723', icon: <WhiteboardIcon /> },
  '739': { name: 'Akustikmöbel', parentId: '724', icon: <AcousticFurnitureIcon /> },
  '740': { name: 'Garderobe', parentId: '725', icon: <ReceptionIcon /> },
  '741': { name: 'Wandverkleidung', parentId: '726', icon: <WallCoveringIcon /> },
  '742': { name: 'Sessel', parentId: '721', icon: <OneSeatSofaIcon /> },
  '743': { name: 'Sofa 2-Sitzer', parentId: '721', icon: <TwoSeatSofaIcon /> },
  '744': { name: 'Tischleuchte', parentId: '727', icon: <DeskLampIcon /> },
  '745': { name: 'Deckenleuchte', parentId: '727', icon: <CeilingLampIcon /> },
  '746': { name: 'Highboard', parentId: '722', icon: <CabinetStoragesIcon /> },
  '747': { name: 'Regal', parentId: '722', icon: <RackIcon /> },
  '748': { name: 'Schrank', parentId: '722', icon: <WardrobeIcon /> },
  '749': { name: 'Tischtrenner', parentId: '726', icon: <TableSeparatorIcon /> },
  '750': { name: 'Meeting-Hub', parentId: '721', icon: <MeetingHubIcon /> },
  '751': { name: 'Sofa 3-Sitzer', parentId: '721', icon: <ThreeSeatSofaIcon /> },
  '752': { name: 'Tische', parentId: null, icon: <SimpleTableIcon /> },
  '753': { name: 'Pendelleuchte', parentId: '727', icon: <CeilingLightIcon /> },
  '754': { name: 'Stehleuchte', parentId: '727', icon: <LampIcon /> },
  '755': { name: 'Couchtisch', parentId: '752', icon: <CoffeeTableIcon /> },
  '756': { name: 'Beistelltisch', parentId: '752', icon: <NightStandIcon /> },
  '757': { name: 'Teppich', parentId: '725', icon: <CarpetIcon /> },
  '758': { name: 'Bench 4er x 140', parentId: '720', icon: <BenchFourSeatIcon /> },
  '759': { name: 'Pinnwand', parentId: '725', icon: <WallIcon /> },
  '760': { name: 'Meetingtisch mittel (8-10 pax)', parentId: '752', icon: <MeetingTableIcon /> },
  '761': { name: 'Meetingstuhl', parentId: '721', icon: <ComputerChairIcon /> },
  '762': { name: 'Whiteboard Wand', parentId: '723', icon: <WhiteboardWallIcon /> },
  '763': { name: 'Hochtisch', parentId: '752', icon: <HighTableIcon /> },
  '764': { name: 'Barhocker', parentId: '721', icon: <BarstoolIcon /> },
  '765': { name: 'Bildersets', parentId: '725', icon: <PhotoSetsIcon /> },
  '766': { name: 'Stuhl', parentId: '721', icon: <ChairIcon /> },
  '767': { name: 'Schreibtisch 140', parentId: '720', icon: <TableNarrowStorageIcon /> },
  '768': { name: 'Modulsofa', parentId: '721', icon: <ModularSofaIcon /> },
  '769': { name: 'Sofa', parentId: '721', icon: <NoLegsSofaIcon /> },
  '770': { name: 'Bank', parentId: '721', icon: <SimpleBenchIcon /> },
};

/**
 * Always returns JSX.Element representing the given category
 * In any wrong case returns at least icon for 'unknown' category
 * @param categoryId
 */
export function getCategoryIcon(categoryId: string): JSX.Element {
  return categoryIcons[String(categoryId)] ? categoryIcons[String(categoryId)].icon : categoryIcons['unknown'].icon;
}
