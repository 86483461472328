import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireLog, RequireRoomPackagesRepository } from '../available-stores';
import { useQuery, UseQueryResult, useStore, UseMutationResult, useMutation } from '../hooks/use-store';
import { gqlDocsRoomPackages } from '../gql-documents/gql-room-packages';
import {
  Mutation,
  Query,
  RoomPackage,
  MutationPurchaseRoomPackageArgs,
  PurchaseResponse,
  MutationCompletePaypalRoomPackagePurchaseArgs,
} from '../api/generated';

export class RoomPackagesRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'RoomPackagesRepository');
  }

  /**
   * Request and return room type list
   */
  getList = (): Promise<RoomPackage[]> => {
    return this.query(gqlDocsRoomPackages.getRoomPackages, 'roomPackages');
  };

  purchaseRoomPackage = (variables: MutationPurchaseRoomPackageArgs): Promise<PurchaseResponse> => {
    return this.mutate(gqlDocsRoomPackages.purchaseRoomPackage, 'purchaseRoomPackage', variables);
  };

  completePaypalRoomPackagePurchase = (variables: MutationCompletePaypalRoomPackagePurchaseArgs): Promise<boolean> => {
    return this.mutate(
      gqlDocsRoomPackages.completePaypalRoomPackagePurchase,
      'completePaypalRoomPackagePurchase',
      variables,
      { refetchQueries: ['me'] },
    );
  };
}

/**
 * Syntax sugar to obtain RoomPackage list
 */
export function useGetRoomPackages(): UseQueryResult<void, RoomPackage[]> {
  const roomPackageRepository = useStore<RequireRoomPackagesRepository>('RoomPackagesRepository');

  return useQuery(roomPackageRepository.getList, undefined);
}

// purchase room package
export function usePurchaseRoomPackage(): UseMutationResult<MutationPurchaseRoomPackageArgs, PurchaseResponse> {
  const roomPackageRepository = useStore<RequireRoomPackagesRepository>('RoomPackagesRepository');
  return useMutation(roomPackageRepository.purchaseRoomPackage);
}

// complete purchase room package
export function useCompletePaypalRoomPackagePurchase(): UseMutationResult<
  MutationCompletePaypalRoomPackagePurchaseArgs,
  boolean
> {
  const roomPackageRepository = useStore<RequireRoomPackagesRepository>('RoomPackagesRepository');
  return useMutation(roomPackageRepository.completePaypalRoomPackagePurchase);
}
