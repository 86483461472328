import React from 'react';

export const SingleBedIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="40" height="43" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.6 27.6998V27.5998V27.4998L32.6 12.2998V1.9998C32.6 1.0998 31.8 0.299805 30.9 0.299805H8.79996C7.89996 0.299805 7.09998 1.0998 7.09998 1.9998V12.1998L0.0999756 27.3998V27.4998V27.5998V41.9998C0.0999756 42.3998 0.399957 42.5998 0.799957 42.5998H4.99997C5.39997 42.5998 5.69998 42.2998 5.69998 41.9998V33.6998H34.3V41.9998C34.3 42.3998 34.6 42.5998 35 42.5998H39.2C39.6 42.5998 39.9 42.2998 39.9 41.9998L39.6 27.6998ZM8.09998 12.9998H31.4L37.8 27.0998H1.59998L8.09998 12.9998ZM27.2 11.6998H12.4V7.59981C12.4 6.89981 13 6.3998 13.6 6.3998H26C26.7 6.3998 27.2 6.99981 27.2 7.59981V11.6998ZM8.39996 1.9998C8.39996 1.7998 8.59996 1.59981 8.79996 1.59981H30.9C31.1 1.59981 31.3 1.7998 31.3 1.9998V11.6998H28.6V7.59981C28.6 6.19981 27.5 5.09981 26.1 5.09981H13.7C12.3 5.09981 11.2 6.19981 11.2 7.59981V11.6998H8.49997V1.9998H8.39996ZM38.3 41.3998H35.4V33.0998C35.4 32.6998 35.1 32.4998 34.7 32.4998H4.79996C4.39996 32.4998 4.09998 32.7998 4.09998 33.0998V41.3998H1.19998V28.3998H38.1V41.3998H38.3Z"
      fill="currentColor"
    />
  </svg>
);
