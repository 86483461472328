import React from 'react';

import { Link } from '../../Link';
import { PageHeader, PageHeaderLeftColumn, PageHeaderCenterColumn, PageHeaderRightColumn } from '../PageHeader';
import { Logo } from './PageHeaderNormal.styled';

export const PageHeaderNormal: React.FC = () => {
  return (
    <PageHeader>
      <PageHeaderLeftColumn />
      <PageHeaderCenterColumn>
        <Link to="/">
          <Logo />
        </Link>
      </PageHeaderCenterColumn>
      <PageHeaderRightColumn />
    </PageHeader>
  );
};
