import React from 'react';

import { CMSContent } from '../../lib/components/CMSContent/CMSContent';
import { Header } from '../../lib/components/Header';
import { Container } from '../../lib/components/Container';
import { PageFooter } from '../../lib/components/Page/PageFooter';

import { ImprintStyled, Content } from './Imprint.styled';

export const Imprint: React.FC = () => (
  <ImprintStyled>
    <Header />
    <Content>
      <Container>
        <CMSContent>
          <br />
          <br />
          <br />
          <h1>Impressum</h1>
          <br />
          <br />
          <h5>
            Furnished GmbH (Gesellschaft mit beschränkter Haftung)
            <br />
            Olga-Benario-Prestes-Str. 11
            <br />
            10407 Berlin
            <br />
            <br />
            Vertretungsberechtigte Geschäftsführer: Daniela Rank, Michael Boehland
            <br />
            E-Mail: contact(at)furnished.de
            <br />
            <br />
            Amtsgericht: Berlin (Charlottenburg)
            <br />
            Handelsregisternummer: HRB 213916B
            <br />
            Steuernummer: in Beantragung
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG: in Beantragung
            <br />
            <br />
            Inhaltlich Verantwortlicher nach § 55 Abs. 2 RStV: Daniela Rank (Anschrift wie zuvor genannt)
          </h5>
          <br />
          <br />
          <h5>
            <strong>Nutzungshinweis</strong>
            <br />
            Unser Angebot richtet sich ausschließlich an Geschäftskunden (B2B). Ein Geschäftskunde ist eine natürliche
            oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts
            in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt, sowie Freiberufler,
            Behörden, Körperschaften des öffentlichen Rechts und Vereine.
            <br />
            Natürliche Personen, die als Unternehmer handeln, müssen das 18. Lebensjahr vollendet haben. Mit dem
            Akzeptieren der AGB bestätigen Sie, dass Sie als Unternehmer im Sinne des BGB (§ 14 Abs. 1) tätig sind.
          </h5>
          <br />

          <h5>
            <strong>Rechtlicher Hinweis</strong>
            <br />
            Bei rechtlichen Anfragen bitten wir vorab um eine Kontaktaufnahme per E-Mail unter contact(at)furnished.de
          </h5>
          <br />
          <h5>
            <strong>Gesetzlicher Hinweis</strong>
            <br />
            Die auf diesen Seiten veröffentlichten Bilder sind urheberrechtlich geschützt. Für Fehler im Text oder
            falsche Links wird keine Haftung übernommen. Für die Inhalte externer Links sind die jeweiligen Anbieter
            verantwortlich.
          </h5>
          <br />
          <h5>
            <strong>Streitschlichtung</strong>
            <br />
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.
            <br />
            https://ec.europa.eu/consumers/odr
            <br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </h5>
        </CMSContent>
      </Container>
    </Content>
    <PageFooter />
  </ImprintStyled>
);
