import React from 'react';
import { useField, useFormikContext } from 'formik';

import { SliderEdit } from '../../../../../lib/components/SliderEdit';
import { ParameterWrapper } from '../RoomParamsPage/RoomParamsPage.styled';

interface ISliderFieldProps {
  isDisabled?: boolean;
  label: string;
  units?: string;
  name: string;
  min: number;
  max: number;
  step: number;
  smartTipContent?: React.ReactNode;
  onInfoButtonClick?: (anchorRef: React.RefObject<any>, smartTipContent: React.ReactNode) => void;
}

export const SliderField: React.FC<ISliderFieldProps> = (props: ISliderFieldProps) => {
  const { isDisabled, label, units, name, max, min, step, smartTipContent, onInfoButtonClick = () => {} } = props;
  const { setFieldValue } = useFormikContext<any>();
  const [field] = useField(name);

  const handleChange = React.useCallback(
    (value: number) => {
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );

  const handleOnInfoButtonClick = (anchorRef: React.RefObject<any>) => {
    onInfoButtonClick && onInfoButtonClick(anchorRef, smartTipContent);
  };

  return (
    <ParameterWrapper isDisabled={Boolean(isDisabled)}>
      <SliderEdit
        label={label}
        value={Number(field.value || 0)}
        max={max}
        min={min}
        step={step}
        units={units}
        onChange={handleChange}
        onInfoButtonClick={handleOnInfoButtonClick}
      />
    </ParameterWrapper>
  );
};
