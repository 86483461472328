import React, { useMemo } from 'react';
import { IProductTotalSubscriptionItem } from './ProductTotal.types';
import { Container, FormCheckbox } from '../../../../lib/components';

import {
  ProductTotalStyled,
  ProductTotalTitle,
  TotalFlexContainer,
  Subscription,
  CheckboxWrapper,
} from './ProductTotal.styled';
import { useIsInvitedUser } from '../../../../lib/core/hooks/useIsInvitedUser';

export const ProductTotal: React.FC = () => {
  const isInvitedUser = useIsInvitedUser();

  const subscription: IProductTotalSubscriptionItem[] = useMemo(
    () => [
      {
        id: 'subscribeToNewsletter',
        text: 'Immer auf dem aktuellen Stand: Zum Newsletter anmelden',
      },
      {
        id: 'contactMe',
        text: 'Bitte kontaktieren Sie mich direkt',
      },
      {
        id: 'acceptTermsAndConditions',
        text: 'AGBs gelesen und akzeptiert',
      },
    ],
    [],
  );

  const renderSubscriptionItems = (item: IProductTotalSubscriptionItem, key: number) => {
    const { id, text } = item;

    return (
      <CheckboxWrapper key={key}>
        <FormCheckbox data-hook-checkbox={text} name={id} disabled={isInvitedUser}>
          {text}
        </FormCheckbox>
      </CheckboxWrapper>
    );
  };

  return (
    <ProductTotalStyled>
      <Container>
        <TotalFlexContainer>
          <Subscription>
            <ProductTotalTitle>Weitere Informationen</ProductTotalTitle>
            {subscription.map(renderSubscriptionItems)}
          </Subscription>
        </TotalFlexContainer>
      </Container>
    </ProductTotalStyled>
  );
};
