import React from 'react';

import { IStyledCounterButtonProps, StyledCounterButton, Button, Count } from './CounterButton.styled';

export interface ICounterButtonProps extends Partial<IStyledCounterButtonProps> {
  min?: number;
  max?: number;
  value: number;
  onChange?: (value: number) => unknown;
  notChangeable?: boolean;
}

export const CounterButton: React.FC<ICounterButtonProps> = props => {
  const { min = 1, max = 100, value, onChange, notChangeable = false, mode = 'card' } = props;

  const handleDecrease = () => {
    if (value - 1 >= min) {
      onChange && onChange(value - 1);
    }
  };

  const handleIncrease = () => {
    if (value + 1 <= max) {
      onChange && onChange(value + 1);
    }
  };

  return (
    <StyledCounterButton mode={mode}>
      {!notChangeable && (
        <Button type="button" data-hook-button={'Reduce'} onClick={handleDecrease}>
          –
        </Button>
      )}
      <Count data-hook-value={'Pieces'}>{value} Stück</Count>
      {!notChangeable && (
        <Button type="button" data-hook-button={'Increase'} onClick={handleIncrease}>
          +
        </Button>
      )}
    </StyledCounterButton>
  );
};
