import React from 'react';

export const TwoSeatSofaIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="55" height="38" viewBox="0 0 55 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M54.2 8.7H52.2C52.2 8.6 52.2 8.6 52.2 8.5V5.7C52.2 2.6 49.6 0 46.5 0H8.39996C5.29996 0 2.70001 2.6 2.70001 5.7V8.5C2.70001 8.6 2.70001 8.6 2.70001 8.7H0.700012C0.300012 8.7 0 9 0 9.3V22.2V32.6C0 33 0.300012 33.2 0.700012 33.2H3.20001V36.8C3.20001 37.2 3.49996 37.4 3.89996 37.4H50.9C51.3 37.4 51.6 37.1 51.6 36.8V33.2H54.1C54.5 33.2 54.8 32.9 54.8 32.6V22.2V9.4C54.9 9 54.6 8.7 54.2 8.7ZM53.6 21.7H49.1C49.4 21.7 49.7 21.4 49.7 21.1V10H53.6V21.7ZM4.09998 8.5V5.7C4.09998 3.3 6.1 1.3 8.5 1.3H46.6C49 1.3 51 3.3 51 5.7V8.5C51 8.6 51 8.6 51 8.7H49C48.6 8.7 48.3 9 48.3 9.3V21C48.3 21.3 48.6 21.6 48.9 21.6H6.09998C6.39998 21.6 6.70001 21.3 6.70001 21V9.4C6.70001 9 6.4 8.8 6 8.8H4C4.1 8.7 4.09998 8.6 4.09998 8.5ZM5.39996 10V21C5.39996 21.3 5.7 21.6 6 21.6H1.5V10H5.39996ZM50.4 36.2H4.70001V33.3H50.4V36.2ZM53.6 32H1.59998V23H53.6V32Z"
      fill="currentColor"
    />
  </svg>
);
