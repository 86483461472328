import { LoadState } from '../load-state';

/**
 * Most probably you need to use mergeQueries() or On() functions instead of this.
 * But if you sure, then this function merges few LoadStates into one.
 * If any is in none or pending, returns pending state
 * If any has error, returns the first error
 * If all are done, returns done state.
 * @param ls1
 * @param ls2
 * @param ls3
 * @param ls4
 */
export function mergeLoadStates(ls1: LoadState, ls2?: LoadState, ls3?: LoadState, ls4?: LoadState): LoadState {
  const states = [ls1, ls2, ls3, ls4].filter(ls => ls instanceof LoadState) as LoadState[];

  // Any errors?
  const errorState = states.find(ls => ls?.isError());

  if (errorState) {
    return errorState;
  }

  // Any none or pending?
  if (states.find(ls => ls.isPending())) {
    return LoadState.pending();
  }

  // Any none or pending?
  if (states.find(ls => ls.isNone())) {
    return LoadState.none();
  }

  // Done is left the only possible options here
  return LoadState.done();
}
