import { styled, colors, css, h4, body } from '../../themes';

export const Button = styled.button`
  ${h4};
  appearance: none;

  width: 42px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.black};
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const Count = styled.div`
  ${body};
  color: ${colors.black};
  text-align: center;
  width: 100%;
`;

export interface IStyledCounterButtonProps {
  mode: 'form' | 'card' | 'plain';
}

export const StyledCounterButton = styled.div<IStyledCounterButtonProps>`
  width: 100%;
  height: 42px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  ${({ mode }) => {
    switch (mode) {
      default:
      case 'form':
        return css`
          background-color: ${colors.white};
          border: 1px solid ${colors.grayLighter};
          border-radius: 2px;
          height: 60px;
          ${Count} {
            font-size: 25px;
          }
        `;
      case 'card':
        return css`
          background-color: ${colors.grayLight};

          ${Button} {
            width: 17px;
          }
        `;
      case 'plain':
        return css`
          padding: 0;
          width: 160px;
        `;
    }
  }};
`;
