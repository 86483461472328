import React, { useCallback, useState } from 'react';

import { getRandomProductImage } from '../../../../lib/utils/getRandomProductImage';
import { formatCurrency } from '../../../../lib/utils/format-number';

import { FlexContainer } from '../../../../lib/components/FlexContainer';
import { Fab } from '../../../../lib/components/Fab';
import { CounterButton } from '../../../../lib/components/CounterButton';
import { Button } from '../../../../lib/components/Button';
import { ReloadIcon } from '../../../../lib/icons';
import { ProductConfiguration } from '../../../../lib/core/api/generated';
import { SideSheet } from '../../../../lib/components/SideSheet';

import { ProductOptions } from './components/ProductOptions';
import { ProductSet } from '../ConfiguratorItemsRootStep/FurnitureEditorPage/components/ProductSet';
import { Typography } from '../../../../lib/themes/typography/Typography';
import { SmartTipDialog } from '../../../../lib/components/SmartTipDialog';

import {
  ProductDetailsStyled,
  Info,
  Tips,
  ProductImage,
  Subtitle,
  Description,
  Details,
  DetailsTop,
  Price,
  Amount,
  TableRow,
  TableCol,
  Total,
} from './ProductDetails.styled';
import { useIsInvitedUser } from '../../../../lib/core/hooks/useIsInvitedUser';

export type ProductConfigurationSimple = Omit<ProductConfiguration, 'configurableProduct'>;

interface IProductDetails {
  productConfiguration: ProductConfigurationSimple;
  productConfigurations: ProductConfigurationSimple[];
  productSetId: string | null;
  onDone: (productConfiguration: ProductConfigurationSimple, amount: number) => Promise<any>;
  amount: number;
  // if isEditable then the user can change the amount of product and select other product from the product set
  isEditable: boolean;
  isAmountEditable?: boolean;
  isInvitedUser?: boolean;
}

export const ProductDetails: React.FC<IProductDetails> = props => {
  const { onDone, isEditable, isAmountEditable = isEditable, productSetId, isInvitedUser } = props;
  const tipButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [isTipOpened, setIsTipOpened] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [amount, setAmount] = useState<number>(props.amount);
  const [isShowProductSet, setIsShowProductSet] = useState(false);

  const [productConfiguration, setProductConfiguration] = useState<ProductConfigurationSimple>(
    props.productConfiguration,
  );

  const [productConfigurations, setProductConfigurations] = useState<ProductConfigurationSimple[]>(
    props.productConfigurations,
  );

  const { name, description, price } = productConfiguration;

  const handleOnDoneClick = useCallback(() => {
    setIsLoading(true);
    onDone(productConfiguration, amount).finally(() => setIsLoading(false));
  }, [onDone, productConfiguration, amount]);

  const handleOnShowProductSetClick = useCallback(() => {
    setIsShowProductSet(true);
  }, [setIsShowProductSet]);

  const handleOnCloseProductSet = useCallback(() => {
    setIsShowProductSet(false);
  }, [setIsShowProductSet]);

  const handleOnReplaceProduct = useCallback(
    (_productConfiguration: ProductConfiguration, _amount: number) => {
      setIsShowProductSet(false);
      setProductConfiguration(_productConfiguration);
      setProductConfigurations(_productConfiguration.configurableProduct.productConfigurations);
      setAmount(_amount);
    },
    [setProductConfiguration, setAmount],
  );

  const handleOnChangeProductConfiguration = useCallback(
    (_productConfiguration: ProductConfiguration) => {
      setProductConfiguration(_productConfiguration);
    },
    [setProductConfiguration],
  );

  const renderTableRow = (item, key) => {
    const { title: tableItemTitle, value } = item;

    return (
      <TableRow key={key}>
        <TableCol>{tableItemTitle}</TableCol>
        <TableCol>{value}</TableCol>
      </TableRow>
    );
  };

  const image: string = productConfiguration.imageUrls[0] || getRandomProductImage(productConfiguration.id || '0');
  const total: number = (price ?? 0) * amount;

  const onClickTipButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsTipOpened(true);
  };

  const handleTipClose = () => {
    setIsTipOpened(false);
  };

  return (
    <>
      <ProductDetailsStyled>
        <Typography tag="h1" variant="h1">
          {productConfiguration.name}
        </Typography>

        <Typography
          tag="h2"
          variant="h5"
          css={`
            margin-bottom: 38px;
          `}
        >
          {productConfiguration.configurationName}
        </Typography>
        <FlexContainer>
          <Info>
            {productConfiguration.hint && (
              <Tips>
                <Fab onClick={onClickTipButton} refEl={tipButtonRef} />
              </Tips>
            )}
            <ProductImage src={image} alt={name} />
            <Subtitle type="h5">Produktbeschreibung</Subtitle>
            <Description dangerouslySetInnerHTML={{ __html: description || '' }} />
            {/* TODO: a dropdown component should be placed here */}
            {/*Show characteristics*/}
          </Info>
          <Details>
            <DetailsTop>
              <Price>je {formatCurrency(price ?? 0)}</Price>
              <Total tag="h5" variant="h5">
                {formatCurrency(total)}
              </Total>

              <Amount>
                <CounterButton value={amount} notChangeable={!isAmountEditable} mode="form" onChange={setAmount} />
              </Amount>
              {!isInvitedUser && (
                <div style={{ marginBottom: '20px' }}>
                  <Button variant="contained" color="bronze" onClick={handleOnDoneClick} isLoading={isLoading}>
                    Speichern
                  </Button>
                </div>
              )}
              {isEditable && productSetId && (
                <Button
                  variant="outlined"
                  color="bronze"
                  onClick={handleOnShowProductSetClick}
                  iconStart={<ReloadIcon />}
                >
                  Produkt ersetzen
                </Button>
              )}
            </DetailsTop>
            {/*<DetailsBottom>*/}
            {/*  <SelectedType>*/}
            {/*    <SelectedTypeTitle>Gewählte Option</SelectedTypeTitle>*/}
            {/*    <SelectedTypeName type="h4">mit Höhenverstellung</SelectedTypeName>*/}
            {/*  </SelectedType>*/}
            {/*  <Subtitle type="h5">Produktdetails</Subtitle>*/}
            {/*  <Table>/!*<TableBody>{tableData.map(renderTableRow)}</TableBody>*!/</Table>*/}
            {/*</DetailsBottom>*/}
          </Details>
        </FlexContainer>

        {!isInvitedUser && (
          <ProductOptions
            title={'Optionen'}
            productConfiguration={productConfiguration}
            productConfigurations={productConfigurations}
            onChange={handleOnChangeProductConfiguration}
          />
        )}
      </ProductDetailsStyled>

      {productSetId && (
        <SideSheet onClose={handleOnCloseProductSet} isOpened={isShowProductSet}>
          <ProductSet
            title={productConfiguration.name}
            productSetId={productSetId}
            amount={amount}
            onSelect={handleOnReplaceProduct}
          />
        </SideSheet>
      )}
      <SmartTipDialog anchorRef={tipButtonRef} isOpened={isTipOpened} onClose={handleTipClose}>
        {productConfiguration.hint}
      </SmartTipDialog>
    </>
  );
};
