import React from 'react';
import { useField, useFormikContext } from 'formik';

import { RadioGroup } from '../../RadioGroup';

interface IFormRadioProps {
  name: string;
  items: { value: string; label: string }[];
  className?: string;
  isRound?: boolean;
  disabled?: boolean;
}

export const FormRadioGroup: React.FC<IFormRadioProps> = ({ name, disabled, items, isRound, className }) => {
  const [field] = useField<string>(name);
  const { setFieldValue } = useFormikContext();

  const handleOnChange = React.useCallback(
    (value: string) => {
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );

  return (
    <RadioGroup
      name={name}
      value={field.value}
      items={items}
      onChange={handleOnChange}
      isRound={isRound}
      className={className}
      disabled={disabled}
    />
  );
};
