import React from 'react';

export const AcousticFurnitureIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="52" height="59" viewBox="0 0 52 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 48V15C27 14.7239 26.7761 14.5 26.5 14.5C26.2239 14.5 26 14.7239 26 15V48C26 48.2761 26.2239 48.5 26.5 48.5C26.7761 48.5 27 48.2761 27 48ZM26.5 13.5C25.6716 13.5 25 14.1716 25 15V48C25 48.8284 25.6716 49.5 26.5 49.5C27.3284 49.5 28 48.8284 28 48V15C28 14.1716 27.3284 13.5 26.5 13.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 36V26C2 25.7239 1.77614 25.5 1.5 25.5C1.22386 25.5 1 25.7239 1 26V36C1 36.2761 1.22386 36.5 1.5 36.5C1.77614 36.5 2 36.2761 2 36ZM1.5 24.5C0.671573 24.5 0 25.1716 0 26V36C0 36.8284 0.671573 37.5 1.5 37.5C2.32843 37.5 3 36.8284 3 36V26C3 25.1716 2.32843 24.5 1.5 24.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 16.5H12C11.4477 16.5 11 16.9477 11 17.5V45.5C11 46.0523 11.4477 46.5 12 46.5H24C24.5523 46.5 25 46.0523 25 45.5V17.5C25 16.9477 24.5523 16.5 24 16.5ZM12 15.5C10.8954 15.5 10 16.3954 10 17.5V45.5C10 46.6046 10.8954 47.5 12 47.5H24C25.1046 47.5 26 46.6046 26 45.5V17.5C26 16.3954 25.1046 15.5 24 15.5H12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 26C4.17157 26 3.5 26.6716 3.5 27.5V34.5C3.5 35.3284 4.17157 36 5 36H10.5C10.7761 36 11 36.2239 11 36.5C11 36.7761 10.7761 37 10.5 37H5C3.61929 37 2.5 35.8807 2.5 34.5V27.5C2.5 26.1193 3.61929 25 5 25H10.5C10.7761 25 11 25.2239 11 25.5C11 25.7761 10.7761 26 10.5 26H5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.5001 1C38.4813 1 32.1557 4.8313 27.936 12.3448C27.8008 12.5856 27.496 12.6712 27.2553 12.536C27.0145 12.4007 26.9289 12.0959 27.0641 11.8552C31.4469 4.05112 38.0923 0 46.5001 0C46.7762 0 47.0001 0.223858 47.0001 0.5C47.0001 0.776142 46.7762 1 46.5001 1ZM28.7699 48.9504C28.9883 48.7814 29.3024 48.8215 29.4714 49.0399C33.753 54.5743 39.8096 58 46.5001 58C46.7762 58 47.0001 58.2239 47.0001 58.5C47.0001 58.7761 46.7762 59 46.5001 59C39.4482 59 33.1172 55.3867 28.6804 49.6518C28.5114 49.4334 28.5515 49.1194 28.7699 48.9504Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 16C46.2472 16 43.686 16.8846 41.5486 18.4072C41.3237 18.5675 41.0115 18.515 40.8512 18.2901C40.691 18.0652 40.7435 17.753 40.9684 17.5928C43.2657 15.9562 46.028 15 49 15C49.2761 15 49.5 15.2239 49.5 15.5C49.5 15.7761 49.2761 16 49 16ZM40.1029 18.9065C40.2926 19.1071 40.2838 19.4235 40.0832 19.6133C39.6834 19.9913 39.3045 20.3945 38.9486 20.8205C36.8064 23.3849 35.5 26.7761 35.5 30.5C35.5 35.558 37.9099 40.0006 41.5486 42.5928C41.7735 42.753 41.8259 43.0652 41.6657 43.2901C41.5055 43.515 41.1933 43.5675 40.9684 43.4072C37.0625 40.6248 34.5 35.8768 34.5 30.5C34.5 26.5404 35.8896 22.9226 38.1811 20.1795C38.5619 19.7236 38.9676 19.2919 39.3961 18.8867C39.5967 18.697 39.9132 18.7058 40.1029 18.9065ZM43.9187 44.4916C44.0153 44.2329 44.3034 44.1016 44.5621 44.1983C45.9517 44.7177 47.4448 45 49 45C49.2761 45 49.5 45.2239 49.5 45.5C49.5 45.7761 49.2761 46 49 46C47.3227 46 45.7112 45.6954 44.212 45.135C43.9533 45.0383 43.822 44.7503 43.9187 44.4916Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.4837 27.3735C50.5536 27.6407 50.3937 27.9139 50.1265 27.9837C49.7023 28.0947 49.3086 28.2848 48.9617 28.5384C48.6725 28.7497 48.416 29.005 48.2018 29.2948C47.7614 29.8904 47.5 30.6311 47.5 31.436C47.5 31.7121 47.2761 31.936 47 31.936C46.7239 31.936 46.5 31.7121 46.5 31.436C46.5 30.4111 46.8337 29.4632 47.3977 28.7003C47.6717 28.3296 48.0003 28.0024 48.3716 27.731C48.8173 27.4053 49.3249 27.1597 49.8735 27.0163C50.1407 26.9464 50.4139 27.1063 50.4837 27.3735ZM47.638 33.6952C47.8491 33.5173 48.1646 33.5442 48.3425 33.7554C48.9863 34.5194 49.9387 35 51 35C51.2761 35 51.5 35.2239 51.5 35.5C51.5 35.7761 51.2761 36 51 36C49.6283 36 48.4013 35.3771 47.5778 34.3998C47.3999 34.1886 47.4268 33.8732 47.638 33.6952Z"
      fill="currentColor"
    />
  </svg>
);
