/**
 * This is extension of standard proposed ECMA Observable,
 * It can handle multiple subscriptions at once
 * Respectfully stolen from Zen-observable & RxJS
 */
import { Observer, ReadOnlySubject, Subscription } from './observable-types';

export class Subject<T> implements ReadOnlySubject<T> {
  private _closed: boolean;
  private _observers: Set<Observer<T>>;

  /**
   * @param _value - initial value of the subject
   */
  constructor(private _value: T) {
    this._observers = new Set<Observer<T>>();
    this._closed = false;
  }

  /**
   * Subscribe on Subject value changes.
   * returns Subscription object to unsubscribe from the Subject
   * @param observerOrNext
   * @param error
   * @param complete
   */
  subscribe(
    observerOrNext: ((value: T) => void) | Observer<T>,
    error?: (error: any) => void,
    complete?: () => void,
  ): Subscription {
    const observer =
      typeof observerOrNext === 'object' && observerOrNext !== null
        ? (observerOrNext as Observer<T>)
        : {
            next: observerOrNext as (value: T) => void,
            error,
            complete,
          };
    this._observers.add(observer);
    return {
      unsubscribe: () => {
        this._observers.delete(observer);
      },
    };
  }

  /**
   * Pushes the next value into the Subject
   * @param value
   */
  next(value: T): void {
    if (this._closed) {
      throw new Error(`Trying to push next value into a closed Subject ${JSON.stringify(value)}`);
    }

    this._value = value;
    this._iterateSafe('next', value);
  }

  /**
   * Sends error message to all subscribers
   * @param errorValue
   */
  error(errorValue: any): void {
    if (this._closed) {
      throw new Error(`Trying to push error into a closed Subject ${JSON.stringify(errorValue)}`);
    }

    this._iterateSafe('error', errorValue);
  }

  /**
   * Pushes complete message to all subscribers and mark Subject as closed
   * can be called on closed Subject ay times
   */
  complete(): void {
    this._iterateSafe('complete');
    this._closed = true;
  }

  get closed(): boolean {
    return this._closed;
  }

  /**
   * Returns current value of the observable
   */
  getCurrent(): T {
    return this._value;
  }

  /**
   * Make a copy of observers and iterate it.
   * Because it could be changed anytime bu subscribers
   * @param funName
   * @param value
   * @private
   */
  private _iterateSafe(funName: keyof Subject<T>, value?: T) {
    const copy = new Set(this._observers);
    copy.forEach(observer => observer[funName] && observer[funName](value));
  }
}
