import { styled, colors } from '../../../../lib/themes';
import { Image } from '../../../../lib/components';

export const ImageStyled = styled(Image)`
  width: 222px;
  height: 222px;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 150px;
  position: relative;

  .swiper {
    width: calc(100vw - 20px);
  }

  .swiper-slide {
    width: 282px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    :nth-child(5n) {
      //transform-origin: 50% 50%;
      animation: stretch 1.8s ease-in-out infinite;
      ${ImageStyled} {
        animation: bounce 1.8s ease-in-out infinite;
      }
    }

    @keyframes bounce {
      30%,
      70% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-50px);
      }
    }

    @keyframes stretch {
      0% {
        transform: scaleX(1);
      }
      // up stretch
      15% {
        transform: scale(1.05, 0.9);
      }
      30% {
        transform: scaleX(1);
      }
      100% {
        transform: scaleX(1);
      }
    }
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${colors.black};
  width: 800px;
  margin-bottom: 165px;
`;
