import React from 'react';
import { Modal } from '../../../../lib/components/Modal';

interface IAuthModalProps {
  isOpened: boolean;
  onClose?: () => unknown;
  title: string;
  width?: string;
}

export const AuthModal: React.FC<IAuthModalProps> = props => {
  const { isOpened, onClose, title, width, children } = props;

  return (
    <Modal title={title} isOpened={isOpened} onClose={onClose} width={width}>
      {children}
    </Modal>
  );
};
