import { colors, styled, body } from '../../../../lib/themes';

export const Root = styled.nav`
  height: 48px;
  display: flex;
  justify-content: flex-start;
  background: ${colors.white};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  padding-left: 297px;
  position: sticky;
  top: 80px;
  z-index: 100;
`;

interface IItemProps {
  isSelected: boolean;
}

export const Item = styled.button<IItemProps>`
  ${body};
  display: flex;
  align-items: center;
  color: ${({ isSelected }) => (isSelected ? colors.bronze : colors.grayMedium)};
  margin-right: 68px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    color: ${colors.bronzeLight};
  }

  &:active {
    color: ${colors.bronzeDark};
  }

  &:disabled {
    color: ${colors.grayMedium};
    &:hover,
    &:focus {
      color: ${colors.grayMedium};
    }
  }
`;
