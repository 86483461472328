import { styled, css, colors, h5 } from '../../themes';

import { CheckIcon } from '../../icons';

export const Icon = styled(CheckIcon)`
  position: absolute;
  color: ${colors.bronze};
`;

export const BulletIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${colors.bronze};
  transform: translate(-50%, -50%);
`;

export const Input = styled.input.attrs({ type: 'radio' })`
  all: unset;

  position: absolute;
  display: inline-block;

  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const InputWrapper = styled.div<{ isValid: boolean; isRound: boolean }>`
  position: relative;

  display: inline-flex;
  align-items: center;

  width: 32px;
  height: 32px;

  margin-right: 16px;
  border: 1px solid ${colors.dark};

  justify-content: center;
  user-select: none;

  ${({ isValid }) =>
    !isValid
      ? css`
          border-color: ${colors.red};
        `
      : ''}

  ${({ isRound }) =>
    isRound
      ? css`
          border-radius: 50%;
        `
      : ''}
`;

export const Label = styled.span`
  ${h5};
  flex: 1;
`;

interface IStyledRadioProps {
  disabled?: boolean;
}

export const StyledRadio = styled.label<IStyledRadioProps>`
  display: inline-flex;
  align-items: center;
  flex: 1;
  position: relative;
  cursor: pointer;
  user-select: none;
  ${({ disabled }) =>
    !disabled
      ? css`
          &:hover {
            ${InputWrapper} {
              border: 1px solid ${colors.bronzeLight};
            }
            ${Icon} {
              color: ${colors.bronzeLight};
            }
          }
        `
      : css`
          cursor: default;
          ${InputWrapper} {
            border: 1px solid ${colors.grayLighter};
          }
        `}
`;
