import { TUrl } from '../../types';
import config from 'config';

const { staticServer } = config;

export interface IHomePageFixtures {
  interiorConsultant: {
    // sectionTitle: string;
    firstRow: {
      title: string;
      text: string;
      image: string;
    };
    secondRow: {
      title: string;
      text: string;
      image: string;
      buttonLabel: string;
    };
  };
  whatSpeaksForFurnished: {
    firstRow: {
      title: string;
      text: string;
      image: string;
    };
    secondRow: {
      title: string;
      text: string;
      image: string;
    };
  };

  trialAccount: {
    sectionTitle: string;
    paragraphs: string[];
    buttonLabel: string;
  };

  styles: {
    sectionTitle: string;
  };
}

export const fixtures: IHomePageFixtures = {
  interiorConsultant: {
    // sectionTitle: 'Ihr digitaler Interior-Berater',
    firstRow: {
      title: 'Design-Konzepte',
      text: 'Die Arbeitswelt hat sich drastisch verändert, dennoch ist es unabdingbar einen Ort für Mitarbeiter zu schaffen, welcher einladend und inspirierend ist. Begegungsflächen sind heute noch wichtiger als jemals zuvor.',
      image: require('./assets/consultant-1.png'),
    },
    secondRow: {
      title: 'Furnished ist die Lösung',
      text: 'Wir bieten die Inspiration und die Interior Design Sicherheit, dass richtige Produktportfolio auszuwählen, um ein stimmiges Gesamtbild zuerschaffen.',
      image: require('./assets/consultant-2.png'),
      buttonLabel: 'Mehr erfahren',
    },
  },

  whatSpeaksForFurnished: {
    firstRow: {
      title: 'Zeit Sparen',
      text: 'Furnished übernimmt alle logistischen Vorgänge, liefert Ihre Büroeinrichtung, baut auf und montiert – als Einmal-Lieferung.',
      image: require('./assets/what-speaks-for-furnished-1.jpg'),
    },
    secondRow: {
      title: 'Immer alles im Blick',
      text: 'Das Online-Portal hält Sie auf dem Laufenden und unterstützt bei der internen Abstimmung, Koordination und Budgetierung: Im Portal kann durch digitale Freigabeprozesse als Team gearbeitet werden.',
      image: require('./assets/what-speaks-for-furnished-2.jpg'),
    },
  },

  trialAccount: {
    sectionTitle: 'Nutze jetzt Deine Möglichkeiten im Trial Account',
    paragraphs: [
      'Mit dem Trial Account kannst Du die Features Deines digitalen Interior Beraters für 3 Tage kostenlos ausprobieren.',
      'Wenn Dir die Ergebnisse gefallen, dann bestelle Deine Zusammenstellung oder lege ein Account an und gestalte weitere Räume.',
    ],
    buttonLabel: 'Starte Dein erstes Projekt',
  },

  styles: {
    sectionTitle: 'Unsere Designsrichtungen für Ihre Räume',
  },
};

export interface IPromoBanner {
  title: string;
  description: string;
  cover: TUrl;
}

export const promoBanners: IPromoBanner[] = [
  {
    title: 'Herzlich willkommen!',
    description:
      'Besonders in diesen Zeiten ist es wichtig Bewegungsflächen zu schaffen. Gemeinsam mit dem no ordinary design Team helfen wir einen leichten Zugang zu den Arbeitswelten von morgen zu erlangen.<br/>Viel Spaß beim Stöbern!',
    cover: `${staticServer}/cms/site/assets/files/2307/banner.png`,
  },
  // {
  //   title: 'Herzlich willkommen!',
  //   description: `Schreibtisch, Stuhl und Container für 659 Euro: so entstehen ganze Großraumbüros für kleinstes Geld und höchste Ansprüche.`,
  //   cover: `${staticServer}/cms/site/assets/files/2307/banner.png`,
  // },
  // {
  //   title: 'Herzlich willkommen!',
  //   description: `Interne Abstimmung durch Freigabe-Tool, dabei volle Kostentransparenz - und die Logistik erledigen wir von der Lieferung über den Aufbau bis zur Entsorgung von Verpackungen. So einfach war Interior-Design noch nie.`,
  //   cover: `${staticServer}/cms/site/assets/files/2307/banner.png`,
  // },
];
