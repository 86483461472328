import React, { useState, useEffect, useCallback } from 'react';

import { fetchMoodboards, ICMSMoodboard } from '../../fetchMoodboards';
import { Header } from '../../../../lib/components/Header';
import { Page, PageSection } from '../../../../lib/components/Page';
import { Container } from '../../../../lib/components/Container';
import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';
import { MoodboardSlider } from '../../components/MoodboardSlider';
import { Button } from '../../../../lib/components/Button';

import { Root, Menu, MenuItem, Divider, MoodboardTitle } from './Moodboards.styled';

interface IMoodboard {
  id: string;
  images?: {
    name: string;
    descriptions: {
      name: string;
      x: number;
      y: number;
      title: string;
      text: string;
    }[];
  }[];
}

export const Moodboards = () => {
  const [moodboards, setMoodboards] = useState<ICMSMoodboard[]>([]);
  const [activeMoodboard, setActiveMoodboard] = useState<any>(null);

  useEffect(() => {
    fetchMoodboards().then(moodboards => {
      setMoodboards(moodboards);
    });
  }, []);

  const renderMenuItems = useCallback(
    menuItems =>
      menuItems.map(({ sku }) => (
        <MenuItem id={sku} key={sku}>
          {sku}
        </MenuItem>
      )),
    [],
  );

  const getMoodboardContent = useCallback(list => {
    return list.map(({ image, children: { list: points } }) => {
      const { url, width, height } = image[0];

      return {
        url,
        size: [width, height],
        descriptions: points.map(
          ({ buttonCoordinateX, buttonCoordinateY, contentType, contentHtml, contentText, style }) => ({
            coordinates: [buttonCoordinateX, buttonCoordinateY],
            contentType: contentType.id,
            contentHtml,
            contentText,
            style,
          }),
        ),
      };
    });
  }, []);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLUListElement>) => {
      const { target } = event;
      const selectedMoodboard = moodboards.find(item => item.sku === target['id']);

      if (selectedMoodboard) {
        setActiveMoodboard(selectedMoodboard);
      }
    },
    [moodboards],
  );

  const handleCopyMoodbordsAsJson = useCallback(() => {
    if (!moodboards) {
      return;
    }
    const decodeMoodboards: IMoodboard[] = ([] = moodboards.map(cmsMoodboard => {
      return {
        id: cmsMoodboard.sku,
        images: cmsMoodboard.children.list.map(image => {
          return {
            name: image.name,
            descriptions: image.children.list.map(description => {
              const content = document.createElement('div');
              content.innerHTML = description.contentText;

              const title = (content.getElementsByClassName('details__title')[0] as HTMLDivElement).innerText.trim();

              const text = (
                content.getElementsByClassName('details__description')[0] as HTMLDivElement
              ).innerText.trim();

              return {
                name: description.name,
                x: description.buttonCoordinateX,
                y: description.buttonCoordinateY,
                title,
                text,
              };
            }),
          };
        }),
      };
    }));
    navigator.clipboard
      .writeText(JSON.stringify({ moodboards: decodeMoodboards }, null, 2))
      .then(() => alert('copied'));
  }, [moodboards]);

  return (
    <Root>
      <Header />
      <Page>
        <PageSection>
          <Container>
            <BlockTitle>Moodboard testing</BlockTitle>
            {moodboards.length > 0 && (
              <>
                <Menu onClick={handleMenuClick}>{renderMenuItems(moodboards)}</Menu>
                <Divider />
                <Button onClick={handleCopyMoodbordsAsJson}>Copy mood-boards to clipboard as JSON</Button>
                <Divider />
                {activeMoodboard && (
                  <>
                    <MoodboardTitle type="h3">{activeMoodboard.sku}</MoodboardTitle>
                    <ul>
                      <li> Click on image to see the click coordinates in console log </li>
                      <li> Put mouse pointer in button to see current button coordinates </li>
                    </ul>
                    <br />
                  </>
                )}
              </>
            )}
          </Container>

          {activeMoodboard && (
            <MoodboardSlider
              moodboard={
                activeMoodboard.children.list
                  ? { sku: activeMoodboard.sku, images: getMoodboardContent(activeMoodboard.children.list) }
                  : null
              }
            />
          )}
        </PageSection>
      </Page>
    </Root>
  );
};
