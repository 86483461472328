import { styled, colors, css } from '../../themes';

interface ISelectable {
  selected?: boolean;
}

export const StyledPagination = styled.nav`
  display: flex;
`;

export const Item = styled.button<ISelectable>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  svg {
    width: 6px;
    height: 12px;
    color: ${colors.black};
    path {
      stroke-width: 8px;
    }
  }
  ${({ selected }) =>
    selected
      ? css`
          color: ${colors.white};
          background-color: ${colors.black};
        `
      : css`
          &:hover,
          &:focus {
            border: 1px solid ${colors.black};
          }
          &:disabled {
            border: none;
          }
        `};
`;
