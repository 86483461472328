import React from 'react';

import { StyledContainer } from './Container.styled';

export type ContainerSize = 'desktop' | 'tablet';

export interface IContainerProps {
  size?: ContainerSize;
  className?: string;
}

export const Container: React.FC<IContainerProps> = ({ children, size = 'desktop', className }) => {
  return (
    <StyledContainer size={size} className={className}>
      {children}
    </StyledContainer>
  );
};
