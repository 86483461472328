import React, { useCallback, useEffect } from 'react';

import { Slider } from '../../../../lib/components/Swiper';
import officePng from '../../../../static/img/mocks/plan-for-me/office.png';
import { Moodboard, MoodboardImage, MoodboardImageDescription } from '../../../../lib/core/api/generated';
import { SideSheet } from '../../../../lib/components/SideSheet';
import { FabStyled, RoomPicture, RoomPictureWrapper } from './MoodboardSlider.styled';

interface MoodboardSliderProps {
  moodboard?: Moodboard | null;
}

const getSelector = (prefix: string, id: number) => `${prefix}-${id}`;
const descriptionPrefix = 'descriptionId';

export const MoodboardSlider: React.FC<MoodboardSliderProps> = ({ moodboard }) => {
  const moodboardSlider = React.useRef<{ swiper: any }>();

  const [isShowTip, setIsShowTip] = React.useState(false);
  const [selectedDescriptionId, selectDescriptionId] = React.useState<number>(0);
  const [selectedImageId, selectImageId] = React.useState<number>(0);

  const handleOnCloseTip = useCallback(() => {
    setIsShowTip(false);
  }, []);

  const handleOnShowTip = useCallback(
    (imageId: number, descriptionId: number) => () => {
      setIsShowTip(true);
      selectDescriptionId(descriptionId);
      selectImageId(imageId);
    },
    [],
  );

  useEffect(() => {
    if (moodboardSlider.current && moodboardSlider.current.swiper) {
      const { swiper } = moodboardSlider.current;
      swiper.update();
      swiper.slideTo(0);
    }
  }, [moodboard]);

  const calcPercentPosition = (size, coordinate) => (coordinate / size) * 100;

  const renderMoodboardImage = useCallback(
    (image: MoodboardImage, imageId) => {
      const [imageWidth, imageHeight] = image.size;

      return (
        <RoomPictureWrapper key={imageId}>
          <RoomPicture
            src={image.url}
            onClick={(e: React.MouseEvent<HTMLImageElement>) => {
              const rect = (e.target as any).getBoundingClientRect();
              const x = e.clientX - rect.left; // x position within the element.
              const y = e.clientY - rect.top; // y position within the element.
              console.log('Click coordinates are', {
                x: Math.round((imageWidth * x) / rect.width),
                y: Math.round((imageHeight * y) / rect.height),
              });
            }}
          />
          {image.descriptions.map(({ coordinates: [left, top] }, descriptionId) => (
            <FabStyled
              positionX={calcPercentPosition(imageWidth, left)}
              positionY={calcPercentPosition(imageHeight, top)}
              variant="plus"
              onClick={handleOnShowTip(imageId, descriptionId)}
              title={`Button coordinates are [${left}, ${top}]`}
              key={descriptionId}
            />
          ))}
        </RoomPictureWrapper>
      );
    },
    [handleOnShowTip],
  );

  const renderSideSheetContent = useCallback(
    ({ contentType, contentHtml, contentText, style }: MoodboardImageDescription, index) => {
      const isContentHtml = contentType === 'html';

      return (
        <>
          <div
            css={`
              margin-bottom: 40px;
            `}
            id={getSelector(descriptionPrefix, index)}
            key={`content-${index}`}
            dangerouslySetInnerHTML={{
              __html: isContentHtml ? contentHtml : contentText,
            }}
          />

          {/* TODO What about the scope of style ?*/}
          {!isContentHtml && <style key={`style-${index}`}>{style}</style>}
        </>
      );
    },
    [],
  );

  return moodboard ? (
    <>
      <Slider
        ref={moodboardSlider}
        direction="horizontal"
        effect="slide"
        slidesPerView={1}
        autoHeight={true}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        bulletsColor="white"
        isNavigation={true}
      >
        {moodboard.images.map(renderMoodboardImage)}
      </Slider>
      <SideSheet
        onClose={handleOnCloseTip}
        isOpened={isShowTip}
        scrollToId={[descriptionPrefix, selectedDescriptionId]}
      >
        <div
          css={`
            margin: 48px 48px 0 0;
          `}
        >
          {moodboard.images[selectedImageId]?.descriptions.map(renderSideSheetContent)}
        </div>
      </SideSheet>
    </>
  ) : (
    renderMoodboardImage({ url: officePng, descriptions: [], size: [] }, 1)
  );
};
