import React, { useCallback } from 'react';
import { SmartTip } from '../SmartTip';
import { Popover } from '../Popover';

interface ISmartTipDialogProps {
  anchorRef: React.RefObject<any>;
  children: React.ReactNode;
  isOpened: boolean;
  onClose: () => void;
}

export const SmartTipDialog: React.FC<ISmartTipDialogProps> = ({
  anchorRef,
  children,
  isOpened,
  onClose,
  ...rest
}: ISmartTipDialogProps) => {
  return (
    <Popover anchorRef={anchorRef} isOpened={isOpened} onClose={onClose}>
      <SmartTip isDialog={true} closable={true} onClose={onClose} {...rest}>
        {children}
      </SmartTip>
    </Popover>
  );
};
