import React from 'react';
import { MutationInviteExecutiveForRoomArgs } from '../../core/api/generated';
import { useInviteExecutiveForRoom } from '../../core/repositories/room-repository';
import { noop } from '../../utils/placeholders';
import { Modal } from '../Modal';
import { InvitationForm } from '../InvitationForm/InvitationForm';
import { useSnackBar } from '../SnackBar';

interface IInvitationWindowProps {
  roomId: MutationInviteExecutiveForRoomArgs['roomId'];
  onClose: () => void;
}

export const InvitationWindow: React.FC<IInvitationWindowProps> = ({ roomId, onClose = noop }) => {
  const { showSuccess } = useSnackBar();
  const [submitInviteExecutiveForRoom] = useInviteExecutiveForRoom();

  const showSuccessMessage = React.useCallback(() => {
    showSuccess('Die Einladung wurde gesendet');
    onClose();
  }, [onClose, showSuccess]);

  const handleSubmit = React.useCallback(
    ({ email }: MutationInviteExecutiveForRoomArgs) =>
      submitInviteExecutiveForRoom({ email, roomId }).then(showSuccessMessage),
    [roomId, showSuccessMessage, submitInviteExecutiveForRoom],
  );

  return (
    <Modal title={'Freigabe anfordern'} isOpened={true} closable={true} onClose={onClose}>
      <InvitationForm onSubmit={handleSubmit} onClose={onClose} />
    </Modal>
  );
};
