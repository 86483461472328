import React from 'react';

import { Button } from '../../../../lib/components';
import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';
import {
  StyledInteriorConsultant,
  Row,
  FirstRowContent,
  SecondRowContent,
  RowTitle,
  RowText,
  RowImage,
  RowButtonWrapper,
} from './InteriorConsultant.styled';

import { fixtures } from '../../fixtures';

export const InteriorConsultant: React.FC = () => {
  const {
    interiorConsultant: { firstRow, secondRow },
  } = fixtures;

  return (
    <StyledInteriorConsultant>
      <BlockTitle>Ihr digitaler Berater für Büro-Interieur</BlockTitle>
      <Row>
        <FirstRowContent>
          <RowTitle>{firstRow.title}</RowTitle>
          <RowText dangerouslySetInnerHTML={{ __html: firstRow.text }} />
        </FirstRowContent>
        <RowImage src={firstRow.image} />
      </Row>
      <Row>
        <RowImage src={secondRow.image} />
        <SecondRowContent>
          <RowTitle>{secondRow.title}</RowTitle>
          <RowText dangerouslySetInnerHTML={{ __html: secondRow.text }} />
          {/*<RowButtonWrapper>*/}
          {/*  <Button variant="outlined" color="bronze" href="/about">*/}
          {/*    {secondRow.buttonLabel}*/}
          {/*  </Button>*/}
          {/*</RowButtonWrapper>*/}
        </SecondRowContent>
      </Row>
    </StyledInteriorConsultant>
  );
};
