import React, { useMemo } from 'react';
import { Header } from '../../../../components/Header';
import { useWatchCurrentAccount } from '../../../../../../lib/core/repositories/user-repository';

import { TabsStyled } from './AccountHeader.styled';
import { ITabItem } from '../../../../../../lib/components/Navigation/Tabs';
import { useLocation } from 'react-router-dom';
import { noop } from '../../../../../../lib/utils/placeholders';

export const AccountHeader: React.FC = () => {
  const [currentAccount] = useWatchCurrentAccount();
  const { pathname } = useLocation();

  const tabs: ITabItem[] = useMemo(() => {
    const currentId = currentAccount ? currentAccount.id : '';
    return [
      { name: 'Projekte', disabled: false, to: `/accounts/${currentId}/projects`, value: 'projects' },
      { name: 'Bestellungen', disabled: true, to: `/accounts/${currentId}/orders`, value: 'orders' },
      { name: 'Einstellungen', disabled: false, to: `/accounts/${currentId}/settings`, value: 'settings' },
      { name: 'Profil', disabled: false, to: `/accounts/${currentId}/profile`, value: 'profile' },
    ];
  }, [currentAccount]);

  const activeTab = pathname.split('/').pop();

  return (
    <Header>
      <TabsStyled items={tabs} value={activeTab} onChange={noop} />
    </Header>
  );
};
