import { styled, css, applyCssIf, colors, h5 } from '../../themes';

import { CheckIcon } from '../../icons';
import { StyledButton } from '../Button/Button.styled';

export const Input = styled.input.attrs({ type: 'checkbox' })`
  all: unset;

  position: absolute;
  display: inline-block;

  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const Icon = styled(CheckIcon)`
  position: absolute;
  color: ${colors.bronze};
`;

export const InputWrapper = styled.div<{ isValid: boolean }>`
  position: relative;

  display: inline-flex;
  align-items: center;
  align-self: start;

  width: 32px;
  height: 32px;

  margin-right: 16px;
  border: 1px solid ${colors.dark};

  justify-content: center;
  user-select: none;

  ${({ isValid }) => css`
    ${applyCssIf(!isValid)(css`
      border-color: ${colors.red};
    `)}
  `}
`;

export const Label = styled.span`
  ${h5}
  flex: 1;
`;

interface IStyledCheckboxProps {
  disabled?: boolean;
}

export const StyledCheckbox = styled.label<IStyledCheckboxProps>`
  ${h5};
  display: inline-flex;
  align-items: center;
  flex: 1;
  position: relative;
  cursor: pointer;
  user-select: none;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          ${InputWrapper} {
            border: 1px solid ${colors.grayLighter};
          }
        `
      : css`
          &:hover {
            ${InputWrapper} {
              border: 1px solid ${colors.bronzeLight};
            }
            ${Icon} {
              color: ${colors.bronzeLight};
            }
          }
        `}

  ${StyledButton} {
    padding: 0;
    min-width: auto;
  }
`;
