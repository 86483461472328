import React from 'react';

export const DeskIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="48" height="29" viewBox="0 0 48 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.1 0.0996094H0.799957C0.399957 0.0996094 0.0999756 0.3996 0.0999756 0.6996V5.09961C0.0999756 5.49961 0.399957 5.6996 0.799957 5.6996H2.59998V28.1996C2.59998 28.5996 2.89996 28.7996 3.29996 28.7996H24C24.4 28.7996 24.7 28.4996 24.7 28.1996V11.2996H44V28.1996C44 28.5996 44.3 28.7996 44.7 28.7996C45.1 28.7996 45.4 28.4996 45.4 28.1996V10.8996C45.4 10.7996 45.4 10.6996 45.4 10.6996V5.79961H47.2C47.6 5.79961 47.9 5.4996 47.9 5.1996V0.799606C47.8 0.399606 47.5 0.0996094 47.1 0.0996094ZM23.3 27.5996H3.89996V5.79961H23.3V10.6996V27.5996ZM24.6 9.9996V5.6996H44V5.79961C44 5.89961 44 5.9996 44 5.9996V9.8996H24.6V9.9996ZM46.5 4.4996H44.7H24H3.29996H1.49997V1.3996H46.5V4.4996Z"
      fill="currentColor"
    />
    <path
      d="M35.3999 7.2998H32.9999C32.5999 7.2998 32.2999 7.5998 32.2999 7.8998C32.2999 8.1998 32.5999 8.4998 32.9999 8.4998H35.3999C35.7999 8.4998 36.0999 8.1998 36.0999 7.8998C36.0999 7.5998 35.6999 7.2998 35.3999 7.2998Z"
      fill="currentColor"
    />
    <path
      d="M19.8 14.7998C19.4 14.7998 19.1 15.0998 19.1 15.3998V17.7998C19.1 18.1998 19.4 18.3998 19.8 18.3998C20.2 18.3998 20.5 18.0998 20.5 17.7998V15.3998C20.5 15.0998 20.2 14.7998 19.8 14.7998Z"
      fill="currentColor"
    />
  </svg>
);
