import React from 'react';
import { Root, ContentWrapper, Title } from './Footer.styled';
import { Button } from '../../../../lib/components/Button';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

interface IFooterProps {
  expiresAt?: string | null;
}

export const Footer: React.FC<IFooterProps> = ({ expiresAt }) => {
  if (expiresAt) {
    const expiresDate = new Date(expiresAt);
    const now = new Date();

    const message =
      now < expiresDate
        ? `${formatDistance(now, expiresDate, { locale: de })} verblieben`
        : 'Die Probezeit ist abgelaufen';
    return (
      <Root>
        <ContentWrapper>
          <Title>Probe-Account ({message})</Title>
          <Button variant="outlined" color="bronze" href="/purchase-room-package">
            Premium freischalten
          </Button>
        </ContentWrapper>
      </Root>
    );
  }
  return null;
};
