import React from 'react';

export const CeilingLampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="35" height="48" viewBox="0 0 35 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.48 11.5765C23.94 11.153 28.28 6.49413 28.28 0.847067C28.28 0.282362 27.86 0 27.44 0H7.84001C7.28001 0 7 0.423538 7 0.847067C7 6.49413 11.34 11.153 16.8 11.5765V28.2353H18.48V11.5765ZM26.46 1.8353H8.68C9.1 6.35295 12.88 9.88235 17.64 9.88235C22.26 9.88235 26.04 6.35295 26.46 1.8353Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 44.4683C34.9999 43.9938 34.9813 43.5238 34.9448 43.0588C34.2316 33.9727 26.6937 26.8235 17.5 26.8235C8.30627 26.8235 0.768396 33.9727 0.0551844 43.0588C0.018686 43.5238 6.03807e-05 43.9939 1.46566e-07 44.4683L0 44.4706H35V44.4683ZM1.46003 43.0588H33.54C32.8305 34.7534 25.9201 28.2353 17.5 28.2353C9.0799 28.2353 2.16948 34.7534 1.46003 43.0588Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6 43.7648C12.6 44.2598 12.6842 44.7349 12.839 45.1765C13.4156 46.8214 14.9713 48 16.8 48C18.6287 48 20.1844 46.8214 20.761 45.1765C20.9158 44.7349 21 44.2598 21 43.7648L12.6 43.7648ZM19.2254 45.1765H14.3746C14.8587 46.0204 15.7636 46.5882 16.8 46.5882C17.8364 46.5882 18.7413 46.0204 19.2254 45.1765Z"
      fill="currentColor"
    />
  </svg>
);
