import React from 'react';

export const BarstoolIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="20" height="40" viewBox="0 0 20 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.9 3.90004V1.60002C19.9 0.800018 19.3 0.10001 18.5 1.04525e-05C13.8 -2.50718e-05 3.70001 4.41403e-05 1.40002 1.04525e-05C0.600024 0.10001 0 0.800018 0 1.60002V3.90004C0 4.80004 0.700006 5.50001 1.60001 5.50001H2.40002L0.200012 38.5C0.200012 38.7 0.200024 38.9 0.400024 39C0.500024 39.1 0.700024 39.2 0.900024 39.2H3.90002C4.20002 39.2 4.5 39 4.5 38.6L6.70001 5.40004H13.1L15.3 38.6C15.3 38.9 15.6 39.2 15.9 39.2H18.9C19.1 39.2 19.3 39.1 19.4 39C19.5 38.9 19.6 38.7 19.6 38.5L17.4 5.50001H18.2C19.2 5.60001 19.9 4.80004 19.9 3.90004ZM1.30002 3.90004V1.60002C1.30002 1.40002 1.50001 1.30003 1.60001 1.30003H18.3C18.5 1.30003 18.6 1.50002 18.6 1.60002V3.90004C18.6 4.10004 18.4 4.20002 18.3 4.20002H1.70001C1.40001 4.30002 1.30002 4.10004 1.30002 3.90004ZM3.30002 38H1.60001L3.70001 5.50001H5.40002L3.30002 38ZM18.3 38H16.6L14.5 5.50001H16.2L18.3 38Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 22.125H5V20.875H15V22.125Z" fill="currentColor" />
  </svg>
);
