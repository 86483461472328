import React from 'react';

export const CouchIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="66" height="32" viewBox="0 0 66 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M65.8 15.9002C65.8 13.0002 63.5 10.7002 60.6 10.7002C59.1 10.7002 57.7 11.4002 56.8 12.4002V3.9002C56.8 1.9002 55.2 0.200195 53.1 0.200195H35.9C34.7 0.200195 33.6 0.800195 32.9 1.7002C32.2 0.800195 31.2 0.200195 29.9 0.200195H12.8C10.8 0.200195 9.1 1.8002 9.1 3.9002V12.5002C8.1 11.4002 6.7 10.7002 5.2 10.7002C2.3 10.7002 0 13.0002 0 15.9002C0 18.6002 2.1 20.8002 4.7 21.1002V28.4002C4.7 28.8002 5 29.0002 5.3 29.0002H10.2V31.2002C10.2 31.6002 10.5 31.8002 10.8 31.8002H19.2C19.6 31.8002 19.8 31.5002 19.8 31.2002V29.0002H45.8V31.2002C45.8 31.6002 46.1 31.8002 46.4 31.8002H54.8C55.2 31.8002 55.4 31.5002 55.4 31.2002V29.0002H60.5C60.9 29.0002 61.1 28.7002 61.1 28.4002V21.1002C63.8 20.8002 65.8 18.6002 65.8 15.9002ZM35.9 1.6002H53.1C54.4 1.6002 55.5 2.7002 55.5 4.0002V15.0002C55.4 15.3002 55.4 15.5002 55.4 15.8002H33.7V3.9002C33.7 3.7002 33.7 3.6002 33.7 3.4002C33.8 2.4002 34.8 1.6002 35.9 1.6002ZM10.5 17.0002H55.4V22.8002H10.5V17.0002ZM12.8 1.6002H30C31.1 1.6002 32.1 2.4002 32.3 3.5002C32.3 3.7002 32.3 3.8002 32.3 4.0002V15.8002H10.5V3.9002C10.5 2.6002 11.5 1.6002 12.8 1.6002ZM1.3 15.9002C1.3 13.7002 3.1 12.0002 5.2 12.0002C7.3 12.0002 9.1 13.8002 9.1 15.9002C9.1 18.0002 7.3 19.8002 5.2 19.8002C3.1 19.8002 1.3 18.1002 1.3 15.9002ZM18.6 30.7002H11.5V29.1002H18.6V30.7002ZM54.2 30.7002H47.1V29.1002H54.2V30.7002ZM6 27.8002V21.1002C7.2 20.9002 8.3 20.3002 9.1 19.4002V23.6002C9.1 24.0002 9.4 24.2002 9.7 24.2002H56C56.4 24.2002 56.6 23.9002 56.6 23.6002V19.4002C57.4 20.4002 58.6 21.0002 59.9 21.2002V27.9002H6V27.8002ZM60.6 19.9002C58.5 19.9002 56.8 18.3002 56.7 16.3002C56.7 16.2002 56.8 16.1002 56.8 16.0002V15.1002C57.2 13.3002 58.8 12.0002 60.6 12.0002C62.8 12.0002 64.5 13.8002 64.5 15.9002C64.5 18.0002 62.8 19.9002 60.6 19.9002Z"
      fill="currentColor"
    />
  </svg>
);
