import { styled, colors } from '../../../../lib/themes';

export const Icon = styled.div`
  margin-right: 30px;

  svg {
    width: 100px;
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 25px;
`;

export const StyledPlanTypeCard = styled.div`
  display: flex;

  width: 540px;
  padding: 27px 40px 36px;

  border: 2px solid #e0e0e0;
  border-radius: 10px;

  transition: all 0.2s ease-out;
  cursor: pointer;

  :first-child {
    margin-right: 30px;
  }

  &:hover {
    ${Title} {
      color: ${colors.bronze};
    }

    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  }
`;
