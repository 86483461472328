import React from 'react';

export const HelpIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 26V23" stroke="currentColor" strokeWidth="2" />
    <path
      d="M7 10C7 6.13401 10.134 3 14 3C17.866 3 21 6.13401 21 10C21 12.8278 19.3232 15.264 16.9097 16.3685C15.4031 17.058 14 18.3431 14 20V21"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
