import React from 'react';

import { TitleStyled } from './Title.styled';
import { TitleAlign, TitleType } from './Title.types';

interface ITitleProps {
  type?: TitleType;
  align?: TitleAlign;
  className?: string;
}

export const Title: React.FC<ITitleProps> = ({ children, type = 'h1', align = 'left', className }) => {
  return (
    <TitleStyled type={type} align={align} className={className}>
      {children}
    </TitleStyled>
  );
};
