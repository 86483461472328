import React from 'react';

import { BigPlusIcon } from '../../icons';

import { Root, Subtitle, Title } from './AddCard.styled';

interface IAddCardProps {
  title: string;
  subtitle?: string;
  onClick: () => unknown;
  className?: string;
}

export const AddCard: React.FC<IAddCardProps> = props => {
  const { title, subtitle, onClick, children, className } = props;

  return (
    <Root onClick={onClick} className={className}>
      <Title>{title}</Title>
      {subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
      {children}
      <BigPlusIcon style={{ marginTop: 70 }} />
    </Root>
  );
};
