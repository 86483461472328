import React from 'react';

export const SimpleBenchIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="54" height="29" viewBox="0 0 54 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M51.6 3H2.29999C0.999988 3 0 4 0 5.3V9.60001C0 10.9 0.999988 11.9 2.29999 11.9H5L2 28.3C2 28.7 2.20004 29 2.60004 29H2.70001C3.00001 29 3.29999 28.8 3.29999 28.4L6.29999 11.9H47.6L50.6 28.4C50.6 28.7 50.9 29 51.2 29H51.3C51.7 29 51.9 28.6 51.9 28.3L48.9 11.9H51.6C52.9 11.9 53.9 10.9 53.9 9.60001V5.3C53.9 4.1 52.9 3 51.6 3ZM52.6 9.70001C52.6 10.3 52.1 10.7 51.6 10.7H2.29999C1.69999 10.7 1.29999 10.2 1.29999 9.70001V5.40001C1.29999 4.80001 1.79999 4.40001 2.29999 4.40001H51.6C52.2 4.40001 52.6 4.90001 52.6 5.40001V9.70001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.5C2 1.11929 3.11929 0 4.5 0H49.5C50.8807 0 52 1.11929 52 2.5V3.5C52 3.77614 51.7761 4 51.5 4C51.2239 4 51 3.77614 51 3.5V2.5C51 1.67157 50.3284 1 49.5 1H4.5C3.67157 1 3 1.67157 3 2.5V3.5C3 3.77614 2.77614 4 2.5 4C2.22386 4 2 3.77614 2 3.5V2.5Z"
      fill="currentColor"
    />
  </svg>
);
