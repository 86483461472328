import React from 'react';

export const ModularSofaIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="60" height="30" viewBox="0 0 60 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.2 9.29999H53.1V2.5C53.1 1.1 52 0 50.6 0H8.10001C6.70001 0 5.60001 1.1 5.60001 2.5V9.29999H0.700012C0.300012 9.29999 0 9.59999 0 9.89999V24.2C0 24.6 0.300012 24.8 0.700012 24.8H6.70001V28.7C6.70001 29.1 7.00002 29.3 7.40002 29.3H14.2C14.6 29.3 14.9 29 14.9 28.7V24.8H44V28.7C44 29.1 44.3 29.3 44.7 29.3H51.5C51.9 29.3 52.2 29 52.2 28.7V24.8H58.4C58.8 24.8 59.1 24.5 59.1 24.2V10C58.9 9.6 58.6 9.29999 58.2 9.29999ZM7 2.60001C7 1.90001 7.50001 1.39999 8.20001 1.39999H50.7C51.4 1.39999 51.9 1.90001 51.9 2.60001V18.1H7V2.60001ZM13.6 28.2H8.10001V24.9H13.6V28.2ZM50.8 28.2H45.3V24.9H50.8V28.2ZM57.6 23.6H51.4H44.6H14.2H7.40002H1.40002V10.6H5.60001V18.7C5.60001 19.1 5.90002 19.3 6.30002 19.3H52.5C52.9 19.3 53.2 19 53.2 18.7V10.6H57.6V23.6Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.375 19V1H21.625V19H20.375Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.375 24V19H19.625V24H18.375Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M38.375 19V1H39.625V19H38.375Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M40.375 24V19H41.625V24H40.375Z" fill="currentColor" />
  </svg>
);
