import React from 'react';

export const SquareWardrobeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="39" height="56" viewBox="0 0 39 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.7 0.799805H19.6H1.5C1.1 0.799805 0.799988 1.09981 0.799988 1.39981V35.9998V43.7998V51.5998C0.799988 51.9998 1.1 52.2998 1.5 52.2998H3.89999V55.1998C3.89999 55.5998 4.20001 55.8998 4.60001 55.8998C5.00001 55.8998 5.29999 55.5998 5.29999 55.1998V52.2998H34V55.1998C34 55.5998 34.3 55.8998 34.7 55.8998C35.1 55.8998 35.4 55.5998 35.4 55.1998V52.2998H37.8C38.2 52.2998 38.5 51.9998 38.5 51.5998V43.7998V35.9998V1.39981C38.3 1.09981 38 0.799805 37.7 0.799805ZM2.10001 36.6998H19.5H37V43.1998H2.10001V36.6998ZM37 35.3998H20.2V2.09982H37V35.3998ZM2.10001 2.09982H18.9V35.3998H2.10001V2.09982ZM37 50.9998H2.10001V44.4998H37V50.9998Z"
      fill="currentColor"
    />
    <path
      d="M14.7 16.5C14.3 16.5 14 16.8 14 17.1V20.3C14 20.7 14.3 20.9 14.7 20.9C15.1 20.9 15.4 20.6 15.4 20.3V17.1C15.4 16.8 15.1 16.5 14.7 16.5Z"
      fill="currentColor"
    />
    <path
      d="M24.4 20.9996C24.8 20.9996 25.1 20.6996 25.1 20.3996V17.1996C25.1 16.7996 24.8 16.5996 24.4 16.5996C24 16.5996 23.7 16.8996 23.7 17.1996V20.3996C23.8 20.6996 24.1 20.9996 24.4 20.9996Z"
      fill="currentColor"
    />
    <path
      d="M8.30002 38.5996C7.60002 38.5996 6.90002 39.1996 6.90002 39.9996C6.90002 40.7996 7.50002 41.3996 8.30002 41.3996C9.00002 41.3996 9.70004 40.7996 9.70004 39.9996C9.70004 39.1996 9.10002 38.5996 8.30002 38.5996ZM8.30002 39.9996V39.8996C8.40002 39.8996 8.40002 39.8996 8.30002 39.9996Z"
      fill="currentColor"
    />
    <path
      d="M8.30002 49.0998C9.00002 49.0998 9.70004 48.4998 9.70004 47.6998C9.70004 46.9998 9.10002 46.2998 8.30002 46.2998C7.60002 46.2998 6.90002 46.8998 6.90002 47.6998C7.00002 48.4998 7.60002 49.0998 8.30002 49.0998ZM8.30002 47.6998C8.40002 47.6998 8.40002 47.6998 8.30002 47.6998V47.7998V47.6998Z"
      fill="currentColor"
    />
    <path
      d="M30.8 38.5996C30.1 38.5996 29.4 39.1996 29.4 39.9996C29.4 40.7996 30 41.3996 30.8 41.3996C31.5 41.3996 32.2 40.7996 32.2 39.9996C32.2 39.1996 31.5 38.5996 30.8 38.5996ZM30.8 39.9996V39.8996C30.8 39.8996 30.9 39.8996 30.8 39.9996Z"
      fill="currentColor"
    />
    <path
      d="M30.8 49.0998C31.5 49.0998 32.2 48.4998 32.2 47.6998C32.2 46.9998 31.6 46.2998 30.8 46.2998C30.1 46.2998 29.4 46.8998 29.4 47.6998C29.4 48.4998 30.1 49.0998 30.8 49.0998ZM30.8 47.6998C30.8 47.6998 30.9 47.6998 30.8 47.6998V47.7998V47.6998Z"
      fill="currentColor"
    />
  </svg>
);
