import React from 'react';

export interface MenuContext {
  close: () => void;
  open: () => void;
  flip: () => void;
}

export const menuContext = React.createContext<MenuContext>({
  close: () => undefined,
  open: () => undefined,
  flip: () => undefined,
});

export const MenuProvider = menuContext.Provider;
export const MenuConsimer = menuContext.Consumer;
