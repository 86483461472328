import { styled } from '../../../../lib/themes';

export const FurnitureProductsWrapper = styled.div`
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  padding: 85px 40px;
  flex-wrap: wrap;
  grid-template: '0 0 0';
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  margin: 0 auto;

  @media (max-width: 1240px) {
    grid-template: '0 0';
  }

  & > * {
    width: 350px;
  }
`;
