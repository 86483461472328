import React, { ChangeEvent } from 'react';
import { StyledRadio, InputWrapper, Input, Icon, BulletIcon, Label } from './Radio.styled';

export interface IRadioProps {
  name?: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  isRound?: boolean;
  error?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Radio: React.FC<IRadioProps> = ({
  name,
  value,
  checked,
  disabled,
  isRound = false,
  error,
  onChange,
  children,
}) => {
  const isValid = !Boolean(error);

  return (
    <StyledRadio disabled={disabled}>
      <InputWrapper isValid={isValid} isRound={isRound}>
        <Input name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} />
        {checked ? isRound ? <BulletIcon /> : <Icon /> : null}
      </InputWrapper>
      <Label>{children}</Label>
    </StyledRadio>
  );
};
