export interface ToastType {
  message: string;
  id: number;
  options: SnackBarToastOptionsType;
}

export interface SnackBarState {
  toasts: Array<ToastType>;
  add: (message: string, options: SnackBarToastOptionsType) => number;
  remove: (id: number) => unknown;
  idCounter: number;
  removeAll: () => unknown;
}

export interface SnackBarProp {
  toasts: Array<ToastType>;
  dismiss: (id: number) => unknown;
}

export interface SnackBarMessageProp {
  toast: ToastType;
  id: number;
  options: SnackBarToastOptionsType;
  dismiss: (id: number) => unknown;
}

export interface SnackBarToastOptionsType {
  autoDismiss?: boolean;
  autoDismissTime?: number;
  component?: object;
  className?: any;
  closeButton?: boolean;
  type: SnackBarType;
}

export enum SnackBarType {
  message = 'message',
  alert = 'alert',
  warning = 'warning',
  success = 'success',
}
