import { styled, css } from '../../themes';

export interface IJumbotronStyledProps {
  isClosable: boolean;
}

export const Root = styled.div<IJumbotronStyledProps>`
  padding: 40px 0;

  ${({ isClosable }) =>
    isClosable &&
    css`
      position: relative;
      padding-right: 128px;
      min-height: 128px;
    `}
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
`;
