import React from 'react';

export const ThreeSeatCouchIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="60" height="29" viewBox="0 0 60 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M57.2 6.70001H54.8V4.70001C54.8 2.10001 52.7 0 50.1 0H42C40.5 0 39.2 0.8 38.5 2C37.8 0.8 36.5 0 35 0H26.4C24.9 0 23.7 0.799994 22.9 1.89999C22.2 0.799994 20.9 0 19.4 0H11.3C8.70002 0 6.60001 2.10001 6.60001 4.70001V6.70001H3.80002C2.10002 6.70001 0.700012 8.09999 0.700012 9.79999V24.9C0.700012 25.3 1.00002 25.6 1.30002 25.6H6.50002V27.9C6.50002 28.3 6.80001 28.6 7.10001 28.6H17.1C17.5 28.6 17.7 28.3 17.7 27.9V25.6H43.2V27.9C43.2 28.3 43.5 28.6 43.8 28.6H53.8C54.2 28.6 54.4 28.3 54.4 27.9V25.6H59.2C59.6 25.6 59.8 25.3 59.8 24.9V9.79999C60.2 8.09999 58.9 6.70001 57.2 6.70001ZM39.2 4.20001C39.2 2.60001 40.5 1.29999 42.1 1.29999H50.2C52.1 1.29999 53.6 2.80001 53.6 4.70001V15.6H39.3V4.20001H39.2ZM26.4 1.39999H35C36.6 1.39999 37.9 2.69999 37.9 4.29999V15.8H23.6V4.29999C23.6 4.19999 23.6 4.1 23.6 4C23.7 2.5 24.9 1.39999 26.4 1.39999ZM8.00002 4.70001C8.00002 2.80001 9.50002 1.29999 11.4 1.29999H19.5C21 1.29999 22.2 2.39999 22.3 3.89999C22.3 3.99999 22.3 4.10001 22.3 4.20001V15.7H8.00002V4.70001ZM16.7 27.3H8.00002V25.6H16.7V27.3ZM53.5 27.3H44.8V25.6H53.5V27.3ZM58.9 24.3H2.10001V9.79999C2.10001 8.79999 2.90002 8 3.90002 8H6.70001V16.3C6.70001 16.7 7.00002 16.9 7.30002 16.9H22.9H23H38.6H54.2C54.6 16.9 54.8 16.6 54.8 16.3V8H57.2C58.2 8 59 8.79999 59 9.79999V24.3H58.9Z"
      fill="currentColor"
    />
  </svg>
);
