import { styled, h2 } from '../../../../../lib/themes';
import media from '../../../../../lib/utils/media';
import { Button } from '../../../../../lib/components/Button';
import { Typography } from '../../../../../lib/themes/typography/Typography';
import { ArrowIcon } from '../../../../../lib/icons';
import { IconButton } from '../../../../../lib/components/IconButton';

export const Title = styled.h2`
  ${h2};
`;

const Block = styled.div`
  margin-bottom: 52px;
`;

export const RoomTypeBlock = styled(Block)``;

export const ImageCardsContainer = styled.div`
  display: flex;
`;

export const ImageCardTitle = styled(Typography)`
  margin-bottom: 180px;

  ${media.tablet`
    font-size: 20px;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackButton = styled(IconButton).attrs({ icon: 'Back', type: 'button' })`
  width: 56px;
  height: 56px;
  margin-right: 74px;
`;

export const ImageCardButton = styled(Button).attrs({ variant: 'text', color: 'white' })`
  width: 100%;
  text-align: center;
  opacity: 0;
`;

export const ArrowIconStyled = styled(ArrowIcon)`
  position: absolute;
  top: 50%;
  right: 20px;
  height: 15px;
  transform: translateY(-50%);
`;
