import React from 'react';

export const LampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M45.5 18.5V8.79999C45.5 8.59999 45.4 8.49999 45.3 8.39999C45.9 8.39999 46.5 8.39999 47.1 8.39999C47.5 8.39999 47.8 8.10001 47.8 7.70001C47.8 7.30001 47.5 7 47.1 7C38 7 30.7 10 25.4 16C13.6 29.2 14.9 52.3 15.3 56.7H8.70001C8.30001 56.7 8 57 8 57.4C8 57.8 8.30001 58.1 8.70001 58.1H23.4C23.8 58.1 24.1 57.8 24.1 57.4C24.1 57 23.8 56.7 23.4 56.7H16.6C16.3 52.8 14.9 29.6 26.4 16.9C30.9 11.8 37 8.99999 44.4 8.39999C44.3 8.49999 44.3 8.59999 44.3 8.79999V18.5C38.3 18.8 33.4 23.9 33.4 30C33.4 31.2 33.6 32.4 34 33.6C34.1 33.9 34.3 34 34.6 34H55.2C55.5 34 55.7001 33.8 55.8 33.6C56.2001 32.4 56.4 31.2 56.4 30C56.3 23.9 51.5 18.8 45.5 18.5ZM54.7 32.8H35C34.7 31.9 34.6 31 34.6 30C34.6 24.4 39.2 19.8 44.8 19.8C50.4 19.8 55 24.4 55 30C55 30.9 54.9 31.8 54.7 32.8Z"
      fill="currentColor"
    />
    <path
      d="M41.7 22.6002C38.9 23.6002 37 26.3002 37 29.3002C37 29.7002 37.3 30.0002 37.7 30.0002C38.1 30.0002 38.4 29.7002 38.4 29.3002C38.4 26.8002 39.9 24.6002 42.3 23.8002C42.6 23.7002 42.8 23.3002 42.7 23.0002C42.5 22.7002 42.1 22.5002 41.7 22.6002Z"
      fill="currentColor"
    />
  </svg>
);
