import React, { ReactNode } from 'react';

import { ProductOptionStyled, OptionTitle } from './ProductOption.styled';

interface IProductOptionProps {
  title: string;
  children: ReactNode;
}

export const ProductOption: React.FC<IProductOptionProps> = ({ title, children, ...rest }) => (
  <ProductOptionStyled {...rest}>
    <OptionTitle>{title}</OptionTitle>
    {children}
  </ProductOptionStyled>
);
