import React from 'react';
import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';
import {
  FirstRowContent,
  Row,
  RowImage,
  RowText,
  RowTitle,
  SecondRowContent,
  StyledInteriorConsultant,
} from './WhatSpeaksForFurnished.styled';

import { fixtures } from '../../fixtures';

export const WhatSpeaksForFurnished: React.FC = () => {
  const {
    whatSpeaksForFurnished: { firstRow, secondRow },
  } = fixtures;

  return (
    <StyledInteriorConsultant>
      <BlockTitle>Was für Furnished spricht</BlockTitle>
      <Row>
        <FirstRowContent>
          <RowTitle>{firstRow.title}</RowTitle>
          <RowText dangerouslySetInnerHTML={{ __html: firstRow.text }} />
        </FirstRowContent>
        <RowImage src={firstRow.image} />
      </Row>
      <Row>
        <RowImage src={secondRow.image} />
        <SecondRowContent>
          <RowTitle>{secondRow.title}</RowTitle>
          <RowText dangerouslySetInnerHTML={{ __html: secondRow.text }} />
        </SecondRowContent>
      </Row>
    </StyledInteriorConsultant>
  );
};
