import React from 'react';

export const BenchFourSeatIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="64" height="41" viewBox="0 0 64 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M61.6 18H2.29999C0.999988 18 0 19 0 20.3V24.6C0 25.9 0.999988 26.9 2.29999 26.9H5L2 40.3C2 40.7 2.20004 41 2.60004 41H2.70001C3.00001 41 3.29999 40.8 3.29999 40.4L6.29999 26.9H57.6L60.6 40.4C60.6 40.7 60.9 41 61.2 41H61.3C61.7 41 61.9 40.6 61.9 40.3L58.9 26.9H61.6C62.9 26.9 63.9 25.9 63.9 24.6V20.3C63.9 19.1 62.9 18 61.6 18ZM62.6 24.7C62.6 25.3 62.1 25.7 61.6 25.7H2.29999C1.69999 25.7 1.29999 25.2 1.29999 24.7V20.4C1.29999 19.8 1.79999 19.4 2.29999 19.4H61.6C62.2 19.4 62.6 19.9 62.6 20.4V24.7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.375 3.625C1.375 1.62297 2.99797 0 5 0H59C61.002 0 62.625 1.62297 62.625 3.625V18.625H61.375V3.625C61.375 2.31332 60.3117 1.25 59 1.25H5C3.68832 1.25 2.625 2.31332 2.625 3.625V18.625H1.375V3.625Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.5 16.5C32.5 15.1193 33.6193 14 35 14H43C44.3807 14 45.5 15.1193 45.5 16.5V18.5C45.5 18.7761 45.2761 19 45 19C44.7239 19 44.5 18.7761 44.5 18.5V16.5C44.5 15.6716 43.8284 15 43 15H35C34.1716 15 33.5 15.6716 33.5 16.5V18.5C33.5 18.7761 33.2761 19 33 19C32.7239 19 32.5 18.7761 32.5 18.5V16.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 16.5C4 15.1193 5.11929 14 6.5 14H14.5C15.8807 14 17 15.1193 17 16.5V18.5C17 18.7761 16.7761 19 16.5 19C16.2239 19 16 18.7761 16 18.5V16.5C16 15.6716 15.3284 15 14.5 15H6.5C5.67157 15 5 15.6716 5 16.5V18.5C5 18.7761 4.77614 19 4.5 19C4.22386 19 4 18.7761 4 18.5V16.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.5 16.5C46.5 15.1193 47.6193 14 49 14H57C58.3807 14 59.5 15.1193 59.5 16.5V18.5C59.5 18.7761 59.2761 19 59 19C58.7239 19 58.5 18.7761 58.5 18.5V16.5C58.5 15.6716 57.8284 15 57 15H49C48.1716 15 47.5 15.6716 47.5 16.5V18.5C47.5 18.7761 47.2761 19 47 19C46.7239 19 46.5 18.7761 46.5 18.5V16.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 16.5C18 15.1193 19.1193 14 20.5 14H28.5C29.8807 14 31 15.1193 31 16.5V18.5C31 18.7761 30.7761 19 30.5 19C30.2239 19 30 18.7761 30 18.5V16.5C30 15.6716 29.3284 15 28.5 15H20.5C19.6716 15 19 15.6716 19 16.5V18.5C19 18.7761 18.7761 19 18.5 19C18.2239 19 18 18.7761 18 18.5V16.5Z"
      fill="currentColor"
    />
  </svg>
);
