import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetProject } from '../../../../lib/core/repositories/project-repository';
import { ITabItem } from '../../../../lib/components/Navigation/Tabs';
import { Room } from '../../../../lib/core/api/generated';
import { HeaderUserMenu } from '../../../../lib/components/Header/HeaderUserMenu';
import { HeaderBackButton } from '../../../../lib/components/HeaderBackButton';

import { ButtonsWrapper, Navigation, Root, StyledTabs } from './Header.styled';

interface IHeaderProps {
  room: Room;
  tabs?: ITabItem<any>[];
  value?: any;
  onChange?: (value: any) => unknown;
}

export const Header: React.FC<IHeaderProps> = props => {
  const { value, room, tabs = [], onChange = () => {} } = props;
  const { accountId } = useParams<{ accountId: string }>();

  const [project] = useGetProject({ id: room.projectId });

  return (
    <Root>
      <HeaderBackButton
        backHref={`/accounts/${accountId}/projects/${room.projectId}`}
        backButtonText={project ? project.name : room.projectId}
        space={room.name}
      />
      <Navigation>
        <StyledTabs onChange={onChange} items={tabs} value={value} stepMargin={30} counted={true} />
      </Navigation>

      <ButtonsWrapper>
        <HeaderUserMenu />
      </ButtonsWrapper>
    </Root>
  );
};
