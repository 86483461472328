import { Address, MutationSaveRoomArgs, RoomShippingAddressInput } from '../../../../../lib/core/api/generated';
import { ISummaryForm } from './LieferungScreen';

export function fromSummaryForm(
  roomId: string,
  form: ISummaryForm,
  billingAddress: Address | null,
): MutationSaveRoomArgs {
  const {
    fastShipping,
    subscribeToNewsletter,
    contactMe,
    acceptTermsAndConditions,
    firstName,
    lastName,
    street,
    city,
    postcode,
    countryId,
    telephone,
    company,
    deliveryZoneTypeSelect,
    isRestrictedParking,
    isTruckParkingAvailable,
    restrictedParkingComment,
    elevatorSelect,
    elevatorMeasurements,
    additionalComments,
    floor,
  } = form;

  let shippingAddress: RoomShippingAddressInput;
  if (form.isTheSameAddress && billingAddress) {
    shippingAddress = {
      firstName: billingAddress.firstName,
      lastName: billingAddress.lastName,
      street: billingAddress.street,
      city: billingAddress.city,
      postcode: billingAddress.postcode,
      countryId: billingAddress.countryId,
      telephone: billingAddress.telephone,
      company: billingAddress.company,

      deliveryZoneTypeSelect,
      floor,
      restrictedParkingSelect: isRestrictedParking ? '5578' : '5579',
      restrictedParkingComment: isRestrictedParking ? restrictedParkingComment : '',
      truckParkingAvailableSelect: isTruckParkingAvailable ? '5580' : '5581',
      elevatorSelect,

      elevatorMeasurements,
      additionalComments,
    };
  } else {
    shippingAddress = {
      firstName,
      lastName,
      street,
      city,
      postcode,
      countryId,
      telephone,
      company,

      deliveryZoneTypeSelect,
      floor,
      restrictedParkingSelect: isRestrictedParking ? '5578' : '5579',
      restrictedParkingComment: isRestrictedParking ? restrictedParkingComment : '',
      truckParkingAvailableSelect: isTruckParkingAvailable ? '5580' : '5581',
      elevatorSelect,

      elevatorMeasurements,
      additionalComments,
    };
  }

  return {
    roomId,
    fastShipping,
    subscribeToNewsletter,
    contactMe,
    acceptTermsAndConditions,
    shippingAddress,
  };
}
