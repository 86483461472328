import { Button } from '../Button';
import { styled } from '../../themes';

export const ButtonStyled = styled(Button)`
  padding: 0;
  width: 100%;
  min-width: auto;
  height: 100%;
  border: 0;
  background-color: transparent;
  transition: color 0.2s ease-in-out;

  svg {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
  }
`;

export type ISocialIconType = 'ig' | 'yt' | 'fb' | 'tw';

export interface ISocialsItem {
  icon: ISocialIconType;
}

export const SocialsItem = styled.li<ISocialsItem>`
  flex-grow: 0;
  width: 24px;
  height: 24px;

  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const SocialsStyled = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
`;
