import { BaseQueryResult, UseQueryResult } from './use-store';
import { ErrorState, LoadState } from '../load-state';
import { mergeLoadStates } from './merge-load-states';

// TODO: define default loadRender and errorRenderer
// tslint:disable:function-name
/**
 * Convenience function to easy rendering different data loading states
 * Please, note, you either should put your useQuery in the beginning of function component
 * Or never use this function inside any conditional rendering operators or functions.
 * onDone might have loadState argument, which shows whether existing data is being updated from API
 * So, you may render some kinda loader, indicating the current data is being re-fetched
 * @param onDone
 * @param onLoad
 * @param onError
 * @param q1
 * @constructor
 */
export function On<T1, R1>(
  onDone: (r1: NonNullable<R1>, loadState: LoadState) => JSX.Element | null,
  onLoad: (r1: R1 | null) => JSX.Element | null,
  onError: (errorState: ErrorState) => JSX.Element | null,
  q1: UseQueryResult<T1, R1> | BaseQueryResult<R1>,
);
export function On<T1, R1, T2, R2>(
  onDone: (r1: NonNullable<R1>, r2: NonNullable<R2>, loadState: LoadState) => JSX.Element | null,
  onLoad: (r1: R1 | null, r2: R2 | null) => JSX.Element | null,
  onError: (errorState: ErrorState) => JSX.Element | null,
  q1: UseQueryResult<T1, R1> | BaseQueryResult<R1>,
  q2: UseQueryResult<T2, R2> | BaseQueryResult<R2>,
);
export function On<T1, R1, T2, R2, T3, R3>(
  onDone: (r1: NonNullable<R1>, r2: NonNullable<R2>, r3: NonNullable<R3>, loadState: LoadState) => JSX.Element | null,
  onLoad: (r1: R1 | null, r2: R2 | null, r3: R3 | null) => JSX.Element | null,
  onError: (errorState: ErrorState) => JSX.Element | null,
  q1: UseQueryResult<T1, R1> | BaseQueryResult<R1>,
  q2: UseQueryResult<T2, R2> | BaseQueryResult<R2>,
  q3: UseQueryResult<T3, R3> | BaseQueryResult<R3>,
);
export function On<T1, R1, T2, R2, T3, R3, T4, R4>(
  onDone: (
    r1: NonNullable<R1>,
    r2: NonNullable<R2>,
    r3: NonNullable<R3>,
    r4: NonNullable<R4>,
    loadState: LoadState,
  ) => JSX.Element | null,
  onLoad: (r1: R1 | null, r2: R2 | null, r3: R3 | null, r4: R4 | null) => JSX.Element | null,
  onError: (errorState: ErrorState) => JSX.Element | null,
  q1: UseQueryResult<T1, R1> | BaseQueryResult<R1>,
  q2: UseQueryResult<T2, R2> | BaseQueryResult<R2>,
  q3: UseQueryResult<T3, R3> | BaseQueryResult<R3>,
  q4: UseQueryResult<T4, R4> | BaseQueryResult<R4>,
);
export function On<T1, R1, T2, R2, T3, R3, T4, R4>(
  onDone: (
    r1: NonNullable<R1>,
    r2: NonNullable<R2>,
    r3: NonNullable<R3>,
    r4: NonNullable<R4>,
    loadState: LoadState,
  ) => JSX.Element | null,
  onLoad: (r1: R1 | null, r2: R2 | null, r3: R3 | null, r4: R4 | null) => JSX.Element | null,
  onError: (errorState: ErrorState) => JSX.Element | null,
  q1: UseQueryResult<T1, R1> | BaseQueryResult<R1>,
  q2?: UseQueryResult<T2, R2> | BaseQueryResult<R2>,
  q3?: UseQueryResult<T3, R3> | BaseQueryResult<R3>,
  q4?: UseQueryResult<T4, R4> | BaseQueryResult<R4>,
) {
  const queryResults:
    | [UseQueryResult<T1, R1> | BaseQueryResult<R1>]
    | [UseQueryResult<T1, R1> | BaseQueryResult<R1>, UseQueryResult<T2, R2> | BaseQueryResult<R2>]
    | [
        UseQueryResult<T1, R1> | BaseQueryResult<R1>,
        UseQueryResult<T2, R2> | BaseQueryResult<R2>,
        UseQueryResult<T3, R3> | BaseQueryResult<R3>,
      ]
    | [
        UseQueryResult<T1, R1> | BaseQueryResult<R1>,
        UseQueryResult<T2, R2> | BaseQueryResult<R2>,
        UseQueryResult<T3, R3> | BaseQueryResult<R3>,
        UseQueryResult<T4, R4> | BaseQueryResult<R4>,
      ] = q4
    ? ([q1, q2, q3, q4] as [
        UseQueryResult<T1, R1> | BaseQueryResult<R1>,
        UseQueryResult<T2, R2> | BaseQueryResult<R2>,
        UseQueryResult<T3, R3> | BaseQueryResult<R3>,
        UseQueryResult<T4, R4> | BaseQueryResult<R4>,
      ])
    : q3
    ? ([q1, q2, q3] as [
        UseQueryResult<T1, R1> | BaseQueryResult<R1>,
        UseQueryResult<T2, R2> | BaseQueryResult<R2>,
        UseQueryResult<T3, R3> | BaseQueryResult<R3>,
      ])
    : q2
    ? [q1, q2]
    : [q1];

  const mergedLoadedState = mergeLoadStates(q1 && q1[1], q2 && q2[1], q3 && q3[1], q4 && q4[1]);

  // If we have all the results, call onDone with current loadState
  if (q1[0] && (!q2 || q2[0]) && (!q3 || q3[0])) {
    // Wash off all the dirty, dirty thoughts I had about you...
    return (onDone as any)(...(queryResults as any).map(qr => qr[0]), mergedLoadedState);
  }

  if (mergedLoadedState.isNoneOrPending()) {
    return onLoad(q1[0], q2 ? q2[0] : null, q3 ? q3[0] : null, q4 ? q4[0] : null);
  }

  const firstError = queryResults.find((qr: UseQueryResult<any, any>) => qr[1].isError());
  if (firstError) {
    return onError(firstError[1] as ErrorState);
  }

  return;
}
