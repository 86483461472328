import React from 'react';

export const RackIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M59.5 40.8H41.8V23.1C41.8 22.7 41.5 22.4 41.2 22.4H35L36.6 18.4C36.7 18.2 36.7 18 36.5 17.8C36.4 17.6 36.2 17.5 36 17.5H34.3C34.6 16.6 35.4 15 36.7 14.5C37 14.4 37.2 14 37.1 13.7C37 13.4 36.6 13.2 36.3 13.3C34.4 14 33.5 16 33.1 17.2C33 16.2 32.8 14.6 32.4 13.2C32.3 12.9 31.9 12.7 31.6 12.8C31.3 12.9 31.1 13.3 31.2 13.6C31.6 15 31.8 16.6 31.8 17.5C31.7 16.8 31.2 15.5 29.4 14.5C29.1 14.3 28.7 14.4 28.5 14.7C28.3 15 28.4 15.4 28.7 15.6C29.9 16.3 30.3 17.1 30.4 17.5H28.1C27.9 17.5 27.7 17.6 27.6 17.8C27.5 18 27.5 18.2 27.5 18.4L29.1 22.4H23.5V4.69998C23.5 4.29998 23.2 4 22.9 4H4.6C4.2 4 4 4.29998 4 4.69998V23V41.3V59.6C4 60 4.3 60.3 4.6 60.3H22.9H41.2H59.5C59.9 60.3 60.1 60 60.1 59.6V41.3C60.1 41.1 59.9 40.8 59.5 40.8ZM40.5 40.8H23.5V23.8H30H33.9H40.4V40.8H40.5ZM5.2 23.8H22.2V40.8H5.2V23.8ZM29.1 18.9H35L33.6 22.5H30.5L29.1 18.9ZM5.2 5.5H22.2V22.5H17.6C17.6 22.4 17.6 22.2 17.5 22.1L12.9 14.2C12.7 13.9 12.3 13.8 12 14C11.7 14.2 11.6 14.6 11.8 14.9L16.3 22.5H9.1V22.4V13.3C9.1 12.9 8.8 12.6 8.5 12.6C8.2 12.6 7.9 12.9 7.9 13.3V22.4V22.5H5.3V5.5H5.2ZM5.2 42.1H22.2V59.1H5.2V42.1ZM23.5 42.1H40.5V59.1H36.2C36.2 59 36.2 59 36.2 58.9L32 50.3C31.8 50 31.5 49.8 31.1 50C30.8 50.2 30.6 50.5 30.8 50.9L34.8 59.1H31.4C31.4 59 31.4 59 31.4 58.9L27.2 50.3C27 50 26.7 49.8 26.3 50C26 50.2 25.8 50.5 26 50.9L30 59.1H23.5V42.1ZM58.8 59.1H53.5V59V49.9C53.5 49.5 53.2 49.2 52.9 49.2C52.5 49.2 52.3 49.5 52.3 49.9V59V59.1H49.7V59V49.9C49.7 49.5 49.4 49.2 49.1 49.2C48.7 49.2 48.5 49.5 48.5 49.9V59V59.1H45.9V59V49.9C45.9 49.5 45.6 49.2 45.3 49.2C45 49.2 44.7 49.5 44.7 49.9V59V59.1H42.1V42.1H59.1V59.1H58.8Z"
      fill="currentColor"
    />
    <path
      d="M18.4004 36.9004H8.50039C8.10039 36.9004 7.90039 37.2004 7.90039 37.6004C7.90039 38.0004 8.20039 38.3004 8.50039 38.3004H18.4004C18.8004 38.3004 19.0004 38.0004 19.0004 37.6004C19.0004 37.2004 18.7004 36.9004 18.4004 36.9004Z"
      fill="currentColor"
    />
    <path
      d="M18.4004 33H8.50039C8.10039 33 7.90039 33.3 7.90039 33.7C7.90039 34.1 8.20039 34.4 8.50039 34.4H18.4004C18.8004 34.4 19.0004 34.1 19.0004 33.7C19.0004 33.3 18.7004 33 18.4004 33Z"
      fill="currentColor"
    />
  </svg>
);
