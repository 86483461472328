import { styled } from '../../../../../../lib/themes';

import { TitleStyled } from '../../../../../../lib/components/Title/Title.styled';

import { FormLayout } from '../../../../../../lib/components/Form/FormLayout';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  padding: 144px 60px 40px;
  box-sizing: border-box;

  ${TitleStyled} {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
  }

  form,
  ${FormLayout} {
    height: 100%;
  }

  ${FormLayout} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 150px;
`;
