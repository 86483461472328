import { css, styled, h4, h2, body, h5 } from '../../../../../lib/themes';
import { Typography as TypographyMain } from '../../../../../lib/themes/typography/Typography';

export const Title = styled.h2`
  ${h2}
`;

export const Page = styled.div`
  padding: 100px 0 200px;
`;

const Block = styled.div`
  margin-top: 100px;
`;

export const ParametersBlock = styled(Block)``;

export const ParametersWrapper = styled.div`
  padding: 50px 0;
`;

interface IParameterWrapperProps {
  isDisabled: boolean;
}

export const ParameterWrapper = styled.div<IParameterWrapperProps>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  width: 285px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      user-select: none;
    `}
`;

export const ParameterTitle = styled.h4`
  ${h4};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
  height: 40px;
`;

export const ParameterColumn = styled(ParameterWrapper)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 0;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 20px;
  }
`;

export const ParametersRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 34px;
  }
`;

export const Typography = styled(TypographyMain)`
  margin-bottom: 40px;
`;

export const Description = styled.div`
  ${body};

  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  p {
    ${h5};
  }
`;

export const Image = styled.img`
  width: 346px;
  height: 260px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
