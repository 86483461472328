/**
 * Make from tree structure flat array
 * @param items - tree
 * @param key - child items keys
 */

export function flattenTree<T>(items: T[], key: string): T[] {
  if (!items) {
    return [];
  }
  return items.reduce((acc, item) => {
    acc.push(item, ...flattenTree<T>(item[key], key));
    return acc;
  }, [] as T[]);
}
