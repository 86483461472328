import { colors, h5, styled } from '../../themes';
import { ArrowIcon } from '../../icons';

export const HeaderTitles = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-width: 240px;
  height: 80px;
  text-overflow: ellipsis;
  margin-right: auto;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: ${colors.grayLighter};
    transform: translateY(-50%);
  }

  & > * {
    justify-content: flex-start;
    overflow: hidden;
    margin: 0;
    height: 80px;
    padding-left: 52px;
    min-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Icon = styled(ArrowIcon)`
  flex-shrink: 0;
  width: auto !important;
  height: 15px !important;
  transform: scaleX(-1);
  margin: 0 !important;
`;

export const Badge = styled.strong`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${h5};
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  background-color: ${colors.bronze};
  margin-left: 33px;
`;
