import React from 'react';

import { Room } from '../../../../lib/core/api/generated';
import { Container, FlexContainer, Image } from '../../../../lib/components';
import {
  SummaryContent,
  Workspace,
  WorkspaceContent,
  WorkspaceTitle,
  WorkspaceSubtitle,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableCell,
} from './Summary.styled';

interface ISummaryProps {
  room: Room;
}

export const Summary: React.FC<ISummaryProps> = ({ room }) => {
  const {
    size,
    staticWorkstations,
    soundproofingRequired,
    flexibleWorkstations,
    spaceRequired,
    loungeRequired,
    storageRequired,
  } = room;

  // TODO: find out all these parameters
  const parametersTable = [
    {
      'Feste Arbeitsplätze': staticWorkstations,
    },
    {
      Stauraum: storageRequired,
    },
    {
      Akustik: soundproofingRequired,
    },
  ];

  const renderTableBody = () =>
    parametersTable.map((row, index) => (
      <TableRow key={index}>
        {Object.keys(row).map((parameter, colIndex) => {
          const value = row[parameter];

          return (
            <React.Fragment key={colIndex}>
              <TableCell>{parameter}</TableCell>
              <TableCell>{value === true ? 'Ja' : value === false ? 'Nein' : value}</TableCell>
            </React.Fragment>
          );
        })}
      </TableRow>
    ));

  const image: string = room.moodboard?.images[0].url || '/img/mocks/plan-for-me/workspace.jpg';

  return (
    <Container
      css={`
        margin-bottom: 82px;
      `}
    >
      <SummaryContent>
        <FlexContainer direction={'column'}>
          <Workspace>
            <WorkspaceContent>
              <WorkspaceTitle>{room.roomType?.name}</WorkspaceTitle>
              <WorkspaceSubtitle>{room.roomDesign?.name}</WorkspaceSubtitle>
            </WorkspaceContent>
            <Image
              src={`${image}?size=x730`}
              alt={room.roomType?.name || ''}
              css={`
                width: 100%;
                height: 172px;
                img {
                  height: auto;
                  width: 100%;
                }
              `}
            />
          </Workspace>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader colSpan={2}>Ihre Parameter</TableHeader>
                {/*<TableHeader>Raumgröße</TableHeader>*/}
                {/*<TableHeader>{size} qm</TableHeader>*/}
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </FlexContainer>
      </SummaryContent>
    </Container>
  );
};
