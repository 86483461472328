import React from 'react';

export const PlusIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.47754 6.06152H14.7656V9.88477H9.47754V15.8613H5.44922V9.88477H0.146484V6.06152H5.44922V0.333984H9.47754V6.06152Z"
      fill="currentColor"
    />
  </svg>
);
