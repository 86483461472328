import { css } from 'styled-components';

export const h1 = css`
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 45px;
  min-height: 45px;
`;

export const h2 = css`
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 53px;
  min-height: 53px;
`;

export const h3 = css`
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  min-height: 35px;
`;

export const h4 = css`
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  min-height: 30px;
`;

export const h5 = css`
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  min-height: 30px;
`;

export const body = css`
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  min-height: 25px;
`;

export const button = css`
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 42px;
`;

export const helper = css`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
`;
