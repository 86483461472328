import React from 'react';
import { CoreError } from '../../core/errors/core-error';
import { translateErrortoHR } from './errorsTranslation';
import { ErrorState } from '../../core/load-state';

interface IErrorProps {
  error: CoreError | ErrorState | any;
}

/**
 * Renders error translation in case when needed
 * @constructor
 */
export const CError: React.FC<IErrorProps> = ({ error }) => {
  return <div>{translateErrortoHR(error instanceof ErrorState ? error.getError() : error)}</div>;
};
