import * as React from 'react';
import * as Yup from 'yup';

import { useRegister } from '../../../lib/core/repositories/register-repository';
import { useSignIn } from '../../../lib/core/repositories/auth-repository';
import { MutationRegisterArgs, User } from '../../../lib/core/api/generated';
import { GqlExistError } from '../../../lib/core/errors/gql-error';

import { Form, FormInput, FormFieldRow, FormButton, Button, FormFooter } from '../../../lib/components';
import { PrivacyPolicy } from '../../payment/components';
import { FormLayout } from '../../../lib/components/Form';
import { FormCheckbox } from '../../../lib/components/Form';
import { h5 } from '../../../lib/themes/typography';
import { requiredString } from '../../../lib/utils/yup-reductions';

export interface IRegistrationFormProps {
  afterSubmit?: () => unknown;
  onClickSignIn?: () => unknown;
  inviteToken?: string;
}

export interface IRegistrationForm extends MutationRegisterArgs {
  agreedPrivacyPolicy: boolean;
}

/**
 * Renders registration form,
 * On success registration, we automatically try to login the user
 * and redirect him to the first his accounts
 * @param afterSuccess
 * @constructor
 */
export const RegistrationForm: React.FC<IRegistrationFormProps> = ({
  afterSubmit,
  onClickSignIn,
  inviteToken,
}: IRegistrationFormProps) => {
  const [submitRegister] = useRegister();
  const [submitSignIn] = useSignIn();
  const [isPrivacyPolicyOpened, setPrivacyPolicyOpened] = React.useState<boolean>(false);

  // On success registration, we automatically try to login the user and redirect him to the first his accounts
  const onSubmit = React.useCallback(
    (data: MutationRegisterArgs) =>
      submitRegister(data).then((user: User) =>
        submitSignIn({ username: user.username as string, password: data.password }),
      ),
    [submitRegister, submitSignIn],
  );

  const handleOpenPrivacyPolicy = React.useCallback(() => {
    setPrivacyPolicyOpened(true);
  }, []);

  const handleClosePrivacyPolicy = React.useCallback(() => {
    setPrivacyPolicyOpened(false);
  }, []);

  return (
    <>
      <PrivacyPolicy isOpened={isPrivacyPolicyOpened} onClose={handleClosePrivacyPolicy} />
      <Form
        onSubmit={onSubmit}
        afterSubmit={afterSubmit}
        initialValues={inviteToken ? { ...initialValues, inviteToken } : initialValues}
        validationSchema={validationSchema}
        errorsHR={[
          [
            GqlExistError,
            'Diese E-Mail ist bereits registriert. Verwenden Sie eine andere oder stellen Sie Ihr Passwort wieder her.',
          ],
        ]}
      >
        <FormLayout>
          <FormFieldRow>
            <FormInput name="lastName" label="Name" autoFocusOnEmpty={true} />
            <FormInput name="firstName" label="Vorname" />
          </FormFieldRow>
          <FormInput name="accountName" label="Firma" />
          <FormInput type="email" name="email" label="Email" />
          <FormInput type="password" name="password" label="Passwort" />
          <FormFieldRow>
            <FormCheckbox name="agreedPrivacyPolicy" data-hook-checkbox={'Ich akzeptiere'}>
              Ich akzeptiere{' '}
              <Button
                variant="text"
                color="bronze"
                onClick={handleOpenPrivacyPolicy}
                css={`
                  ${h5}
                  height: auto;
                `}
              >
                die AGB
              </Button>
            </FormCheckbox>
          </FormFieldRow>
          <FormFooter>
            <FormButton
              type="button"
              variant="outlined"
              color="bronze"
              isIgnoreValidation={true}
              onClick={onClickSignIn}
            >
              Einloggen
            </FormButton>
            <FormButton type="submit" variant="contained" color="bronze">
              Registrieren
            </FormButton>
          </FormFooter>
        </FormLayout>
      </Form>
    </>
  );
};

const initialValues: IRegistrationForm = {
  firstName: '',
  lastName: '',
  accountName: '',
  email: '',
  password: '',
  agreedPrivacyPolicy: false,
};

const validationSchema = Yup.object().shape({
  firstName: requiredString('Bitte geben Sie Ihren Vornamen ein.'),
  lastName: requiredString('Bitte geben Sie Ihren Nachnamen ein.'),
  accountName: requiredString('Bitte benennen Sie Ihr Konto'),
  email: requiredString('Dieses Feld darf nicht leer sein.').email('E-Mail-Adresse falsch'),
  password: requiredString('Dieses Feld darf nicht leer sein.')
    .min(8, 'Das Passwort sollte aus 8 oder mehr Symbolen bestehen')
    .max(32, 'Das Passwort sollte aus 32 oder weniger Symbolen bestehen'),
  agreedPrivacyPolicy: Yup.boolean().oneOf([true]).required('Dieses Feld darf nicht leer sein.'),
});
