import React from 'react';

export const AcousticsIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14833 1.11621C3.07382 1.181 3.02394 1.27077 3.02394 1.39947V44.1242C3.02394 44.3142 3.17167 44.5237 3.47203 44.5237H14.7306C14.9338 44.5237 15.0353 44.4671 15.0827 44.4221C15.1268 44.3802 15.1787 44.2973 15.1787 44.1242V1.39947C15.1787 1.20954 15.0309 1 14.7306 1H3.47203C3.34904 1 3.23027 1.04494 3.14833 1.11621ZM2.02394 1.39947C2.02394 0.497873 2.78969 0 3.47203 0H14.7306C15.6153 0 16.1787 0.689928 16.1787 1.39947V44.1242C16.1787 44.5133 16.0528 44.8802 15.771 45.1475C15.4925 45.4117 15.1199 45.5237 14.7306 45.5237H3.47203C2.58727 45.5237 2.02394 44.8338 2.02394 44.1242V1.39947Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60713 7.07141C8.236 7.07141 7.07142 8.14437 7.07142 9.60712C7.07142 10.9656 8.24866 12.1428 9.60713 12.1428C11.0699 12.1428 12.1428 10.9782 12.1428 9.60712C12.1428 8.24865 10.9656 7.07141 9.60713 7.07141ZM6.07142 9.60712C6.07142 7.56712 7.70903 6.07141 9.60713 6.07141C11.5179 6.07141 13.1428 7.69636 13.1428 9.60712C13.1428 11.5052 11.6471 13.1428 9.60713 13.1428C7.69637 13.1428 6.07142 11.5179 6.07142 9.60712Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60713 15.1666C8.24866 15.1666 7.07142 16.3438 7.07142 17.7023C7.07142 19.0608 8.24866 20.238 9.60713 20.238C11.0824 20.238 12.1428 19.1775 12.1428 17.7023C12.1428 16.3438 10.9656 15.1666 9.60713 15.1666ZM6.07142 17.7023C6.07142 15.7915 7.69637 14.1666 9.60713 14.1666C11.5179 14.1666 13.1428 15.7915 13.1428 17.7023C13.1428 19.7298 11.6346 21.238 9.60713 21.238C7.69637 21.238 6.07142 19.613 6.07142 17.7023Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 45.7482V48.357C1 48.365 1.04067 48.5713 1.41652 48.5713H16.7682C16.9581 48.5713 17.1039 48.5106 17.1768 48.4537C17.1809 48.4505 17.1845 48.4476 17.1877 48.4448L17.1847 48.4352V45.738C17.1847 45.73 17.144 45.5237 16.7682 45.5237H1.41652C1.31097 45.5237 1.18794 45.5679 1.09377 45.6413C1.01585 45.702 1.00201 45.7444 1 45.7482ZM0.479054 44.8525C0.728572 44.658 1.0638 44.5237 1.41652 44.5237H16.7682C17.538 44.5237 18.1847 45.0317 18.1847 45.738V48.2938C18.2729 48.7072 18.0389 49.0496 17.7915 49.2424C17.5208 49.4534 17.1511 49.5713 16.7682 49.5713H1.41652C0.646715 49.5713 0 49.0633 0 48.357V45.738C0 45.3477 0.239749 45.039 0.479054 44.8525Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4461 1.11627C28.3716 1.18107 28.3218 1.27084 28.3218 1.39954V44.1243C28.3218 44.3142 28.4695 44.5238 28.7698 44.5238H40.0284C40.1514 44.5238 40.2702 44.4788 40.3521 44.4076C40.4266 44.3428 40.4765 44.253 40.4765 44.1243V1.39954C40.4765 1.20961 40.3288 1.00007 40.0284 1.00007H28.7698C28.6469 1.00007 28.5281 1.04501 28.4461 1.11627ZM27.3218 1.39954C27.3218 0.49794 28.0875 6.76513e-05 28.7698 6.76513e-05H40.0284C40.9132 6.76513e-05 41.4765 0.689997 41.4765 1.39954V44.1243C41.4765 45.0259 40.7107 45.5238 40.0284 45.5238H28.7698C27.8851 45.5238 27.3218 44.8339 27.3218 44.1243V1.39954Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.9049 7.07148C33.5465 7.07148 32.3692 8.24871 32.3692 9.60718C32.3692 10.9657 33.5465 12.1429 34.9049 12.1429C36.2634 12.1429 37.4407 10.9657 37.4407 9.60718C37.4407 8.24871 36.2634 7.07148 34.9049 7.07148ZM31.3692 9.60718C31.3692 7.69643 32.9942 6.07148 34.9049 6.07148C36.8157 6.07148 38.4407 7.69643 38.4407 9.60718C38.4407 11.5179 36.8157 13.1429 34.9049 13.1429C32.9942 13.1429 31.3692 11.5179 31.3692 9.60718Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.9049 15.1666C33.5465 15.1666 32.3692 16.3439 32.3692 17.7024C32.3692 19.0608 33.5465 20.2381 34.9049 20.2381C36.2761 20.2381 37.4407 19.1651 37.4407 17.7024C37.4407 16.3439 36.2634 15.1666 34.9049 15.1666ZM31.3692 17.7024C31.3692 15.7916 32.9942 14.1666 34.9049 14.1666C36.8157 14.1666 38.4407 15.7916 38.4407 17.7024C38.4407 19.7424 36.803 21.2381 34.9049 21.2381C32.9942 21.2381 31.3692 19.6131 31.3692 17.7024Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2978 45.7484V48.357C26.2978 48.3646 26.3383 48.5713 26.7153 48.5713H42.0827C42.4597 48.5713 42.5002 48.3646 42.5002 48.357V45.738C42.5002 45.7304 42.4596 45.5237 42.0827 45.5237H26.7153C26.6094 45.5237 26.486 45.568 26.3917 45.6415C26.3138 45.7021 26.2999 45.7444 26.2978 45.7484ZM25.7774 44.8524C26.0271 44.658 26.3625 44.5237 26.7153 44.5237H42.0827C42.8526 44.5237 43.5002 45.0313 43.5002 45.738V48.357C43.5002 49.0638 42.8526 49.5713 42.0827 49.5713H26.7153C25.9454 49.5713 25.2978 49.0638 25.2978 48.357V45.738C25.2978 45.3475 25.5379 45.0388 25.7774 44.8524Z"
      fill="currentColor"
    />
  </svg>
);
