import { styled, h1, h5 } from '../../../../../../lib/themes';

export const Content = styled.div``;

export const Title = styled.h1`
  margin-bottom: 53px;
  ${h1}
`;

export const TopContent = styled.div`
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
`;

export const Description = styled.div`
  margin-right: 75px;
  width: 40%;

  p {
    ${h5}
  }
`;

export const Images = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const Image = styled.img`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 80px;
  }
`;
