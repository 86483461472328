import React from 'react';
import { useField } from 'formik';

import { Input } from '../../Input';

interface IFormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  autoFocusOnEmpty?: boolean;
}

export const FormInput: React.FC<IFormInputProps> = props => {
  const [fieldProps, meta] = useField(props.name);
  const joinedProps = { ...props, ...fieldProps };
  const isError = Boolean(meta.error) && meta.touched;
  const { autoFocusOnEmpty } = props;

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    // TODO leave focused the only first field
    if (autoFocusOnEmpty && inputRef.current && !fieldProps.value) {
      inputRef.current.focus();
    }
  }, [autoFocusOnEmpty, fieldProps.value]);

  return <Input {...joinedProps} error={isError ? meta.error : ''} ref={inputRef} />;
};
