import React from 'react';

import { IPageSectionStyledProps, PageSectionStyled } from './PageSectionStyled';

type IPageSectionProps = Partial<IPageSectionStyledProps>;

export const PageSection: React.FC<IPageSectionProps> = ({ children, noTopMargin = false, noBottomMargin = false }) => (
  <PageSectionStyled noTopMargin={noTopMargin} noBottomMargin={noBottomMargin}>
    {children}
  </PageSectionStyled>
);
