import { colors, styled, css, applyCssIf, h1, h3, h4 } from '../../../../lib/themes';
import media from '../../../../lib/utils/media';

export const SummaryContent = styled.div`
  display: flex;
`;

export const Workspace = styled.div`
  position: relative;
  margin-bottom: 36px;
`;

export const WorkspaceContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 32px 20px;
  color: ${colors.white};
`;

export const WorkspaceTitle = styled.h3`
  ${h3};
  margin-bottom: 20px;
`;

export const WorkspaceSubtitle = styled.h4`
  ${h4};
`;

interface ITable {
  isInner?: boolean;
}

export const Table = styled.table<ITable>`
  border-collapse: collapse;
  margin-top: auto;
  width: 100%;
  font-size: 15px;
  line-height: 1.67;
  text-align: left;

  ${({ isInner }) =>
    applyCssIf(isInner === true)(css`
      ${TableHeader},
      ${TableCell} {
        padding-right: 30px;

        ${media.tablet`
          padding-right: 20px;
        `}
      }
    `)};
`;

const bottomBorder = css`
  border-bottom: 1px solid ${colors.grayMedium};
`;

export const TableHead = styled.thead`
  ${bottomBorder};
`;

export const TableBody = styled.tbody`
  ${bottomBorder};
`;

export const TableCell = styled.td`
  padding-bottom: 15px;
  width: 224px;
`;

interface ITableRow {
  topOffset?: boolean;
}

export const TableRow = styled.tr<ITableRow>`
  &:first-child {
    & > ${TableCell} {
      padding-top: 32px;
    }
  }
  &:last-child {
    & > ${TableCell} {
      padding-bottom: 32px;
    }
  }
`;

export const TableHeader = styled.th`
  padding-bottom: 29px;
  font-weight: 400;

  &:first-child {
    font-size: 20px;
    line-height: 1.5;
  }
`;
