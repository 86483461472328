export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  companyImageUrl?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  projects: Array<Project>;
  roomLimit: Scalars['Int'];
};

export type AcousticPanel = {
  __typename?: 'AcousticPanel';
  id: Scalars['String'];
  name: Scalars['String'];
};

/**
 * additionalComments is a textarea field, linebreaks
 * should be encoded as \\n
 */
export type Address = {
  __typename?: 'Address';
  additionalComments: Scalars['String'];
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  deliveryZoneTypeSelect: AddressSelectField;
  elevatorMeasurements: Scalars['String'];
  elevatorSelect: AddressSelectField;
  firstName: Scalars['String'];
  floor: Scalars['String'];
  isDefaultBilling?: Maybe<Scalars['Boolean']>;
  isDefaultShipping?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  postcode: Scalars['String'];
  restrictedParkingComment: Scalars['String'];
  restrictedParkingSelect: AddressSelectField;
  street: Scalars['String'];
  telephone: Scalars['String'];
  truckParkingAvailableSelect: AddressSelectField;
};

export enum AddressAttributeCode {
  DeliveryZoneTypeSelect = 'deliveryZoneTypeSelect',
  ElevatorSelect = 'elevatorSelect',
  RestrictedParkingSelect = 'restrictedParkingSelect',
  TruckParkingAvailableSelect = 'truckParkingAvailableSelect',
}

/**
 * This value field is an id which matches the id of
 * one AddressSelectFieldOption.
 * Note that value is an empty string if nothing is selected (only for optional fields).
 */
export type AddressSelectField = {
  __typename?: 'AddressSelectField';
  options: Array<AddressSelectFieldOption>;
  value: Scalars['String'];
};

/** Note that value is an empty string for the empty select option (only for optional fields). */
export type AddressSelectFieldOption = {
  __typename?: 'AddressSelectFieldOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Approval = {
  __typename?: 'Approval';
  approvalStatus?: Maybe<ApprovalStatus>;
  invitationMail?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Category = {
  __typename?: 'Category';
  children: Array<Category>;
  childrenIds: Array<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['String']>;
};

export type CeilingType = {
  __typename?: 'CeilingType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ChairRoll = {
  __typename?: 'ChairRoll';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Color = {
  __typename?: 'Color';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ColorScheme = {
  __typename?: 'ColorScheme';
  id: Scalars['String'];
  name: Scalars['String'];
};

/**
 * Frontend needs a list which contains only
 * productConfigurations with size changes.
 * This is handled as FilteredConfigurableProduct
 * internally.
 */
export type ConfigurableProduct = {
  __typename?: 'ConfigurableProduct';
  name: Scalars['String'];
  productConfigurationSkus: Array<Scalars['String']>;
  productConfigurations: Array<ProductConfiguration>;
  sku: Scalars['String'];
};

export type ConfirmResponse = {
  __typename?: 'ConfirmResponse';
  status: ConfirmStatus;
};

export enum ConfirmStatus {
  Ok = 'OK',
  Unconfirmed = 'UNCONFIRMED',
}

export type DeliveryType = {
  __typename?: 'DeliveryType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type FileResponse = {
  __typename?: 'FileResponse';
  uri?: Maybe<Scalars['String']>;
};

export type FloorType = {
  __typename?: 'FloorType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type FurnitureType = {
  __typename?: 'FurnitureType';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Grant = {
  __typename?: 'Grant';
  accessToken: Scalars['String'];
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type InitialRoomItem = {
  __typename?: 'InitialRoomItem';
  amount: Scalars['Int'];
  productConfiguration: ProductConfiguration;
  productConfigurationSku: Scalars['String'];
  productSet?: Maybe<ProductSet>;
  productSetId?: Maybe<Scalars['String']>;
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Material = {
  __typename?: 'Material';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Membership = {
  __typename?: 'Membership';
  account: Account;
  role: Scalars['String'];
};

export type Moodboard = {
  __typename?: 'Moodboard';
  images: Array<MoodboardImage>;
  sku: Scalars['String'];
};

/** size: [Int, Int] */
export type MoodboardImage = {
  __typename?: 'MoodboardImage';
  descriptions: Array<MoodboardImageDescription>;
  size: Array<Scalars['Int']>;
  url: Scalars['String'];
};

/** coordinates: [Int, Int] */
export type MoodboardImageDescription = {
  __typename?: 'MoodboardImageDescription';
  contentHtml: Scalars['String'];
  contentText: Scalars['String'];
  contentType: Scalars['String'];
  coordinates: Array<Scalars['Int']>;
  style: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * This adds all (suggested) RoomItems of a Room.
   * see addRoomItem mutation
   * (This is the bulk operation for addRoomItem)
   */
  addAllRoomItems: Array<RoomItem>;
  /**
   * Add a single RoomItem to the room/cart.
   * This essentially upgrades a suggested RoomItem to a slected RoomItem
   * RoomItem.added = false -> RoomItem.added = true
   */
  addRoomItem: RoomItem;
  /** Approve a room. User has to be an invited executive of room. */
  approveRoom: Room;
  /** Sets a new password for a user (takes a token from a reset email) */
  completePasswordReset: Scalars['Boolean'];
  /** Complete a paypal room package purchase */
  completePaypalRoomPackagePurchase: Scalars['Boolean'];
  /** Confirm an email address using the token from the confirmation mail. */
  confirmMail: ConfirmResponse;
  /** Creates a new Project for the given Account */
  createProject: Project;
  /** Creates a new Room for a specific Project */
  createRoom: Room;
  /**
   * create a new RoomItem
   * note that new RoomItems are automatically added to the Room ( added=true )
   */
  createRoomItem: RoomItem;
  /** Deletes a Project. */
  deleteProject: Scalars['Boolean'];
  /** Deletes a Room. */
  deleteRoom: Scalars['Boolean'];
  /**
   * Drop a single RoomItem from the room/cart.
   * This essentially degrades a selected RoomItem to a suggested RoomItem
   * RoomItem.added = true -> RoomItem.added = false
   */
  dropRoomItem: RoomItem;
  /** Creates pdf file for a specific project and returns the filename */
  generateProjectPDF: FileResponse;
  /** Creates pdf file for a specific room and returns the filename */
  generateRoomPDF: FileResponse;
  importEvent: Scalars['Boolean'];
  /** Invite an executive for a room. */
  inviteExecutiveForRoom: Scalars['Boolean'];
  /**
   * Creates new cart with recommended products for a logged in customer
   * RoomItems are a suggestion and NOT added automatically to the Room
   * (RoomItem.added = false)
   * Use addAllRoomItems mutation to add all RoomItems with one mutation
   */
  letMePlan: Scalars['Boolean'];
  /** Retrieve access and id tokens for a registered user. */
  login: Grant;
  /**
   * Invalidates the refresh token
   *
   * Note: the accessToken should be deleted from the client after the
   * logout request was successful.
   */
  logout: Scalars['Boolean'];
  /** order a project (this orders all rooms within the project) */
  orderProject: Scalars['Boolean'];
  /** Order a room. */
  orderRoom: Scalars['Boolean'];
  /**
   * Creates new cart with recommended products for a logged in customer.
   * All suggested products are added automatically to the Room
   * (RoomItem.added = true)
   */
  planForMe: Scalars['Boolean'];
  /**
   * Creates a new set of initial room items which can be retrieved with
   * the getInitialPlannedRoomItems query. This keeps the roomItems of the
   * Room empty and should be used for the letMePlan flow.
   */
  planInitialRoomItems: Scalars['Boolean'];
  /** Start a room package purchase and get payment datas */
  purchaseRoomPackage: PurchaseResponse;
  /**
   * Refreshes previously obtained tokens.
   *
   * If the optional accessToken is provided and not expired, the api
   * will not issue a new set of tokens, but will return the previous
   * still valid accessToken and refreshToken.
   * The Response does not inlcude the idToken in that case!
   */
  refresh: Grant;
  /**
   * Register a new user. This will also create an account and a trial membership for the user in that account.
   *
   * If username is not passed, the email will be used as username.
   * If first and last name are not passed, they will be empty.
   */
  register: User;
  /** Remove Item from Room */
  removeRoomItem: Scalars['Boolean'];
  /** Sends mail with reset link to given email */
  resetPassword: Scalars['Boolean'];
  /**
   * Saves room information before the ordering process
   * If the customer does not have any shipping address
   * the address needs to contain all required attributes:
   *   - firstName: String
   *   - lastName: String
   *   - street: String
   *   - city: String
   *   - postcode: String
   *   - countryId: String
   *   - telephone: String
   * If a coupon code is supplied and invalid this will throw an error.
   */
  saveRoom: Room;
  /** Change name and image of an existing account. To delete an image set "companyImage: null". */
  updateAccount: Account;
  /**
   * Adds a billing or shipment address to a specific user
   * - the country id has to be a valid ISO 3166-1 alpha-2 code
   * - a user can have multiple addresses,
   *   the latest address will always be set as the default address for shipment and billing
   * - Note that all *Select Fields (
   *     deliveryZoneTypeSelect,
   *     restrictedParkingSelect,
   *     truckParkingAvailableSelect
   *     elevatorSelect
   *   )
   *   expect the appropriate Id (see Address type) as value.
   */
  updateAddress: User;
  /** Updates the email of a user */
  updateEmail: User;
  /** Updates the password of a user */
  updatePassword: Scalars['Boolean'];
  /** Updates an existing project. */
  updateProject: Project;
  /** Updates an existing room. */
  updateRoom: Room;
  /**
   * Update the selected productConfiguration from a RoomItem
   * productConfigurationSku must be from
   *
   *   - the RoomItems ProductSet productConfigurationSkus or
   *   - the RoomItems ProductConfiguration ConfigurableProduct productConfigurationSkus
   */
  updateRoomItem: RoomItem;
  /** Subscribes or Unsubscribes a user to a newsletter */
  updateSubscribeToNewsletter: Scalars['Boolean'];
  /** Updates a full user */
  updateUser: User;
  /** Update a user with admin privileges */
  updateUserAsAdmin: User;
  /** Create RoooItems from a rooms initial RoomItems */
  useInitialRoomItems: Array<RoomItem>;
};

export type MutationAddAllRoomItemsArgs = {
  roomId: Scalars['String'];
};

export type MutationAddRoomItemArgs = {
  roomItemId: Scalars['String'];
};

export type MutationApproveRoomArgs = {
  roomId: Scalars['String'];
};

export type MutationCompletePasswordResetArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationCompletePaypalRoomPackagePurchaseArgs = {
  accountId: Scalars['String'];
  paypalPayerId: Scalars['String'];
  paypalToken: Scalars['String'];
};

export type MutationConfirmMailArgs = {
  token: Scalars['String'];
};

export type MutationCreateProjectArgs = {
  accountId: Scalars['String'];
  name: Scalars['String'];
};

export type MutationCreateRoomArgs = {
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type MutationCreateRoomItemArgs = {
  amount: Scalars['Int'];
  productConfigurationSku: Scalars['String'];
  productSetId?: InputMaybe<Scalars['String']>;
  roomId: Scalars['String'];
};

export type MutationDeleteProjectArgs = {
  projectId: Scalars['String'];
};

export type MutationDeleteRoomArgs = {
  roomId: Scalars['String'];
};

export type MutationDropRoomItemArgs = {
  roomItemId: Scalars['String'];
};

export type MutationGenerateProjectPdfArgs = {
  projectId: Scalars['String'];
};

export type MutationGenerateRoomPdfArgs = {
  roomId: Scalars['String'];
};

export type MutationImportEventArgs = {
  eventName: Scalars['String'];
};

export type MutationInviteExecutiveForRoomArgs = {
  email: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  roomId: Scalars['String'];
};

export type MutationLetMePlanArgs = {
  roomId: Scalars['String'];
};

export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationLogoutArgs = {
  refreshToken: Scalars['String'];
};

export type MutationOrderProjectArgs = {
  projectId: Scalars['String'];
};

export type MutationOrderRoomArgs = {
  id: Scalars['String'];
};

export type MutationPlanForMeArgs = {
  roomId: Scalars['String'];
};

export type MutationPlanInitialRoomItemsArgs = {
  roomId: Scalars['String'];
};

export type MutationPurchaseRoomPackageArgs = {
  accountId: Scalars['String'];
  couponCode?: InputMaybe<Scalars['String']>;
  method: PaymentMethod;
  sku: Scalars['String'];
};

export type MutationRefreshArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  refreshToken: Scalars['String'];
};

export type MutationRegisterArgs = {
  accountName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  inviteToken?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type MutationRemoveRoomItemArgs = {
  roomItemId: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationSaveRoomArgs = {
  acceptTermsAndConditions?: InputMaybe<Scalars['Boolean']>;
  contactMe?: InputMaybe<Scalars['Boolean']>;
  couponCode?: InputMaybe<Scalars['String']>;
  fastShipping?: InputMaybe<Scalars['Boolean']>;
  roomId: Scalars['String'];
  shippingAddress?: InputMaybe<RoomShippingAddressInput>;
  subscribeToNewsletter?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateAccountArgs = {
  companyImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateAddressArgs = {
  additionalComments?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  countryId: Scalars['String'];
  deliveryZoneTypeSelect?: InputMaybe<Scalars['String']>;
  elevatorMeasurements?: InputMaybe<Scalars['String']>;
  elevatorSelect?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  floor?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  postcode: Scalars['String'];
  restrictedParkingComment?: InputMaybe<Scalars['String']>;
  restrictedParkingSelect?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  telephone: Scalars['String'];
  truckParkingAvailableSelect?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateEmailArgs = {
  email: Scalars['String'];
};

export type MutationUpdatePasswordArgs = {
  oldPass: Scalars['String'];
  pass: Scalars['String'];
};

export type MutationUpdateProjectArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateRoomArgs = {
  colorSchemeId?: InputMaybe<Scalars['String']>;
  flexibleWorkstations?: InputMaybe<Scalars['Int']>;
  floorTypeId?: InputMaybe<Scalars['String']>;
  furnitureTypeId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  loungeRequired?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  planMode?: InputMaybe<RoomPlanMode>;
  roomDesignId?: InputMaybe<Scalars['String']>;
  roomTypeId?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  soundproofingRequired?: InputMaybe<Scalars['Boolean']>;
  spaceRequired?: InputMaybe<Scalars['Boolean']>;
  staticWorkstations?: InputMaybe<Scalars['Int']>;
  storageRequired?: InputMaybe<Scalars['Boolean']>;
  workSettingId?: InputMaybe<Scalars['String']>;
  workUnitId?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateRoomItemArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  productConfigurationSku?: InputMaybe<Scalars['String']>;
  roomItemId: Scalars['String'];
};

export type MutationUpdateSubscribeToNewsletterArgs = {
  subscribeToNewsletter: Scalars['Boolean'];
};

export type MutationUpdateUserArgs = {
  address?: InputMaybe<RoomShippingAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  subscribeToNewsletter?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateUserAsAdminArgs = {
  address?: InputMaybe<RoomShippingAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  subscribeToNewsletter?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type MutationUseInitialRoomItemsArgs = {
  roomId: Scalars['String'];
};

export enum PaymentMethod {
  Paypal = 'PAYPAL',
}

export type PaypalCheckoutData = {
  __typename?: 'PaypalCheckoutData';
  editUrl: Scalars['String'];
  startUrl: Scalars['String'];
  token: Scalars['String'];
};

export type Pong = {
  __typename?: 'Pong';
  time: Scalars['String'];
};

export type ProductConfiguration = {
  __typename?: 'ProductConfiguration';
  acousticPanel?: Maybe<AcousticPanel>;
  acousticPanelId?: Maybe<Scalars['String']>;
  assembly?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoryIds: Array<Scalars['String']>;
  ceilingTypeIds: Array<Scalars['String']>;
  ceilingTypes?: Maybe<Array<Maybe<CeilingType>>>;
  chairRoll?: Maybe<ChairRoll>;
  chairRollId?: Maybe<Scalars['String']>;
  color1?: Maybe<Color>;
  color1Id?: Maybe<Scalars['String']>;
  color2?: Maybe<Color>;
  color2Id?: Maybe<Scalars['String']>;
  colorSchemeIds: Array<Scalars['String']>;
  colorSchemes?: Maybe<Array<Maybe<ColorScheme>>>;
  configurableProduct: ConfigurableProduct;
  configurationName: Scalars['String'];
  depth?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  floorTypeIds: Array<Scalars['String']>;
  floorTypes?: Maybe<Array<Maybe<FloorType>>>;
  furnitureType?: Maybe<FurnitureType>;
  furnitureTypeId?: Maybe<Scalars['String']>;
  hasCableFunnel?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  heightAdjustable?: Maybe<Scalars['Boolean']>;
  heightMax?: Maybe<Scalars['Int']>;
  heightMin?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrls: Array<Scalars['String']>;
  manufacturer?: Maybe<Manufacturer>;
  manufacturerId?: Maybe<Scalars['String']>;
  material1?: Maybe<Material>;
  material1Id?: Maybe<Scalars['String']>;
  material2?: Maybe<Material>;
  material2Id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  productFamily?: Maybe<ProductFamily>;
  productFamilyId?: Maybe<Scalars['String']>;
  roomDesign?: Maybe<RoomDesign>;
  roomDesignId?: Maybe<Scalars['String']>;
  roomTypeIds: Array<Scalars['String']>;
  roomTypes?: Maybe<Array<Maybe<RoomType>>>;
  sku: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  workSetting?: Maybe<WorkSetting>;
  workSettingId?: Maybe<Scalars['String']>;
  workUnit?: Maybe<WorkUnit>;
  workUnitId?: Maybe<Scalars['String']>;
};

export type ProductFamily = {
  __typename?: 'ProductFamily';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ProductPage = {
  __typename?: 'ProductPage';
  count: Scalars['Int'];
  hasNext: Scalars['Boolean'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  result: Array<ProductConfiguration>;
  total: Scalars['Int'];
};

export type ProductSet = {
  __typename?: 'ProductSet';
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  productConfigurationSkus: Array<Scalars['String']>;
  productConfigurations: Array<ProductConfiguration>;
};

export type Project = {
  __typename?: 'Project';
  accountId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  rooms: Array<Room>;
};

export type PurchaseResponse = {
  __typename?: 'PurchaseResponse';
  paypal?: Maybe<PaypalCheckoutData>;
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  addressSelectFieldOptions: Array<AddressSelectFieldOption>;
  categories: Array<Category>;
  ceilingTypes: Array<CeilingType>;
  colorSchemes: Array<ColorScheme>;
  floorTypes: Array<FloorType>;
  furnitureTypes: Array<FurnitureType>;
  initialRoomItems: Array<InitialRoomItem>;
  me: User;
  moodboard: Moodboard;
  ping: Pong;
  productSet: ProductSet;
  project: Project;
  room: Room;
  roomDesigns: Array<RoomDesign>;
  roomPackages: Array<RoomPackage>;
  roomTypes: Array<RoomType>;
  searchProducts?: Maybe<ProductPage>;
  workSettings: Array<WorkSetting>;
  workUnits: Array<WorkUnit>;
};

export type QueryAccountArgs = {
  id: Scalars['String'];
};

export type QueryAddressSelectFieldOptionsArgs = {
  attributeCode: AddressAttributeCode;
};

export type QueryInitialRoomItemsArgs = {
  roomId: Scalars['String'];
};

export type QueryMoodboardArgs = {
  id: Scalars['String'];
};

export type QueryProductSetArgs = {
  id: Scalars['String'];
};

export type QueryProjectArgs = {
  id: Scalars['String'];
};

export type QueryRoomArgs = {
  id: Scalars['String'];
};

export type QuerySearchProductsArgs = {
  input?: InputMaybe<SearchInput>;
};

export type Room = {
  __typename?: 'Room';
  acceptTermsAndConditions?: Maybe<Scalars['Boolean']>;
  approvals?: Maybe<Array<Maybe<Approval>>>;
  colorScheme?: Maybe<ColorScheme>;
  colorSchemeId?: Maybe<Scalars['String']>;
  contactMe?: Maybe<Scalars['Boolean']>;
  deliveries: Array<RoomDelivery>;
  fastShipping?: Maybe<Scalars['Boolean']>;
  flexibleWorkstations?: Maybe<Scalars['Int']>;
  floorType?: Maybe<FloorType>;
  floorTypeId?: Maybe<Scalars['String']>;
  fullDelivery?: Maybe<RoomFullDelivery>;
  furnitureType?: Maybe<FurnitureType>;
  furnitureTypeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  items: Array<RoomItem>;
  loungeRequired?: Maybe<Scalars['Boolean']>;
  moodboard?: Maybe<Moodboard>;
  name: Scalars['String'];
  planMode?: Maybe<RoomPlanMode>;
  projectId: Scalars['String'];
  roomDesign?: Maybe<RoomDesign>;
  roomDesignId?: Maybe<Scalars['String']>;
  roomStatus: RoomStatus;
  roomType?: Maybe<RoomType>;
  roomTypeId?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<RoomShippingAddress>;
  size?: Maybe<Scalars['Int']>;
  soundproofingRequired?: Maybe<Scalars['Boolean']>;
  spaceRequired?: Maybe<Scalars['Boolean']>;
  staticWorkstations?: Maybe<Scalars['Int']>;
  storageRequired?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Total>;
  workSetting?: Maybe<WorkSetting>;
  workSettingId?: Maybe<Scalars['String']>;
  workUnit?: Maybe<WorkUnit>;
  workUnitId?: Maybe<Scalars['String']>;
};

export type RoomDelivery = {
  __typename?: 'RoomDelivery';
  deliveryTimeDays: Scalars['Int'];
  deliveryTimeWeeks: Scalars['Int'];
  deliveryType: DeliveryType;
  deliveryTypeId: Scalars['String'];
  manufacturer: Manufacturer;
  price: Scalars['Float'];
  productCount?: Maybe<Scalars['Int']>;
};

export type RoomDesign = {
  __typename?: 'RoomDesign';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RoomFullDelivery = {
  __typename?: 'RoomFullDelivery';
  deliveryTimeDays: Scalars['Int'];
  deliveryTimeWeeks: Scalars['Int'];
  price: Scalars['Float'];
  productCount: Scalars['Int'];
};

export type RoomItem = {
  __typename?: 'RoomItem';
  added: Scalars['Boolean'];
  amount: Scalars['Int'];
  id: Scalars['String'];
  productConfiguration: ProductConfiguration;
  productConfigurationSku: Scalars['String'];
  productSet?: Maybe<ProductSet>;
  productSetId?: Maybe<Scalars['String']>;
};

export type RoomPackage = {
  __typename?: 'RoomPackage';
  name: Scalars['String'];
  price: Scalars['Float'];
  roomCount: Scalars['Int'];
  sku: Scalars['String'];
};

export enum RoomPlanMode {
  LetMePlan = 'LET_ME_PLAN',
  OnlyInitial = 'ONLY_INITIAL',
  PlanForMe = 'PLAN_FOR_ME',
}

export type RoomShippingAddress = {
  __typename?: 'RoomShippingAddress';
  additionalComments: Scalars['String'];
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  deliveryZoneTypeSelect: AddressSelectField;
  elevatorMeasurements: Scalars['String'];
  elevatorSelect: AddressSelectField;
  firstName: Scalars['String'];
  floor: Scalars['String'];
  lastName: Scalars['String'];
  postcode: Scalars['String'];
  restrictedParkingComment: Scalars['String'];
  restrictedParkingSelect: AddressSelectField;
  street: Scalars['String'];
  telephone: Scalars['String'];
  truckParkingAvailableSelect: AddressSelectField;
};

export type RoomShippingAddressInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  deliveryZoneTypeSelect?: InputMaybe<Scalars['String']>;
  elevatorMeasurements?: InputMaybe<Scalars['String']>;
  elevatorSelect?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  restrictedParkingComment?: InputMaybe<Scalars['String']>;
  restrictedParkingSelect?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  truckParkingAvailableSelect?: InputMaybe<Scalars['String']>;
};

export enum RoomStatus {
  Isplanning = 'ISPLANNING',
  New = 'NEW',
  Ordered = 'ORDERED',
  Planned = 'PLANNED',
}

export type RoomType = {
  __typename?: 'RoomType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SearchCriteria = {
  attributeName: Scalars['String'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SearchInput = {
  criteria?: InputMaybe<Array<InputMaybe<SearchCriteria>>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Total = {
  __typename?: 'Total';
  discountAmount: Scalars['Float'];
  originalTotalPrice: Scalars['Float'];
  originalTotalPriceInclTax: Scalars['Float'];
  taxAmount: Scalars['Float'];
  totalItems: Scalars['Int'];
  totalPrice: Scalars['Float'];
  totalPriceInclTax: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  addresses: Array<Address>;
  email: Scalars['String'];
  emailConfirmStatus: ConfirmStatus;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  memberships: Array<Membership>;
  position?: Maybe<Scalars['String']>;
  subscribeToNewsletter: Scalars['Boolean'];
  username?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
};

export type WorkSetting = {
  __typename?: 'WorkSetting';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type WorkUnit = {
  __typename?: 'WorkUnit';
  id: Scalars['String'];
  name: Scalars['String'];
};
