import React from 'react';

import { ArrowDownIcon, ComputerIcon } from '../../../../lib/icons';
import { AuthFormWindow, AuthStatus } from '../../../auth/containers/AuthFormWindow';
import { Banner } from '../../../../lib/components/Banner';
import { Button } from '../../../../lib/components';
import { getT } from '../../../../lib/core/i18n';

import bannerJpg from './assets/banner.jpg';

import { PlanButtonsWrapper } from './FeaturedBanner.styled';

const t = getT('FeaturedBanner');

export const FeaturedBanner: React.FC = () => {
  function readMore() {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState<boolean>(false);

  const handleShowAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  return (
    <Banner backgroundImage={bannerJpg}>
      {/*
      <Title>
        {t('Der erste exklusive')}
        <br />
        {t('Online-Design-Berater für')}
        <br />
        {t('Bürokonzepte')}
      </Title>
      <Text>{t('ab 89,-')}</Text>
      */}
      <PlanButtonsWrapper>
        <Button variant="contained" color="bronze" onClick={handleShowAuthFormWindow} iconStart={<ComputerIcon />}>
          {t('Mitglied werden')}
        </Button>

        <Button variant="text" onClick={readMore} iconStart={<ArrowDownIcon />}>
          {t('Erfahren Sie mehr')}
        </Button>
      </PlanButtonsWrapper>

      {showAuthFormWindow && (
        <AuthFormWindow
          title="Kostenloser Test Account"
          initialAuthStatus={AuthStatus.Registration}
          onClose={handleCloseAuthFormWindow}
        />
      )}
    </Banner>
  );
};
