import React from 'react';

export const OfficeCabinetIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M32.6 0.200195H3.2C1.6 0.200195 0.399994 1.5002 0.399994 3.0002V11.5002V22.2002V30.7002C0.399994 32.3002 1.7 33.5002 3.2 33.5002H3.79999V36.4002C3.79999 36.8002 4.09999 37.0002 4.39999 37.0002H31.3C31.7 37.0002 31.9 36.7002 31.9 36.4002V33.5002H32.5C34.1 33.5002 35.3 32.2002 35.3 30.7002V22.2002V11.6002V3.1002C35.5 1.5002 34.2 0.200195 32.6 0.200195ZM1.59999 12.3002H34.2V21.7002H1.59999V12.3002ZM1.59999 3.1002C1.59999 2.2002 2.29999 1.6002 3.09999 1.6002H32.6C33.5 1.6002 34.1 2.3002 34.1 3.1002V11.0002H1.59999V3.1002ZM30.7 36.0002H5.09999V33.7002H30.7V36.0002ZM34.2 30.9002C34.2 31.8002 33.5 32.4002 32.7 32.4002H31.4H4.39999H3.09999C2.19999 32.4002 1.59999 31.7002 1.59999 30.9002V23.0002H34.2V30.9002Z"
      fill="currentColor"
    />
    <path
      d="M17.9 4.5C17 4.5 16.2 5.3 16.2 6.2C16.2 7.1 17 7.9 17.9 7.9C18.8 7.9 19.6 7.1 19.6 6.2C19.6 5.3 18.9 4.5 17.9 4.5ZM17.9 6.6C17.7 6.6 17.5 6.4 17.5 6.2C17.5 6 17.7 5.8 17.9 5.8C18.1 5.8 18.3 6 18.3 6.2C18.3 6.5 18.1 6.6 17.9 6.6Z"
      fill="currentColor"
    />
    <path
      d="M17.9 15.2998C17 15.2998 16.2 16.0998 16.2 16.9998C16.2 17.8998 17 18.6998 17.9 18.6998C18.8 18.6998 19.6 17.8998 19.6 16.9998C19.6 15.9998 18.9 15.2998 17.9 15.2998ZM17.9 17.3998C17.7 17.3998 17.5 17.1998 17.5 16.9998C17.5 16.7998 17.7 16.5998 17.9 16.5998C18.1 16.5998 18.3 16.7998 18.3 16.9998C18.3 17.1998 18.1 17.3998 17.9 17.3998Z"
      fill="currentColor"
    />
    <path
      d="M17.9 29.4C18.8 29.4 19.6 28.6 19.6 27.7C19.6 26.8 18.8 26 17.9 26C17 26 16.2 26.8 16.2 27.7C16.2 28.7 17 29.4 17.9 29.4ZM17.9 27.3C18.1 27.3 18.3 27.5 18.3 27.7C18.3 27.9 18.1 28.1 17.9 28.1C17.7 28.1 17.5 27.9 17.5 27.7C17.5 27.5 17.7 27.3 17.9 27.3Z"
      fill="currentColor"
    />
  </svg>
);
