import { styled } from '../../themes';

interface IBackdropProps {
  isShow: boolean;
}

export const Backdrop = styled.div<IBackdropProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ isShow }) => (isShow ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0)')};
  animation: ${({ isShow }) => (isShow ? 'fadein 0.4s ease-out' : 'fadeout 0.4s ease-out 1')};
  animation-fill-mode: forwards;
  cursor: pointer;

  @keyframes fadein {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  @keyframes fadeout {
    0% {
      background-color: rgba(0, 0, 0, 0.2);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;
