import React from 'react';
import { useFormikContext } from 'formik';

import { INavigationButtonProps, NavigationButton } from '../Navigation/NavigationButton';

interface IFormNavigationButton extends INavigationButtonProps {
  isIgnoreValidation?: boolean;
}
/**
 * Use this button in Forms to auto-disable when form is not valid, or validating, or submitting
 * @param {IFormNavigationButton} props
 * @constructor
 */
export const FormNavigationButton: React.FC<IFormNavigationButton> = props => {
  const { isValid, isValidating, isSubmitting } = useFormikContext();
  const { disabled = false, size = 'medium', isIgnoreValidation = false, ...rest } = props;

  const disable = disabled || (isIgnoreValidation ? false : !isValid);
  const loading = isValidating || isSubmitting;

  return <NavigationButton {...rest} size={size} disabled={disable} isLoading={loading} />;
};
