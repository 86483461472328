import React from 'react';

export const ReloadIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25 14C25 20.6274 19.6274 26 13 26C6.37258 26 1 20.6274 1 14C1 7.37258 6.37258 2 13 2C15.9997 2 18.7424 3.10068 20.8462 4.92021"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path d="M20.6029 15.75L24.5 9L28.3971 15.75H20.6029Z" fill="currentColor" stroke="currenColor" />
  </svg>
);
