import React from 'react';

export const SquareChairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="29" height="49" viewBox="0 0 29 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.2 22.3002H27.3V0.900177C27.3 0.500177 27 0.200195 26.6 0.200195H2.79999C2.39999 0.200195 2.09998 0.500177 2.09998 0.900177V22.3002H1.20001C0.800012 22.3002 0.5 22.6002 0.5 23.0002V27.8002C0.5 28.2002 0.800012 28.5002 1.20001 28.5002H2.09998V47.4002C2.09998 47.8002 2.39999 48.1002 2.79999 48.1002H6.09998C6.49998 48.1002 6.79999 47.8002 6.79999 47.4002V28.5002H22.7V47.4002C22.7 47.8002 23 48.1002 23.4 48.1002H26.7C27.1 48.1002 27.4 47.8002 27.4 47.4002V28.5002H28.3C28.7 28.5002 29 28.2002 29 27.8002V23.0002C28.8 22.6002 28.6 22.3002 28.2 22.3002ZM3.5 1.50018H26V22.3002H3.5V1.50018ZM5.5 46.6002H3.5V28.3002H5.5V46.6002ZM26 46.6002H24V28.3002H26V46.6002ZM27.5 27.1002H26.6H23.3H6.09998H2.79999H2V23.6002H2.90002H26.7H27.6V27.1002H27.5Z"
      fill="currentColor"
    />
  </svg>
);
