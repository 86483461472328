import React from 'react';

export const PeopleIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.8461 43.0769C47.8461 44.808 47.4955 45.9531 46.9629 46.7337C46.4373 47.5041 45.661 48.0273 44.5929 48.3784C43.5068 48.7356 42.1657 48.8988 40.5846 48.9654C39.3977 49.0153 38.1278 49.0098 36.7712 49.0039C36.3187 49.002 35.8565 49 35.3845 49C34.9126 49 34.4504 49.002 33.9979 49.0039C32.6413 49.0098 31.3714 49.0153 30.1845 48.9654C28.6034 48.8988 27.2623 48.7356 26.1762 48.3784C25.1081 48.0273 24.3317 47.5041 23.8062 46.7337C23.2736 45.9531 22.923 44.808 22.923 43.0769C22.923 39.4788 23.7657 34.6449 25.7911 30.7481C27.8089 26.8658 30.8956 24.0769 35.3845 24.0769C39.8735 24.0769 42.9601 26.8658 44.978 30.7481C47.0033 34.6449 47.8461 39.4788 47.8461 43.0769ZM35.3845 56.7692C38.4243 56.7692 41.276 56.0469 43.8428 54.8461H44.6153C51.7073 54.8461 57.4615 60.6003 57.4615 67.6923V71.1538C57.4615 73.7866 55.325 75.9231 52.6922 75.9231H18.0768C15.444 75.9231 13.3076 73.7866 13.3076 71.1538V67.6923C13.3076 60.6003 19.0618 54.8461 26.1538 54.8461H26.9272C29.503 56.0463 32.3433 56.7692 35.3845 56.7692ZM80.5384 38.4615C80.5384 44.2794 75.8178 49 69.9999 49C64.182 49 59.4615 44.2794 59.4615 38.4615C59.4615 32.6436 64.182 27.9231 69.9999 27.9231C75.8178 27.9231 80.5384 32.6436 80.5384 38.4615ZM69.9999 55.8077C71.9518 55.8077 73.7776 55.4193 75.4783 54.8461H75.7692C82.6568 54.8461 88.2307 60.4201 88.2307 67.3077C88.2307 69.9405 86.0943 72.0769 83.4615 72.0769H63.3076V67.6923C63.3076 63.2498 61.7136 59.1984 59.1216 55.9998C60.6915 55.2722 62.4073 54.8461 64.2307 54.8461H64.5216C66.2223 55.4193 68.048 55.8077 69.9999 55.8077Z"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);
