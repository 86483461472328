import { styled, keyframes, colors } from '../../themes';

const spinnerAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderStyled = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: ${colors.white};

  &.block {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

export const Spinner = styled.div`
  position: relative;
  width: 75px;
  height: 75px;
  -webkit-box-shadow: inset 0 0 0 2px #dddee1;
  box-shadow: inset 0 0 0 2px #dddee1;
  border-radius: 50%;
  background-image: url('/favicon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px auto;
`;

export const SpinnerInner = styled.div`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 75px;
  height: 75px;
  border: 2px solid ${colors.bronze};
  border-radius: 50%;
  -webkit-animation: ${spinnerAnim} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: ${spinnerAnim} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${colors.bronze} transparent transparent transparent;
`;
