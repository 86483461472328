import { styled, css, applyCssIf, colors } from '../../themes';
import media from '../../utils/media';

export const Card = styled.div<{ isOpened: boolean; isNoPadding: boolean }>`
  position: absolute;
  right: 0;

  width: 80vw;
  max-width: 1200px;
  height: 100%;

  background-color: ${colors.white};
  overflow-y: scroll;

  ${({ isOpened }) =>
    applyCssIf(isOpened)(css`
      animation: slideIn 0.4s ease-out forwards;
    `)};

  ${({ isNoPadding }) =>
    applyCssIf(!isNoPadding)(css`
      padding: 50px;
    `)};

  ${({ isOpened }) =>
    applyCssIf(!isOpened)(css`
      animation: slideOut 0.4s ease-out forwards;
    `)};

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }

  ${media.tablet`
    width: 90vw;
  `}
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
`;

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  z-index: 1000;
`;
