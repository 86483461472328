import { ApiRepository } from './api-repository';
import {
  ConfirmResponse,
  Mutation,
  MutationCompletePasswordResetArgs,
  MutationConfirmMailArgs,
  MutationRegisterArgs,
  MutationResetPasswordArgs,
  Query,
  User,
} from '../api/generated';
import { gqlDocsAuthRegister } from '../gql-documents/gql-auth-register';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequiredRegisterRepository, RequireLog } from '../available-stores';
import { useMutation, UseMutationResult, useStore } from '../hooks/use-store';

export class RegisterRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'RegisterRepository');
  }

  register = (variables: MutationRegisterArgs): Promise<User> => {
    return this.mutate(gqlDocsAuthRegister.register, 'register', variables).then((data: User) => {
      return data;
    });
  };

  registerInvitedUser = (variables: MutationRegisterArgs): Promise<User> => {
    return this.mutate(gqlDocsAuthRegister.registerInvitedUser, 'register', variables).then((data: User) => {
      return data;
    });
  };

  confirmMail = (variables: MutationConfirmMailArgs): Promise<ConfirmResponse> => {
    return this.mutate(gqlDocsAuthRegister.confirmEmail, 'confirmMail', variables);
  };

  resetPassword = (variables: MutationResetPasswordArgs): Promise<boolean> => {
    return this.mutate(gqlDocsAuthRegister.resetPassword, 'resetPassword', variables);
  };

  completePasswordReset = (variables: MutationCompletePasswordResetArgs): Promise<boolean> => {
    return this.mutate(gqlDocsAuthRegister.completePasswordReset, 'completePasswordReset', variables);
  };
}

/**
 * Register a new user
 */
export function useRegister(): UseMutationResult<MutationRegisterArgs, User> {
  const registerRepository = useStore<RequiredRegisterRepository>('RegisterRepository');
  return useMutation(registerRepository.register);
}

/**
 * Register a new user
 */
export function useRegisterInvitedUser(): UseMutationResult<MutationRegisterArgs, User> {
  const registerRepository = useStore<RequiredRegisterRepository>('RegisterRepository');
  return useMutation(registerRepository.registerInvitedUser);
}

/**
 * Confirm registered email by token from email sent by api
 * Syntax Sugar for using in forms
 */
export function useConfirmMail(): UseMutationResult<MutationConfirmMailArgs, ConfirmResponse> {
  const registerRepository = useStore<RequiredRegisterRepository>('RegisterRepository');
  return useMutation(registerRepository.confirmMail);
}

/**
 * Reset a current password
 */
export function useResetPassword(): UseMutationResult<MutationResetPasswordArgs, boolean> {
  const registerRepository = useStore<RequiredRegisterRepository>('RegisterRepository');
  return useMutation(registerRepository.resetPassword);
}

/**
 * Reset the current password and set the new one
 */
export function useCompletePasswordReset(): UseMutationResult<MutationCompletePasswordResetArgs, boolean> {
  const registerRepository = useStore<RequiredRegisterRepository>('RegisterRepository');
  return useMutation(registerRepository.completePasswordReset);
}
