import React from 'react';

export const WhiteboardWallIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 3H4C3.44771 3 3 3.44771 3 4V38C3 38.5523 3.44772 39 4 39H52C52.5523 39 53 38.5523 53 38V4C53 3.44772 52.5523 3 52 3ZM4 2C2.89543 2 2 2.89543 2 4V38C2 39.1046 2.89543 40 4 40H52C53.1046 40 54 39.1046 54 38V4C54 2.89543 53.1046 2 52 2H4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54 1H2C1.44772 1 1 1.44772 1 2V40C1 40.5523 1.44771 41 2 41H54C54.5523 41 55 40.5523 55 40V2C55 1.44771 54.5523 1 54 1ZM2 0C0.895431 0 0 0.89543 0 2V40C0 41.1046 0.89543 42 2 42H54C55.1046 42 56 41.1046 56 40V2C56 0.895431 55.1046 0 54 0H2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33 37H23C22.4477 37 22 37.4477 22 38C22 38.5523 22.4477 39 23 39H33C33.5523 39 34 38.5523 34 38C34 37.4477 33.5523 37 33 37ZM23 36C21.8954 36 21 36.8954 21 38C21 39.1046 21.8954 40 23 40H33C34.1046 40 35 39.1046 35 38C35 36.8954 34.1046 36 33 36H23Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9517 14.8469C25.6578 12.8512 28.3698 12.5816 29.455 14.3992L31.9672 18.6068C32.5045 19.5068 33.7758 19.5922 34.4286 18.7721L35.0764 17.9583C36.1177 16.6503 38.1248 16.7133 39.082 18.0841L44.4099 25.7137C44.568 25.9401 44.5127 26.2518 44.2863 26.4099C44.0599 26.568 43.7482 26.5127 43.5901 26.2863L38.2622 18.6566C37.6878 17.8341 36.4836 17.7963 35.8588 18.5812L35.211 19.3949C34.1229 20.7617 32.0042 20.6194 31.1086 19.1194L28.5964 14.9119C27.9453 13.8213 26.3181 13.9831 25.8944 15.1805L24.5711 18.9207L23.6546 21.6836C23.3154 22.7063 22.3592 23.3966 21.2817 23.3966H16.6871C15.5697 23.3966 14.588 22.655 14.2824 21.5802L13.9964 20.5742C13.6268 19.2745 11.862 19.0877 11.2286 20.2812L9.44166 23.6482C9.3122 23.8921 9.00953 23.9849 8.76561 23.8554C8.52169 23.726 8.42889 23.4233 8.55835 23.1794L10.3453 19.8124C11.401 17.8232 14.3424 18.1345 14.9582 20.3007L15.2443 21.3068C15.4276 21.9516 16.0167 22.3966 16.6871 22.3966H21.2817C21.9282 22.3966 22.5019 21.9824 22.7055 21.3688L23.6266 18.5918L24.9517 14.8469Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5102 17.2867C47.5951 17.144 47.6702 16.9947 47.7344 16.8398C47.9055 16.4271 48 15.9746 48 15.5C48 15.0254 47.9055 14.5729 47.7344 14.1602C47.6702 14.0053 47.5951 13.856 47.5102 13.7133C47.4243 13.5688 47.3282 13.4309 47.2229 13.3008C46.8021 13.5506 46.6178 14.0781 46.8107 14.5433C46.9324 14.8367 47 15.1592 47 15.5C47 15.8408 46.9324 16.1633 46.8107 16.4567C46.6178 16.9219 46.8021 17.4494 47.2229 17.6992C47.3282 17.5691 47.4243 17.4312 47.5102 17.2867ZM46.6992 18.2229C46.4495 17.8021 45.9219 17.6178 45.4567 17.8107C45.1633 17.9324 44.8408 18 44.5 18C44.1592 18 43.8367 17.9324 43.5433 17.8107C43.0781 17.6178 42.5505 17.8021 42.3008 18.2229C42.4309 18.3282 42.5688 18.4243 42.7133 18.5102C42.856 18.5951 43.0053 18.6702 43.1602 18.7344C43.5729 18.9055 44.0254 19 44.5 19C44.9746 19 45.4271 18.9055 45.8398 18.7344C45.9947 18.6702 46.144 18.5951 46.2867 18.5102C46.4312 18.4243 46.5691 18.3282 46.6992 18.2229ZM41.7771 17.6992C42.1979 17.4494 42.3822 16.9219 42.1893 16.4567C42.0676 16.1633 42 15.8408 42 15.5C42 15.1592 42.0676 14.8367 42.1893 14.5433C42.3822 14.0781 42.1979 13.5506 41.7771 13.3008C41.6718 13.4309 41.5757 13.5688 41.4898 13.7133C41.4049 13.856 41.3298 14.0053 41.2656 14.1602C41.0945 14.5729 41 15.0254 41 15.5C41 15.9746 41.0945 16.4271 41.2656 16.8398C41.3298 16.9947 41.4049 17.144 41.4898 17.2867C41.5757 17.4312 41.6718 17.5691 41.7771 17.6992ZM42.3008 12.7771C42.5505 13.1979 43.0781 13.3822 43.5433 13.1893C43.8367 13.0676 44.1592 13 44.5 13C44.8408 13 45.1633 13.0676 45.4567 13.1893C45.9219 13.3822 46.4495 13.1979 46.6992 12.7771C46.5691 12.6718 46.4312 12.5757 46.2867 12.4898C46.144 12.4049 45.9947 12.3298 45.8398 12.2656C45.4271 12.0945 44.9746 12 44.5 12C44.0254 12 43.5729 12.0945 43.1602 12.2656C43.0053 12.3298 42.856 12.4049 42.7133 12.4898C42.5688 12.5757 42.4309 12.6718 42.3008 12.7771Z"
      fill="currentColor"
    />
  </svg>
);
