import { styled } from '../../themes';

export interface IStyledFeaturedBannerProps {
  backgroundImage: string;
}

export const Wrapper = styled.div<IStyledFeaturedBannerProps>`
  display: flex;
  align-items: center;

  width: 100%;
  height: calc(100vh - 80px);
  max-height: 640px;
  padding: 0 120px;

  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const Content = styled.div`
  width: 600px;
`;

export const Title = styled.h1`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;

  font-size: 240px;
  font-style: normal;
  font-weight: 700;
  line-height: 200px;
  letter-spacing: 0em;
  text-align: right;
  color: white;
`;
