import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireLog, RequireRoomDesignRepository } from '../available-stores';
import { gqlDocsRoom } from '../gql-documents/gql-room';
import { Mutation, Query, RoomDesign } from '../api/generated';
import { useMutation, UseMutationResult, useQuery, UseQueryResult, useStore } from '../hooks/use-store';

export class RoomDesignRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'RoomDesignRepository');
  }

  /**
   * Request and return room design list
   */
  getList = (): Promise<RoomDesign[]> => {
    return this.query(gqlDocsRoom.roomDesignList, 'roomDesigns');
  };
}

/**
 * Syntax sugar to obtain RoomDesign list
 */
export function useRoomDesignList(): UseQueryResult<void, RoomDesign[]> {
  return useQuery(useStore<RequireRoomDesignRepository>('RoomDesignRepository').getList, undefined);
}
