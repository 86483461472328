import { styled, h2, h5 } from '../../../../lib/themes';

export const StyledInteriorConsultant = styled.div``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:first-of-type {
    margin-bottom: 60px;
  }
`;

export const FirstRowContent = styled.div`
  width: 347px;
`;

export const SecondRowContent = styled.div`
  width: 478px;
`;

export const RowTitle = styled.h3`
  ${h2}
  margin-bottom: 30px;
`;

export const RowText = styled.p`
  ${h5}
`;

export const RowImage = styled.img`
  height: 348px;
  width: auto;
`;

export const RowButtonWrapper = styled.div`
  margin-top: 50px;
`;
