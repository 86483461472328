import React from 'react';
import { Content, IStyledFeaturedBannerProps, Title, Wrapper } from './Banner.styled';

export const Banner: React.FC<IStyledFeaturedBannerProps> = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Title>
        CUSTOM
        <br />
        YOUR
        <br />
        OFFICE
      </Title>

      {children}
    </Wrapper>
  );
};
