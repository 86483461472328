import React from 'react';
import { render } from 'react-dom';
import config from 'config';
import { App } from './modules/core/Application';
import { initializeMainStore } from './app/initialize-stores';
import { createMainContext } from './lib/core/hooks/main-context';

// kick off the polyfills!
import { loadPolyfills } from './lib/utils/polyfills';
import { initializeI18n } from './lib/core/i18n';

import * as de from '../lang/de.js';
import * as en from '../lang/en.js';
import * as ru from '../lang/ru.js';

initializeI18n(
  {
    de,
    en,
    ru,
  },
  'de', // Initialize locale here
);

const lang = window.location.pathname.split('/')[1];
const { languages, apiServer } = config;
const defaultLanguage = languages[0];
const currentLocale = languages.includes(lang) ? lang : defaultLanguage;

// We don't need context provider for this default value
createMainContext(initializeMainStore(`${apiServer}/graphql`));

const startApp = () => {
  render(<App />, document.getElementById('app'));
};

loadPolyfills().then(startApp);
