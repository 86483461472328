export enum RoomParamsStep {
  // PlanType = 'plan-type',
  RoomStyle = 'room-style',
  RoomParams = 'room-params',
}

export enum RoomItemsStep {
  RoomItemsEditor = 'furniture-editor',
  RoomItemsViewer = 'furniture-viewer',
  RoomSummary = 'summary',
}

export enum RoomRootSteps {
  RoomParams = 'room-params',
  RoomItems = 'room-items',
}

/**
 * Basically we divide the room edit process into two pieces:
 * 1. Room config, where user defines planning type, style and other params
 * 2. Room items edit/view, where user edits or views amount of items in the room
 */
export type RoomConfiguratorStep =
  | {
      rootStep: RoomRootSteps.RoomParams;
      step: RoomParamsStep;
    }
  | {
      rootStep: RoomRootSteps.RoomItems;
      step: RoomItemsStep;
    };

export function isRoomFinalParamsStep(a: any): a is {
  rootStep: RoomRootSteps.RoomParams;
  step: RoomParamsStep.RoomParams;
} {
  return Object(a) === a && a.rootStep === RoomRootSteps.RoomParams && a.step === RoomRootSteps.RoomParams;
}

export function isRoomParamsStep(a: any): a is {
  rootStep: RoomRootSteps.RoomParams;
  step: RoomParamsStep;
} {
  return Object(a) === a && a.rootStep === RoomRootSteps.RoomParams;
}

export function isRoomItemsStep(a: any): a is {
  rootStep: RoomRootSteps.RoomItems;
  step: RoomParamsStep;
} {
  return Object(a) === a && a.rootStep === RoomRootSteps.RoomItems;
}

/**
 * Converts page name into appropriate configuration step
 * or if such step does not exist, returns undefined,
 * in such case, you probably need to redirect user into one of possible pages
 * @param page - current URL :page
 */
export function pageToStep(page: string | undefined): RoomConfiguratorStep | undefined {
  if (!page) {
    return undefined;
  }

  const roomParamStep = Object.keys(RoomParamsStep).find(key => RoomParamsStep[key] === page);
  if (roomParamStep) {
    return {
      rootStep: RoomRootSteps.RoomParams,
      step: RoomParamsStep[roomParamStep],
    };
  }
  const roomItemsStep = Object.keys(RoomItemsStep).find(key => RoomItemsStep[key] === page);
  if (roomItemsStep) {
    return {
      rootStep: RoomRootSteps.RoomItems,
      step: RoomItemsStep[roomItemsStep],
    };
  }
  return undefined;
}

/**
 * Convenience helper
 */
export const AllRoomSteps: { [stepAlias: string]: RoomConfiguratorStep } = {
  // PlanType: {
  //   rootStep: RoomRootSteps.RoomParams,
  //   step: RoomParamsStep.PlanType,
  // },
  RoomStyle: {
    rootStep: RoomRootSteps.RoomParams,
    step: RoomParamsStep.RoomStyle,
  },
  RoomParams: {
    rootStep: RoomRootSteps.RoomParams,
    step: RoomParamsStep.RoomParams,
  },
  RoomItemsEditor: {
    rootStep: RoomRootSteps.RoomItems,
    step: RoomItemsStep.RoomItemsEditor,
  },
  RoomItemsViewer: {
    rootStep: RoomRootSteps.RoomItems,
    step: RoomItemsStep.RoomItemsViewer,
  },
  RoomSummary: {
    rootStep: RoomRootSteps.RoomItems,
    step: RoomItemsStep.RoomSummary,
  },
};
