import { styled } from '../../themes';

import { StyledToggleButton } from '../ToggleButton/ToggleButton.styled';

export const StyledToggleButtonGroup = styled.div`
  display: inline-flex;

  ${StyledToggleButton} {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
