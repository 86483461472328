import React, { AnchorHTMLAttributes } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import I18n from 'i18n-js';

import { IGAOptions } from './iga-options';

export interface ILinkProps extends IGAOptions, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'css'> {
  to: string;
}

export const Link = React.forwardRef<HTMLAnchorElement, ILinkProps>(function Link(props, ref) {
  const currentLocale = I18n.currentLocale();
  const defaultLocale = I18n.defaultLocale;

  const prefix = currentLocale === defaultLocale ? '' : `/${currentLocale}`;

  const { to, ...rest } = props;

  return /^(https?:\/\/|tel:)/.test(to) ? (
    <a href={to} {...rest} target="_blank" rel="nofollow noopener noreferrer" />
  ) : (
    <RouterLink to={`${prefix}${to}`} {...rest} ref={ref} />
  );
});
