import 'normalize.css';

import { colors, createGlobalStyle } from '../../lib/themes';
import media from '../../lib/utils/media';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    font-family: 'Roboto', sans-serif;;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-focus-ring-color: rgba(255,255,255,0);
    cursor: default;

    // &::-webkit-scrollbar {
    //   width: 7px;
    //   background-color: transparent;
    // }
    //
    // &::-webkit-scrollbar-thumb {
    //   background-color: ${colors.grayDark};
    // }
    //
    // &::-webkit-scrollbar-track {
    //   box-shadow: none;
    // }
  }

  body {
    font-size: 100%;
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-y: scroll;

    ${media.phone`
      font-size: 12px;
      line-height: 1.34;
    `}
  }

  *:focus,
  *:active,
  *:hover {
    outline: none;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  select {
    text-transform: none;
  }

  button {
    overflow: visible;
  }

  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  input[type='search'] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: none;
    padding: 0;
  }

  textarea {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    vertical-align: top;
  }

  button,
  input,
  select[multiple],
  textarea {
    background-image: none;
  }

  input,
  select,
  textarea {
    border-radius: 0;
    box-shadow: none;
  }

  input,
  textarea {
    resize: none;
    user-select: text;
  }

  [placeholder]:focus::placeholder {
    color: transparent;
  }

  .cms-content {
    span {
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }

    p {
      font-size: 15px;
      line-height: 25px;
    }

    a {
      color: #62d69c;
      text-decoration: none;
    }
  }

  ul, p, h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 15px;
    line-height: 25px;
  }

  h2 {
    font-size: 44px;
    line-height: 44px;
  }

  h3 {
    font-size: 36px;
    line-height: 36px;
  }
`;
