import React from 'react';

export const StorageWardrobeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="39" height="55" viewBox="0 0 39 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.8 0H19.7H1.59998C1.19998 0 0.900024 0.300006 0.900024 0.600006V50.7C0.900024 51.1 1.19998 51.3 1.59998 51.3H4V54.2C4 54.6 4.30001 54.8 4.70001 54.8H34.7C35.1 54.8 35.4 54.5 35.4 54.2V51.3H37.8C38.2 51.3 38.5 51 38.5 50.7V43V35.2V0.600006C38.5 0.300006 38.2 0 37.8 0ZM20.4 35.9H37.2V42.4H20.4V35.9ZM37.2 34.6H20.4V1.3H37.2V34.6ZM2.29999 1.3H19.1V35.3V43.1V50.2H2.29999V1.3ZM34.1 53.7H5.40002V51.4H19.8H34.2V53.7H34.1ZM37.2 50.1H20.4V43.6H37.2V50.1Z"
      fill="currentColor"
    />
    <path
      d="M14.9 23.5C14.5 23.5 14.2 23.8 14.2 24.1V27.3C14.2 27.7 14.5 27.9 14.9 27.9C15.3 27.9 15.6 27.6 15.6 27.3V24.1C15.6 23.8 15.3 23.5 14.9 23.5Z"
      fill="currentColor"
    />
    <path
      d="M30.4 38.5H27.2C26.8 38.5 26.5 38.8 26.5 39.1C26.5 39.4 26.8 39.7 27.2 39.7H30.4C30.8 39.7 31.1 39.4 31.1 39.1C31.1 38.8 30.7 38.5 30.4 38.5Z"
      fill="currentColor"
    />
    <path
      d="M27.2 47.6004H30.4C30.8 47.6004 31.1 47.3004 31.1 47.0004C31.1 46.7004 30.8 46.4004 30.4 46.4004H27.2C26.8 46.4004 26.5 46.7004 26.5 47.0004C26.5 47.3004 26.9 47.6004 27.2 47.6004Z"
      fill="currentColor"
    />
    <path
      d="M24.6 27.9C25 27.9 25.3 27.6 25.3 27.3V24.1C25.3 23.7 25 23.5 24.6 23.5C24.2 23.5 23.9 23.8 23.9 24.1V27.3C24 27.7 24.3 27.9 24.6 27.9Z"
      fill="currentColor"
    />
  </svg>
);
