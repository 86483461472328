import React from 'react';

export const ComputerIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="93" height="78" viewBox="0 0 93 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.90625 75.1109H85.7344M24.7031 64.9998H55.2188M52.3125 53.4442H4.90625C3.80168 53.4442 2.90625 52.5488 2.90625 51.4442V4.88867C2.90625 3.7841 3.80168 2.88867 4.90625 2.88867H75.0156C76.1202 2.88867 77.0156 3.7841 77.0156 4.88867V18.7776"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      d="M63.9375 75.1115L58.125 49.1115H82.8281M81.375 75.1115L85.7344 49.1115H82.8281M68.2969 47.6671C67.8125 41.4078 69.75 26.0004 85.7344 20.2227C87.6719 23.593 93 40.4449 82.8281 49.1115"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      d="M78.4674 24.5547C78.4674 17.6214 74.108 12.9991 71.2017 11.5547C68.7799 13.9621 63.9364 22.9401 65.3895 28.888M62.4857 49.1102C59.5795 46.2214 53.7645 38.4214 59.577 24.5547C62.4828 25.9991 69.75 30.3325 69.75 36.1102"
      stroke="currentColor"
      strokeWidth="4"
    />
  </svg>
);
