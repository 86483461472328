import { styled } from '../../../themes';

export const StyledFormFooter = styled.footer`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;
