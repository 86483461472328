import { styled, colors } from '../../../../lib/themes';

export const Page = styled.div``;

export const WelcomeBlock = styled.h1`
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 100px;
  margin-bottom: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
`;

export const AddProjectButtonWrapper = styled.div`
  margin-bottom: 100px;
`;

export const Video = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  height: 637px;
  border: 1px solid ${colors.black};
  overflow: hidden;
`;
