import React from 'react';
// tslint:disable:max-line-length

export const AccountIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="17" y="34.8572" width="61.7143" height="58.2857" rx="3.60714" stroke="black" strokeWidth="6" />
    <rect x="46.1429" y="60.5715" width="3.42857" height="13.7143" stroke="black" strokeWidth="3.42857" />
    <path
      d="M64.3143 19.4571C64.3143 10.3681 56.9462 3 47.8572 3C38.7681 3 31.4 10.3681 31.4 19.4571L31.4 37.2857"
      stroke="black"
      strokeWidth="6"
    />
    <circle cx="47.8571" cy="55.4289" r="3.85714" stroke="black" strokeWidth="6" />
  </svg>
);
