/**
 * The MainStore is a container for all other stores, especially for those which needed to be singletones
 * and all other stores that need to be cleared on sign-in/out processes
 */
import { ManageableStore } from './manageable-store';
import { CoreError } from './errors/core-error';

/**
 * Type of available store enumeration
 */
export type AvailableStores = {
  [StoreName: string]: ManageableStore<any>;
};

/**
 * Main storage of all DI objects in the system
 */
export class MainStore<T extends AvailableStores> {
  private $storesMap: Map<string, any>;

  constructor() {
    this.$storesMap = new Map<string, any>();
  }

  /**
   * Init the IMainStore object with special stores,
   * basically registres array of stores.
   * Could be used, when you manually create a store with special constructor params
   * @param {ManageableStore[]} stores
   */
  initialize(stores: ManageableStore<any>[]) {
    stores.forEach(store => this.registerStore(store));
  }

  /**
   * register a new store here
   * Store of the same class cannot be registred twice
   * @param {ManageableStore} store
   */
  registerStore(store: ManageableStore<any>) {
    if (!store.getName()) {
      throw new CoreError(`Unable to register store withot name ${store.constructor.name}`);
    }

    // we cannot register store of the same class twice
    if (this.$storesMap.has(store.getName())) {
      throw new CoreError(`Main store already contains ${store.getName()}`);
    }

    this.$storesMap.set(store.getName(), store);
  }

  /**
   * Check if such Store already exists in MainStorew
   * @param {string | AConstructorTypeOf<any>} name
   * @return {boolean}
   */
  has(name: keyof T): boolean {
    return this.$storesMap.has(name as string);
  }

  /**
   * Get store by name or its Constructor
   * @param {string | T} name - store name or store constructor
   * @return {T}
   */
  get<P extends keyof T>(name: P): T[P] {
    if (!this.$storesMap.has(name as string)) {
      if (typeof name === 'string') {
        throw new CoreError(`Unknown store requested ${name}`);
      }
    }
    return this.$storesMap.get(name as string);
  }

  /**
   * Clear or reset all ManageableStores
   * Basically user on sign-in/out
   */
  clearStores() {
    for (const key of this.$storesMap.keys()) {
      // We need to clear each store only once
      const repository = this.$storesMap.get(key);
      if (repository) {
        repository.clear();
      }
    }
  }
}
