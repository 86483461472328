import * as React from 'react';

import { Typography } from '../../../lib/themes/typography/Typography';
import { FormLayout } from '../../../lib/components/Form';
import { Button } from '../../../lib/components/Button';

interface IResetPasswordMessage {
  onClickOk?: () => void;
}

export const ResetPasswordMessage: React.FC<IResetPasswordMessage> = ({ onClickOk }: IResetPasswordMessage) => {
  return (
    <FormLayout style={{ textAlign: 'center' }}>
      <Typography tag="p" variant="h3">
        Wir haben eine Nachricht an ihre E-Mail mit dem Link zum Zurücksetzen des Passworts gesendet
      </Typography>
      <Button variant="contained" color="bronze" onClick={onClickOk}>
        Ok
      </Button>
    </FormLayout>
  );
};
