import { useCallback, useState, useEffect } from 'react';
type AugmentedRequired<T extends object, K extends keyof T = keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type ConfigArg = {
  numberOfPages: number;
  initialPage: number;
  maxButtons?: number;
};

type Config = AugmentedRequired<ConfigArg, 'maxButtons'>;

export enum PaginatorButtonType {
  previous = 'previous',
  next = 'next',
  pageNumber = 'page-number',
  ellipsis = 'ellipsis',
}

export interface PaginatorPiece {
  type: PaginatorButtonType;
  pageNumber?: number;
  isDisabled?: boolean;
}

export interface State {
  activePage: number;
  isFirst: boolean;
  isLast: boolean;
  hasPrevious: boolean;
  hasNext: boolean;
  visiblePieces: PaginatorPiece[];
  goToPage: (pageNumber: number) => void;
}

function computeVisiblePieces(activePage: number, config: Config): PaginatorPiece[] {
  const { numberOfPages, maxButtons } = config;
  const visiblePieces: PaginatorPiece[] = [];

  if (numberOfPages <= maxButtons) {
    for (let page = 1; page <= numberOfPages; page++) {
      visiblePieces.push({ type: PaginatorButtonType.pageNumber, pageNumber: page });
    }

    return visiblePieces;
  }

  let lowerLimit = activePage;
  let upperLimit = activePage;

  visiblePieces.push({
    type: PaginatorButtonType.previous,
    pageNumber: Math.max(1, activePage - 1),
    isDisabled: activePage === 1,
  });

  // From https://gist.github.com/keon/5380f81393ad98ec19e6
  for (let i = 1; i < maxButtons && i < numberOfPages; ) {
    if (lowerLimit > 1) {
      lowerLimit--;
      i++;
    }

    if (i < maxButtons && upperLimit < numberOfPages) {
      upperLimit++;
      i++;
    }
  }

  if (lowerLimit > 1 && lowerLimit !== upperLimit) {
    visiblePieces.push({ type: PaginatorButtonType.pageNumber, pageNumber: 1 });
    visiblePieces.push({ type: PaginatorButtonType.ellipsis, isDisabled: true });
  }

  for (let i = lowerLimit; i <= upperLimit; i++) {
    visiblePieces.push({ type: PaginatorButtonType.pageNumber, pageNumber: i });
  }

  if (activePage < numberOfPages - 2) {
    visiblePieces.push({ type: PaginatorButtonType.ellipsis, isDisabled: true });
    visiblePieces.push({ type: PaginatorButtonType.pageNumber, pageNumber: numberOfPages });
  }

  visiblePieces.push({
    type: PaginatorButtonType.next,
    pageNumber: Math.min(numberOfPages, activePage + 1),
    isDisabled: activePage === numberOfPages,
  });

  return visiblePieces;
}

export function usePagination(_config: ConfigArg) {
  if (typeof _config !== 'object') {
    throw new TypeError(`usePagination(config): config must be an object. Go ${typeof _config} instead`);
  }

  const config: Config = { maxButtons: 5, ..._config };

  if (config.initialPage > config.numberOfPages) {
    config.initialPage = config.numberOfPages;
  }

  if (config.maxButtons > config.numberOfPages) {
    config.maxButtons = config.numberOfPages;
  }

  const [activePage, setActivePage] = useState(config.initialPage);
  const { numberOfPages } = config;
  const isFirst = activePage === 1;
  const isLast = activePage === numberOfPages;
  const hasPrevious = numberOfPages > 1 && activePage > 1;
  const hasNext = activePage < numberOfPages;
  const visiblePieces = computeVisiblePieces(activePage, config);

  const goToPage = useCallback<State['goToPage']>(pageNumber => {
    setActivePage(pageNumber);
  }, []);

  useEffect(() => {
    setActivePage(activePageNumber =>
      config.initialPage !== activePageNumber ? config.initialPage : activePageNumber,
    );
  }, [config.initialPage]);

  return {
    activePage,
    isFirst,
    isLast,
    hasPrevious,
    hasNext,
    visiblePieces,
    goToPage,
  };
}
