import { colors, styled } from '../../../../lib/themes';
import media from '../../../../lib/utils/media';
import { Tabs } from '../../../../lib/components/Navigation/Tabs';

export const Root = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colors.white};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  z-index: 101;

  & > * {
    margin-right: 0;
  }
`;

export const HeaderVariantWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  align-self: flex-start;
  min-width: 0;
`;

export const HeaderVariant = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 80px;
  width: 100%;
  padding-left: 32px;

  ${media.tablet`
    padding-left: 0;
  `}
`;

export const Navigation = styled.nav`
  display: flex;
  position: relative;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-left: 57px;
  margin-right: 20px;
  height: 80px;

  ${media.tablet`
    margin-left: 30px;
  `};

  @media (max-width: 800px) {
    margin-left: 50px;
  }
`;

export const ButtonsWrapper = styled.div`
  justify-self: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  margin-left: auto;

  ${media.tablet`
    margin-right: 20px;
  `}
`;

export const StyledTabs = styled(Tabs)`
  [data-name='tabs-item']:not(:last-child) {
    margin-right: 30px;
  }
`;
