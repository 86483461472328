import { styled, h1, h5, colors } from '../../lib/themes';
import { Container } from '../../lib/components';
import config from 'config';

const { staticServer } = config;

const BgTrialAccountExpires = `${staticServer}/cms/site/assets/files/2308/trial-account-expired-background.svg`;

export const TrialAccountExpiresStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(${BgTrialAccountExpires});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
  }
`;

export const Content = styled(Container)`
  padding-top: 210px;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h1`
  ${h1};
  margin-bottom: 35px;
  color: ${colors.black};
`;

export const Description = styled.p`
  ${h5};
  margin-bottom: 72px;
  color: ${colors.black};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 258px;

  & > *:first-child {
    margin-bottom: 15px;
  }
`;
