import React from 'react';

export const WritingDeskIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="43" height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H41.5769C41.8531 0 42.0769 0.223858 42.0769 0.5V3.95362C42.0769 4.22976 41.8531 4.45362 41.5769 4.45362C24.2687 4.45362 0.917069 4.45362 0.5 4.45362C0.223858 4.45362 0 4.22977 0 3.95362V0.5ZM1 3.45362V1H41.0769V3.45362L1 3.45362Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M40 25.5V3.5H41V25.5H40Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39 16V4H40V16H39Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 16V4H3V16H2Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1 25.5V3.5H2V25.5H1Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1464 23.1464C19.3417 22.9512 19.6583 22.9512 19.8536 23.1464L21 24.2929L21 11.2071L19.8536 12.3536C19.6583 12.5488 19.3417 12.5488 19.1464 12.3536C18.9512 12.1583 18.9512 11.8417 19.1464 11.6464L21.1464 9.64645C21.3417 9.45118 21.6583 9.45118 21.8536 9.64645L23.8536 11.6464C24.0488 11.8417 24.0488 12.1583 23.8536 12.3536C23.6583 12.5488 23.3417 12.5488 23.1464 12.3536L22 11.2071L22 24.2929L23.1464 23.1464C23.3417 22.9512 23.6583 22.9512 23.8536 23.1464C24.0488 23.3417 24.0488 23.6583 23.8536 23.8536L21.8536 25.8536C21.6583 26.0488 21.3417 26.0488 21.1464 25.8536L19.1464 23.8536C18.9512 23.6583 18.9512 23.3417 19.1464 23.1464Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41 25.5H40V26.5H41V25.5ZM40 24.5C39.4477 24.5 39 24.9477 39 25.5V26.5C39 27.0523 39.4477 27.5 40 27.5H41C41.5523 27.5 42 27.0523 42 26.5V25.5C42 24.9477 41.5523 24.5 41 24.5H40Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 4.5H30V6.5H35V4.5ZM30 3.5C29.4477 3.5 29 3.94772 29 4.5V6.5C29 7.05228 29.4477 7.5 30 7.5H35C35.5523 7.5 36 7.05228 36 6.5V4.5C36 3.94772 35.5523 3.5 35 3.5H30Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 25.5H1L1 26.5H2V25.5ZM1 24.5C0.447715 24.5 0 24.9477 0 25.5V26.5C0 27.0523 0.447715 27.5 1 27.5H2C2.55228 27.5 3 27.0523 3 26.5V25.5C3 24.9477 2.55228 24.5 2 24.5H1Z"
      fill="currentColor"
    />
  </svg>
);
