import React from 'react';

import { StyleSlider } from '../../components';
import { StyledSliderBlock } from './SliderBlock.styled';
import { IRoomDesign } from '../../../fixtures';

interface ISliderBlockProps {
  roomDesign: IRoomDesign;
}

export const SliderBlock: React.FC<ISliderBlockProps> = ({ roomDesign }) => {
  return (
    <StyledSliderBlock>
      <StyleSlider roomDesign={roomDesign} />
    </StyledSliderBlock>
  );
};
