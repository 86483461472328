import React from 'react';

import { Slider } from '../../../../lib/components/Swiper';
import { Container } from '../../../../lib/components/Container';
import { Image } from '../../../../lib/components/Image';

import { Content, StyleName, RoomName } from './StyleSlider.styled';
import { IRoomDesign } from '../../../fixtures';

export interface IStyleSliderProps {
  roomDesign: IRoomDesign;
}

export const StyleSlider: React.FC<IStyleSliderProps> = ({ roomDesign }) => {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    if (ref.current && ref.current.swiper) {
      ref.current.swiper.update();
      ref.current.swiper.slideTo(0);
    }
  }, [roomDesign]);

  return (
    <>
      <Slider
        ref={ref}
        slidesPerView={1}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        bulletsColor="white"
        css={`
          height: 600px;
        `}
      >
        {roomDesign.images.map((image, index) => (
          <Image key={`${roomDesign.urn}-${index}`} src={image} alt="" width="100vw" height="100%" />
        ))}
      </Slider>

      <Container>
        <Content>
          <RoomName>Arbeitsbereich</RoomName>
          <StyleName>{roomDesign.title}</StyleName>
        </Content>
      </Container>
    </>
  );
};
