import React from 'react';

export const WarningIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      css={`
        transform: translate3d(-1px, -4px, 0);
      `}
      d="M30.5093 33H3.49072C2.71798 33 2.23723 32.1609 2.62802 31.4943L16.1373 8.44904C16.5236 7.79 17.4764 7.79 17.8627 8.44904L31.372 31.4943C31.7628 32.1609 31.282 33 30.5093 33Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M17 16L17 25"
      stroke="currentColor"
      strokeWidth="2"
      css={`
        transform: translate3d(-1px, -4px, 0);
      `}
    />
    <path
      d="M17 27L17 29"
      stroke="currentColor"
      strokeWidth="2"
      css={`
        transform: translate3d(-1px, -4px, 0);
      `}
    />
  </svg>
);
