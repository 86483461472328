import React from 'react';

export const BulbFloorLampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="19" height="53" viewBox="0 0 19 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.10002 6.79973C8.40002 6.59973 8.50003 6.19976 8.30003 5.89976C8.10003 5.59976 7.70002 5.49975 7.40002 5.69975C5.50002 6.79975 4.40002 8.89971 4.40002 11.0997C4.40002 11.4997 4.70002 11.7997 5.00002 11.7997C5.30002 11.7997 5.60002 11.4997 5.60002 11.0997C5.70002 9.29971 6.60002 7.69973 8.10002 6.79973Z"
      fill="currentColor"
    />
    <path
      d="M17.9 51.0001H10.1V29.1001H13.9C14.3 29.1001 14.5 28.8001 14.5 28.4001V23.6001V22.4001C14.5 20.4001 16 16.8001 17.3 14.3001C18 13.0001 18.4 11.5001 18.4 10.0001C18.4 7.50008 17.4 5.2001 15.6 3.4001C13.8 1.7001 11.4 0.800103 8.90002 0.900103C4.20002 1.1001 0.400018 4.90005 0.300018 9.60005C0.200018 11.3001 0.600017 12.9001 1.40002 14.3001C2.50002 16.3001 4.20001 20.2001 4.20001 22.5001V23.6001V28.4001C4.20001 28.8001 4.50002 29.1001 4.80002 29.1001H8.60001V51.0001H0.800018C0.400018 51.0001 0.200012 51.3001 0.200012 51.7001C0.200012 52.1001 0.500018 52.4001 0.800018 52.4001H17.6C18 52.4001 18.2 52.1001 18.2 51.7001C18.6 51.3001 18.3 51.0001 17.9 51.0001ZM2.70001 13.8001C2.00001 12.6001 1.70001 11.2001 1.70001 9.80007C1.90001 5.80007 5.10001 2.5001 9.10001 2.4001C11.3 2.3001 13.3 3.10008 14.8 4.50008C16.3 6.00008 17.2 8.00005 17.2 10.1001C17.2 11.4001 16.9 12.7001 16.3 13.8001C16 14.4001 13.3 19.4001 13.3 22.6001V23.2001H11.6L12.5 15.8001C12.6 15.4001 12.4 15.0001 12.1 14.8001C11.8 14.6001 11.4 14.5001 11 14.7001C10.6 14.9001 10.1 15.1001 9.40002 15.1001C8.80002 15.1001 8.20002 14.9001 7.80002 14.7001C7.40002 14.5001 7.00001 14.6001 6.70001 14.8001C6.40001 15.0001 6.20002 15.4001 6.30002 15.8001L7.20001 23.2001H5.50002V22.7001C5.70002 20.1001 3.90001 15.9001 2.70001 13.8001ZM8.70001 23.1001L7.80002 16.0001C8.30002 16.2001 8.90001 16.3001 9.50002 16.3001C10.2 16.3001 10.8 16.1001 11.2 16.0001L10.3 23.1001H8.70001ZM5.70001 24.4001H13.4V27.9001H5.70001V24.4001Z"
      fill="currentColor"
    />
  </svg>
);
