import React from 'react';

export const SlidingWardrobeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="49" height="35" viewBox="0 0 49 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.6 0H0.599976C0.199976 0 0 0.3 0 0.6V30.8C0 31.2 0.299976 31.4 0.599976 31.4H3.39999V34.2C3.39999 34.6 3.7 34.8 4 34.8H44.1C44.5 34.8 44.7 34.5 44.7 34.2V31.4H47.5C47.9 31.4 48.1 31.1 48.1 30.8V0.6C48.2 0.3 47.9 0 47.6 0ZM15.3 11.4H32.8V20.1H15.3V11.4ZM15.3 21.5H32.8V30.2H15.3V21.5ZM32.8 9.9H15.3V1.3H32.8V9.9ZM1.19998 1.3H14V10.6C14 10.6 14 10.6 14 10.7C14 10.7 14 10.7 14 10.8V20.8C14 20.8 14 20.8 14 20.9C14 20.9 14 20.9 14 21V30.3H1.19998V1.3ZM43.5 33.6H4.69998V31.5H43.5V33.6ZM46.9 30.1H34.1V1.3H46.9V30.1Z"
      fill="currentColor"
    />
    <path
      d="M19 8.6H29C29.4 8.6 29.6 8.3 29.6 8C29.6 7.7 29.3 7.4 29 7.4H19C18.6 7.4 18.4 7.7 18.4 8C18.4 8.3 18.7 8.6 19 8.6Z"
      fill="currentColor"
    />
    <path
      d="M29 17.4H19C18.6 17.4 18.4 17.7 18.4 18C18.4 18.3 18.7 18.6 19 18.6H29C29.4 18.6 29.6 18.3 29.6 18C29.6 17.7 29.4 17.4 29 17.4Z"
      fill="currentColor"
    />
    <path
      d="M29 27.5H19C18.6 27.5 18.4 27.8 18.4 28.1C18.4 28.5 18.7 28.7 19 28.7H29C29.4 28.7 29.6 28.4 29.6 28.1C29.7 27.8 29.4 27.5 29 27.5Z"
      fill="currentColor"
    />
    <path
      d="M11.1 18.4C11.5 18.4 11.7 18.1 11.7 17.8V13.7C11.7 13.3 11.4 13.1 11.1 13.1C10.8 13.1 10.5 13.4 10.5 13.7V17.8C10.5 18.1 10.8 18.4 11.1 18.4Z"
      fill="currentColor"
    />
    <path
      d="M37 18.4C37.4 18.4 37.6 18.1 37.6 17.8V13.7C37.6 13.3 37.3 13.1 37 13.1C36.6 13.1 36.4 13.4 36.4 13.7V17.8C36.3 18.1 36.6 18.4 37 18.4Z"
      fill="currentColor"
    />
  </svg>
);
