import React from 'react';
import { Button } from '../Button';
import { Badge, HeaderTitles, Icon } from './HeaderBackButton.styled';
import { Typography } from '../../themes/typography/Typography';

interface HeaderBackButtonProps {
  backHref: string;
  badgeText?: string;
  backButtonText: string;
  space?: string;
}

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({ backHref, badgeText, backButtonText, space }) => {
  return (
    <HeaderTitles>
      <Button href={backHref} variant="text" color="bronze" iconStart={<Icon />}>
        <span
          css={`
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 20px;
            margin-left: 26px;
          `}
          title={backButtonText}
        >
          {backButtonText}
        </span>
        {space && (
          <Typography tag="h5" variant="button" color="grayDark" title={space}>
            {space}
          </Typography>
        )}
        {badgeText && <Badge>{badgeText}</Badge>}
      </Button>
    </HeaderTitles>
  );
};
