import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireCategoryRepository, RequireLog } from '../available-stores';
import { Category, Mutation, Query } from '../api/generated';
import { gqlDocsCategory } from '../gql-documents/gql-category';
import { useQuery, UseQueryResult, useStore } from '../hooks/use-store';
import { flattenTree } from '../../utils/flatten-tree';
import { makeMap } from '../../utils/make-map';

export interface CategoryMap {
  [id: string]: Category;
}

export class CategoryRepository extends ApiRepository<Query, Mutation> {
  private cachedCategoryMap: CategoryMap | undefined;

  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'CategoryRepository');
  }

  /**
   * Request and return categories list
   */
  getTree = (): Promise<Category[]> => {
    return this.query(gqlDocsCategory.categoriesList, 'categories', undefined, { fetchPolicy: 'cache-first' });
  };

  /**
   * Returns categories transferred to a map.
   * Runs only once
   */
  getCategoryMap = (): Promise<CategoryMap> => {
    if (this.cachedCategoryMap) {
      return Promise.resolve(this.cachedCategoryMap);
    }
    return this.getTree().then(categories => {
      const flattenCategories = flattenTree<Category>(categories, 'children');
      this.cachedCategoryMap = makeMap<Category>(flattenCategories, 'id');
      return this.cachedCategoryMap;
    });
  };
}

/**
 * Load plain category list
 */
export function useCategoryTree(): UseQueryResult<void, Category[]> {
  return useQuery(useStore<RequireCategoryRepository>('CategoryRepository').getTree, undefined);
}

/**
 * Load and cache Category map
 */
export function useCategoryMap(): UseQueryResult<void, CategoryMap> {
  return useQuery(useStore<RequireCategoryRepository>('CategoryRepository').getCategoryMap, undefined);
}

/**
 * Returns root category of a category with given id
 * @param categoryId - given category Id
 * @param categoryMap
 */
export function getRootCategory(categoryId: string, categoryMap: CategoryMap): Category | undefined {
  let category = categoryMap[categoryId];
  if (!category) {
    return undefined;
  }

  while (category.parentId) {
    category = categoryMap[category.parentId];
    if (!category) {
      return undefined;
    }
  }
  return category;
}

/**
 * This actually should be done by graphql on API side
 * @param categoryIds
 * @param categoryMap
 */
export function getCategoriesByIds(categoryIds: string[], categoryMap: CategoryMap): Category[] {
  return categoryIds.map(id => categoryMap[id]).filter(category => category);
}

export function getRootCategoriesByIds(categoryIds: string[], categoryMap: CategoryMap): Category[] {
  const rootCategories: Category[] = getCategoriesByIds(categoryIds, categoryMap)
    .map(category => getRootCategory(category.id, categoryMap))
    .filter(category => category) as Category[];

  // make unique list
  return [...new Set(rootCategories)];
}
