import { styled, css } from '../../themes';
import { IImageProps } from './Image';

export const StyledImage = styled.div<Partial<IImageProps>>`
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;

    width: 100%;
    height: auto;

    transform: translate(-50%, -50%);
  }

  ${({ width, height, minWidth, minHeight }) => css`
    width: ${width};
    height: ${height};
    min-width: ${minWidth};
    min-height: ${minHeight};
  `};
`;
