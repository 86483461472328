import React from 'react';

export const RoundedArmchairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M35.8 9.60016H34.9V8.7002C34.9 4.3002 31.3 0.700195 26.9 0.700195H16.2C11.8 0.700195 8.20001 4.3002 8.20001 8.7002V9.60016H6.89999C3.19999 9.60016 0.200012 12.6002 0.200012 16.3002V31.9002C0.200012 33.4002 1.39999 34.6002 2.89999 34.6002H8.20001V38.0002C8.20001 38.4002 8.49999 38.7002 8.79999 38.7002H14.4C14.8 38.7002 15 38.4002 15 38.0002V34.6002H28V38.0002C28 38.4002 28.3 38.7002 28.6 38.7002H34.2C34.6 38.7002 34.8 38.4002 34.8 38.0002V34.6002H39.7C41.2 34.6002 42.4 33.4002 42.4 31.9002V16.3002C42.4 12.6002 39.5 9.60016 35.8 9.60016ZM9.39999 8.80017C9.39999 5.10017 12.4 2.10016 16.1 2.10016H26.8C30.5 2.10016 33.5 5.10017 33.5 8.80017V22.9002H9.29999V8.80017H9.39999ZM13.7 37.3002H9.39999V34.6002H13.7V37.3002ZM33.6 37.3002H29.3V34.6002H33.6V37.3002ZM41.1 31.9002C41.1 32.7002 40.5 33.3002 39.7 33.3002H2.70001C1.90001 33.3002 1.29999 32.7002 1.29999 31.9002V16.3002C1.29999 13.3002 3.70001 10.9002 6.70001 10.9002H8V23.5002C8 23.9002 8.30001 24.2002 8.60001 24.2002H34.1C34.5 24.2002 34.7 23.9002 34.7 23.5002V10.9002H35.6C38.6 10.9002 41 13.3002 41 16.3002V31.9002H41.1Z"
      fill="currentColor"
    />
  </svg>
);
