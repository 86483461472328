// TODO: choose better color names

export const colors = {
  bronze: '#D88F6B',
  bronzeLight: '#F0A884',
  bronzeDark: '#7D462C',
  gray: '#C4C4C4',
  grayLight: '#F7F7F7',
  grayLighter: '#E0E0E0',
  grayLightest: '#F0F0F0',
  grayMedium: '#BDBDBD',
  dark: '#232323',
  grayMediumDark: '#979797',
  grayDark: '#737373',
  black: '#000000',
  white: '#FFFFFF',
  red: '#EA001C',
} as const;

export type Colors = typeof colors;

export type Color = keyof typeof colors;
