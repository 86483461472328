import React from 'react';

export const CurvedArmchairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="51" height="36" viewBox="0 0 51 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M50.3 17.9004C50.3 14.8004 47.8 12.3004 44.7 12.3004C43 12.3004 41.4 13.1004 40.4 14.3004V4.80038C40.4 2.60038 38.6 0.900391 36.5 0.900391H14.2C12 0.900391 10.3 2.70038 10.3 4.80038V14.3004C9.29999 13.1004 7.7 12.3004 6 12.3004C2.9 12.3004 0.400024 14.8004 0.400024 17.9004C0.400024 20.8004 2.69998 23.3004 5.59998 23.5004V31.5004C5.59998 31.9004 5.89999 32.2004 6.29999 32.2004H10.5V34.7004C10.5 35.1004 10.8 35.4004 11.2 35.4004H17.9C18.3 35.4004 18.6 35.1004 18.6 34.7004V32.2004H32.8V34.7004C32.8 35.1004 33.1 35.4004 33.5 35.4004H40.2C40.6 35.4004 40.9 35.1004 40.9 34.7004V32.2004H45.3C45.7 32.2004 46 31.9004 46 31.5004V23.5004C48.1 23.1004 50.3 20.7004 50.3 17.9004ZM14.1 2.20041H36.4C37.8 2.20041 39 3.40038 39 4.80038V17.7004H11.5V4.80038C11.5 3.30038 12.6 2.20041 14.1 2.20041ZM11.5 19.0004H39V25.5004H11.5V19.0004ZM1.5 17.9004C1.5 15.5004 3.39999 13.6004 5.79999 13.6004C8.19999 13.6004 10.1 15.5004 10.1 17.9004C10.1 20.3004 8.19999 22.2004 5.79999 22.2004C3.49999 22.2004 1.5 20.3004 1.5 17.9004ZM16.9 34.0004H11.5V32.1004H16.9V34.0004ZM39 34.0004H33.6V32.1004H39V34.0004ZM6.70001 30.8004V23.4004C8.10001 23.2004 9.30001 22.5004 10.2 21.4004V26.0004C10.2 26.4004 10.5 26.7004 10.9 26.7004H39.7C40.1 26.7004 40.4 26.4004 40.4 26.0004V21.4004C41.3 22.5004 42.6 23.2004 44.1 23.4004V30.7004H6.70001V30.8004ZM44.6 22.2004C42.2 22.2004 40.3 20.3004 40.3 17.9004C40.3 15.5004 42.2 13.6004 44.6 13.6004C47 13.6004 48.9 15.5004 48.9 17.9004C49 20.3004 47 22.2004 44.6 22.2004Z"
      fill="currentColor"
    />
  </svg>
);
