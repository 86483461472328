import React from 'react';

export const SoftArmchairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.1 20.1C40.3 18.9 38.9 18.2 37.4 18.2H34.8L35.9 14V13.9V13.8C35.9 6.19999 29.3 0 21.2 0C13.1 0 6.50001 6.19999 6.50001 13.8V13.9V14L7.60002 18.2H5.00001C3.50001 18.2 2.2 18.9 1.3 20.1C0.500001 21.3 0.300001 22.9 0.800001 24.2L5.00001 35.1C5.30001 35.9 6.00001 36.4 6.90001 36.4H9.40001V40C9.40001 40.4 9.70001 40.7 10 40.7H14.4C14.8 40.7 15 40.4 15 40V36.4H26.9V40C26.9 40.4 27.2 40.7 27.5 40.7H31.9C32.3 40.7 32.5 40.4 32.5 40V36.4H35.1C35.9 36.4 36.7 35.9 37 35.1L41.2 24.2C42.1 22.8 41.9 21.3 41.1 20.1ZM21.3 1.29999C28.7 1.29999 34.7 6.80001 34.7 13.7L30.9 28.2H11.7L7.90001 13.7C7.90001 6.80001 13.9 1.29999 21.3 1.29999ZM13.9 39.4H10.8V36.5H13.9V39.4ZM31.5 39.4H28.4V36.5H31.5V39.4ZM40.3 23.7L36.1 34.6C36 34.9 35.7 35 35.5 35H7.10002C6.80002 35 6.60001 34.8 6.50001 34.6L2.3 23.7C1.9 22.7 2.00002 21.7 2.60002 20.8C3.20002 19.9 4.10003 19.4 5.20003 19.4H8.00001H8.10002L10.6 29V29.1C10.6 29.1 10.6 29.2 10.7 29.2C10.7 29.2 10.7 29.2 10.8 29.3L10.9 29.4C10.9 29.4 10.9 29.4 11 29.4C11.1 29.4 11.2 29.4 11.2 29.4H31.2C31.2 29.4 31.2 29.4 31.3 29.4H31.4C31.7 29.4 32 29.2 32 28.9L34.5 19.4H37.4C38.5 19.4 39.4 19.9 40 20.8C40.6 21.7 40.7 22.7 40.3 23.7Z"
      fill="currentColor"
    />
  </svg>
);
