import { styled, css, colors, applyCssIf, h3 } from '../../themes';
import { ISelectable } from '../../components/common-types';

export const StyledImageTabs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabLabel = styled.h3`
  ${h3}
  margin-bottom: 50px;
`;

export const TabButton = styled.div`
  display: none;
  position: absolute;
  bottom: 36px;
  z-index: 1;
`;

export const TabImage = styled.div`
  position: relative;
  width: 350px;
  height: 350px;

  display: flex;
  justify-content: center;
  cursor: pointer;

  :hover,
  :focus {
    ${TabButton} {
      display: block;
    }

    ::before {
      content: '';
      position: absolute;
      width: 350px;
      height: 350px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      z-index: 1;
    }
  }
`;

export const Tab = styled.div<ISelectable>`
  ${({ selected }) => css`
    ${TabImage} {
      ${applyCssIf(selected)(css`
        ::after {
          content: '';

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 100%;
          height: 100%;
          padding: 12px;
          box-sizing: content-box;

          border: 3px solid ${colors.bronze};
        }
      `)}
    }
  `}
`;
