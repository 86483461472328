import { styled } from '../themes';

export const Scrollable = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  cursor: default;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;

  //&::-webkit-scrollbar {
  //  width: 2px;
  //  background-color: transparent;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  background-color: rgba(240, 240, 240, 0.8);
  //}
  //
  //&::-webkit-scrollbar-track {
  //  box-shadow: none;
  //}
`;
