import { styled, colors } from '../../../../../../lib/themes';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 38px;
  height: 100%;
  text-align: center;
  background-color: ${colors.grayLight};
`;
