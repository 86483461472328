import React from 'react';
import { ButtonsWrapper, LogoWrapper, Logo, Root, LogoIconStyled } from './Header.styled';
import { HeaderUserMenu } from './HeaderUserMenu';
import { NavigationButton } from '../Navigation/NavigationButton';

interface IHeaderProps {
  backButtonTitle?: string;
  backHref?: string;
}

export const Header: React.FC<IHeaderProps> = props => {
  const { backButtonTitle, backHref } = props;

  return (
    <Root>
      {backButtonTitle && (
        <NavigationButton direction="back" href={backHref ? backHref : '/'} variant={'text'} color="bronze">
          {backButtonTitle}
        </NavigationButton>
      )}
      <LogoWrapper>
        <Logo to={'/'}>
          <LogoIconStyled />
        </Logo>
      </LogoWrapper>

      <ButtonsWrapper>
        <HeaderUserMenu />
      </ButtonsWrapper>
    </Root>
  );
};
