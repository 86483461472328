import { styled } from '../../../../lib/themes';
import { Typography } from '../../../../lib/themes/typography/Typography';

export const Root = styled.div``;

export const Content = styled.div`
  padding-top: 80px;
  text-align: center;
`;

export const Image = styled.img`
  margin-bottom: 77px;
`;

export const Title = styled(Typography)`
  margin-bottom: 20px;
`;

export const Paragraph = styled(Typography)`
  margin-bottom: 44px;
`;
