import React from 'react';

export const StorageTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="54" height="28" viewBox="0 0 54 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M53.3 0.5H22H4.1H0.6C0.2 0.5 0 0.800012 0 1.20001C0 1.60001 0.3 1.89999 0.6 1.89999H3.5V7.89999V14.6V27C3.5 27.4 3.8 27.7 4.1 27.7C4.5 27.7 4.7 27.4 4.7 27V15.1H22C22.4 15.1 22.6 14.8 22.6 14.4V7.70001V1.70001H49V27C49 27.4 49.3 27.7 49.6 27.7C50 27.7 50.2 27.4 50.2 27V1.79999H53.1C53.5 1.79999 53.7 1.50001 53.7 1.10001C53.9 0.800006 53.6 0.5 53.3 0.5ZM21.4 13.8H4.8V8.39999H21.4V13.8ZM21.4 7.20001H4.8V1.79999H21.4V7.20001Z"
      fill="currentColor"
    />
    <path
      d="M13.1 6C14 6 14.6 5.3 14.6 4.5C14.6 3.7 13.9 3 13.1 3C12.2 3 11.6 3.7 11.6 4.5C11.6 5.3 12.2 6 13.1 6ZM13.1 4.20001C13.2 4.20001 13.3 4.29999 13.3 4.39999C13.3 4.69999 12.8 4.69999 12.8 4.39999C12.8 4.29999 13 4.20001 13.1 4.20001Z"
      fill="currentColor"
    />
    <path
      d="M13.1 12.7002C14 12.7002 14.6 12.0002 14.6 11.2002C14.6 10.3002 13.9 9.7002 13.1 9.7002C12.2 9.7002 11.6 10.4002 11.6 11.2002C11.5 12.0002 12.2 12.7002 13.1 12.7002ZM13.1 10.9002C13.2 10.9002 13.3 11.0002 13.3 11.1002C13.3 11.4002 12.8 11.4002 12.8 11.1002C12.8 11.0002 13 10.9002 13.1 10.9002Z"
      fill="currentColor"
    />
  </svg>
);
