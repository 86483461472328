import React from 'react';

export const NoLegsSofaIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="58" height="32" viewBox="0 0 58 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M57 6H55.3V5.60001C55.3 2.80001 53 0.5 50.2 0.5H7.5C4.7 0.5 2.39999 2.80001 2.39999 5.60001V6H0.700012C0.300012 6 0 6.30001 0 6.70001V18.2V27.4C0 27.8 0.300012 28.1 0.700012 28.1H2.79999V31.2C2.79999 31.6 3.1 31.9 3.5 31.9H54.2C54.6 31.9 54.9 31.6 54.9 31.2V28H57C57.4 28 57.7 27.7 57.7 27.3V18.1V6.60001C57.6 6.30001 57.3 6 57 6ZM56.3 17.5H52.7C52.8 17.4 52.9 17.2 52.9 17V7.29999H56.2V17.5H56.3ZM3.60001 5.60001C3.60001 3.50001 5.29999 1.79999 7.39999 1.79999H50.1C52.2 1.79999 53.9 3.50001 53.9 5.60001V6H52.2C51.8 6 51.5 6.30001 51.5 6.70001V17C51.5 17.2 51.6 17.4 51.7 17.5H5.5C5.6 17.4 5.70001 17.2 5.70001 17V6.60001C5.70001 6.20001 5.4 5.89999 5 5.89999H3.29999V5.60001H3.60001ZM4.60001 7.29999V17C4.60001 17.2 4.69999 17.4 4.79999 17.5H1.20001V7.29999H4.60001ZM53.5 30.4H4.10001V28H53.5V30.4ZM56.3 26.7H1.29999V18.8H56.3V26.7Z"
      fill="currentColor"
    />
  </svg>
);
