import React from 'react';

export const WallIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="56" height="39" viewBox="0 0 56 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 3C2.5 2.72386 2.72386 2.5 3 2.5H53C53.2761 2.5 53.5 2.72386 53.5 3V36C53.5 36.2761 53.2761 36.5 53 36.5H3C2.72386 36.5 2.5 36.2761 2.5 36V3ZM3.5 3.5V35.5H52.5V3.5H3.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H55C55.2761 0.5 55.5 0.723858 55.5 1V38C55.5 38.2761 55.2761 38.5 55 38.5H1C0.723858 38.5 0.5 38.2761 0.5 38V1ZM1.5 1.5V37.5H54.5V1.5H1.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 8C7.5 7.72386 7.72386 7.5 8 7.5H9C9.27614 7.5 9.5 7.72386 9.5 8C9.5 8.27614 9.27614 8.5 9 8.5H8C7.72386 8.5 7.5 8.27614 7.5 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 11C7.5 10.7239 7.72386 10.5 8 10.5H9C9.27614 10.5 9.5 10.7239 9.5 11C9.5 11.2761 9.27614 11.5 9 11.5H8C7.72386 11.5 7.5 11.2761 7.5 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 11C11.5 10.7239 11.7239 10.5 12 10.5H13C13.2761 10.5 13.5 10.7239 13.5 11C13.5 11.2761 13.2761 11.5 13 11.5H12C11.7239 11.5 11.5 11.2761 11.5 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 15C15.5 14.7239 15.7239 14.5 16 14.5H17C17.2761 14.5 17.5 14.7239 17.5 15C17.5 15.2761 17.2761 15.5 17 15.5H16C15.7239 15.5 15.5 15.2761 15.5 15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 20C15.5 19.7239 15.7239 19.5 16 19.5H17C17.2761 19.5 17.5 19.7239 17.5 20C17.5 20.2761 17.2761 20.5 17 20.5H16C15.7239 20.5 15.5 20.2761 15.5 20Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 20C18.5 19.7239 18.7239 19.5 19 19.5H20C20.2761 19.5 20.5 19.7239 20.5 20C20.5 20.2761 20.2761 20.5 20 20.5H19C18.7239 20.5 18.5 20.2761 18.5 20Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 30C12.5 29.7239 12.7239 29.5 13 29.5H14C14.2761 29.5 14.5 29.7239 14.5 30C14.5 30.2761 14.2761 30.5 14 30.5H13C12.7239 30.5 12.5 30.2761 12.5 30Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 30C8.5 29.7239 8.72386 29.5 9 29.5H10C10.2761 29.5 10.5 29.7239 10.5 30C10.5 30.2761 10.2761 30.5 10 30.5H9C8.72386 30.5 8.5 30.2761 8.5 30Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5 32C47.5 31.7239 47.7239 31.5 48 31.5H48.5C48.7761 31.5 49 31.7239 49 32C49 32.2761 48.7761 32.5 48.5 32.5H48C47.7239 32.5 47.5 32.2761 47.5 32Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.5 29C49.5 28.7239 49.7239 28.5 50 28.5H50.5C50.7761 28.5 51 28.7239 51 29C51 29.2761 50.7761 29.5 50.5 29.5H50C49.7239 29.5 49.5 29.2761 49.5 29Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.5 29C45.5 28.7239 45.7239 28.5 46 28.5H46.5C46.7761 28.5 47 28.7239 47 29C47 29.2761 46.7761 29.5 46.5 29.5H46C45.7239 29.5 45.5 29.2761 45.5 29Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 26C10.5 25.7239 10.7239 25.5 11 25.5H12C12.2761 25.5 12.5 25.7239 12.5 26C12.5 26.2761 12.2761 26.5 12 26.5H11C10.7239 26.5 10.5 26.2761 10.5 26Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 26C15.5 25.7239 15.7239 25.5 16 25.5H17C17.2761 25.5 17.5 25.7239 17.5 26C17.5 26.2761 17.2761 26.5 17 26.5H16C15.7239 26.5 15.5 26.2761 15.5 26Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.9109 5.91409C38.9583 5.64206 39.2173 5.45999 39.4894 5.50744L49.825 7.31003C50.0971 7.35748 50.2791 7.61647 50.2317 7.8885L47.8282 21.6694C47.7808 21.9414 47.5218 22.1235 47.2497 22.076L36.9141 20.2734C36.6421 20.226 36.46 19.967 36.5074 19.695L38.9109 5.91409ZM39.8101 6.57847L37.5785 19.3742L46.929 21.005L49.1606 8.20925L39.8101 6.57847Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45 7.5C44.1716 7.5 43.5 8.17157 43.5 9C43.5 9.82843 44.1716 10.5 45 10.5C45.8284 10.5 46.5 9.82843 46.5 9C46.5 8.17157 45.8284 7.5 45 7.5ZM42.5 9C42.5 7.61929 43.6193 6.5 45 6.5C46.3807 6.5 47.5 7.61929 47.5 9C47.5 10.3807 46.3807 11.5 45 11.5C43.6193 11.5 42.5 10.3807 42.5 9Z"
      fill="currentColor"
    />
  </svg>
);
