import React from 'react';
import * as Yup from 'yup';

import { Form, FormButton, FormInput } from '../../../../../../lib/components/Form';
import { FormLayout } from '../../../../../../lib/components/Form/FormLayout';
import { GqlErrors } from '../../../../../../lib/core/errors/gql-error';
import { MutationCreateRoomArgs, Room, RoomPlanMode } from '../../../../../../lib/core/api/generated';
import { requiredString } from '../../../../../../lib/utils/yup-reductions';
import { useCreateRoom, useUpdateRoom } from '../../../../../../lib/core/repositories/room-repository';
import { useSnackBar } from '../../../../../../lib/components/SnackBar';

import { ButtonsWrapper, Root } from './NewRoomCard.styled';

interface IRoomCardProps {
  projectId: string;
  afterCreateRoom?: (room: Room) => unknown;
  onClose: () => unknown;
}

export const NewRoomCard: React.FC<IRoomCardProps> = ({ projectId, afterCreateRoom, onClose }) => {
  const [createRoom] = useCreateRoom();
  const [updateRoom] = useUpdateRoom();
  const { showAlert } = useSnackBar();

  const initialValues = React.useMemo(() => ({ projectId, name: '' }), [projectId]);

  const handleOnError = React.useCallback(
    message => {
      showAlert(message);
    },
    [showAlert],
  );

  const handleSubmit = React.useCallback(
    (values: MutationCreateRoomArgs) =>
      createRoom(values).then(room =>
        updateRoom({
          id: room.id,
          planMode: RoomPlanMode.LetMePlan,
        }),
      ),
    [createRoom, updateRoom],
  );

  return (
    <Root>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onError={handleOnError}
        afterSubmit={afterCreateRoom}
        validationSchema={validationSchema}
        errorsHR={[
          [
            GqlErrors.ACCOUNT_ROOM_LIMIT_REACHED,
            'Sie können keine weiteren Räume erstellen. Aktualisieren Sie den Tab und versuchen Sie es erneut.',
          ],
        ]}
      >
        <FormLayout>
          <FormInput
            label={'Raum Bezeichnung'}
            placeholder={'z.B. Arbeitsbereich'}
            name="name"
            autoFocusOnEmpty={true}
          />
          <ButtonsWrapper>
            <FormButton type="submit" variant="outlined" color="bronze">
              Raum erstellen
            </FormButton>
            <FormButton variant="text" color="default" onClick={onClose} isIgnoreValidation={true}>
              Abbrechen
            </FormButton>
          </ButtonsWrapper>
        </FormLayout>
      </Form>
    </Root>
  );
};

const validationSchema = Yup.object().shape({
  name: requiredString('Bitte geben Sie einen Raumnamen ein.')
    .min(2, 'Raumname ist zu kurz')
    .max(18, 'Raumname ist zu lang'),
});
