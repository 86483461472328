import { styled, css, applyCssIf, colors, button, keyframes } from '../../themes';
import { ButtonSize, ButtonColor, ButtonVariant, ICommonButtonProps } from './Button.types';

const isSmall = (size?: ButtonSize): boolean => size === 'small';
const isLarge = (size?: ButtonSize): boolean => size === 'large';

const isText = (variant?: ButtonVariant): boolean => variant === 'text';
const isContained = (variant?: ButtonVariant): boolean => variant === 'contained';
const isOutlined = (variant?: ButtonVariant): boolean => variant === 'outlined';

const isDefault = (color?: ButtonColor): boolean => color === 'default';
const isBronze = (color?: ButtonColor): boolean => color === 'bronze';
const isWhite = (color?: ButtonColor): boolean => color === 'white';

export interface IStyledButtonProps extends ICommonButtonProps {
  /**
   * The URL to link to when the button is clicked.
   * Used for Link component if there is 'href' prop in Button component.
   */
  to?: string;
}

export const StyledButton = styled.button<IStyledButtonProps>`
  // reset default button styles
  border: 0;
  outline: none;
  text-decoration: none;
  user-select: none;
  background-color: transparent;

  cursor: pointer;
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: 225px;
  height: 60px;
  padding: 0 20px;

  border-radius: 2px;
  box-sizing: border-box;

  ${button};

  & > span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    &:first-child {
      height: 27px;
      width: auto;
      margin-right: 16px;
    }
    &:last-child {
      height: 27px;
      width: auto;
      margin-left: 16px;
    }
  }
  ${({ variant }) =>
    applyCssIf(isText(variant))(css`
      min-width: auto;
    `)};

  ${({ size, variant }) =>
    applyCssIf(
      isSmall(size),
      !isText(variant),
    )(css`
      min-width: 230px;
      height: 42px;
      line-height: 1;
    `)};

  ${({ size, variant }) =>
    applyCssIf(
      isSmall(size),
      isText(variant),
    )(css`
      height: 42px;
      line-height: 1;
    `)};

  ${({ size, variant }) =>
    applyCssIf(
      isLarge(size),
      !isText(variant),
    )(css`
      min-width: 255px;
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isText(variant),
      isDefault(color),
    )(css`
      color: ${colors.black};

      &:hover,
      &.hover {
        color: ${colors.bronzeLight};
      }

      &:active,
      &.active {
        color: ${colors.bronzeDark};
      }

      &:disabled {
        pointer-events: none;
        color: ${colors.grayMedium};
      }
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isText(variant),
      isBronze(color),
    )(css`
      color: ${colors.bronze};

      &:hover,
      &.hover {
        color: ${colors.bronzeLight};
      }

      &:active,
      &.active {
        color: ${colors.bronzeDark};
      }

      &:disabled {
        pointer-events: none;
        color: ${colors.grayMedium};
      }
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isText(variant),
      isWhite(color),
    )(css`
      color: ${colors.white};

      &:hover,
      &.hover {
        color: ${colors.grayLightest};
      }

      &:active,
      &.active {
        color: ${colors.grayLighter};
      }

      &:disabled {
        pointer-events: none;
        color: ${colors.grayMedium};
      }
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isContained(variant),
      isDefault(color),
    )(css`
      color: ${colors.black};
      background-color: ${colors.white};
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);

      &:hover,
      &.hover {
        color: ${colors.bronzeLight};
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      }

      &:active,
      &.active {
        color: ${colors.bronzeDark};
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
      }

      &:disabled {
        pointer-events: none;
        color: ${colors.grayMedium};
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
      }
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isContained(variant),
      isBronze(color),
    )(css`
      color: ${colors.white};
      background-color: ${colors.bronze};

      &:hover,
      &.hover {
        background-color: ${colors.bronzeLight};
      }

      &:active,
      &.active {
        background-color: ${colors.bronzeDark};
      }

      &:disabled {
        pointer-events: none;
        background-color: ${colors.grayMedium};
      }
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isOutlined(variant),
      isDefault(color),
    )(css`
      color: ${colors.black};
      border: 2px solid ${colors.black};

      &:hover,
      &.hover {
        color: ${colors.bronzeLight};
        border-color: ${colors.bronzeLight};
      }

      &:active,
      &.active {
        color: ${colors.bronzeDark};
        border-color: ${colors.bronzeDark};
      }

      &:disabled {
        color: ${colors.grayMedium};
        border-color: ${colors.grayMedium};
        pointer-events: none;
      }
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isOutlined(variant),
      isBronze(color),
    )(css`
      color: ${colors.bronze};
      background-color: ${colors.white};
      border: 2px solid ${colors.bronze};

      &:hover,
      &.hover {
        color: ${colors.bronzeLight};
        border-color: ${colors.bronzeLight};
      }

      &:active,
      &.active {
        color: ${colors.bronzeDark};
        border-color: ${colors.bronzeDark};
      }

      &:disabled {
        color: ${colors.grayMedium};
        border-color: ${colors.grayMedium};
        pointer-events: none;
      }
    `)};

  ${({ variant, color }) =>
    applyCssIf(
      isOutlined(variant),
      isWhite(color),
    )(css`
      border: 2px solid ${colors.white};
      color: ${colors.white};
      background-color: transparent;
    `)};

  ${({ variant, color, isLoading }) =>
    applyCssIf(isContained(variant) && !isWhite(color) && !!isLoading)(css`
      &:disabled {
        background-color: ${colors.bronze};
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 3px;
        background-color: ${colors.white};
        animation: ${loadingAnimation} 2.16s cubic-bezier(1, 0.01, 0.73, 1) infinite;
      }
    `)}
`;

const loadingAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;
