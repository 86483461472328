import React from 'react';

import { CrossIcon } from '../../icons';
import { StyledCloseButton } from './CloseButton.styled';

export interface ICloseButtonProps {
  isTransparent?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
}

export const CloseButton: React.FC<ICloseButtonProps> = ({ isTransparent = false, onClick }) => {
  return (
    <StyledCloseButton
      variant="outlined"
      color="bronze"
      onClick={onClick}
      css={isTransparent && { backgroundColor: 'transparent' }}
    >
      <CrossIcon />
    </StyledCloseButton>
  );
};
