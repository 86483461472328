import { styled, h5, body, h1 } from '../../lib/themes';

export const ImprintStyled = styled.div``;

export const Content = styled.div`
  padding-top: 70px;
  padding-bottom: 154px;

  h1 {
    ${h1};
  }

  h5 {
    ${h5};
  }

  p {
    ${body};
  }
`;
