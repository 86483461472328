import { colors, styled, css, body, h3 } from '../../../../lib/themes';
import { Title } from '../../../../lib/components/Title';
import { ITypographyProps, Typography } from '../../../../lib/themes/typography/Typography';

export const ProductDetailsStyled = styled.div`
  max-width: 990px;
  padding-top: 27px;
  padding-bottom: 27px;
  ${body};
`;

export const Info = styled.div`
  position: relative;
  margin-right: 95px;
  margin-bottom: 100px;
  max-width: 570px;
`;

export const Tips = styled.div`
  position: absolute;
  right: 25px;
`;

export const ProductImage = styled.img`
  width: 520px;
  height: 520px;
`;

export const Subtitle = styled(Title)`
  margin-bottom: 30px;
  line-height: 1.25;
`;

export const Description = styled.div`
  ${body};
  margin-bottom: 8px;
  padding-right: 30px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 100px;
`;

export const DetailsTop = styled.div``;

export const DetailsBottom = styled.div`
  padding-bottom: 75px;
`;

export const Price = styled.h3`
  ${h3};
`;

export const Total = styled(Typography)<ITypographyProps>`
  color: ${colors.grayDark};
  margin-bottom: 60px;
`;

export const Amount = styled.div`
  margin-bottom: 20px;
  width: 250px;
  max-width: 100%;
`;

export const SelectedType = styled.div`
  margin-bottom: 46px;
`;

export const SelectedTypeTitle = styled.div`
  color: ${colors.grayMedium};
`;

export const SelectedTypeName = styled(Title)``;

export const Table = styled.table``;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableCol = styled.td`
  &:not(:last-child) {
    padding-right: 61px;
  }
`;
