import React from 'react';

export const SeatingFurnitureIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="59" height="65" viewBox="0 0 59 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.5642 42.6213H18.8322C15.9922 42.6213 12.8682 41.3206 10.7382 39.0083C9.17622 37.2741 8.32422 35.2508 8.32422 33.2276C8.32422 32.794 8.60822 32.3605 8.89222 32.216L10.1702 31.4934L14.1462 7.64784C14.1462 4.03489 17.1282 1 20.6782 1H37.5762C41.1262 1 44.1082 3.89037 44.1082 7.64784L48.0842 31.4934L49.3622 32.216C49.6462 32.3605 49.9302 32.794 49.9302 33.2276C50.0722 35.2508 49.2202 37.4186 47.5162 39.0083C45.5282 41.1761 42.4042 42.6213 39.5642 42.6213Z"
      fill="#F7F7F7"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path d="M51.2599 32.9102H8.32422" stroke="black" strokeMiterlimit="10" />
    <path d="M49.9453 32.9093H52.7201V20.3786C52.7201 19.6501 53.3042 19.213 53.8884 19.213H58.9998" fill="#F7F7F7" />
    <path
      d="M49.9453 32.9093H52.7201V20.3786C52.7201 19.6501 53.3042 19.213 53.8884 19.213H58.9998"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path d="M9.05445 32.7315H6.2797V20.2008C6.2797 19.4723 5.69554 19.0352 5.11139 19.0352H0" fill="#F7F7F7" />
    <path
      d="M9.05445 32.7315H6.2797V20.2008C6.2797 19.4723 5.69554 19.0352 5.11139 19.0352H0"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M36.51 46.2637H23.6586C22.9284 46.2637 22.4902 45.9723 22.4902 45.5352V43.3496C22.4902 42.9125 23.0744 42.6211 23.6586 42.6211H36.51C37.2402 42.6211 37.6784 42.9125 37.6784 43.3496V45.5352C37.8244 45.8266 37.2402 46.2637 36.51 46.2637Z"
      fill="#F7F7F7"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M30.0834 53.9869C29.3532 53.9869 28.915 53.5498 28.915 53.1126V47.2844C28.915 46.7016 29.4992 46.4102 30.0834 46.4102C30.8136 46.4102 31.2517 46.8473 31.2517 47.2844V53.1126C31.3977 53.5498 30.8136 53.9869 30.0834 53.9869Z"
      fill="#F7F7F7"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M46.732 64.1858V61.2717L31.2518 57.0462C30.6677 56.9005 30.3756 56.4633 30.3756 55.8805V53.2578H30.0835V55.8805C30.0835 56.4633 29.6454 56.9005 29.2073 57.0462L13.5811 61.2717V64.1858"
      fill="#F7F7F7"
    />
    <path
      d="M46.732 64.1858V61.2717L31.2518 57.0462C30.6677 56.9005 30.3756 56.4633 30.3756 55.8805V53.2578H30.0835V55.8805C30.0835 56.4633 29.6454 56.9005 29.2073 57.0462L13.5811 61.2717V64.1858"
      stroke="black"
      strokeMiterlimit="10"
    />
  </svg>
);
