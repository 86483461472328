import React from 'react';
import { RoomConfiguratorStep, RoomItemsStep, RoomParamsStep } from '../../typesUtils';
import { Header } from '../Header';
import { RoomConfigRules } from '../../roomConfigRules';
import { Room } from '../../../../lib/core/api/generated';
import { useIsInvitedUser } from '../../../../lib/core/hooks/useIsInvitedUser';

export interface IConfiguratorPageHeaderProps {
  step: RoomParamsStep | RoomItemsStep;
  changeStep: (newStep: RoomParamsStep | RoomItemsStep) => unknown;
  room: Room;
}

/**
 * Common component for the room viewing and editing page,
 * shows possible steps and current, responsible for navigation between steps
 */
export const ConfiguratorPageHeader: React.FC<IConfiguratorPageHeaderProps> = ({ step, changeStep, room }) => {
  const isInvitedUser = useIsInvitedUser();
  const possibleSteps = RoomConfigRules.possibleRoomSteps(room.planMode);
  const availableSteps = RoomConfigRules.availableRoomSteps(room, isInvitedUser);

  const navigationItems = React.useMemo(() => {
    return RoomConfigRules.stepsToTabs(possibleSteps, availableSteps);
  }, [possibleSteps, availableSteps]);

  const onChangeTab = React.useCallback((value: RoomConfiguratorStep) => changeStep(value.step), [changeStep]);
  // const tabs = step === RoomParamsStep.PlanType ? [] : navigationItems;
  const tabs = navigationItems;

  const activeStep = tabs.find(tab => tab?.value?.step === step);
  return <Header tabs={tabs} value={activeStep && activeStep.value} onChange={onChangeTab} room={room} />;
};
