import React, { useCallback } from 'react';

import { Slider } from '../../../../../lib/components/Swiper';
import { Moodboard, MoodboardImage, MoodboardImageDescription } from '../../../../../lib/core/api/generated';
import { SideSheet } from '../../../../../lib/components/SideSheet';

import { Description, FabStyled, RoomPicture, RoomPictureWrapper } from './MoodboardSlider.styled';

import officePng from '../../../../../static/img/mocks/plan-for-me/office.png';

interface MoodboardSliderProps {
  moodboard?: Moodboard | null;
}

const getSelector = (prefix: string, id: number) => `${prefix}-${id}`;
const descriptionPrefix = 'description';

export const MoodboardSlider: React.FC<MoodboardSliderProps> = ({ moodboard }) => {
  const moodboardSlider = React.useRef<{ swiper: any }>();

  const [isShowTip, setIsShowTip] = React.useState(false);
  const [selectedDescriptionId, selectDescriptionId] = React.useState<number>(0);
  const [selectedImageId, selectImageId] = React.useState<number>(0);

  const handleOnCloseTip = useCallback(() => {
    setIsShowTip(false);
  }, []);

  const handleOnShowTip = useCallback(
    (imageId: number, descriptionId: number) => () => {
      setIsShowTip(true);
      selectDescriptionId(descriptionId);
      selectImageId(imageId);
    },
    [],
  );

  const calcPercentPosition = (size, coordinate) => (coordinate / size) * 100;

  const renderMoodboardImage = useCallback(
    (image: MoodboardImage, imageId) => {
      const [imageWidth, imageHeight] = image.size;

      return (
        <RoomPictureWrapper key={imageId}>
          <RoomPicture src={image.url} />
          {image.descriptions.map(({ coordinates: [left, top] }, descriptionId) => (
            <FabStyled
              positionX={calcPercentPosition(imageWidth, left)}
              positionY={calcPercentPosition(imageHeight, top)}
              variant="plus"
              onClick={handleOnShowTip(imageId, descriptionId)}
              key={descriptionId}
            />
          ))}
        </RoomPictureWrapper>
      );
    },
    [handleOnShowTip],
  );

  const renderSideSheetContent = useCallback(
    ({ contentType, contentHtml, contentText, style }: MoodboardImageDescription, index) => {
      const isContentHtml = contentType === 'html';

      return (
        <React.Fragment key={index}>
          <Description
            id={getSelector(descriptionPrefix, index)}
            dangerouslySetInnerHTML={{
              __html: isContentHtml ? contentHtml : contentText,
            }}
          />

          {/* TODO What about the scope of style ?*/}
          {!isContentHtml && <style>{style}</style>}
        </React.Fragment>
      );
    },
    [],
  );

  return moodboard ? (
    <>
      <Slider
        ref={moodboardSlider}
        direction="horizontal"
        effect="slide"
        slidesPerView={1}
        autoHeight={true}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        bulletsColor="white"
        isNavigation={true}
      >
        {moodboard.images.map(renderMoodboardImage)}
      </Slider>
      <SideSheet
        onClose={handleOnCloseTip}
        isOpened={isShowTip}
        scrollToId={[descriptionPrefix, selectedDescriptionId]}
      >
        <div
          css={`
            margin: 48px 48px 0 0;
          `}
        >
          {moodboard.images[selectedImageId]?.descriptions.map(renderSideSheetContent)}
        </div>
      </SideSheet>
    </>
  ) : (
    renderMoodboardImage({ url: officePng, descriptions: [], size: [] }, 1)
  );
};
