import React from 'react';

export const SmallLogoIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.5264 9.70119V14.9327H11.8739V17.6402H3.57239V24.2255H0.375977V7.0166H12.9317V9.70119H3.5264Z"
      fill="currentColor"
    />
    <path d="M12.9317 0.568359H0.375977V2.33514H12.9317V0.568359Z" fill="currentColor" />
  </svg>
);
