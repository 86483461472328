import React from 'react';
import { StyledImage } from './Image.styled';

export interface IImageProps {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  className?: string;
}

export const Image: React.FC<IImageProps> = props => {
  const { src, alt, width = '100%', height = 'auto', minWidth = 'auto', minHeight = 'auto', className = '' } = props;

  return (
    <StyledImage width={width} height={height} minWidth={minWidth} minHeight={minHeight} className={className}>
      <img src={src} alt={alt} />
    </StyledImage>
  );
};
