import React from 'react';
import { useField, useFormikContext } from 'formik';

import { Container } from './ImageCardsFormField.styled';

export interface IImageCard {
  component: React.FC<any>;
  value: string;
}

interface IImageCardsFormFieldProps {
  name: string;
  imageCards: IImageCard[];
  onChange: () => unknown;
}

export const ImageCardsFormField: React.FC<IImageCardsFormFieldProps> = props => {
  const { name, imageCards, onChange } = props;

  const [{ value: currentValue }] = useField<string>(name);
  const { setFieldValue } = useFormikContext<any>();

  const handleOnClick = React.useCallback(
    value => (event: React.MouseEvent<HTMLDivElement>) => {
      setFieldValue(name, value);
      // I do know why we should wait
      setTimeout(() => {
        onChange();
      }, 0);
    },
    [name, onChange, setFieldValue],
  );

  return (
    <Container>
      {imageCards.map(({ component, value }) => {
        const isActive = currentValue === value;
        return React.createElement(component, { onClick: handleOnClick(value), key: `${name}${value}`, isActive });
      })}
    </Container>
  );
};
