import { styled, applyCssIf, css, colors, h3, h5 } from '../../themes';

type FabVariant = 'plus' | 'info';

const isPlus = (variant: FabVariant): boolean => variant === 'plus';
const isInfo = (variant: FabVariant): boolean => variant === 'info';

export interface IFabStyledProps {
  variant: FabVariant;
}

export const FabStyled = styled.button<IFabStyledProps>`
  all: unset;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  padding: 5px;

  ${h3};
  text-align: center;
  color: ${colors.white};

  border: 1px solid ${colors.bronze};
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;

  transition: all 0.15s;

  cursor: pointer;

  &:hover {
    color: ${colors.bronzeLight};

    border: 2px solid ${colors.bronzeLight};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  ${({ variant }) =>
    applyCssIf(isPlus(variant))(css`
      ::before {
        content: '';
        position: absolute;
        left: 5px;
        top: 5px;

        width: calc(100% - 10px);
        height: calc(100% - 10px);

        background-color: ${colors.bronze};
        border-radius: 50%;

        transition: all 0.15s;

        z-index: -1;
      }

      :hover {
        ::before {
          background-color: transparent;
        }
      }
    `)};

  ${({ variant }) =>
    applyCssIf(isInfo(variant))(css`
      width: 40px;
      height: 40px;

      ${h5};
      color: ${colors.bronze};

      background-color: ${colors.white};
      border: 2px solid ${colors.bronze};
    `)};
`;
