import { styled, colors } from '../../themes';

export const StyledMenu = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  background-color: ${colors.white};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
`;
