import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireFloorTypeRepository, RequireLog } from '../available-stores';
import { gqlDocsRoom } from '../gql-documents/gql-room';
import { FloorType, Mutation, Query } from '../api/generated';
import { useStore, UseQueryResult, useQuery } from '../hooks/use-store';

export class FloorTypeRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'FloorTypeRepository');
  }

  /**
   * Request and return room design list
   */
  getList = (): Promise<FloorType[]> => {
    return this.query(gqlDocsRoom.floorTypeList, 'floorTypes');
  };
}

/**
 * Syntax sugar to obtain FloorType list
 */
export function useFloorTypeList(): UseQueryResult<void, FloorType[]> {
  return useQuery(useStore<RequireFloorTypeRepository>('FloorTypeRepository').getList, undefined);
}
