import { gql } from '@apollo/client';

export const gqlSearchProducts = {
  searchProducts: gql`
    query searchProducts($categoryId: String!, $page: Int!, $pageSize: Int!) {
      searchProducts(
        input: { criteria: [{ attributeName: "category_id", value: $categoryId }], page: $page, pageSize: $pageSize }
      ) {
        result {
          id
          sku
          name
          price
          imageUrls
          description
          hint
          configurableProduct {
            productConfigurations {
              id
              sku
              name
              price
              imageUrls
              description
              hint
              width
              depth
              height
              heightAdjustable
              heightMin
              heightMax
              categoryIds
            }
          }
          width
          depth
          height
          heightAdjustable
          heightMin
          heightMax
          categoryIds
        }
        pageSize
        total
        count
        hasNext
      }
    }
  `,
};
