import { CoreError } from './core-error';

/**
 * When something happened on API
 */
export class ApiError extends CoreError {
  static readonly status: number = 0;

  constructor(protected httpStatus: number, protected description: string) {
    super(`${httpStatus} ${description}`);
  }

  getErrorType() {
    return 'ApiError';
  }

  getHttpStatus() {
    return this.httpStatus;
  }
}
