import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireLog, RequireWorkUnitsRepository } from '../available-stores';
import { gqlDocsRoom } from '../gql-documents/gql-room';
import { Mutation, Query, WorkUnit } from '../api/generated';
import { useStore, UseQueryResult, useQuery } from '../hooks/use-store';

export class WorkUnitsRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'WorkUnitsRepository');
  }

  /**
   * Request and return workUnits list
   */
  getList = (): Promise<WorkUnit[]> => {
    return this.query(gqlDocsRoom.workUnits, 'workUnits');
  };
}

/**
 * Syntax sugar to obtain workUnits list
 */
export function useWorkUnitsList(): UseQueryResult<void, WorkUnit[]> {
  return useQuery(useStore<RequireWorkUnitsRepository>('WorkUnitsRepository').getList, undefined);
}
