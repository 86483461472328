import React from 'react';

export const WhiteboardIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="32" height="56" viewBox="0 0 32 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5645 55.2047L22.9719 31.0935L23.9543 30.9064L28.5469 55.0175L27.5645 55.2047Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1765 31.0935L5.58387 55.2047L4.60153 55.0175L9.19412 30.9064L10.1765 31.0935Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0743 3.53715V0H17.0743V3.53715H16.0743Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5 41.7611V29.8946H16.5V41.7611H15.5Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.05841 4.2084C2.88933 4.30956 2.86466 4.39851 2.86466 4.44968V4.46848L1.00049 29.2221C1.00445 29.2727 1.03534 29.3765 1.19942 29.5371C1.23526 29.5722 1.32436 29.6271 1.47458 29.6724C1.61745 29.7154 1.77282 29.7367 1.8985 29.7367H30.1015C30.4932 29.7367 30.6947 29.6408 30.8006 29.5371L30.8582 29.4808L30.9197 29.4506C30.9203 29.45 30.9208 29.4494 30.9215 29.4486C30.9301 29.4384 30.9434 29.4188 30.9573 29.3882C30.9842 29.3289 30.9972 29.2646 30.9996 29.2231L29.1353 4.46848V4.44968C29.1353 4.45461 29.1353 4.45444 29.1333 4.44988C29.1259 4.43226 29.0908 4.34902 28.925 4.24082C28.7402 4.12021 28.4849 4.03687 28.2368 4.03687H3.76316C3.48631 4.03687 3.22919 4.10621 3.05841 4.2084ZM2.54497 3.35027C2.89863 3.13867 3.34076 3.03687 3.76316 3.03687H28.2368C28.688 3.03687 29.132 3.18173 29.4716 3.40342C29.7825 3.60637 30.1247 3.95828 30.1351 4.42762L32 29.191V29.2098C32 29.3981 31.9544 29.611 31.8679 29.8016C31.7924 29.9677 31.6615 30.1693 31.4472 30.3011C31.0919 30.6168 30.6161 30.7367 30.1015 30.7367H1.8985C1.67455 30.7367 1.42202 30.7009 1.18613 30.6299C0.957594 30.561 0.697068 30.4448 0.49983 30.2517C0.212357 29.9702 0 29.6213 0 29.2098V29.191L1.86484 4.42847C1.87355 3.92033 2.19447 3.55998 2.54497 3.35027Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8576 38.1534H7.25983V37.1534H24.8576V38.1534Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5634 27.1054C10.561 27.1117 10.5562 27.1275 10.5562 27.1562V29.6664C10.5562 29.6951 10.561 29.7109 10.5634 29.7171C10.5657 29.7228 10.567 29.7236 10.5662 29.7228C10.567 29.7234 10.5699 29.7255 10.5765 29.728C10.586 29.7316 10.6059 29.7369 10.6389 29.7369H21.011C21.044 29.7369 21.0639 29.7316 21.0734 29.728C21.08 29.7255 21.0829 29.7234 21.0837 29.7228C21.0829 29.7236 21.0843 29.7228 21.0865 29.7171C21.0889 29.7109 21.0937 29.6951 21.0937 29.6664V27.1562C21.0937 27.1275 21.0889 27.1117 21.0865 27.1054C21.0843 27.0997 21.0829 27.099 21.0837 27.0998C21.0829 27.0992 21.08 27.0971 21.0734 27.0946C21.0639 27.091 21.044 27.0857 21.011 27.0857H10.6389C10.6059 27.0857 10.586 27.091 10.5765 27.0946C10.5698 27.0971 10.5669 27.0992 10.5662 27.0998C10.5658 27.1004 10.5648 27.102 10.5634 27.1054ZM9.8666 26.3853C10.0756 26.1807 10.3536 26.0857 10.6389 26.0857H21.011C21.2963 26.0857 21.5743 26.1807 21.7833 26.3853C21.9932 26.5908 22.0937 26.8682 22.0937 27.1562V29.6664C22.0937 29.9544 21.9932 30.2318 21.7833 30.4373C21.5743 30.6419 21.2963 30.7369 21.011 30.7369H10.6389C10.3536 30.7369 10.0756 30.6419 9.8666 30.4373C9.65668 30.2318 9.55615 29.9544 9.55615 29.6664V27.1562C9.55615 26.8682 9.65668 26.5908 9.8666 26.3853Z"
      fill="currentColor"
    />
  </svg>
);
