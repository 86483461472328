import { styled, body, h4, colors, button } from '../../../../../../../../lib/themes';

export const ToggleButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  legend {
    margin-bottom: 28px;
    ${body}
  }
`;

export const ToggleButtonContent = styled.div`
  justify-content: center;
  align-items: center;
  ${h4};
  color: ${colors.black};
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  cursor: pointer;

  svg {
    margin-right: 20px;
  }

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  }

  display: block;
  user-select: none;

  height: 42px;
  padding: 0 30px;
  margin-bottom: 19px;

  ${button};
  text-align: center;

  border-radius: 2px;
  box-shadow: none;

  &:hover {
    color: ${colors.bronzeLight};
    border-color: ${colors.bronzeLight};
    box-shadow: none;
  }
`;

export const ToggleButtonWrapper = styled.label`
  input {
    opacity: 0;
    display: none;
  }

  input:checked + ${ToggleButtonContent} {
    border: 2px solid ${colors.bronze};
  }
`;
