import { styled, css, applyCssIf, colors, h4 } from '../../themes';

interface ISmartTipStyledProps {
  fill: boolean;
  isDialog: boolean;
}

export const SmartTipStyled = styled.div<ISmartTipStyledProps>`
  position: relative;

  padding: 20px 50px;

  background: ${colors.white};
  box-shadow: 0 2px 16px rgba(66, 93, 134, 0.25);

  width: 326px;

  ${({ fill }) =>
    applyCssIf(fill)(css`
      width: 100%;
    `)};

  &:before {
    content: '!';

    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: center;
    align-items: center;

    ${h4};
    text-align: center;
    color: ${colors.white};

    width: 48px;
    height: 48px;

    background-color: ${colors.bronze};
    border-radius: 50%;

    z-index: 1;
  }

  ${({ isDialog }) =>
    applyCssIf(isDialog)(css`
      &:before {
        top: 10px;
        transform: translateX(-50%);
      }

      ${CloseButton} {
        top: 20px;
        right: 20px;
        transform: none;
      }
    `)}
`;

export const CloseButton = styled.button`
  appearance: none;

  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;
  padding: 5px;

  color: ${colors.gray};

  border: none;
  background: transparent;
`;
