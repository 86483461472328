import React from 'react';

export const BenchPlusIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="41" height="37" viewBox="0 0 41 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.6 13.5H2.29999C0.999988 13.5 0 14.5 0 15.8V20.1C0 21.4 0.999988 22.4 2.29999 22.4H5L2 35.8C2 36.2 2.20004 36.5 2.60004 36.5H2.70001C3.00001 36.5 3.29999 36.3 3.29999 35.9L6.29999 22.4H19.6L22.6 35.9C22.6 36.2 22.9 36.5 23.2 36.5H23.3C23.7 36.5 23.9 36.1 23.9 35.8L20.9 22.4H23.6C24.9 22.4 25.9 21.4 25.9 20.1V15.8C25.9 14.6 24.9 13.5 23.6 13.5ZM24.6 20.2C24.6 20.8 24.1 21.2 23.6 21.2H2.29999C1.69999 21.2 1.29999 20.7 1.29999 20.2V15.9C1.29999 15.3 1.79999 14.9 2.29999 14.9H23.6C24.2 14.9 24.6 15.4 24.6 15.9V20.2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5 0C31.7761 0 32 0.223858 32 0.5V7.5H40C40.2761 7.5 40.5 7.72386 40.5 8C40.5 8.27614 40.2761 8.5 40 8.5H32V15.5C32 15.7761 31.7761 16 31.5 16C31.2239 16 31 15.7761 31 15.5V8.5H23C22.7239 8.5 22.5 8.27614 22.5 8C22.5 7.72386 22.7239 7.5 23 7.5H31V0.5C31 0.223858 31.2239 0 31.5 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 11.5C4 10.1193 5.11929 9 6.5 9H19.5C20.8807 9 22 10.1193 22 11.5V13.5C22 13.7761 21.7761 14 21.5 14C21.2239 14 21 13.7761 21 13.5V11.5C21 10.6716 20.3284 10 19.5 10H6.5C5.67157 10 5 10.6716 5 11.5V13.5C5 13.7761 4.77614 14 4.5 14C4.22386 14 4 13.7761 4 13.5V11.5Z"
      fill="currentColor"
    />
  </svg>
);
