import { styled, css, colors, h5 } from '../../themes';

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  margin-bottom: 30px;
  width: 364px;
  height: 280px;
`;

type DescriptionProp = {
  center?: boolean;
};

export const Description = styled.p<DescriptionProp>`
  ${h5};
  margin-bottom: 40px;

  ${p =>
    p.center &&
    css`
      margin-bottom: 0;
      text-align: center;
    `}
`;
