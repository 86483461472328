import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireLog, RequireRoomTypeRepository } from '../available-stores';
import { gqlDocsRoom } from '../gql-documents/gql-room';
import { Mutation, Query, RoomType } from '../api/generated';
import { useMutation, useQuery, UseQueryResult, useStore } from '../hooks/use-store';

export class RoomTypeRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'RoomTypeRepository');
  }

  /**
   * Request and return room type list
   */
  getList = (): Promise<RoomType[]> => {
    return this.query(gqlDocsRoom.roomTypeList, 'roomTypes');
  };
}

/**
 * Syntax sugar to obtain RoomType list
 */
export function useRoomTypeList(): UseQueryResult<void, RoomType[]> {
  return useQuery(useStore<RequireRoomTypeRepository>('RoomTypeRepository').getList, undefined);
}
