import React from 'react';

export const SquareArmchairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43.2 11.2002H37.1V3.0002C37.1 1.4002 35.8 0.200195 34.3 0.200195H9.99997C8.39997 0.200195 7.19998 1.5002 7.19998 3.0002V11.1002H1.29996C1.09996 11.1002 0.999957 11.2002 0.799957 11.3002C0.699957 11.4002 0.599976 11.6002 0.599976 11.8002V28.7002C0.599976 29.1002 0.899982 29.3002 1.19998 29.3002H8.39996V34.1002C8.39996 34.5002 8.69997 34.7002 8.99997 34.7002H17C17.4 34.7002 17.6 34.4002 17.6 34.1002V29.3002H26.2V34.1002C26.2 34.5002 26.5 34.7002 26.8 34.7002H34.8C35.2 34.7002 35.4 34.4002 35.4 34.1002V29.3002H42.8C43.2 29.3002 43.4 29.0002 43.4 28.7002V11.8002C43.9 11.5002 43.6 11.2002 43.2 11.2002ZM8.49997 3.0002C8.49997 2.2002 9.19997 1.5002 9.99997 1.5002H34.3C35.1 1.5002 35.8 2.2002 35.8 3.0002V21.5002H8.49997V3.0002ZM16.5 33.5002H9.79996V29.4002H16.5V33.5002ZM34.5 33.5002H27.8V29.4002H34.5V33.5002ZM42.6 28.0002H35.2H27.2H17.3H9.29996H2.09998V12.4002H7.29996V22.1002C7.29996 22.5002 7.59996 22.7002 7.89996 22.7002H36.5C36.9 22.7002 37.1 22.4002 37.1 22.1002V12.4002H42.6V28.0002Z"
      fill="currentColor"
    />
  </svg>
);
