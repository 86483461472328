import React from 'react';

export const DoubleHeadFloorLampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="25" height="49" viewBox="0 0 25 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.9 1.19962C24.1 0.999615 24.1 0.699603 24 0.499603C23.9 0.299603 23.7 0.0996094 23.4 0.0996094H10.4C10.2 0.0996094 9.90002 0.199603 9.80002 0.499603C9.70002 0.699603 9.70002 0.999615 9.90002 1.19962L14.8 7.59961L14.9 7.69962C14.7 7.99962 14.6 8.39959 14.6 8.79959C14.6 9.79959 15.3 10.6996 16.2 10.9996V18.2996C14.9 16.4996 12.8 15.2996 10.5 15.2996H8.00003C7.70003 14.6996 7.10002 14.1996 6.40002 14.1996C6.10002 14.1996 5.90004 14.2996 5.70004 14.3996L1.50003 11.1996C1.30003 10.9996 1.00002 10.9996 0.800018 11.0996C0.600018 11.1996 0.400024 11.3996 0.400024 11.6996V20.2996C0.400024 20.4996 0.500018 20.7996 0.800018 20.8996C0.900018 20.8996 1.00004 20.9996 1.10004 20.9996C1.20004 20.9996 1.40003 20.9996 1.50003 20.8996L5.70004 17.6996C5.90004 17.7996 6.20002 17.8996 6.40002 17.8996C7.10002 17.8996 7.70003 17.4996 8.00003 16.7996H10.5C13.6 16.7996 16.2 19.3996 16.2 22.4996V46.6996H13C12.6 46.6996 12.4 46.9996 12.4 47.3996C12.4 47.7996 12.7 48.0996 13 48.0996H20.8C21.2 48.0996 21.4 47.7996 21.4 47.3996C21.4 46.9996 21.1 46.6996 20.8 46.6996H17.5V10.9996C18.4 10.6996 19.1 9.89959 19.1 8.79959C19.1 8.39959 19 7.99962 18.8 7.69962L18.9 7.59961L23.9 1.19962ZM1.80002 13.0996L4.80002 15.3996C4.70002 15.5996 4.70004 15.7996 4.70004 15.9996C4.70004 16.1996 4.70002 16.3996 4.80002 16.5996L1.80002 18.8996V13.0996ZM6.40002 16.4996C6.20002 16.4996 6.00003 16.2996 6.00003 16.0996C6.00003 15.8996 6.20002 15.6996 6.40002 15.6996C6.60002 15.6996 6.80002 15.8996 6.80002 16.0996C6.90002 16.2996 6.70002 16.4996 6.40002 16.4996ZM11.7 1.4996H22L17.9 6.79959C17.6 6.59959 17.2 6.4996 16.8 6.4996C16.4 6.4996 16.1 6.59959 15.7 6.79959L11.7 1.4996ZM16.9 9.79959C16.4 9.79959 15.9 9.39959 15.9 8.79959C15.9 8.29959 16.3 7.79959 16.9 7.79959C17.4 7.79959 17.9 8.19959 17.9 8.79959C17.9 9.39959 17.4 9.79959 16.9 9.79959Z"
      fill="currentColor"
    />
  </svg>
);
