import React from 'react';

export const SmallTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M57.6 16H8.29999C6.99999 16 6 17 6 18.3V22.6C6 23.9 6.99999 24.9 8.29999 24.9H11L8 48.3C8 48.7 8.20004 49 8.60004 49H8.70001C9.00001 49 9.29999 48.8 9.29999 48.4L12.3 24.9H53.6L56.6 48.4C56.6 48.7 56.9 49 57.2 49H57.3C57.7 49 57.9 48.6 57.9 48.3L54.9 24.9H57.6C58.9 24.9 59.9 23.9 59.9 22.6V18.3C59.9 17.1 58.9 16 57.6 16ZM58.6 22.7C58.6 23.3 58.1 23.7 57.6 23.7H8.29999C7.69999 23.7 7.29999 23.2 7.29999 22.7V18.4C7.29999 17.8 7.79999 17.4 8.29999 17.4H57.6C58.2 17.4 58.6 17.9 58.6 18.4V22.7Z"
      fill="currentColor"
    />
  </svg>
);
