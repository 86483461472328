import { styled } from '../../themes';
import { Button } from '../Button';

export const StyledCloseButton = styled(Button)`
  min-width: 48px;
  width: 48px;
  height: 48px;

  padding: 0;
  border-radius: 50%;

  svg {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
  }
`;
