import React from 'react';

import { CloseButton } from '../CloseButton';
import { IJumbotronStyledProps, Root, CloseButtonWrapper } from './Jumbotron.styled';

type IJumbotronProps = Partial<IJumbotronStyledProps>;

export const Jumbotron: React.FC<IJumbotronProps> = ({ isClosable = false, children }) => {
  const [isClosed, setClosed] = React.useState(false);

  const handleClose = React.useCallback(() => setClosed(true), []);

  return !isClosed ? (
    <Root isClosable={isClosable}>
      {isClosable && (
        <CloseButtonWrapper>
          <CloseButton isTransparent={true} onClick={handleClose} />
        </CloseButtonWrapper>
      )}
      {children}
    </Root>
  ) : null;
};
