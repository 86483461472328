import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, Modal, Page, PageFooter, PageHeaderNormal, PageSection } from '../../../../lib/components';

import { FeaturedBanner, InteriorConsultant, WhatStylesDoWeOffer } from '../../components';

import { AuthFormWindow, AuthStatus } from '../../../auth/containers/AuthFormWindow';
import { AuthModal } from '../../../auth/containers/AuthModal';
import { Brands } from '../../components/Brands';
import { DesignStyles } from '../../components/DesignStyles/DesignStyles';
import { InvitedUserRegistrationForm } from '../../../auth/components/InvitedUserRegistrationForm';
import { PromoBannerLazy } from '../../components/PromoBanner/PromoBannerLazy';
import { RequireUserRepository } from '../../../../lib/core/available-stores';
import { WhatSpeaksForFurnished } from '../../components/WhatSpeaksForFurnished';
import { useCommonStorageValue } from '../../../../lib/core/common-store-settings';
import { useStore } from '../../../../lib/core/hooks/use-store';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const HomeDesktop = () => {
  // TODO not obvious what is token. Need to use another invitation url
  const query = useQuery();
  const inviteToken: string | undefined = query.get('token') || undefined;
  const userRepository = useStore<RequireUserRepository>('UserRepository');
  const [showAuthFormForInvitedUser, setShowAuthFormForInvitedUser] = React.useState(Boolean(inviteToken));

  const [isOpenBanner, setOpenBanner] = React.useState(false);
  const [isNotFirstVisit, setIsNotFirstVisit] = useCommonStorageValue('isNotFirstVisit');

  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState<boolean>(false);

  const handleShowAuthFormWindow = React.useCallback(() => {
    handleCloseBanner();
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  React.useEffect(() => {
    if (!isNotFirstVisit) {
      setIsNotFirstVisit(true);
      setOpenBanner(true);
    }
  }, [isOpenBanner, isNotFirstVisit, setIsNotFirstVisit]);

  const handleCloseAuthFormForInvitedUser = useCallback(() => {
    setShowAuthFormForInvitedUser(false);
  }, []);

  const handleAfterSubmit = useCallback(() => {
    handleCloseAuthFormForInvitedUser();
    userRepository.routeAfterSignIn();
  }, [userRepository, handleCloseAuthFormForInvitedUser]);

  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  return (
    <Page>
      <PageHeaderNormal />

      <AuthModal
        isOpened={showAuthFormForInvitedUser}
        title="Registrierung"
        onClose={handleCloseAuthFormForInvitedUser}
      >
        <InvitedUserRegistrationForm
          onCancel={handleCloseAuthFormForInvitedUser}
          inviteToken={inviteToken}
          afterSubmit={handleAfterSubmit}
        />
      </AuthModal>

      {showAuthFormWindow && (
        <AuthFormWindow initialAuthStatus={AuthStatus.Registration} onClose={handleCloseAuthFormWindow} />
      )}

      <PageSection noTopMargin={true}>
        <FeaturedBanner />
      </PageSection>

      {/*<PageSection>*/}
      {/*  <Container>*/}
      {/*    <VideoPlayer srcList={[{ src: introductionMp4, type: 'video/mp4' }]} autoPlay={true} />*/}
      {/*  </Container>*/}
      {/*</PageSection>*/}

      <PageSection>
        <Container>
          <InteriorConsultant />
        </Container>
      </PageSection>

      <PageSection>
        <WhatStylesDoWeOffer />
      </PageSection>

      <PageSection>
        <Container>
          <WhatSpeaksForFurnished />
        </Container>
      </PageSection>

      <PageSection>
        <DesignStyles />
      </PageSection>

      {/*
      <PageSection>
        <Brands />
      </PageSection>
      */}

      {/*<PageSection noBottomMargin={true}>*/}
      {/*  <Container>*/}
      {/*    <ChooseRoomPackage />*/}
      {/*  </Container>*/}
      {/*</PageSection>*/}

      {/*<PageSection title="Oder gestalten Sie einen Raum kostenlos für 3 Tage">*/}
      {/*  <FreeTrialBlock />*/}
      {/*</PageSection>*/}

      {isOpenBanner && (
        <Modal
          isOpened={isOpenBanner}
          onClose={handleCloseBanner}
          colorButton="white"
          width="1038px"
          height="656px"
          noPadding={true}
        >
          <PromoBannerLazy onClickButtonSlide={handleShowAuthFormWindow} />
        </Modal>
      )}

      <PageFooter />
    </Page>
  );
};

export default HomeDesktop;
