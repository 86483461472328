import React, { useState, ReactNode } from 'react';
import { SnackBarToastOptionsType, ToastType, SnackBarState } from './types';
import { SnackBar } from './SnackBar';

const SnackbarContext = React.createContext<any>(undefined);
const { Provider } = SnackbarContext;

const SnackbarProvider = (props: { children?: ReactNode }) => {
  const add = (message: string, options: SnackBarToastOptionsType): number => {
    let uniqueId;

    setState((curState: SnackBarState) => {
      if (curState.toasts.find(toast => toast.message === message && toast.options.type === options.type)) {
        return curState;
      }

      uniqueId = curState.idCounter + 1;

      const toast: ToastType = {
        message,
        id: uniqueId,
        options: options || {},
      };

      return {
        ...curState,
        idCounter: uniqueId,
        toasts: [...curState.toasts, toast],
      };
    });

    return uniqueId;
  };

  const remove = (id: number) => {
    setState(curState => {
      //remove the toast message using unique id
      const toasts = curState.toasts.filter((toast: ToastType) => toast.id !== id);
      return {
        ...curState,
        toasts: toasts,
      };
    });
  };

  const removeAll = () => {
    setState(curState => {
      return {
        ...curState,
        toasts: [],
      };
    });
  };

  const [state, setState] = useState<SnackBarState>({
    toasts: [],
    add,
    remove,
    removeAll,
    idCounter: 0,
  });

  return (
    <Provider value={state}>
      {props.children}
      <SnackBar toasts={state.toasts} dismiss={remove} />
    </Provider>
  );
};

export { SnackbarContext, SnackbarProvider };
