import React from 'react';

export const LogoIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="170" height="24" viewBox="0 0 170 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.15042 9.13259V14.3641H11.4979V17.0716H3.19641V23.6569H0V6.448H12.5557V9.13259H3.15042Z"
      fill="currentColor"
    />
    <path
      d="M17.9367 16.1304V6.42456H21.1101V15.9927C21.1101 19.5263 22.7198 21.0407 25.5023 21.0407C28.2848 21.0407 29.8485 19.5492 29.8485 15.9927V6.42456H32.9989V16.1304C32.9989 21.1324 30.1705 23.8859 25.4793 23.8859C20.7422 23.8629 17.9367 21.1324 17.9367 16.1304Z"
      fill="currentColor"
    />
    <path
      d="M51.211 23.6105L47.6926 18.5626C47.4627 18.5855 47.2327 18.5855 47.0258 18.5855H43.1395V23.5876H39.9661V6.42456H47.0258C51.5559 6.42456 54.3614 8.71908 54.3614 12.528C54.3614 15.1208 53.0277 17.0482 50.7051 17.966L54.6604 23.6105H51.211ZM46.8878 9.15504H43.1625V16.0157H46.8878C49.6933 16.0157 51.119 14.7307 51.119 12.5968C51.119 10.417 49.6933 9.15504 46.8878 9.15504Z"
      fill="currentColor"
    />
    <path
      d="M75.6794 6.448V23.6339H73.0579L63.5606 12.0007V23.6339H60.3872V6.448H63.0087L72.506 18.0812V6.448H75.6794Z"
      fill="currentColor"
    />
    <path d="M82.7382 6.448H85.9116V23.6339H82.7382V6.448Z" fill="currentColor" />
    <path
      d="M91.6149 21.8212L92.7187 19.3432C94.0984 20.4445 96.306 21.2476 98.3757 21.2476C101.066 21.2476 102.193 20.3069 102.193 18.999C102.193 15.3048 91.9598 17.714 91.9598 11.3353C91.9598 8.58186 94.1904 6.21851 98.8816 6.21851C100.928 6.21851 103.09 6.76919 104.631 7.70994L103.619 10.188C102.055 9.29316 100.353 8.83426 98.8816 8.83426C96.26 8.83426 95.1562 9.86679 95.1562 11.1747C95.1562 14.823 105.366 12.4596 105.366 18.7466C105.366 21.5 103.136 23.8404 98.4217 23.8404C95.7311 23.8634 93.0406 23.0373 91.6149 21.8212Z"
      fill="currentColor"
    />
    <path
      d="M126.27 6.448V23.6339H123.097V16.2915H114.175V23.6339H111.001V6.448H114.175V13.561H123.051V6.448H126.27Z"
      fill="currentColor"
    />
    <path
      d="M146.253 20.9259V23.6105H133.352V6.42456H145.908V9.10915H136.526V13.5605H144.827V16.1763H136.526V20.9259H146.253Z"
      fill="currentColor"
    />
    <path
      d="M152.3 6.448H159.82C165.385 6.448 169.225 9.86683 169.225 15.0295C169.225 20.1922 165.408 23.611 159.82 23.611H152.3V6.448ZM159.659 20.9035C163.499 20.9035 165.983 18.5631 165.983 15.0295C165.983 11.4959 163.499 9.15553 159.659 9.15553H155.474V20.9264H159.659V20.9035Z"
      fill="currentColor"
    />
    <path d="M12.5557 0H0V1.76678H12.5557V0Z" fill="currentColor" />
  </svg>
);
