import React from 'react';

export const FloorLampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="18" height="38" viewBox="0 0 18 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.8 0H0.700012C0.300012 0 0 0.299998 0 0.599998V18.3C0 18.7 0.300012 18.9 0.700012 18.9H8.09998V36.2H2C1.6 36.2 1.29999 36.5 1.29999 36.8C1.29999 37.2 1.6 37.4 2 37.4H15.6C16 37.4 16.3 37.1 16.3 36.8C16.3 36.4 16 36.2 15.6 36.2H9.5V19H16.9C17.3 19 17.6 18.7 17.6 18.4V0.599998C17.5 0.299998 17.2 0 16.8 0ZM16.2 17.7H1.40002V1.3H16.2V17.7Z"
      fill="currentColor"
    />
    <path
      d="M4.20001 15.5C4.60001 15.5 4.90002 15.2 4.90002 14.9V4.1C4.90002 3.7 4.60001 3.5 4.20001 3.5C3.80001 3.5 3.5 3.8 3.5 4.1V14.8C3.5 15.2 3.80001 15.5 4.20001 15.5Z"
      fill="currentColor"
    />
  </svg>
);
