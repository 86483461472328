import React from 'react';

import { ImageCardButton, ImageCardTitle } from '../../../RoomStylePage/RoomStylePage.styled';

import { ComingSoon, ImageCardStyled } from './ImageCardDefault.styled';

export interface ImageCardDefaultProps {
  cover: string;
  onClick?: () => void;
  name: string;
  isActive: boolean;
  isComingSoon?: boolean;
}

export const ImageCardDefault: React.FC<ImageCardDefaultProps> = ({
  cover,
  onClick,
  children,
  name,
  isActive,
  isComingSoon,
}) => {
  return (
    <ImageCardStyled cover={cover} onClick={onClick} isComingSoon={isComingSoon} isActive={isActive}>
      <ImageCardTitle tag="h2" variant="h3">
        {name}
      </ImageCardTitle>
      {!isComingSoon && (
        <ImageCardButton>
          <span>Auswählen</span>
        </ImageCardButton>
      )}
      {isComingSoon && <ComingSoon>COMING SOON</ComingSoon>}
      {children}
    </ImageCardStyled>
  );
};
