import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Slider, Tabs, Image, NavigationButton, Container } from '../../../../lib/components';
import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';
import { Typography } from '../../../../lib/themes/typography/Typography';

import { StyledWhatStyledDoWeOffer, PackageInfo, TabsWrapper, PackageWrapper } from './WhatStylesDoWeOffer.styled';
import { IRoomDesign, roomDesigns } from '../../../fixtures';

const items = roomDesigns.map(item => ({ name: item.title, value: item.urn }));

export const WhatStylesDoWeOffer: React.FC = () => {
  const swiperRef = useRef<any>(null);

  const [roomDesign, setRoomDesign] = useState<IRoomDesign>(roomDesigns[0]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const { swiper } = swiperRef.current;
      swiper.update();
      swiper.slideTo(0);
      swiper.autoplay.start();
    }
  }, [roomDesign]);

  const handleChangeRoomDesign = useCallback(value => {
    const rd = roomDesigns.find(roomDesign => roomDesign.urn === value);
    setRoomDesign(rd ? rd : roomDesigns[0]);
  }, []);

  const { urn, title, tags, description } = roomDesign;

  return (
    <StyledWhatStyledDoWeOffer>
      <Container>
        <BlockTitle
          css={`
            margin-bottom: 30px;
          `}
        >
          Inspiration für den richtigen Design-Stil
        </BlockTitle>
        <Typography
          variant="h4"
          tag="p"
          css={`
            margin: 0 auto 56px;
            max-width: 780px;
            text-align: center;
          `}
        >
          Für die Produktauswahl sollte man auf den Design-Stil und dessen Aussagekraft achten. Ebenso ist die
          Zielgruppe ein wichtiger Indikator. Wir erklären unterschiedliche Design-Welten und geben Beispiele für
          Unternehmensgruppen.
        </Typography>
        <TabsWrapper>
          <Tabs items={items} onChange={handleChangeRoomDesign} value={roomDesign.urn} />
        </TabsWrapper>
        <PackageWrapper>
          <PackageInfo>
            <Typography
              variant="h2"
              tag="h2"
              css={`
                margin-bottom: 30px;
              `}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              tag="h4"
              css={`
                margin-bottom: 30px;
              `}
            >
              {tags.join(' - ')}
            </Typography>
            <Typography
              tag="p"
              variant="body"
              css={`
                margin-bottom: 20px;
              `}
            >
              {description.short}
            </Typography>
            <NavigationButton href={`/styles/${urn}`} color="bronze" variant="outlined" direction="forward">
              Beispiele, die begeistern
            </NavigationButton>
          </PackageInfo>
        </PackageWrapper>
      </Container>

      <Slider
        ref={swiperRef}
        slidesPerView={1}
        autoplay={true}
        // pagination={{
        //   el: '.swiper-pagination',
        //   clickable: true,
        // }}
        bulletsColor="white"
        css={{ height: 600 }}
        className="styles-slider"
      >
        {[roomDesign.cover].map((src, index) => (
          <Image key={`${roomDesign.urn}-${index}`} src={src} alt="" height={'100%'} />
        ))}
      </Slider>
    </StyledWhatStyledDoWeOffer>
  );
};
