import { styled } from '../../../../lib/themes';

export const StyledPaymentAnimation = styled.div``;

export const Animation = styled.div`
  width: 250px;
  height: 250px;
  margin: 70px auto;

  img {
    width: 100%;
    height: auto;
  }
`;

export const TotalPrice = styled.p`
  font-size: 25px;
  line-height: 35px;
  text-align: center;
`;
