import React from 'react';
import { useHistory } from 'react-router-dom';
import { On } from '../../../../lib/core/hooks/on';
import { CError } from '../../../../lib/components/Error';
import { useGetRoomPackages } from '../../../../lib/core/repositories/room-packages-repository';
import { RoomPackage } from '../../../../lib/core/api/generated';
import { RequireAuthRepository } from '../../../../lib/core/available-stores';
import { useStore } from '../../../../lib/core/hooks/use-store';
import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';
import { PackageCard } from '../../../../lib/components';
import { AuthFormWindow } from '../../../auth/containers/AuthFormWindow';
import { Packages, RoomPackages } from './ChooseRoomPackage.styled';
import { useHasAuth } from '../../../../lib/core/repositories/auth-repository';

export const ChooseRoomPackage: React.FC = () => {
  const hasAuth = useHasAuth();

  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState(false);
  const history = useHistory();

  const handleShowAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  const handleSelectPackage = React.useCallback(
    (roomPackage: RoomPackage) => {
      if (hasAuth) {
        history.push(`/payment/${roomPackage.name}`);
      } else {
        handleShowAuthFormWindow();
      }
    },
    [hasAuth, handleShowAuthFormWindow, history],
  );

  const handleAfterSubmit = React.useCallback(() => {
    history.push(`/payment`);
  }, [history]);

  return On(
    (packages: RoomPackage[]) => {
      return (
        <RoomPackages>
          <BlockTitle>
            Das passende Paket für jede Planung:
            <br /> Jetzt auswählen und Ihr erstes Projekt starten.
          </BlockTitle>
          <Packages>
            {packages.map(item => (
              <PackageCard key={item.sku} designPackage={item} onSelect={handleSelectPackage} />
            ))}
          </Packages>
          {showAuthFormWindow && <AuthFormWindow onClose={handleCloseAuthFormWindow} afterSubmit={handleAfterSubmit} />}
        </RoomPackages>
      );
    },
    () => <div>Loading...</div>,
    errorState => <CError error={errorState} />,
    useGetRoomPackages(),
  );
};
