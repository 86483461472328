import { ApiRepository } from './api-repository';
import { Mutation, ProductPage, Query } from '../api/generated';
import { MainStore } from '../main-store';
import { RequireApolloStore, RequireLog, RequireSearchProductsRepository } from '../available-stores';
import { gqlSearchProducts } from '../gql-documents/gql-search-products';
import { useQuery, UseQueryResult, useStore } from '../hooks/use-store';

interface ISearchParams {
  categoryId: string;
  page: number;
  pageSize: number;
}

export class SearchProductsRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'SearchProductsRepository');
  }

  searchProducts = ({ categoryId, page, pageSize }: ISearchParams): Promise<ProductPage> => {
    return this.query(gqlSearchProducts.searchProducts, 'searchProducts', { categoryId, page, pageSize });
  };
}

export function useSearchProducts(
  categoryId: string,
  page: number,
  pageSize: number,
): UseQueryResult<ISearchParams, ProductPage> {
  return useQuery(useStore<RequireSearchProductsRepository>('SearchProductsRepository').searchProducts, {
    categoryId,
    page,
    pageSize,
  });
}
