import { styled } from '../../themes';

const offset = '43px';

export const FormLayout = styled.div`
  & > * {
    &:not(:last-child) {
      margin-bottom: ${offset};
    }
  }

  thead th,
  tbody tr:not(:last-child) th,
  tr:not(:last-child) td {
    padding-bottom: ${offset};
  }
`;
