import React from 'react';

import { HeaderUserMenu } from '../../Header/HeaderUserMenu';

import { StyledPageHeader, LeftColumn, CenterColumn, RightColumn } from './PageHeader.styled';

export const PageHeader: React.FC = ({ children }) => <StyledPageHeader>{children}</StyledPageHeader>;

export const PageHeaderLeftColumn: React.FC = ({ children }) => <LeftColumn>{children}</LeftColumn>;

export const PageHeaderCenterColumn: React.FC = ({ children }) => <CenterColumn>{children}</CenterColumn>;

export const PageHeaderRightColumn: React.FC = ({ children }) => (
  <RightColumn>
    {children}
    <HeaderUserMenu />
  </RightColumn>
);
