import { ManageableStore } from '../manageable-store';
import { MainStore } from '../main-store';
import { useStore } from '../hooks/use-store';
import { RequireLog, RequireRoomRepository } from '../available-stores';

/**
 * This store is basically logs everything to somewhere (console/api/etc)
 */
export class Log extends ManageableStore {
  /**
   * Create a new apollo client and remembers it
   * @param mainStore
   * @param _debug
   */
  constructor(mainStore: MainStore<any>, protected _debug: boolean) {
    super(mainStore, 'Log');
  }

  // TODO: Send logs to somewhere

  public log(message: string, ...args: any[]) {
    if (this._debug) {
      console.log(message, ...args);
    }
  }

  public fatal(e: Error) {
    console.error(`Fatal error: `, e);
  }

  public error(e: Error) {
    if (this._debug) {
      console.error(`Error: `, e);
    }
  }

  clear() {}
}

/**
 * Obtain Log within the current context
 */
export function useLog() {
  useStore<RequireLog>('Log');
}
