import React from 'react';

export const HighboardIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.7 0H5.79999C2.59999 0 0 2.6 0 5.8V32.7C0 35.9 2.59999 38.5 5.79999 38.5C15.2902 38.5 26.4912 38.5 29.7 38.5C32.9 38.5 35.5 35.9 35.5 32.7V5.8C35.5 2.6 32.9 0 29.7 0ZM5.79999 1.3H29.7C32.2 1.3 34.2 3.3 34.2 5.8V18.6H1.40002V5.8C1.30002 3.3 3.29999 1.3 5.79999 1.3ZM29.7 37.1H5.79999C3.29999 37.1 1.29999 35.1 1.29999 32.6V19.8H34.1V32.6C34.2 35.1 32.1 37.1 29.7 37.1Z"
      fill="currentColor"
    />
    <path
      d="M14.3 8.5H20.3C20.7 8.5 21 8.20001 21 7.90001C21 7.60001 20.7 7.3 20.3 7.3H14.3C13.9 7.3 13.6 7.60001 13.6 7.90001C13.6 8.20001 14 8.5 14.3 8.5Z"
      fill="currentColor"
    />
    <path
      d="M20.3 25.8H14.3C13.9 25.8 13.6 26.1 13.6 26.4C13.6 26.8 13.9 27 14.3 27H20.3C20.7 27 21 26.7 21 26.4C21 26.1 20.7 25.8 20.3 25.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 42.75C8.19036 42.75 8.75 42.1904 8.75 41.5C8.75 40.8096 8.19036 40.25 7.5 40.25C6.80964 40.25 6.25 40.8096 6.25 41.5C6.25 42.1904 6.80964 42.75 7.5 42.75ZM7.5 44C8.88071 44 10 42.8807 10 41.5C10 40.1193 8.88071 39 7.5 39C6.11929 39 5 40.1193 5 41.5C5 42.8807 6.11929 44 7.5 44Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5 42.75C27.1904 42.75 27.75 42.1904 27.75 41.5C27.75 40.8096 27.1904 40.25 26.5 40.25C25.8096 40.25 25.25 40.8096 25.25 41.5C25.25 42.1904 25.8096 42.75 26.5 42.75ZM26.5 44C27.8807 44 29 42.8807 29 41.5C29 40.1193 27.8807 39 26.5 39C25.1193 39 24 40.1193 24 41.5C24 42.8807 25.1193 44 26.5 44Z"
      fill="currentColor"
    />
  </svg>
);
