import { colors, styled } from '../../themes';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Summary = styled.button`
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.grayLight};
  border: none;
  width: 100%;
`;

export const SummaryContent = styled.div`
  width: 1110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IContentProps {
  isShow: boolean;
}

export const Content = styled.div<IContentProps>`
  transition: max-height 0.2s;
  max-height: ${props => (props.isShow ? '500vh' : 0)};
  overflow: hidden;
  width: 1230px;
  max-width: 100%;
`;

interface IToggleIconWrapperProps {
  isShow: boolean;
}

export const ToggleIconWrapper = styled.div<IToggleIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 103px;
  color: ${props => (props.isShow ? colors.dark : colors.bronze)};
  transform: ${props => (props.isShow ? 'scale(1, -1)' : 'none')};
  transition: all 0.2s;
`;
