import { styled } from '../../lib/themes';

export const PageInDevelopmentStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 120px 0;
`;

export const PageName = styled.span`
  text-transform: capitalize;
`;
