import React, { useRef } from 'react';
import { ITabItem, Tabs } from '../../../../../../../lib/components/Navigation/Tabs';
import { Root, Wrapper, ScrollHide } from './Solutions.styled';
import { filterProductsBySolution, IRoomProduct, Solution } from '../../hooks/products-hooks';
import { getCategoryIcon } from '../../../../../categoryIcons';
import { useSwipeable } from 'react-swipeable';

interface ISolutions {
  items: Solution[];
  value: Solution;
  products?: IRoomProduct[];
  onChange: (value: Solution) => unknown;
  stepMargin?: number;
}

/**
 * Renders all solutions as Tabs with styled wrapper around
 * @param items - all solutions
 * @param value - current solution
 * @param onChange
 * @param allProducts - needed to count products for current solution
 * @constructor
 */
export const Solutions: React.FC<ISolutions> = ({ items, value, onChange, products, stepMargin }) => {
  const handleOnChange = React.useCallback(
    (value: string) => {
      const solution = items.find(item => item.id === value);
      solution && onChange(solution);
    },
    [items, onChange],
  );

  const tabs = React.useMemo(() => {
    return items.map(solution => {
      const counter = products ? { counter: filterProductsBySolution(products, solution).length } : {};
      return {
        name: solution.name,
        value: solution.id,
        icon: getCategoryIcon(solution.id),
        ...counter,
      } as ITabItem;
    });
  }, [products, items]);

  const index = items.findIndex(item => item.id === value.id);

  const ref = useRef<HTMLDivElement>(null);

  const scrollPosition = useRef(0);

  const handlers = useSwipeable({
    onSwiping: ({ deltaX, first }) => {
      if (ref.current) {
        if (first) {
          scrollPosition.current = ref.current.scrollLeft;
        }
        ref.current.scrollLeft = scrollPosition.current + deltaX;
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <ScrollHide>
      <Wrapper {...handlers} ref={ref} data-id={'wrapper'}>
        <Root data-id={'root'}>
          <Tabs items={tabs} value={value.id} onChange={handleOnChange} itemsWidth={160} stepMargin={stepMargin} />
        </Root>
      </Wrapper>
    </ScrollHide>
  );
};
