import { styled } from '../../../../../../../lib/themes';

export const ProductsWrapper = styled.div`
  display: grid;
  padding: 60px 10px;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  width: 100%;
`;
