import React, { useCallback, useEffect, useRef, useState } from 'react';

import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';
import { Container, Image, NavigationButton, Slider, Tabs } from '../../../../lib/components';
import { Typography } from '../../../../lib/themes/typography/Typography';

import { PackageInfo, PackageWrapper, StyledWhatStyledDoWeOffer, TabsWrapper } from './DesignStyles.styled';

import { tabs, designStyles, TDesignStyle } from './data';

const items = tabs.map(item => ({ name: item.label, value: item.urn }));

export const DesignStyles: React.FC = () => {
  const swiperRef = useRef<any>(null);

  const [designStyle, setDesignStyle] = useState<TDesignStyle>(designStyles[0]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const { swiper } = swiperRef.current;
      swiper.update();
      swiper.slideTo(0);
      swiper.autoplay.start();
    }
  }, [designStyle]);

  const handleChangeRoomDesign = useCallback(value => {
    const designStyle = designStyles.find(roomDesign => roomDesign.urn === value) || designStyles[0];
    setDesignStyle(designStyle);
  }, []);

  const { urn, title, subtitle, description, cover, link } = designStyle;

  return (
    <StyledWhatStyledDoWeOffer>
      <Container>
        <BlockTitle
          css={`
            margin-bottom: 30px;
          `}
        >
          Ausgewählte Design-Stile für Ihre Branche
        </BlockTitle>
        <Typography
          variant="h4"
          tag="p"
          css={`
            margin: 0 auto 56px;
            max-width: 780px;
            text-align: center;
          `}
        >
          Jedes Team hat unterschiedliche Bedürfnisse. Wir helfen Ihnen mit einer Selektion, die für Ihr Unternehmen
          richtige Wahl zu treffen.
        </Typography>
        <TabsWrapper>
          <Tabs items={items} onChange={handleChangeRoomDesign} value={designStyle.urn} />
        </TabsWrapper>
        <PackageWrapper>
          <PackageInfo>
            <Typography
              variant="h2"
              tag="h2"
              css={`
                margin-bottom: 30px;
              `}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              tag="h4"
              css={`
                margin-bottom: 30px;
              `}
            >
              {subtitle}
            </Typography>
            <Typography
              tag="p"
              variant="body"
              css={`
                margin-bottom: 20px;
              `}
            >
              {description}
            </Typography>
            <NavigationButton href={link} color="bronze" variant="outlined" direction="forward">
              Beispiele, die begeistern
            </NavigationButton>
          </PackageInfo>
        </PackageWrapper>
      </Container>

      <Slider
        ref={swiperRef}
        slidesPerView={1}
        autoplay={true}
        // pagination={{
        //   el: '.swiper-pagination',
        //   clickable: true,
        // }}
        bulletsColor="white"
        css={{ height: 600 }}
        className="styles-slider"
      >
        {[cover].map((src, index) => (
          <Image key={`${urn}-${index}`} src={src} alt="" height="100%" />
        ))}
      </Slider>
    </StyledWhatStyledDoWeOffer>
  );
};
