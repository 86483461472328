import React from 'react';

export const ExtraLargeBedIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="55" height="33" viewBox="0 0 55 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M50.5 17.3002V3.90018C51.2 3.60018 51.8 2.90019 51.8 2.10019C51.8 1.00019 50.9 0.200195 49.9 0.200195C48.8 0.200195 48 1.10019 48 2.10019C48 2.90019 48.5 3.70018 49.3 3.90018V8.60019C46.2 4.40019 37.6 1.50018 27.3 1.50018C17 1.50018 8.49999 4.40019 5.29999 8.60019V3.90018C5.99999 3.60018 6.59998 2.90019 6.59998 2.10019C6.59998 1.00019 5.70001 0.200195 4.70001 0.200195C3.60001 0.200195 2.79999 1.10019 2.79999 2.10019C2.79999 2.90019 3.29998 3.70018 4.09998 3.90018V17.3002C1.99998 17.4002 0.400024 19.1002 0.400024 21.1002V26.8002V31.8002C0.400024 32.2002 0.699976 32.5002 1.09998 32.5002C1.49998 32.5002 1.79999 32.2002 1.79999 31.8002V27.4002H53.2V31.8002C53.2 32.2002 53.5 32.5002 53.9 32.5002C54.3 32.5002 54.6 32.2002 54.6 31.8002V26.8002V21.1002C54.2 19.1002 52.6 17.4002 50.5 17.3002ZM5.29999 17.3002V14.8002H49.2V17.3002H5.29999ZM27.2 2.7002C39.1 2.7002 49.2 7.00019 49.2 12.1002V13.4002H5.29999V12.1002C5.29999 7.10019 15.3 2.7002 27.2 2.7002ZM1.5 21.2002C1.5 19.8002 2.6 18.7002 4 18.7002H50.3C51.7 18.7002 52.8 19.8002 52.8 21.2002V26.2002H1.40002V21.2002H1.5Z"
      fill="currentColor"
    />
  </svg>
);
