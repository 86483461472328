import { styled } from '../../../../lib/themes';

export const StyledPrivacyPolicy = styled.div`
  h2 {
    margin-bottom: 50px;
  }

  h3,
  p {
    font-weight: normal;
    margin-bottom: 30px;
  }
`;
