export function eventPath(evt) {
  let path = (evt.composedPath && evt.composedPath()) || evt.path;
  const target = evt.target;

  if (path != null) {
    // Safari doesn't include Window, and it should.
    path = path.indexOf(window) < 0 ? path.concat([window]) : path;
    return path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo) {
    const parentNode = node.parentNode;

    if (!parentNode) {
      return memo;
    }
    return getParents(parentNode, memo.concat([parentNode]));
  }

  return [target].concat(getParents(target, [])).concat([window]);
}
