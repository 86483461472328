import React, { memo } from 'react';
import { CountsTitle, IconWrapper, Title } from './FurnitureExpansionPanelHead.styled';

interface Props {
  icon: any;
  title: string;
  productsCount: number;
}

export const FurnitureExpansionPanelHead: React.FC<Props> = memo(({ icon, title, productsCount }) => {
  return (
    <>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
      <CountsTitle>{productsCount} Produkte</CountsTitle>
    </>
  );
});

FurnitureExpansionPanelHead.displayName = 'FurnitureExpansionPanelHead';
