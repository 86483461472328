import React from 'react';

import { InstagramIcon } from '../../icons/InstagramIcon';
import { YouTubeIcon } from '../../icons/YouTubeIcon';
import { FacebookIcon } from '../../icons/FacebookIcon';
import { TwitterIcon } from '../../icons/TwitterIcon';

import { ButtonStyled, SocialsItem, SocialsStyled, ISocialIconType } from './Socials.styled';

export interface ISocialsProps {
  ig?: string;
  yt?: string;
  fb?: string;
  tw?: string;
  className?: string;
}

interface SocialLinkProps {
  type: ISocialIconType;
  href: string;
}

const SocialLink: React.FC<SocialLinkProps> = ({ type, href }: SocialLinkProps) => (
  <SocialsItem icon={type}>
    <ButtonStyled href={href} target="_blank" rel="nofollow noopener noreferrer">
      {type === 'ig' && <InstagramIcon />}
      {type === 'yt' && <YouTubeIcon />}
      {type === 'fb' && <FacebookIcon />}
      {type === 'tw' && <TwitterIcon />}
    </ButtonStyled>
  </SocialsItem>
);

export class Socials extends React.PureComponent<ISocialsProps> {
  public render() {
    const { className, ig, yt, fb, tw } = this.props;
    return (
      <SocialsStyled className={className}>
        {ig && <SocialLink type={'ig'} href={ig} />}
        {yt && <SocialLink type={'yt'} href={yt} />}
        {fb && <SocialLink type={'fb'} href={fb} />}
        {tw && <SocialLink type={'tw'} href={tw} />}
      </SocialsStyled>
    );
  }
}
