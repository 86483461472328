import { colors, styled, h3, h5 } from '../../../../lib/themes';

import { ArrowIcon as ArrowIconMain } from '../../../../lib/icons';

import { Modal, Button as ButtonMain } from '../../../../lib/components';

export const ModalStyled = styled(Modal)`
  padding-left: 50px;
  padding-right: 50px;

  .swiper-slide {
    padding-bottom: 120px;
    display: flex;
    align-items: center;
  }
`;

export const Slide = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 490px;
  text-align: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 410px;
  user-select: none;
`;

export const Title = styled.h3`
  ${h3};
  margin-bottom: 20px;
  color: ${colors.black};
`;

export const Description = styled.p`
  ${h5};
  color: ${colors.black};
`;

export const ArrowIcon = styled(ArrowIconMain)`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
`;
export const Button = styled(ButtonMain)`
  position: absolute;
  z-index: 2;
  right: 50px;
  bottom: 40px;

  ${ArrowIcon}:first-child {
    margin-right: 0;
  }
`;
