import React from 'react';

export const WardrobeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="39" height="52" viewBox="0 0 39 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.3 0.5H19.2H1.09998C0.699976 0.5 0.400024 0.800012 0.400024 1.20001V47.5C0.400024 47.9 0.699976 48.2 1.09998 48.2H3.29999C3.29999 48.3 3.29999 48.3 3.29999 48.4V51C3.29999 51.4 3.6 51.7 4 51.7H34.7C35.1 51.7 35.4 51.4 35.4 51V48.4C35.4 48.3 35.4 48.3 35.4 48.2H37.6C38 48.2 38.3 47.9 38.3 47.5V1.20001C38 0.800012 37.7 0.5 37.3 0.5ZM1.79999 1.79999H18.6V46.8H1.79999V1.79999ZM33.9 48.3V50.2H4.5V48.3C4.5 48.2 4.5 48.2 4.5 48.1H19.2H34C33.9 48.1 33.9 48.2 33.9 48.3ZM36.7 46.8H19.9V1.79999H36.7V46.8Z"
      fill="currentColor"
    />
    <path
      d="M14.4 22.0996C14 22.0996 13.7 22.3996 13.7 22.7996V25.9996C13.7 26.3996 14 26.6996 14.4 26.6996C14.8 26.6996 15.1 26.3996 15.1 25.9996V22.7996C15 22.2996 14.7 22.0996 14.4 22.0996Z"
      fill="currentColor"
    />
    <path
      d="M24.1 26.5004C24.5 26.5004 24.8 26.2004 24.8 25.8004V22.6004C24.8 22.2004 24.5 21.9004 24.1 21.9004C23.7 21.9004 23.4 22.2004 23.4 22.6004V25.8004C23.4 26.2004 23.7 26.5004 24.1 26.5004Z"
      fill="currentColor"
    />
  </svg>
);
