import React from 'react';

export const OrbitLampIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="30" height="39" viewBox="0 0 30 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.1 19.5002L24.5 1.2002C24.4 0.900195 24.2 0.700195 23.9 0.700195H5.59998C5.29998 0.700195 5 0.900195 5 1.2002L0.400024 19.5002C0.400024 19.7002 0.4 19.9002 0.5 20.1002C0.6 20.3002 0.8 20.3002 1 20.3002H7.90002C5.30002 22.4002 3.79999 25.4002 3.79999 28.8002C3.79999 32.9002 6.09999 36.6002 9.79999 38.5002C9.89999 38.5002 9.99998 38.6002 10.1 38.6002H19.5C19.6 38.6002 19.7 38.6002 19.8 38.5002C23.5 36.6002 25.8 32.9002 25.8 28.8002C25.8 25.4002 24.3 22.3002 21.7 20.3002H28.6C28.8 20.3002 29 20.2002 29.1 20.1002C29.1 19.9002 29.2 19.7002 29.1 19.5002ZM24.4 28.8002C24.4 32.4002 22.4 35.6002 19.3 37.3002H10.2C7.00001 35.6002 5.09998 32.4002 5.09998 28.8002C5.09998 25.2002 7.10001 22.0002 10.2 20.3002H19.3C22.4 22.0002 24.4 25.2002 24.4 28.8002ZM1.90002 19.0002L6.20001 2.00018H23.5L27.8 19.0002H1.90002Z"
      fill="currentColor"
    />
    <path
      d="M9.50002 4.40047C9.20002 4.30047 8.80003 4.60047 8.70004 4.90047L6.70004 15.9005C6.60004 16.3005 6.90004 16.6005 7.20004 16.7005H7.30001C7.60001 16.7005 7.90005 16.5005 7.90005 16.2005L9.90005 5.20046C10.1 4.80046 9.90002 4.40047 9.50002 4.40047Z"
      fill="currentColor"
    />
    <path
      d="M10.5 24.3C10.6 24.5 10.8 24.5 11 24.5C11.1 24.5 11.3 24.5 11.4 24.4C11.7 24.2 12 23.9 12.4 23.8C12.7 23.6 12.8 23.2 12.7 22.9C12.5 22.6 12.1 22.5 11.8 22.6C11.4 22.8 11 23.1 10.6 23.4C10.3 23.6 10.2 24 10.5 24.3Z"
      fill="currentColor"
    />
    <path
      d="M9.59996 25.3002C9.29996 25.1002 8.89999 25.3002 8.69999 25.6002C7.79999 27.3002 7.69999 29.1003 8.19999 30.9003C8.29999 31.2003 8.49997 31.4003 8.79997 31.4003C8.89997 31.4003 8.89998 31.4003 8.99998 31.4003C9.29998 31.3003 9.50001 30.9002 9.40001 30.6002C8.90001 29.1002 9.09997 27.6002 9.79997 26.3002C10.1 25.9002 9.99996 25.5002 9.59996 25.3002Z"
      fill="currentColor"
    />
  </svg>
);
