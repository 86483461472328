import { gql } from '@apollo/client';

export const gqlDocsAuthRegister = {
  login: gql`
    mutation login($username: String!, $password: String!) {
      login(username: $username, password: $password) {
        idToken
        accessToken
        refreshToken
      }
    }
  `,
  /* This is special case, check ApolloStore.customFetch */
  refresh: `
  mutation refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      idToken
      accessToken
      refreshToken
    }
  }
  `,

  logout: gql`
    mutation logout($refreshToken: String!) {
      logout(refreshToken: $refreshToken)
    }
  `,

  register: gql`
    mutation register(
      $username: String
      $email: String!
      $password: String!
      $firstName: String!
      $lastName: String!
      $accountName: String
      $locale: String
    ) {
      register(
        username: $username
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        accountName: $accountName
        locale: $locale
      ) {
        username
        email
        firstName
        lastName
        id
        locale
        emailConfirmStatus
      }
    }
  `,

  registerInvitedUser: gql`
    mutation register($firstName: String!, $lastName: String!, $password: String!, $inviteToken: String) {
      register(password: $password, firstName: $firstName, lastName: $lastName, inviteToken: $inviteToken) {
        id
        username
        email
        memberships {
          account {
            id
            name
          }
          role
        }
      }
    }
  `,

  confirmEmail: gql`
    mutation confirmMail($token: String!) {
      confirmMail(token: $token) {
        status
      }
    }
  `,

  resetPassword: gql`
    mutation resetPassword($email: String!) {
      resetPassword(email: $email)
    }
  `,

  completePasswordReset: gql`
    mutation completePasswordReset($token: String!, $password: String!) {
      completePasswordReset(token: $token, password: $password)
    }
  `,
};
