/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 **/

async function loadSmoothScrollPolyfill() {
  const smoothscroll = await import('smoothscroll-polyfill');
  smoothscroll.polyfill();
}

export function loadPolyfills(): Promise<any> {
  const polyfills: any[] = [];

  if (!supportsResizeObserver()) {
    polyfills.push(import('resize-observer-polyfill'));
  }
  if (!supportsSmoothScroll()) {
    polyfills.push(loadSmoothScrollPolyfill());
  }

  return Promise.all(polyfills);
}

function supportsSmoothScroll() {
  return 'scrollBehavior' in document.documentElement.style;
}

function supportsResizeObserver() {
  return (
    'ResizeObserver' in global &&
    'ResizeObserverEntry' in global &&
    'contentRect' in window.ResizeObserverEntry.prototype
  );
}
