import { styled, css, applyCssIf } from '../../../themes';

export interface IPageSectionStyledProps {
  noTopMargin: boolean;
  noBottomMargin: boolean;
}

export const PageSectionStyled = styled.section<IPageSectionStyledProps>`
  margin-bottom: 200px;

  &:first-of-type {
    margin-top: 100px;
  }

  ${({ noTopMargin, noBottomMargin }) => css`
    ${applyCssIf(noTopMargin)(css`
      &,
      &:first-of-type {
        margin-top: 0;
      }
    `)}

    ${applyCssIf(noBottomMargin)(css`
      &,
      &:last-of-type {
        margin-bottom: 0;
      }
    `)}
  `}
`;
