import { colors, styled, button } from '../../themes';

export const Root = styled.button`
  all: unset;
  cursor: pointer;

  width: 100%;
  height: 189px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${button};
  color: ${colors.black};
  background-color: ${colors.grayLight};

  svg {
    color: ${colors.grayMedium};
  }
`;

export const IconWrapper = styled.div`
  width: 446px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
