import { styled, css, colors } from '../../../../../../../lib/themes';
import { ImageCard } from '../../../../../../../lib/components/ImageCard';
import { ImageCardContent } from '../../../../../../../lib/components/ImageCard/ImageCard.styled';

export const ComingSoon = styled.h2`
  margin-top: -30px;
  font-size: 8.4vw;
  line-height: 7.2vw;
  font-weight: 900;
  transform: rotate(-90deg) translateX(45px);
  transition: transform 0.3s;
`;

interface IImageCardProp {
  isComingSoon?: boolean;
  isActive: boolean;
}

export const ImageCardStyled = styled(ImageCard)<IImageCardProp>`
  ${({ isComingSoon }) =>
    isComingSoon &&
    css`
      cursor: default;
      pointer-events: none;

      ${ImageCardContent} {
        justify-content: space-between;
      }
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${colors.bronze};
      transition: border;
    `};
`;
