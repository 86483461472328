import { styled } from '../../../../lib/themes';

export const StyledTipVonMaria = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;

export const HeaderAvatar = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: auto;
  }
`;

export const HeaderTitle = styled.h2`
  font-weight: bold;
  font-size: 44px;
  line-height: 45px;
`;

export const Content = styled.div``;

export const ContentTitle = styled.h3`
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 30px;
`;

export const ContentText = styled.p`
  font-size: 15px;
  line-height: 25px;
`;

export const TextBlock1 = styled.div`
  width: 540px;
`;

export const TextBlock2 = styled.div`
  width: 260px;
`;

export const ContentImg01 = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 80px;
`;

export const ContentImg02 = styled.img`
  width: 100%;
  height: auto;
  margin: 80px 0 100px;
`;

export const ContentImg03 = styled.img`
  width: 60%;
  height: auto;
`;

export const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;
