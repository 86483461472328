import { h1, styled } from '../../../../lib/themes';

export const Title = styled.h1`
  ${h1};
  margin-bottom: 60px;
`;

export const ModalContent = styled.div`
  text-align: center;

  & > * {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
`;
