import React from 'react';

import {
  StyledSliderEdit,
  Label,
  InputWrapper,
  Input,
  Meters,
  InfoButton,
  DragPin,
  RangeInput,
} from './SliderEdit.styled';

export interface ISliderEditProps {
  label: string;
  units: string;
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => unknown;
  onInfoButtonClick: (anchorRef: React.RefObject<any>) => void;
}

const limitValue = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

const getProgress = (value: number, min: number, max: number) => {
  return limitValue((value / (max - min)) * 100, 0, 100);
};

export const SliderEdit: React.FC<Partial<ISliderEditProps>> = (props: ISliderEditProps) => {
  const {
    label = '',
    units = '',
    value = 0,
    min = 0,
    max = 100,
    step = 10,
    onChange = () => {},
    onInfoButtonClick = () => {},
  }: ISliderEditProps = props;

  const ref = React.useRef<HTMLButtonElement | null>(null);

  const initialProgress = getProgress(value, min, max);
  const [progress, setProgress] = React.useState(initialProgress);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(limitValue(+event.target.value, min, max));
  };

  const handleInfoButtonClick = () => {
    onInfoButtonClick(ref);
  };

  React.useEffect(() => {
    setProgress(getProgress(value, min, max));
  }, [value, min, max]);

  return (
    <StyledSliderEdit>
      <Label>{label}</Label>
      <InputWrapper>
        <Input
          data-hook-input={label}
          value={`${value}`}
          min={`${min}`}
          max={`${max}`}
          step={step}
          onChange={handleChange}
        />
        {units ? (
          <Meters units={units}>
            <span>{value} </span>
          </Meters>
        ) : null}
        <InfoButton onClick={handleInfoButtonClick} refEl={ref} />
      </InputWrapper>
      <RangeInput progress={progress} value={value} min={min} max={max} step={step} onChange={handleChange} />
    </StyledSliderEdit>
  );
};
