import React from 'react';

export const ComputerChairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="27" height="47" viewBox="0 0 27 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.1 43.6H23.7L23.6 43.5L14.2 37.7V32.7H15.9C16.3 32.7 16.5 32.4 16.5 32.1V30H23.9C25.3 30 26.5 28.8 26.5 27.4C26.5 26 25.3 24.8 23.9 24.8H23.8C23.6 22.9 22.7 16.5 22.7 13.9C22.7 12.5 22.9 9.6 23.4 5.5C23.5 4.2 23.1 2.9 22.3 2C21.4 1 20.2 0.5 18.9 0.5H8.2C6.9 0.5 5.70001 1 4.80001 2C3.90001 3 3.5 4.2 3.7 5.5C4.1 9.6 4.40001 12.5 4.40001 13.9C4.40001 16.5 3.60001 22.9 3.30001 24.8H3.2C1.8 24.8 0.600006 26 0.600006 27.4C0.600006 28.8 1.8 30 3.2 30H10.6V32.1C10.6 32.5 10.9 32.7 11.2 32.7H12.9V37.7L3.50001 43.5L3.40001 43.6H3.00001C2.10001 43.6 1.30001 44.4 1.30001 45.3C1.30001 46.2 2.10001 47 3.00001 47H4.2C5.1 47 5.90001 46.2 5.90001 45.3C5.90001 44.8 5.6 44.3 5.2 44L13.5 38.9L21.8 44C21.4 44.3 21.1 44.8 21.1 45.3C21.1 46.2 21.9 47 22.8 47H24C24.9 47 25.7 46.2 25.7 45.3C25.8 44.4 25 43.6 24.1 43.6ZM5.60001 13.9C5.60001 12.1 5.20001 8.3 4.90001 5.4C4.80001 4.5 5.1 3.6 5.7 2.9C6.3 2.2 7.20001 1.8 8.10001 1.8H18.8C19.7 1.8 20.6 2.2 21.2 2.9C21.8 3.6 22.1 4.5 22 5.4C21.7 8.3 21.3 12.1 21.3 13.9C21.3 16.6 22.1 22.7 22.3 24.8H4.40001C4.90001 22.7 5.60001 16.5 5.60001 13.9ZM1.80001 27.4C1.80001 26.7 2.40001 26.1 3.10001 26.1H3.80001H23.2H23.9C24.6 26.1 25.2 26.7 25.2 27.4C25.2 28.1 24.6 28.7 23.9 28.7H15.9H11.2H3.2C2.4 28.7 1.80001 28.1 1.80001 27.4ZM11.8 30H15.2V31.5H11.8V30ZM4.2 45.7H3.00001C2.80001 45.7 2.60001 45.5 2.60001 45.3C2.60001 45.1 2.80001 44.9 3.00001 44.9H4.2C4.4 44.9 4.60001 45.1 4.60001 45.3C4.60001 45.5 4.4 45.7 4.2 45.7ZM24.1 45.7H22.9C22.7 45.7 22.5 45.5 22.5 45.3C22.5 45.1 22.7 44.9 22.9 44.9H24.1C24.3 44.9 24.5 45.1 24.5 45.3C24.5 45.5 24.3 45.7 24.1 45.7Z"
      fill="currentColor"
    />
  </svg>
);
