import React, { useLayoutEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Header } from '../../lib/components/Header';
import { Container } from '../../lib/components/Container';
import { Title } from '../../lib/components/Title';
import { PageFooter } from '../../lib/components/Page/PageFooter';

import { PageInDevelopmentStyled, Content, PageName } from './PageInDevelopment.styled';

export const PageInDevelopment: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <PageInDevelopmentStyled>
      <Header />
      <Content>
        <Container>
          <Title>
            <PageName>{match.params[0].split('-').join(' ')}</PageName> page is in development
          </Title>
        </Container>
      </Content>
      <PageFooter />
    </PageInDevelopmentStyled>
  );
};
