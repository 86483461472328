import React from 'react';

export const DrawerUnitIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="52" height="39" viewBox="0 0 52 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.1 8.60016C16 8.60016 16.8 7.80021 16.8 6.90021C16.8 6.00021 16 5.2002 15.1 5.2002C14.2 5.2002 13.4 6.00021 13.4 6.90021C13.4 7.80021 14.2 8.60016 15.1 8.60016ZM15.1 6.40021C15.3 6.40021 15.5 6.60017 15.5 6.80017C15.5 7.00017 15.3 7.2002 15.1 7.2002C14.9 7.2002 14.7 7.00017 14.7 6.80017C14.7 6.60017 14.9 6.40021 15.1 6.40021Z"
      fill="currentColor"
    />
    <path
      d="M15.1 19.2994C16 19.2994 16.8 18.4994 16.8 17.5994C16.8 16.6994 16 15.8994 15.1 15.8994C14.2 15.8994 13.4 16.6994 13.4 17.5994C13.4 18.4994 14.2 19.2994 15.1 19.2994ZM15.1 17.1994C15.3 17.1994 15.5 17.3994 15.5 17.5994C15.5 17.7994 15.3 17.9994 15.1 17.9994C14.9 17.9994 14.7 17.7994 14.7 17.5994C14.7 17.3994 14.9 17.1994 15.1 17.1994Z"
      fill="currentColor"
    />
    <path
      d="M15.1 26.5996C14.2 26.5996 13.4 27.3996 13.4 28.2996C13.4 29.1996 14.2 29.9996 15.1 29.9996C16 29.9996 16.8 29.1996 16.8 28.2996C16.9 27.3996 16.1 26.5996 15.1 26.5996ZM15.1 28.7996C14.9 28.7996 14.7 28.5997 14.7 28.3997C14.7 28.1997 14.9 27.9996 15.1 27.9996C15.3 27.9996 15.5 28.1997 15.5 28.3997C15.6 28.5997 15.4 28.7996 15.1 28.7996Z"
      fill="currentColor"
    />
    <path
      d="M36.7 8.60016C37.6 8.60016 38.4 7.80021 38.4 6.90021C38.4 6.00021 37.6 5.2002 36.7 5.2002C35.8 5.2002 35 6.00021 35 6.90021C35 7.80021 35.7 8.60016 36.7 8.60016ZM36.7 6.40021C36.9 6.40021 37.1 6.60017 37.1 6.80017C37.1 7.00017 36.9 7.2002 36.7 7.2002C36.5 7.2002 36.3 7.00017 36.3 6.80017C36.3 6.60017 36.4 6.40021 36.7 6.40021Z"
      fill="currentColor"
    />
    <path
      d="M36.7 19.2994C37.6 19.2994 38.4 18.4994 38.4 17.5994C38.4 16.6994 37.6 15.8994 36.7 15.8994C35.8 15.8994 35 16.6994 35 17.5994C35 18.4994 35.7 19.2994 36.7 19.2994ZM36.7 17.1994C36.9 17.1994 37.1 17.3994 37.1 17.5994C37.1 17.7994 36.9 17.9994 36.7 17.9994C36.5 17.9994 36.3 17.7994 36.3 17.5994C36.3 17.3994 36.4 17.1994 36.7 17.1994Z"
      fill="currentColor"
    />
    <path
      d="M36.7 26.5996C35.8 26.5996 35 27.3996 35 28.2996C35 29.1996 35.8 29.9996 36.7 29.9996C37.6 29.9996 38.4 29.1996 38.4 28.2996C38.4 27.3996 37.6 26.5996 36.7 26.5996ZM36.7 28.7996C36.5 28.7996 36.3 28.5997 36.3 28.3997C36.3 28.1997 36.5 27.9996 36.7 27.9996C36.9 27.9996 37.1 28.1997 37.1 28.3997C37.1 28.5997 36.9 28.7996 36.7 28.7996Z"
      fill="currentColor"
    />
    <path
      d="M51 0.799805H46.1H5.60001H0.800013C0.400013 0.799805 0.200012 1.09982 0.200012 1.49982V33.6998C0.200012 34.0998 0.500013 34.3998 0.800013 34.3998H5.00001V37.8998C5.00001 38.2998 5.30001 38.5998 5.60001 38.5998H46.1C46.5 38.5998 46.7 38.2998 46.7 37.8998V34.3998H51C51.4 34.3998 51.6 34.0998 51.6 33.6998V1.49982C51.7 1.09982 51.4 0.799805 51 0.799805ZM6.30001 23.5998H45.5V32.9998H6.30001V23.5998ZM45.5 22.2998H6.30001V12.8998H45.5V22.2998ZM45.5 11.5998H6.30001V2.19983H45.5V11.5998ZM1.40001 2.09979H5.00001V12.1998V22.9998V33.0998H1.40001V2.09979ZM45.5 37.1998H6.30001V34.8998H45.5V37.1998ZM50.4 33.0998H46.8V22.9998V12.2998V2.19983H50.4V33.0998Z"
      fill="currentColor"
    />
  </svg>
);
