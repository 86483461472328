import React, { useCallback, useEffect, useState } from 'react';

import { useEscape } from '../../hooks/useEscape';
import { noop } from '../../utils/placeholders';
import { Portal, Backdrop, CloseButton } from '../../components';
import { Container, Card, CloseButtonWrapper } from './SideSheet.styled';

export interface ISideSheetProps {
  isOpened?: boolean;
  onClose?: () => unknown;
  isNoPadding?: boolean;
  scrollToId?: [string, number];
}

export const SideSheet: React.FC<ISideSheetProps> = ({
  isOpened = false,
  onClose = noop,
  isNoPadding = false,
  children,
  scrollToId,
}) => {
  const [attached, setAttached] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);

  useEscape(onClose);

  useEffect(() => {
    setOpened(isOpened);
    let timeout;
    if (isOpened) {
      setAttached(true);
    } else {
      timeout = setTimeout(() => {
        setAttached(false);
        onClose && onClose();
      }, 400);
    }
    return () => {
      timeout && window.clearTimeout(timeout);
    };
  }, [isOpened, onClose]);

  useEffect(() => {
    let timeout;
    if (scrollToId && isOpened) {
      timeout = setTimeout(() => {
        const [prefix, id] = scrollToId;
        const selector = `${prefix}-${id}`;
        const element = document.getElementById(selector);
        if (element && id !== 0) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 400);
    }
    return () => {
      timeout && window.clearTimeout(timeout);
    };
  }, [scrollToId, isOpened]);

  const stopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  return attached ? (
    <Portal>
      <Container onClick={stopPropagation}>
        <Backdrop isShow={opened} onClick={onClose} />
        <Card isOpened={opened} isNoPadding={isNoPadding}>
          <CloseButtonWrapper>
            <CloseButton onClick={onClose} />
          </CloseButtonWrapper>
          {children}
        </Card>
      </Container>
    </Portal>
  ) : null;
};
