import React from 'react';
// tslint:disable:max-line-length

export const YouTubeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.615 0.183881C16.011 -0.0621189 7.984 -0.0611189 4.385 0.183881C0.488 0.449881 0.029 2.80388 0 8.99988C0.029 15.1849 0.484 17.5489 4.385 17.8159C7.985 18.0609 16.011 18.0619 19.615 17.8159C23.512 17.5499 23.971 15.1959 24 8.99988C23.971 2.81488 23.516 0.450881 19.615 0.183881ZM9 12.9999V4.99988L17 8.99288L9 12.9999Z"
      fill="currentColor"
    />
  </svg>
);
