/**
 * This is not mobx replacement
 * This hook is to listen to ECMA Observable tc39/proposal-observable (zen-observable)
 * It should not be used directly, but as internal tool for other hooks
 * that provides particular, not abstract meaning,
 * e.g. useHasAuth() hook
 */
import React from 'react';
import { ReadOnlySubject, Subscription } from './observable-types';

export function useSubject<T>(subject: ReadOnlySubject<T>): T {
  const subscriptionRef = React.useRef<Subscription>();
  const [value, setValue] = React.useState(subject.getCurrent());

  // Subscribe if didnt yet
  if (!subscriptionRef.current) {
    subscriptionRef.current = subject.subscribe(nextValue => setValue(nextValue));
  }

  // Cleanup. No deps needed, cuz subscription always happens once
  React.useEffect(() => () => subscriptionRef.current && subscriptionRef.current.unsubscribe(), []);

  return value;
}
