import React from 'react';

export const CarpetIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="31" height="55" viewBox="0 0 31 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2634 15.6373L1.7765 27.2955L15.2634 38.9537L28.7504 27.2955L15.2634 15.6373ZM0.263428 27.2955L15.2634 40.2616L30.2634 27.2955L15.2634 14.3294L0.263428 27.2955Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2634 28.3757L1.7765 40.0339L15.2634 51.6921L28.7504 40.0339L15.2634 28.3757ZM0.263428 40.0339L15.2634 53L30.2634 40.0339L15.2634 27.0678L0.263428 40.0339Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2634 3.3079L1.7765 14.9661L15.2634 26.6243L28.7504 14.9661L15.2634 3.3079ZM0.263428 14.9661L15.2634 27.9322L30.2634 14.9661L15.2634 2L0.263428 14.9661Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M30 3H1V52H30V3ZM0 2V53H31V2H0Z" fill="currentColor" />
    <path d="M0 53H1V55H0V53Z" fill="currentColor" />
    <path d="M2 0H3V2H2V0Z" fill="currentColor" />
    <path d="M2 53H3V55H2V53Z" fill="currentColor" />
    <path d="M4 0H5V2H4V0Z" fill="currentColor" />
    <path d="M4 53H5V55H4V53Z" fill="currentColor" />
    <path d="M6 0H7V2H6V0Z" fill="currentColor" />
    <path d="M6 53H7V55H6V53Z" fill="currentColor" />
    <path d="M8 0H9V2H8V0Z" fill="currentColor" />
    <path d="M8 53H9V55H8V53Z" fill="currentColor" />
    <path d="M10 0H11V2H10V0Z" fill="currentColor" />
    <path d="M10 53H11V55H10V53Z" fill="currentColor" />
    <path d="M12 0H13V2H12V0Z" fill="currentColor" />
    <path d="M12 53H13V55H12V53Z" fill="currentColor" />
    <path d="M14 0H15V2H14V0Z" fill="currentColor" />
    <path d="M14 53H15V55H14V53Z" fill="currentColor" />
    <path d="M16 0H17V2H16V0Z" fill="currentColor" />
    <path d="M16 53H17V55H16V53Z" fill="currentColor" />
    <path d="M18 0H19V2H18V0Z" fill="currentColor" />
    <path d="M18 53H19V55H18V53Z" fill="currentColor" />
    <path d="M20 0H21V2H20V0Z" fill="currentColor" />
    <path d="M20 53H21V55H20V53Z" fill="currentColor" />
    <path d="M22 0H23V2H22V0Z" fill="currentColor" />
    <path d="M22 53H23V55H22V53Z" fill="currentColor" />
    <path d="M24 0H25V2H24V0Z" fill="currentColor" />
    <path d="M24 53H25V55H24V53Z" fill="currentColor" />
    <path d="M26 0H27V2H26V0Z" fill="currentColor" />
    <path d="M26 53H27V55H26V53Z" fill="currentColor" />
    <path d="M28 0H29V2H28V0Z" fill="currentColor" />
    <path d="M28 53H29V55H28V53Z" fill="currentColor" />
    <path d="M30 0H31V2H30V0Z" fill="currentColor" />
    <path d="M30 53H31V55H30V53Z" fill="currentColor" />
    <path d="M0 0H1V2H0V0Z" fill="currentColor" />
  </svg>
);
