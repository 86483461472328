import * as React from 'react';
import { AvailableStores, MainStore } from '../main-store';

export interface MainStoreContext<T extends AvailableStores> {
  mainStore: MainStore<T>;
}

let mainContext: React.Context<MainStoreContext<any>>;

/**
 * Initialize mainStore and provide it here before App rendering
 * @param {MainStore<T extends AvailableStores>} mainStore
 * @return {React.Context<MainStoreContext<T extends AvailableStores>>}
 */
export function createMainContext<T extends AvailableStores>(
  mainStore: MainStore<T>,
): React.Context<MainStoreContext<T>> {
  mainContext = React.createContext({ mainStore });
  return mainContext;
}

export function getMainContext<T extends AvailableStores>(): React.Context<MainStoreContext<T>> {
  return mainContext as React.Context<MainStoreContext<T>>;
}
