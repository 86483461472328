import { TUrl } from '../../../../types';

export type TDesignStyleUrn = 'urban-cool' | 'homely-chic' | 'modern-glam';

import homelyChicJpg from './assets/homely-chic.jpg';
import modernGlamJpg from './assets/modern-glam.jpg';
import urbanCoolJpg from './assets/urban-cool.jpg';

export interface IDesignStylesTab {
  urn: TDesignStyleUrn;
  label: string;
}

export interface TDesignStyle {
  urn: string;
  title: string;
  subtitle: string;
  description: string;
  cover: TUrl;
  link: string;
}

export const tabs: IDesignStylesTab[] = [
  { urn: 'urban-cool', label: 'Startup' },
  { urn: 'homely-chic', label: 'Co-Working' },
  { urn: 'modern-glam', label: 'Marketing' },
];

export const designStyles: TDesignStyle[] = [
  {
    urn: 'urban-cool',
    title: 'URBAN COOL',
    subtitle: 'Material-Mix - flexible - Lebendige Atmosphäre',
    description:
      'Startups profitieren von einfachen, aber wirkungsvollen Gestaltungselementen sowie einer flexiblen Raumgestaltung',
    link: '/styles/urban-cool',
    cover: urbanCoolJpg,
  },
  {
    urn: 'homely-chic',
    title: 'HOMELY CHIC',
    subtitle: 'Naturmaterialien - schlicht & vielseitig - harmonisch',
    description:
      'Optische Abtrennungen helfen, den Raum zu gliedern und Ruhezonen zu schaffen. Ein offener und freundlicher Community Bereich ist das Herz eines Co-Working Konzepts',
    link: '/styles/homely-chic',
    cover: homelyChicJpg,
  },
  {
    urn: 'modern-glam',
    title: 'MODERN GLAM',
    subtitle: 'Individuell - high-class - glamourös',
    description:
      'Auffällige Tapeten mit Dschungelmotiv in Kombination mit hochwertigem Holz und Messing bringen eine moderne Eleganz in Empfangs- oder Meetingräume',
    link: '/styles/modern-glam',
    cover: modernGlamJpg,
  },
];
