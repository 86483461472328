import { colors, styled } from '../../../../../lib/themes';
import { CheckIconRounded } from '../../../../../lib/icons';

export const StyledSummaryPage = styled.div`
  padding: 100px 0;
`;

export const RadioFieldStyled = styled.div`
  display: flex;

  & > * {
    flex: 0 0 auto;

    &:not(:first-child) {
      margin-left: 100px;
    }
  }
`;

export const CheckIconStyled = styled(CheckIconRounded)`
  color: ${colors.bronze};
`;

export const ErrorContainer = styled.div`
  &:not(:empty) {
    padding-top: 78px;
  }
`;
