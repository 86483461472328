import React from 'react';

export const LargeBedIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="54" height="33" viewBox="0 0 54 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M52.9 17.7996H50.3C50.4 17.6996 50.4 17.5996 50.4 17.3996V13.3996C50.4 12.9996 50.1 12.7996 49.8 12.7996H48.2V1.1996C48.2 0.7996 47.9 0.599609 47.6 0.599609H6.29999C5.89999 0.599609 5.69999 0.8996 5.69999 1.1996V12.7996H4.09999C3.69999 12.7996 3.49999 13.0996 3.49999 13.3996V17.3996C3.49999 17.4996 3.59999 17.6996 3.59999 17.7996H0.999994C0.599994 17.7996 0.399994 18.0996 0.399994 18.3996V28.2996C0.399994 28.6996 0.699994 28.8996 0.999994 28.8996H3.49999V31.7996C3.49999 32.1996 3.79999 32.3996 4.09999 32.3996H12.2C12.6 32.3996 12.8 32.0996 12.8 31.7996V28.9996H40.5V31.8996C40.5 32.2996 40.8 32.4996 41.1 32.4996H49.2C49.6 32.4996 49.8 32.1996 49.8 31.8996V28.9996H52.8C53.2 28.9996 53.4 28.6996 53.4 28.3996V18.4996C53.5 18.0996 53.2 17.7996 52.9 17.7996ZM6.89999 1.6996H46.8V12.6996H43.4V6.3996C43.4 5.9996 43.1 5.79961 42.8 5.79961H29C28.6 5.79961 28.4 6.0996 28.4 6.3996V12.6996H25.5V6.3996C25.5 5.9996 25.2 5.79961 24.9 5.79961H11C10.6 5.79961 10.4 6.0996 10.4 6.3996V12.6996H6.99999V1.6996H6.89999ZM29.6 12.6996V7.09961H42.1V12.6996H29.6ZM11.6 12.6996V7.09961H24.1V12.6996H11.6ZM4.69999 17.3996V13.9996H49.1V17.3996C49.1 17.4996 49.2 17.6996 49.2 17.7996H4.59999C4.69999 17.6996 4.69999 17.4996 4.69999 17.3996ZM11.5 31.2996H4.69999V28.9996H11.5V31.2996ZM48.6 31.2996H41.8V28.9996H48.6V31.2996ZM52.2 27.6996H1.59999V19.0996H52.2V27.6996Z"
      fill="currentColor"
    />
  </svg>
);
