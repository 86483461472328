import { styled } from '../../../../lib/themes';

export const ColorPaletteStyled = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: auto;
`;

export const Body = styled.tbody``;

export const Row = styled.tr``;

export const Color = styled.td`
  padding: 0;
  height: 100%;
`;
