import React from 'react';

export const SideboardIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="42" height="30" viewBox="0 0 42 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.4 0.799805H1C0.6 0.799805 0.299988 1.09981 0.299988 1.39981V8.09982V26.6998C0.299988 27.0998 0.6 27.3998 1 27.3998H3.29999V29.1998C3.29999 29.5998 3.6 29.8998 4 29.8998H8.59998C8.99998 29.8998 9.29999 29.5998 9.29999 29.1998V27.3998H20.8H32.3V29.1998C32.3 29.5998 32.6 29.8998 33 29.8998H37.6C38 29.8998 38.3 29.5998 38.3 29.1998V27.3998H40.6C41 27.3998 41.3 27.0998 41.3 26.6998V8.09982V1.39981C41.1 1.09981 40.8 0.799805 40.4 0.799805ZM39.8 2.09982V7.49982H20.8H1.79999V2.09982H39.8ZM1.70001 8.7998H20.1V26.0998H8.59998H4H1.70001V8.7998ZM7.90002 28.4998H4.59998V27.3998H7.90002V28.4998ZM36.9 28.4998H33.6V27.3998H36.9V28.4998ZM39.8 26.0998H37.5H32.9H21.4V8.7998H39.8V26.0998Z"
      fill="currentColor"
    />
    <path
      d="M15 19.1996C16 19.1996 16.8 18.3996 16.8 17.3996C16.8 16.3996 16 15.5996 15 15.5996C14 15.5996 13.2 16.3996 13.2 17.3996C13.2 18.3996 14 19.1996 15 19.1996ZM15 16.9996C15.3 16.9996 15.5 17.1996 15.5 17.4996C15.5 17.7996 15.3 17.9996 15 17.9996C14.7 17.9996 14.5 17.7996 14.5 17.4996C14.5 17.1996 14.7 16.9996 15 16.9996Z"
      fill="currentColor"
    />
    <path
      d="M26.5 19.1996C27.5 19.1996 28.3 18.3996 28.3 17.3996C28.3 16.3996 27.5 15.5996 26.5 15.5996C25.5 15.5996 24.7 16.3996 24.7 17.3996C24.7 18.3996 25.5 19.1996 26.5 19.1996ZM26.5 16.9996C26.8 16.9996 27 17.1996 27 17.4996C27 17.7996 26.8 17.9996 26.5 17.9996C26.2 17.9996 26 17.7996 26 17.4996C26 17.1996 26.3 16.9996 26.5 16.9996Z"
      fill="currentColor"
    />
  </svg>
);
