import React from 'react';

export const RoundedBedIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="49" height="28" viewBox="0 0 49 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.2 0.400391H10.4C5.20002 0.400391 0.900024 4.70039 0.900024 9.90039V13.9004V14.1004V23.4004C0.900024 23.8004 1.20004 24.1004 1.60004 24.1004H3.90002V27.0004C3.90002 27.4004 4.20004 27.7004 4.60004 27.7004H7.50003C7.90003 27.7004 8.20004 27.4004 8.20004 27.0004V24.1004H41.7V27.0004C41.7 27.4004 42 27.7004 42.4 27.7004H45.3C45.7 27.7004 46 27.4004 46 27.0004V24.1004H48.3C48.7 24.1004 49 23.8004 49 23.4004V14.1004V13.9004V9.90039C48.7 4.60039 44.5 0.400391 39.2 0.400391ZM6.80002 26.3004H5.20004V24.0004H6.80002V26.3004ZM44.5 26.3004H42.9V24.0004H44.5V26.3004ZM47.4 22.8004H2.20004V14.6004H47.4V22.8004ZM7.10004 13.3004C7.10004 13.2004 7.10004 13.2004 7.10004 13.3004C7.10004 11.0004 8.90004 9.20041 11.2 9.20041H18.3C20.6 9.20041 22.4 11.0004 22.4 13.3004H7.10004ZM27.2 13.3004C27.2 13.2004 27.2 13.2004 27.2 13.3004C27.2 11.0004 29 9.20041 31.3 9.20041H38.4C40.7 9.20041 42.5 11.0004 42.5 13.3004H27.2ZM47.4 13.3004H43.8C43.8 10.3004 41.4 7.90039 38.4 7.90039H31.3C28.3 7.90039 25.9 10.3004 25.9 13.3004H23.7C23.7 10.3004 21.3 7.90039 18.3 7.90039H11.2C8.20004 7.90039 5.80002 10.3004 5.80002 13.3004H2.20004V9.90039C2.20004 5.40039 5.90002 1.70041 10.4 1.70041H39.2C43.7 1.70041 47.4 5.40039 47.4 9.90039V13.3004Z"
      fill="currentColor"
    />
  </svg>
);
