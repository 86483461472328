import React from 'react';

export const BenchPlusWideIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="47" height="37" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.6 13.5H2.29999C0.999988 13.5 0 14.5 0 15.8V20.1C0 21.4 0.999988 22.4 2.29999 22.4H5L2 35.8C2 36.2 2.20004 36.5 2.60004 36.5H2.70001C3.00001 36.5 3.29999 36.3 3.29999 35.9L6.29999 22.4H25.6L28.6 35.9C28.6 36.2 28.9 36.5 29.2 36.5H29.3C29.7 36.5 29.9 36.1 29.9 35.8L26.9 22.4H29.6C30.9 22.4 31.9 21.4 31.9 20.1V15.8C31.9 14.6 30.9 13.5 29.6 13.5ZM30.6 20.2C30.6 20.8 30.1 21.2 29.6 21.2H2.29999C1.69999 21.2 1.29999 20.7 1.29999 20.2V15.9C1.29999 15.3 1.79999 14.9 2.29999 14.9H29.6C30.2 14.9 30.6 15.4 30.6 15.9V20.2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.5 0C37.7761 0 38 0.223858 38 0.5V7.5H46C46.2761 7.5 46.5 7.72386 46.5 8C46.5 8.27614 46.2761 8.5 46 8.5H38V15.5C38 15.7761 37.7761 16 37.5 16C37.2239 16 37 15.7761 37 15.5V8.5H29C28.7239 8.5 28.5 8.27614 28.5 8C28.5 7.72386 28.7239 7.5 29 7.5H37V0.5C37 0.223858 37.2239 0 37.5 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11.5C5 10.1193 6.11929 9 7.5 9H25.5C26.8807 9 28 10.1193 28 11.5V13.5C28 13.7761 27.7761 14 27.5 14C27.2239 14 27 13.7761 27 13.5V11.5C27 10.6716 26.3284 10 25.5 10H7.5C6.67157 10 6 10.6716 6 11.5V13.5C6 13.7761 5.77614 14 5.5 14C5.22386 14 5 13.7761 5 13.5V11.5Z"
      fill="currentColor"
    />
  </svg>
);
