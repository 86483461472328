import React from 'react';

export const StoolIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M46.8 24.9H44.2C43.8 24.9 43.6 25.2 43.6 25.5V26.8C43.6 27.2 43.9 27.4 44.2 27.4H44.8V33.2C44.4 33 43.9 32.8 43.4 32.8H43.2C42.9 31.1 42 25.8 42.1 23.9C42.2 22.7 42.6 20.9 43 19.1C43.4 17.4 43.8 15.6 44 14.1C44.1 12.8 43.7 11.5 42.8 10.5C41.9 9.5 40.7 9 39.3 9H26C24.7 9 23.4 9.6 22.5 10.5C21.6 11.5 21.2 12.8 21.3 14.1C21.5 15.6 21.9 17.4 22.3 19.1C22.7 20.9 23.1 22.7 23.2 23.9C23.3 25.8 22.4 31.1 22.1 32.8H21.9C21.4 32.8 20.9 32.9 20.5 33.2V27.4H21.2C21.6 27.4 21.8 27.1 21.8 26.8V25.5C21.8 25.1 21.5 24.9 21.2 24.9H18.6C18.2 24.9 18 25.2 18 25.5V26.8C18 27.2 18.3 27.4 18.6 27.4H19.3V35.5C19.3 37 20.5 38.2 22 38.2H29.6C29.7 39.6 30.7 40.7 32 41V46.2L22.3 52.2L22.2 52.3H21.8C20.8 52.3 20 53.1 20 54.1C20 55.1 20.8 55.9 21.8 55.9H23C24 55.9 24.8 55.1 24.8 54.1C24.8 53.5 24.5 53 24.1 52.7L32.7 47.4L41.3 52.7C40.9 53 40.6 53.5 40.6 54.1C40.6 55.1 41.4 55.9 42.4 55.9H43.6C44.6 55.9 45.4 55.1 45.4 54.1C45.4 53.1 44.6 52.3 43.6 52.3H43.2L43.1 52.2L33.4 46.2V41C34.7 40.7 35.7 39.6 35.8 38.2H43.4C44.9 38.2 46.1 37 46.1 35.5V27.4H46.8C47.2 27.4 47.4 27.1 47.4 26.8V25.5C47.4 25.1 47.1 24.9 46.8 24.9ZM24.5 23.9C24.4 22.5 24 20.7 23.6 18.8C23.2 17.1 22.8 15.4 22.7 14C22.6 13.1 22.9 12.1 23.5 11.4C24.1 10.7 25 10.3 26 10.3H39.4C40.4 10.3 41.3 10.7 41.9 11.4C42.5 12.1 42.8 13.1 42.7 14C42.6 15.4 42.1 17.2 41.8 18.8C41.4 20.7 40.9 22.5 40.9 23.9C40.8 25.9 41.6 31 42 32.9H23.5C23.8 30.9 24.6 25.9 24.5 23.9ZM23 54.5H21.8C21.6 54.5 21.3 54.3 21.3 54C21.3 53.7 21.5 53.5 21.8 53.5H23C23.2 53.5 23.5 53.7 23.5 54C23.5 54.3 23.3 54.5 23 54.5ZM44 54.1C44 54.3 43.8 54.6 43.5 54.6H42.3C42.1 54.6 41.8 54.4 41.8 54.1C41.8 53.8 42 53.6 42.3 53.6H43.5C43.8 53.6 44 53.8 44 54.1ZM32.7 39.8C31.8 39.8 31 39.1 30.9 38.3H34.5C34.4 39.1 33.6 39.8 32.7 39.8ZM43.4 36.9H35.1H30.2H21.9C21.1 36.9 20.5 36.3 20.5 35.5C20.5 34.7 21.1 34.1 21.9 34.1H22.6H42.6H43.3C44.1 34.1 44.7 34.7 44.7 35.5C44.8 36.3 44.2 36.9 43.4 36.9Z"
      fill="currentColor"
    />
  </svg>
);
