import React from 'react';

export const SimpleTableIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="53" height="26" viewBox="0 0 53 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H51.5769C51.8531 0 52.0769 0.223858 52.0769 0.5V3.95362C52.0769 4.22976 51.8531 4.45362 51.5769 4.45362C34.2687 4.45362 0.917069 4.45362 0.5 4.45362C0.223858 4.45362 0 4.22977 0 3.95362V0.5ZM1 3.45362V1H51.0769V3.45362L1 3.45362Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M50 25.5V3.5H51V25.5H50Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1 25.5V3.5H2V25.5H1Z" fill="currentColor" />
  </svg>
);
