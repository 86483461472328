import React from 'react';

import { ColorPaletteStyled, Body, Row, Color } from './ColorPalette.styled';

interface IColorPalette {
  colors: string[];
  className?: string;
}
export const ColorPalette: React.FC<IColorPalette> = ({ colors, className }) => (
  <ColorPaletteStyled className={className}>
    <Body>
      <Row>
        {colors.map(color => (
          <Color key={color} style={{ backgroundColor: color }} />
        ))}
      </Row>
    </Body>
  </ColorPaletteStyled>
);
