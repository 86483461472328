import { useCallback, useContext } from 'react';
import { ConfirmationModalContext } from './ConfirmationModalProvider';
import { ConfirmationModalsState, ModalParams } from './types';

export const useConfirmationModal = () => {
  const Context = useContext<ConfirmationModalsState>(ConfirmationModalContext);

  return useCallback(
    (params: ModalParams) => {
      Context.add(params);
    },
    [Context],
  );
};
