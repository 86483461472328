import { styled, css, applyCssIf } from '../../themes';
import { ContainerDirection } from './FlexContainer';

import { Container } from '../Container';

const isColumnDirection = (direction: ContainerDirection) => direction === 'column';

export const StyledFlexContainer = styled(Container)<{ direction: ContainerDirection }>`
  width: 100%;
  display: flex;

  ${({ direction }) =>
    applyCssIf(isColumnDirection(direction))(css`
      flex-direction: column;
    `)};
`;
