import React from 'react';

export const BedIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.5 23.2002V23.1002V23.0002L35.8 10.5002V2.2002C35.8 1.4002 35.1 0.700195 34.3 0.700195H7.40002C6.60002 0.700195 5.90002 1.4002 5.90002 2.2002V10.5002L0.200012 23.0002V23.1002V23.2002V35.0002C0.200012 35.4002 0.500024 35.6002 0.900024 35.6002H5.29999C5.69999 35.6002 6 35.3002 6 35.0002V29.3002H36V35.0002C36 35.4002 36.3 35.6002 36.7 35.6002H41.1C41.5 35.6002 41.8 35.3002 41.8 35.0002L41.5 23.2002ZM6.90002 11.2002H34.7L39.9 22.5002H1.79999L6.90002 11.2002ZM18.8 9.9002H10.1V6.7002C10.1 6.2002 10.5 5.8002 11 5.8002H17.9C18.4 5.8002 18.8 6.2002 18.8 6.7002V9.9002ZM31.4 9.9002H22.7V6.7002C22.7 6.2002 23.1 5.8002 23.6 5.8002H30.5C31 5.8002 31.4 6.2002 31.4 6.7002V9.9002ZM7.09998 2.1002C7.09998 2.0002 7.19999 1.9002 7.29999 1.9002H34.2C34.3 1.9002 34.4 2.0002 34.4 2.1002V9.9002H32.7V6.7002C32.7 5.5002 31.7 4.5002 30.5 4.5002H23.6C22.4 4.5002 21.4 5.5002 21.4 6.7002V9.9002H20V6.7002C20 5.5002 19 4.5002 17.8 4.5002H11C9.8 4.5002 8.79999 5.5002 8.79999 6.7002V9.9002H7.09998V2.1002ZM40.2 34.3002H37.1V28.6002C37.1 28.2002 36.8 28.0002 36.4 28.0002H5.09998C4.69998 28.0002 4.40002 28.3002 4.40002 28.6002V34.3002H1.29999V23.9002H40.1V34.3002H40.2Z"
      fill="currentColor"
    />
  </svg>
);
