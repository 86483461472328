import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';

import { Popover } from '../Popover';
import { StyledMenu } from './Menu.styled';
import { MenuProvider } from './MenuContext';

interface IBaseMenuProps {
  /**
   * Menu takes care about its opened state itself, but you may control it by passing isOpened prop
   */
  isOpened?: boolean;

  /**
   * Called after the menu closes
   */
  onClose?: () => void;
}

type IRenderMenuProps =
  | {
      /**
       * You may fully control menu state via isOpened props
       * and pass anchorRef to bind menu position to
       */
      anchorRef: MutableRefObject<any>;
    }
  | {
      /**
       * Or you may pass anchor Element here and let menu control opened state itself
       * without any fuss
       * If anchor element has onClick prop it will be handled automatically
       */
      anchorElement: JSX.Element;
    };

type IMenuProps = IBaseMenuProps & IRenderMenuProps;

export const Menu: React.FC<IMenuProps> = props => {
  const { children, isOpened = false, onClose } = props;
  const { anchorRef, anchorElement } = props as any;

  const [isOpenedState, setOpened] = React.useState<boolean>(isOpened);
  useEffect(() => setOpened(isOpened), [isOpened]);

  const triggerElementRef = useRef<HTMLElement | null>(null);

  const contextValue = useMemo(
    () => ({
      close: () => {
        setOpened(false);
        onClose && onClose();
      },
      open: () => setOpened(true),
      flip: () => setOpened(!isOpenedState),
    }),
    [onClose, isOpenedState],
  );

  return (
    <MenuProvider value={contextValue}>
      {anchorElement
        ? React.cloneElement(anchorElement, {
            ...anchorElement.props,
            ref: triggerElementRef,
            onClick: anchorElement.props.onClick || contextValue.flip,
          })
        : null}
      <Popover
        anchorRef={anchorElement ? triggerElementRef : anchorRef}
        isOpened={isOpenedState}
        onClose={contextValue.close}
      >
        <StyledMenu>{children}</StyledMenu>
      </Popover>
    </MenuProvider>
  );
};
