import { ApiRepository } from './api-repository';
import { MainStore } from '../main-store';
import {
  RequireAccountRepository,
  RequireApolloStore,
  RequireLog,
  RequireProjectRepository,
  RequireUserRepository,
} from '../available-stores';
import { gqlProjectDocs } from '../gql-documents/gql-project';
import { UseMutationResult, useStore, useMutation, UseQueryResult, useWatchQuery } from '../hooks/use-store';
import {
  Mutation,
  MutationCreateProjectArgs,
  MutationDeleteProjectArgs,
  Project,
  Query,
  QueryProjectArgs,
} from '../api/generated';
import { gqlDocsAccount } from '../gql-documents/gql-account';

export class ProjectRepository extends ApiRepository<
  Query,
  Mutation,
  RequireApolloStore & RequireLog & RequireAccountRepository
> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog & RequireAccountRepository>) {
    super(mainStore, 'ProjectRepository');
  }

  /**
   * Get project by Id
   * @param variables
   */
  getProject = (variables: QueryProjectArgs) => {
    return this.watchQuery<Query['project'], QueryProjectArgs>(gqlProjectDocs.getProject, variables);
  };

  /**
   * Delete project by Id
   * @param variables
   */
  deleteProject = (variables: MutationDeleteProjectArgs): Promise<boolean> => {
    return this.mutate<boolean>(gqlProjectDocs.deleteProject, 'deleteProject', variables, {
      refetchQueries: ['account'],
    });
  };

  /**
   * Create a project in an account
   * @param variables
   */
  createProject = (variables: MutationCreateProjectArgs): Promise<Project> => {
    return this.mutate<Project>(gqlProjectDocs.createProject, 'createProject', variables, {
      refetchQueries: ['account'],
    });
  };
}
``;
/**
 * Syntax sugar to obtain a project by Id
 */
export function useGetProject(variables: QueryProjectArgs): UseQueryResult<QueryProjectArgs, Project> {
  return useWatchQuery(useStore<RequireProjectRepository>('ProjectRepository').getProject, 'project', variables);
}

/**
 * Syntax sugar to create a project
 */
export function useCreateProject(): UseMutationResult<MutationCreateProjectArgs, Project> {
  return useMutation(useStore<RequireProjectRepository>('ProjectRepository').createProject);
}

/**
 * Syntax sugar to delete a project
 */
export function useDeleteProject(): UseMutationResult<MutationDeleteProjectArgs, boolean> {
  return useMutation(useStore<RequireProjectRepository>('ProjectRepository').deleteProject);
}
