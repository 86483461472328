import React from 'react';

export const CabinetIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="34" height="54" viewBox="0 0 34 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29 0.400391H5.59998C2.89998 0.400391 0.599976 2.60039 0.599976 5.40039V42.8004C0.599976 45.5004 2.79998 47.8004 5.59998 47.8004H7.69998L4.79996 52.2004C4.59996 52.5004 4.69997 52.9004 4.99997 53.1004C5.09997 53.2004 5.19996 53.2004 5.39996 53.2004C5.59996 53.2004 5.79996 53.1004 5.89996 52.9004L9.29996 47.7004H25.3L28.7 52.9004C28.8 53.1004 29 53.2004 29.2 53.2004C29.3 53.2004 29.4 53.2004 29.6 53.1004C29.9 52.9004 30 52.5004 29.8 52.2004L26.9 47.8004H29C31.7 47.8004 34 45.6004 34 42.8004V5.30039C33.9 2.60039 31.7 0.400391 29 0.400391ZM1.89996 42.8004V5.30039C1.89996 3.30039 3.49998 1.60039 5.59998 1.60039H16.7V46.3004H5.59998C3.49998 46.4004 1.89996 44.8004 1.89996 42.8004ZM32.6 42.8004C32.6 44.8004 31 46.5004 28.9 46.5004H17.8V1.70039H29C31 1.70039 32.7 3.30039 32.7 5.40039V42.8004H32.6Z"
      fill="currentColor"
    />
    <path
      d="M12.3999 21.5C11.3999 21.5 10.7 22.3 10.7 23.2C10.7 24.2 11.4999 24.9 12.3999 24.9C13.3999 24.9 14.0999 24.1 14.0999 23.2C14.0999 22.3 13.3999 21.5 12.3999 21.5ZM12.3999 23.7C12.1999 23.7 11.9999 23.5 11.9999 23.3C11.9999 23.1 12.1999 22.9 12.3999 22.9C12.5999 22.9 12.7999 23.1 12.7999 23.3C12.7999 23.5 12.5999 23.7 12.3999 23.7Z"
      fill="currentColor"
    />
    <path
      d="M22.1 21.5C21.1 21.5 20.4 22.3 20.4 23.2C20.4 24.2 21.2 24.9 22.1 24.9C23.1 24.9 23.8 24.1 23.8 23.2C23.9 22.3 23.1 21.5 22.1 21.5ZM22.1 23.7C21.9 23.7 21.7 23.5 21.7 23.3C21.7 23.1 21.9 22.9 22.1 22.9C22.3 22.9 22.5 23.1 22.5 23.3C22.6 23.5 22.4 23.7 22.1 23.7Z"
      fill="currentColor"
    />
  </svg>
);
