import React, { useCallback, useRef } from 'react';
import { MutationDeleteRoomArgs, Room, RoomStatus } from '../../../../../../lib/core/api/generated';
import { useDeleteRoom } from '../../../../../../lib/core/repositories/room-repository';
import { RoomItemsStep, RoomParamsStep } from '../../../../../configurator/typesUtils';
import { Button } from '../../../../../../lib/components/Button';
import { Menu, MenuItem, InvitationWindow } from '../../../../../../lib/components';
import { CheckIcon, TrashIcon } from '../../../../../../lib/icons';
import { formatCurrency } from '../../../../../../lib/utils/format-number';
import { useIsInvitedUser } from '../../../../../../lib/core/hooks/useIsInvitedUser';

import {
  ButtonMore,
  Buttons,
  Footer,
  Header,
  ImageWrapper,
  Info,
  MoreIconStyled,
  MoreInfo,
  Progress,
  Root,
  Status,
  StyledRoomTile,
  Title,
  StatusWrap,
  ApprovalsStatus,
} from './RoomCard.styled';
import { useConfirmationModal } from '../../../../../../lib/components/ConfirmationModal/useConfirmationModal';

export const isRoomStatusNew = roomStatus => roomStatus === RoomStatus.New;
export const isRoomStatusPlanned = roomStatus => roomStatus === RoomStatus.Planned;
export const isRoomStatusOrdered = roomStatus => roomStatus === RoomStatus.Ordered;

const decodeRoomStatus = ({ roomStatus, roomTypeId }: Room): { title: string; progress: number } => {
  switch (roomStatus) {
    case RoomStatus.New:
      return {
        title: 'Gestartet',
        progress: roomTypeId ? 40 : 10,
      };
    case RoomStatus.Planned:
      return {
        title: 'Finished',
        progress: 60,
      };
    case RoomStatus.Ordered:
      return {
        title: 'Ordered',
        progress: 100,
      };
    default:
      return {
        title: 'Unknown',
        progress: 0,
      };
  }
};

interface IRoomTileProps {
  room: Room;
  accountId: string;
  isInvitedUser?: boolean;
  deleteRoom: (params: MutationDeleteRoomArgs) => unknown;
}

export const RoomCard: React.FC<IRoomTileProps> = ({ accountId, room, isInvitedUser, deleteRoom }) => {
  const { id, name, projectId, total, items, roomStatus, approvals } = room;

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isPopupButton, setTogglePopupButton] = React.useState<boolean>(false);
  const showConfirm = useConfirmationModal();
  const [isInvitationWindowOpened, setInvitationWindowOpened] = React.useState<boolean>(false);
  const handleOpenInvitationWindow = React.useCallback(() => setInvitationWindowOpened(true), []);
  const handleCloseInvitationWindow = React.useCallback(() => setInvitationWindowOpened(false), []);

  const handleAgreeDeletion = React.useCallback(() => {
    deleteRoom({ roomId: id });
  }, [deleteRoom, id]);

  const handleClickDelete = React.useCallback(() => {
    showConfirm({
      message: 'Soll dieser Raum gelöscht werden? Diese Aktion ist unwiderruflich.',
      onAgree: handleAgreeDeletion,
    });
  }, [showConfirm, handleAgreeDeletion]);

  const handleTogglePopupButton = useCallback(() => {
    setTogglePopupButton(!isPopupButton);
  }, [isPopupButton]);

  const decodedRoomStatus = decodeRoomStatus(room);

  const renderNewMenu = () => (
    <Menu anchorRef={buttonRef} isOpened={isPopupButton} onClose={handleTogglePopupButton}>
      <MenuItem onClick={handleClickDelete}>
        Raum löschen <TrashIcon />
      </MenuItem>
    </Menu>
  );

  const renderPlanningMenu = () => (
    <Menu anchorRef={buttonRef} isOpened={isPopupButton} onClose={handleTogglePopupButton}>
      <MenuItem URL={`/accounts/${accountId}/projects/${projectId}/rooms/${id}/${RoomItemsStep.RoomSummary}`}>
        Zusammenfassung
      </MenuItem>
      {!isInvitedUser && <MenuItem onClick={handleOpenInvitationWindow}>Freigabe</MenuItem>}
      {!isInvitedUser && (
        <MenuItem onClick={handleClickDelete}>
          Raum löschen <TrashIcon />
        </MenuItem>
      )}
    </Menu>
  );

  const renderOrderedMenu = () => {
    return (
      <Menu anchorRef={buttonRef} isOpened={isPopupButton} onClose={handleTogglePopupButton}>
        <MenuItem onClick={handleOpenInvitationWindow}>Freigabe</MenuItem>
        <MenuItem onClick={() => {}}>Neue Produkte vom Katalog hinzufügen </MenuItem>
      </Menu>
    );
  };
  const image: string = room.moodboard?.images[0]?.url || '/img/mocks/plan-for-me/office.png';

  const getApprovalsStatus = () => {
    if (approvals && approvals.length > 0) {
      const isApproved = approvals.every(item => item?.approvalStatus === 'APPROVED');

      return isApproved ? 'Freigegeben' : 'Freigabe erfragt';
    }

    return '';
  };

  return (
    <>
      <Root>
        <StyledRoomTile>
          <Header>
            {name && <Title>{name}</Title>}
            {items && (
              <Info>
                {items.length && total
                  ? `${items.length} items / ${formatCurrency(total.totalPriceInclTax)}`
                  : 'No items'}
              </Info>
            )}
          </Header>
          <ImageWrapper>
            <img src={`${image}?size=x220`} alt={name} />
          </ImageWrapper>
          <Footer>
            <StatusWrap>
              <Status>
                {isRoomStatusOrdered(roomStatus) && <CheckIcon />}
                {decodedRoomStatus.title}
              </Status>
              {isRoomStatusPlanned(roomStatus) && <ApprovalsStatus>{getApprovalsStatus()}</ApprovalsStatus>}
            </StatusWrap>
            <Progress status={roomStatus} progress={decodedRoomStatus.progress} />
            <Buttons>
              {isRoomStatusNew(roomStatus) && (
                <Button
                  color="bronze"
                  // href={`/accounts/${accountId}/projects/${projectId}/rooms/${id}/${RoomParamsStep.PlanType}`}
                  href={`/accounts/${accountId}/projects/${projectId}/rooms/${id}/${RoomParamsStep.RoomStyle}`}
                >
                  Fortsetzen
                </Button>
              )}

              {isRoomStatusPlanned(roomStatus) && (
                <Button
                  color="bronze"
                  href={`/accounts/${accountId}/projects/${projectId}/rooms/${id}/${RoomItemsStep.RoomSummary}`}
                >
                  {isInvitedUser ? 'Freigeben' : 'Bestellen'}
                </Button>
              )}

              {isRoomStatusOrdered(roomStatus) && (
                <Button
                  color="bronze"
                  href={`/accounts/${accountId}/projects/${projectId}/rooms/${id}/${RoomItemsStep.RoomSummary}`}
                >
                  Details anzeigen
                </Button>
              )}

              <MoreInfo>
                <ButtonMore onClick={handleTogglePopupButton} type="button" ref={buttonRef}>
                  <MoreIconStyled />
                </ButtonMore>
                {isRoomStatusNew(roomStatus) && renderNewMenu()}
                {isRoomStatusPlanned(roomStatus) && renderPlanningMenu()}
                {!isInvitedUser && isRoomStatusOrdered(roomStatus) && renderOrderedMenu()}
              </MoreInfo>
            </Buttons>
          </Footer>
        </StyledRoomTile>
      </Root>

      {isInvitationWindowOpened && <InvitationWindow roomId={room.id} onClose={handleCloseInvitationWindow} />}
    </>
  );
};
