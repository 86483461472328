import { styled, css, applyCssIf } from '../../themes';
import media from '../../utils/media';

import { ContainerSize } from './Container';

const isTablet = (size: ContainerSize) => size === 'tablet';

export const StyledContainer = styled.div<{ size: ContainerSize }>`
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;

  ${({ size }) =>
    applyCssIf(isTablet(size))(css`
      max-width: 960px;

      ${media.tabletSmall`
        max-width: 720px;
      `}
    `)};
`;
