import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  ApprovalStatus,
  PaymentMethod,
  PurchaseResponse,
  Room,
  RoomPlanMode,
  RoomStatus,
} from '../../../../../../lib/core/api/generated';
import { ConfiguratorPageFooter } from '../../../../components/ConfiguratorPageFooter';
import { Container } from '../../../../../../lib/components/Container';
import { FlexContainer, Form, FormCheckbox, InvitationWindow } from '../../../../../../lib/components';
import { GqlErrors } from '../../../../../../lib/core/errors/gql-error';
import { PaymentAnimation } from '../../../../../payment/components';
import { ProductsTable } from '../../../../components/ProductsTable';
import { RoomItemsStep } from '../../../../typesUtils';
import { ShareIcon } from '../../../../../../lib/icons';
import { Summary } from '../../../../components/Summary';
import { TotalPanel } from '../../../../components/TotalPanel';
import { Typography } from '../../../../../../lib/themes/typography/Typography';
import { formatCurrency } from '../../../../../../lib/utils/format-number';
import { noop } from '../../../../../../lib/utils/placeholders';
import { pause } from '../../../../../../lib/utils/pause';
import {
  useApproveRoom,
  useGenerateRoomPDF,
  useOrderRoom,
} from '../../../../../../lib/core/repositories/room-repository';
import { useBillingAddress, useCurrentAccount, useMe } from '../../../../../../lib/core/repositories/user-repository';
import {
  useCompletePaypalRoomPackagePurchase,
  usePurchaseRoomPackage,
} from '../../../../../../lib/core/repositories/room-packages-repository';
import { useIsInvitedUser } from '../../../../../../lib/core/hooks/useIsInvitedUser';
import { useSnackBar } from '../../../../../../lib/components/SnackBar';

import {
  ContentItem,
  ProductOrCost,
} from '../../../../components/ConfiguratorPageFooter/ConfiguratorPageFooter.styled';
import { DownloadButton, DownloadIconStyled, Root, ShareButton, Wrap } from './SummaryScreen.styled';

interface SummaryScreenProps {
  room: Room;
  changeScreen: (screen: number) => unknown;
}

export function SummaryScreen({ room }: SummaryScreenProps): JSX.Element {
  const [approveRoom] = useApproveRoom();
  const [billingAddress] = useBillingAddress();
  const [completePaypalRoomPackagePurchase] = useCompletePaypalRoomPackagePurchase();
  const [currentAccount] = useCurrentAccount();
  const [generateRoomPDF] = useGenerateRoomPDF();
  const [purchaseRoomPackage] = usePurchaseRoomPackage();
  const [submitOrderRoom] = useOrderRoom();
  const [user] = useMe();
  const history = useHistory();
  const isInvitedUser = useIsInvitedUser();
  const { accountId } = useParams<{ accountId: string }>();
  const { showAlert, showSuccess } = useSnackBar();

  const [isDownloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [isPaymentAnimationOpened, setPaymentAnimationOpened] = useState<boolean>(false);
  const [isShowInvitationWindow, setIsShowInvitationWindow] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  const closeInvitationWindow = useCallback(() => setIsShowInvitationWindow(false), []);
  const openInvitationWindow = useCallback(() => setIsShowInvitationWindow(true), []);

  const statusApprovalRoom =
    room.approvals && room.approvals.find(userRoom => user?.id === userRoom?.userId)?.approvalStatus;
  const isApprovedRoom = statusApprovalRoom && statusApprovalRoom === ApprovalStatus.Approved;
  const isRoomOrdered = room.roomStatus === RoomStatus.Ordered;

  const purchaseCartCheck = useCallback(() => {
    return purchaseRoomPackage({
      accountId,
      sku: 'CART-CHECK',
      method: PaymentMethod.Paypal,
    }).then(({ paypal }: PurchaseResponse) => {
      if (paypal) {
        setToken(paypal.token);
        // for test
        // paypal.startUrl = `http://localhost:8080/payment/paypal/action/success?token=${paypal.token}&PayerID=JETCTFW7TKAPW`;
        window.open(paypal.startUrl, '_blank');
      }
    });
  }, [accountId, purchaseRoomPackage]);

  const makeOrder = useCallback(() => {
    const { id, projectId } = room;
    return submitOrderRoom(id)
      .then(() => history.push(`/success-order/${accountId}/${projectId}`))
      .catch(error => {
        window.scrollTo({ top: 0, left: 0 });
        throw error;
      });
  }, [accountId, history, room, submitOrderRoom]);

  const handleSubmit = useCallback(
    ({ isProveByExpert }) => {
      if (isInvitedUser) {
        // TODO add handler from success approveRoom
        return approveRoom({ roomId: room.id }).then(() => showSuccess('Freigeben gesendet'));
      }

      if (isProveByExpert) {
        return purchaseCartCheck();
      }
      return makeOrder();
    },
    [approveRoom, isInvitedUser, makeOrder, purchaseCartCheck, room, showSuccess],
  );

  useEffect(() => {
    const handleOnMessage = (event: MessageEvent) => {
      const { origin, data } = event;

      // Listen to messages from our domain
      if (('https://furnished.de' === origin || true) && currentAccount && data.token === token && data.payerId) {
        setPaymentAnimationOpened(true);

        Promise.all([
          completePaypalRoomPackagePurchase({
            accountId: currentAccount.id,
            paypalToken: token,
            paypalPayerId: data.payerId,
          }),
          pause(2000),
        ])
          .then(result => {
            // if (result) {
            //   history.push(`/accounts/${currentAccount.id}/projects`);
            // }
            return makeOrder();
          })
          .catch(error => console.error({ error }))
          .finally(() => {
            setPaymentAnimationOpened(false);
          });
      }
    };

    window.addEventListener('message', handleOnMessage, false);
    return () => window.removeEventListener('message', handleOnMessage);
  }, [completePaypalRoomPackagePurchase, currentAccount, makeOrder, setPaymentAnimationOpened, token]);

  const handleDownloadButtonClick = useCallback(() => {
    setDownloadLoading(true);
    const errorMessage = 'Es ist leider ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal';
    generateRoomPDF({ roomId: room.id })
      .then(response => {
        if (response.uri) {
          window.open(response.uri, '_blank');
        } else {
          showAlert(errorMessage);
        }
      })
      .catch(() => {
        showAlert(errorMessage);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  }, [generateRoomPDF, room.id, showAlert]);

  const roomAmount = room.items.reduce((acc, item) => acc + item.amount, 0);
  const roomTotal = room.items.reduce((acc, item) => acc + item.amount * (item.productConfiguration.price || 0), 0);

  const isEditable: boolean = isInvitedUser
    ? false
    : (true || room.planMode === RoomPlanMode.LetMePlan) && room.roomStatus !== RoomStatus.Ordered;

  const handleOnError = useCallback(hr => showAlert(hr), [showAlert]);

  return (
    <Root>
      <Container>
        <FlexContainer
          css={`
            padding-top: 46px;
          `}
        >
          <FlexContainer
            direction="column"
            css={`
              margin-right: 58px;
            `}
          >
            <Summary room={room} />

            {room.items.filter(({ added }) => added).length > 0 && (
              <ProductsTable items={room.items} isEditable={isEditable} isAmountEditable={isEditable} />
            )}
          </FlexContainer>

          <FlexContainer
            direction="column"
            css={`
              width: auto;
            `}
          >
            <Wrap>
              <TotalPanel room={room} isOrdered={isRoomOrdered} />
              <FlexContainer
                direction="column"
                css={`
                  width: 254px;
                  margin: 39px;
                  & > * {
                    margin-bottom: 22px;
                  }
                `}
              >
                {!isInvitedUser && (
                  <ShareButton onClick={openInvitationWindow}>
                    <ShareIcon /> Freigabe
                  </ShareButton>
                )}
                <DownloadButton
                  variant="contained"
                  size="medium"
                  color="bronze"
                  onClick={handleDownloadButtonClick}
                  isLoading={isDownloadLoading}
                >
                  <DownloadIconStyled />
                  Download
                </DownloadButton>
              </FlexContainer>
            </Wrap>
          </FlexContainer>
        </FlexContainer>
      </Container>

      {isShowInvitationWindow && <InvitationWindow roomId={room.id} onClose={closeInvitationWindow} />}

      {isPaymentAnimationOpened && <PaymentAnimation total={29} />}

      <Form
        onSubmit={handleSubmit}
        initialValues={{ isProveByExpert: false }}
        onError={handleOnError}
        errorsHR={[[GqlErrors.NO_APPROVAL, 'No Approval room']]}
      >
        {!isRoomOrdered && !isApprovedRoom && (
          <Container>
            <Typography
              variant="h1"
              tag="h2"
              css={`
                margin-bottom: 40px;
              `}
            >
              Warenkorb von Experten prüfen lassen für nur 29€
            </Typography>
            <Typography
              variant="h4"
              tag="p"
              css={`
                margin-bottom: 70px;
              `}
            >
              Für 29€ werden wir die Produkte auf Harmonie und Verfügbarkeit prüfen, ebenso senden wir ein digitales
              Design Booklet zur weiteren Inspiration.
            </Typography>

            <FormCheckbox
              name="isProveByExpert"
              css={`
                margin-bottom: 50px;
              `}
            >
              Ja ich möchte das ein Experte meinen Warenkorb und die darin befindlichen Produkte vor der Bestellung
              prüft und das sich gegenfalls der Preis ändern kann.
              <br />
              <br />
              Ich verstehe das ich vor dem finalen Kauf der Änderung zustimmen oder diese ablehnen kann und das die
              bezahlte Servicegebühr bei ablehnen des Angebots nicht erstattbar ist.
            </FormCheckbox>
          </Container>
        )}

        <ConfiguratorPageFooter
          step={RoomItemsStep.RoomSummary}
          changeStep={noop}
          // prevStep={AllRoomSteps.RoomSummary}
          submitTitle={isRoomOrdered || isApprovedRoom ? undefined : isInvitedUser ? 'Freigeben' : 'Bestellen'}
        >
          <ContentItem>
            <ProductOrCost>{roomAmount} Produkte</ProductOrCost>
            <ProductOrCost>{formatCurrency(roomTotal)}</ProductOrCost>
          </ContentItem>
        </ConfiguratorPageFooter>
      </Form>
    </Root>
  );
}
