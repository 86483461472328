import React from 'react';
import { Redirect as RouterRedirect, RedirectProps } from 'react-router-dom';
import I18n from 'i18n-js';

export class Redirect extends React.Component<RedirectProps, any> {
  render() {
    const currentLang = I18n.currentLocale();
    const defaultLang = I18n.defaultLocale;
    const to = this.props.to as string;
    const prefix = currentLang === defaultLang ? '' : `/${currentLang}`;

    return <RouterRedirect {...this.props} to={`${prefix}${to}`} />;
  }
}
