import React from 'react';

export const StorageIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="52" height="54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.319 52.726h47.403c.46 0 .92-.462.92-1.039v-50c0-.461-.344-.923-.92-.923H2.32c-.46 0-.92.462-.92.923v50.116c0 .461.46.923.92.923zM25.56 3.074v49.192M49.607 27.324H2.318M49.607 14.621H2.318M15.204 21.549h-4.602M4.756 3.012v10.11M47.285 3.012v10.11M45.047 3.012v10.11M42.807 11.996H32.734M42.807 9.75H32.734M6.996 3.012v10.11M9.23 3.012v10.11M11.47 3.012l3.358 10.11M15.204 34.252h-4.602M40.518 21.55h-4.602M40.518 34.252h-4.602"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
