import React from 'react';

export const ThreeDoorWardrobeIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M49.1 0.599609H33.2H17.3H17.2H17.1H1.20001C0.800012 0.599609 0.5 0.89961 0.5 1.19961V47.7996C0.5 48.1996 0.800012 48.3996 1.20001 48.3996H3V50.9996C3 51.3996 3.30001 51.5996 3.70001 51.5996H46.8C47.2 51.5996 47.5 51.2996 47.5 50.9996V48.4996H49.1C49.5 48.4996 49.8 48.1996 49.8 47.8996V1.19961C49.8 0.89961 49.5 0.599609 49.1 0.599609ZM18 1.89961H32.6V47.1996H18V1.89961ZM2 1.89961H16.6V47.1996H2V1.89961ZM46.2 50.3996H4.40002V48.4996H17.2H17.3H17.4H33.3H46.3V50.3996H46.2ZM48.5 47.1996H33.9V1.89961H48.5V47.1996Z"
      fill="currentColor"
    />
    <path
      d="M12.5 25.6992C12.1 25.6992 11.8 25.9992 11.8 26.2992V29.4992C11.8 29.8992 12.1 30.0992 12.5 30.0992C12.9 30.0992 13.2 29.7992 13.2 29.4992V26.2992C13.2 25.9992 12.9 25.6992 12.5 25.6992Z"
      fill="currentColor"
    />
    <path
      d="M22.3 25.6992C21.9 25.6992 21.6 25.9992 21.6 26.2992V29.4992C21.6 29.8992 21.9 30.0992 22.3 30.0992C22.7 30.0992 23 29.7992 23 29.4992V26.2992C22.9 25.9992 22.6 25.6992 22.3 25.6992Z"
      fill="currentColor"
    />
    <path
      d="M38.1 30.1998C38.5 30.1998 38.8 29.8998 38.8 29.5998V26.3998C38.8 25.9998 38.5 25.7998 38.1 25.7998C37.7 25.7998 37.4 26.0998 37.4 26.3998V29.5998C37.5 29.8998 37.8 30.1998 38.1 30.1998Z"
      fill="currentColor"
    />
  </svg>
);
