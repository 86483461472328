import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useConfirmMail } from '../../../../lib/core/repositories/register-repository';
import { ConfirmStatus } from '../../../../lib/core/api/generated';
import { pause } from '../../../../lib/utils/pause';
import { Typography } from '../../../../lib/themes/typography/Typography';
import { Page } from '../../../../lib/components/Page';
import { PageHeaderNormal } from '../../../../lib/components/Page';
import { EnvelopeIcon } from '../../../../lib/icons/EnvelopeIcon';
import { EnvelopeSuccessIcon } from '../../../../lib/icons/EnvelopeSuccessIcon';
import { PageContent, Message, Paragraph } from './EmailConfirmationPage.styled';
import fallenCup from '../../../../static/img/mocks/email-confirmation/fallen-cup.png';

type ConfirmationStatus = 'request' | 'success' | 'failure';

interface IEmailConfirmationPageParams {
  token: string;
}

export const EmailConfirmationPage: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<IEmailConfirmationPageParams>();
  const [submitMailConfirmation] = useConfirmMail();
  const [status, setStatus] = React.useState<ConfirmationStatus>('request');
  const delay = 2000;

  React.useEffect(() => {
    Promise.all([submitMailConfirmation({ token }), pause(delay)])
      .then(response => {
        setStatus(response[0].status === ConfirmStatus.Ok ? 'success' : 'failure');
      })
      .catch(() => {
        setTimeout(() => {
          window.scrollTo({ top: 0, left: 0 });
          setStatus('failure');
        }, delay);
      });
  }, [submitMailConfirmation, token]);

  React.useEffect(() => {
    if (status === 'success') {
      setTimeout(() => {
        // TODO: Change redirect url (?)
        history.push('/');
      }, 3000);
    }
  }, [history, status]);

  const renderStatusMessage = React.useCallback(() => {
    switch (status) {
      default:
      case 'request':
        return (
          <>
            <EnvelopeIcon />
            <Paragraph>Email confirmation in the process...</Paragraph>
          </>
        );
      case 'success':
        return (
          <>
            <EnvelopeSuccessIcon />
            <Paragraph>Your email address has been verified successfully!</Paragraph>
          </>
        );
      case 'failure':
        return (
          <>
            <img src={fallenCup} alt="Fallen cup" />
            <Paragraph>Confirmation error</Paragraph>
          </>
        );
    }
  }, [status]);

  return (
    <Page>
      <PageHeaderNormal />
      <PageContent>
        <Typography tag="h1" variant="h2">
          Email Confirmation
        </Typography>
        <Message>{renderStatusMessage()}</Message>
      </PageContent>
    </Page>
  );
};
