import { colors, styled } from '../../../../lib/themes';

export const StyledPaymentPageFooter = styled.div`
  position: fixed;
  bottom: 0;

  width: 100%;
  height: 80px;

  display: flex;
  align-items: center;

  background: ${colors.grayLight};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;
