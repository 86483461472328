import { Dispatch, SetStateAction, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Category, Room, RoomItem } from '../../../../../../lib/core/api/generated';
import { mergeQueries, SimpleQueryResult } from '../../../../../../lib/core/hooks/merge-queries';
import { useGetRoom } from '../../../../../../lib/core/repositories/room-repository';
import {
  getRootCategoriesByIds,
  useCategoryMap,
  useCategoryTree,
} from '../../../../../../lib/core/repositories/category-repository';
import { UseQueryResult } from '../../../../../../lib/core/hooks/use-store';
import { useDeepMemo } from '../../../../../../lib/core/hooks/deepMemo';

export interface IRoomProduct extends RoomItem {
  solutions: Category[];
}

/**
 * Solution is kinda filter of product list,
 * basically it includes all root categories + special 'all' value
 */
export type Solution = Category | { id: 'all'; name: 'Alle' };

/**
 * Value for 'All" solution.
 * Please, always compare Category and Solutions by id field.
 * Use this const for initialization or function argument,
 * but never in compare expression
 */
export const solutionAll: Solution = { id: 'all', name: 'Alle' };

function compare(a: RoomItem, b: RoomItem): number {
  return Number(a.id) - Number(b.id);
}

/**
 * This hook is very specific to FurnitureEditorPage
 * It takes initialRoomItems if it already has any
 * and merges it with room.items. This is needed to display kinda shopfront
 * with all possible products and user could adjust amount of any of them.
 * Sorting order is prioritized for initialRoomItems
 * @param roomId
 */
export function useRoomProducts(roomId: Room['id']): SimpleQueryResult<{
  products: IRoomProduct[];
  initialAmount: number;
  initialTotal: number;
  roomAmount: number;
  roomTotal: number;
} | null> {
  const [roomData, loadState] = mergeQueries([useGetRoom({ id: roomId }), useCategoryMap()]);

  const [productsResult, setProductResult] = useState<any>({
    products: [],
    initialAmount: 0,
    initialTotal: 0,
    roomAmount: 0,
    roomTotal: 0,
  });

  useDeepCompareEffect(() => {
    const [room, categoryMap] = roomData;

    if (loadState.isDone()) {
      const products: IRoomProduct[] = room.items
        .map(roomItem => ({
          ...roomItem,
          solutions: getRootCategoriesByIds(roomItem.productConfiguration.categoryIds, categoryMap),
        }))
        .sort(compare);

      const { initialAmount, initialTotal, roomAmount, roomTotal } = room.items.reduce(
        (acc, roomItem) => {
          if (!roomItem.added) {
            acc.initialAmount += roomItem.amount;
            acc.initialTotal += (roomItem.productConfiguration.price || 0) * roomItem.amount;
          } else {
            acc.roomAmount += roomItem.amount;
            acc.roomTotal += (roomItem.productConfiguration.price || 0) * roomItem.amount;
          }

          return acc;
        },
        { initialAmount: 0, initialTotal: 0, roomAmount: 0, roomTotal: 0 },
      );
      setProductResult({ products, initialAmount, initialTotal, roomAmount, roomTotal });
    }
  }, [roomData, loadState]);

  return [productsResult, loadState];
}

/**
 * Returns all Solutions, which includes
 * all root categories + alle item
 */
export function useSolutions(): UseQueryResult<void, Solution[]> {
  const [categories, loadState, requestFunc] = useCategoryTree();
  return [Array.isArray(categories) ? ([solutionAll] as Solution[]).concat(categories) : null, loadState, requestFunc];
}

/**
 * This hook handles current solution(root category) state and returns product list
 * filtered by the current solution
 * returns [roomProductsBySolution, currentSolution, setSolution]
 */
export function useRoomProductsBySolution([roomProducts, loadState]: SimpleQueryResult<IRoomProduct[]>): [
  IRoomProduct[],
  Solution,
  Dispatch<SetStateAction<Solution>>,
] {
  const [currentSolution, setSolution] = useState<Solution>(solutionAll);
  const productsBySolution = useDeepMemo(
    () => filterProductsBySolution(roomProducts, currentSolution),
    [roomProducts, loadState, currentSolution],
  );

  return [productsBySolution, currentSolution, setSolution];
}

export function filterProductsBySolution(allProducts: IRoomProduct[], solution: Solution): IRoomProduct[] {
  return solution.id === solutionAll.id
    ? allProducts
    : allProducts.filter(
        product => product.solutions && product.solutions.find(category => category.id === solution.id),
      );
}
