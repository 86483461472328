import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AddCard } from '../../../../../../../lib/components/AddCard';
import { SideSheet } from '../../../../../../../lib/components/SideSheet';

import { ProductDetails } from '../../../../ProductDetails';
import { Catalog } from '../Catalog';

import { useSnackBar } from '../../../../../../../lib/components/SnackBar';

import { Root } from './LetMePlanProducts.styled';
import {
  useAddRoomItem,
  useCreateRoomItem,
  useDropRoomItem,
  useRemoveRoomItem,
  useUpdateRoomItem,
} from '../../../../../../../lib/core/repositories/room-repository';
import { IRoomProduct } from '../../hooks/products-hooks';
import { ProductConfiguration } from '../../../../../../../lib/core/api/generated';
import { ProductCard } from '../../../../../../../lib/components/ProductCard';
import { useIsInvitedUser } from '../../../../../../../lib/core/hooks/useIsInvitedUser';

interface ILetMePlanProductsProps {
  roomId: string;
  roomProducts: IRoomProduct[];
}

/**
 * This Component renders shopfront with products already in the room (room.items.added === true) and
 * products suggested for the room (room.items.added === false), so user could
 * 1. Change amount of products which are already in the room
 * 2. Add suggested product into the room allowing change amount before adding
 * 3. Exclude product from the room
 * 4. Add another product via SideSheet, where user can change size, color of the product,
 *    or select another similar product
 */
export const LetMePlanProducts: React.FC<ILetMePlanProductsProps> = ({ roomId, roomProducts }) => {
  // Show Product Catalog
  const [isShowCatalog, setIsShowCatalog] = useState(false);

  // Show SideSheet with products details, where user can change size, color, or similarProduct
  const [productToDetail, setProductToDetail] = useState<IRoomProduct | null>(null);

  // API hooks
  const [addRoomItem] = useAddRoomItem();
  const [dropRoomItem] = useDropRoomItem();
  const isInvitedUser = useIsInvitedUser();
  const [createRoomItem] = useCreateRoomItem();
  const [updateRoomItem] = useUpdateRoomItem();
  const [removeRoomItem] = useRemoveRoomItem();

  const [editedAmount, setEditedAmount] = useState<{ [roomItemId: string]: number }>({});

  const debounceAmountChangingRef = useRef<{ [id: string]: number | undefined }>({});

  const { showAlert } = useSnackBar();

  useEffect(() => {
    return () => {
      Object.keys(debounceAmountChangingRef.current).forEach(roomItemId => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.clearTimeout(debounceAmountChangingRef.current[roomItemId]);
      });
    };
  }, []);

  const handleOpenDetails = (_roomProduct: IRoomProduct) => () => {
    setProductToDetail(_roomProduct);
  };

  const handleOnCloseProductDetail = () => {
    setProductToDetail(null);
  };

  // Add or remove product from room
  const handleClickToggleInListButton = (product: IRoomProduct) => (e: any) => {
    if (product.added) {
      if (product.productSetId) {
        // product from initial items
        return dropRoomItem({ roomItemId: product.id });
      }
      // product from catalog, permanent remove it
      return removeRoomItem({ roomItemId: product.id });
    }

    return addRoomItem({ roomItemId: product.id });
  };

  // Add modified or similar product into the room
  const handleOnProductDetailDone =
    ({ id: roomItemId }: IRoomProduct) =>
    (productConfiguration: ProductConfiguration, amount: number): Promise<unknown> => {
      setEditedAmount({
        ...editedAmount,
        [roomItemId]: amount,
      });

      return updateRoomItem({
        roomItemId,
        productConfigurationSku: productConfiguration.sku,
        amount,
      }).then(() => {
        setProductToDetail(null);
      });
    };

  const handleChangeAmount = useCallback(
    (roomProduct: IRoomProduct) => (amount: number) => {
      const { current: debounceAmountChanging } = debounceAmountChangingRef;

      setEditedAmount({
        ...editedAmount,
        [roomProduct.id]: amount,
      });

      window.clearTimeout(debounceAmountChanging[roomProduct.id]);

      debounceAmountChanging[roomProduct.id] = window.setTimeout(() => {
        debounceAmountChanging[roomProduct.id] = undefined;
        updateRoomItem({
          roomItemId: roomProduct.id,
          productConfigurationSku: roomProduct.productConfiguration.sku,
          amount,
        });
      }, 500);
    },
    [editedAmount, updateRoomItem],
  );

  const handleOnShowCatalog = () => {
    setIsShowCatalog(true);
  };

  const handleOnCloseCatalog = () => {
    setIsShowCatalog(false);
  };

  const handleOnSelectProductFromCatalog = (productConfiguration: ProductConfiguration, amount: number) => {
    return createRoomItem({
      roomId,
      productConfigurationSku: productConfiguration.sku,
      amount,
    }).catch(() => showAlert('Es ist ein Fehler mit dem Server aufgetreten. Bitte versuchen Sie es erneut.'));
  };

  return (
    <>
      <Root>
        {roomProducts.map(product => (
          <ProductCard
            productConfiguration={product.productConfiguration}
            amount={editedAmount[product.id] || product.amount}
            isInList={product.added}
            onChangeAmount={handleChangeAmount(product)}
            onClickDetailsButton={handleOpenDetails(product)}
            onClickToggleInListButton={handleClickToggleInListButton(product)}
            key={product.id}
          />
        ))}

        <AddCard
          title="Produkt hinzufügen"
          onClick={handleOnShowCatalog}
          css={`
            min-height: 497px;
          `}
        />
      </Root>

      <SideSheet isOpened={!!productToDetail} onClose={handleOnCloseProductDetail}>
        {productToDetail && (
          <ProductDetails
            productConfiguration={productToDetail.productConfiguration}
            productConfigurations={
              productToDetail.productConfiguration?.configurableProduct?.productConfigurations || []
            }
            productSetId={productToDetail.productSetId || null}
            isEditable={true}
            amount={editedAmount[productToDetail.id] || productToDetail.amount}
            onDone={handleOnProductDetailDone(productToDetail)}
            isInvitedUser={isInvitedUser}
          />
        )}
      </SideSheet>

      <SideSheet onClose={handleOnCloseCatalog} isOpened={isShowCatalog} isNoPadding={true}>
        <Catalog
          onSelect={handleOnSelectProductFromCatalog}
          roomProducts={roomProducts.map(product => product.productConfiguration.sku)}
        />
      </SideSheet>
    </>
  );
};
