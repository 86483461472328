import React from 'react';

import { LoaderStyled, Spinner, SpinnerInner } from './Loader.styled';

export const Loader: React.FC<{ className?: string }> = ({ className }) => (
  <LoaderStyled className={className}>
    <Spinner>
      <SpinnerInner />
    </Spinner>
  </LoaderStyled>
);
