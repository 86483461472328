import React from 'react';

export const AlertIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="16" cy="16" r="15" stroke="currentColor" strokeWidth="2" />
    <path
      d="M16 13V24"
      stroke="currentColor"
      strokeWidth="2"
      css={`
        transform: translate3d(0, -4px, 0);
      `}
    />
    <path
      d="M16 26L16 28"
      stroke="currentColor"
      strokeWidth="2"
      css={`
        transform: translate3d(0, -4px, 0);
      `}
    />
  </svg>
);
