import React, { useRef } from 'react';
import { Button } from '../../../../../../../lib/components/Button';
import Draggable from 'react-draggable';
import { formatCurrency } from '../../../../../../../lib/utils/format-number';
import { useAddAllRoomItems } from '../../../../../../../lib/core/repositories/room-repository';
import { Room } from '../../../../../../../lib/core/api/generated';
import { Typography } from '../../../../../../../lib/themes/typography/Typography';
import { Amount, Root, Total, Summary } from './PackageStatus.styled';

interface IPackageStatusProps {
  amount: number;
  total: number;
  roomId: Room['id'];
  offsetParent?: HTMLDivElement;
}

export const PackageStatus: React.FC<IPackageStatusProps> = ({ amount, total, roomId, offsetParent }) => {
  const [runAddAllRoomItems, _, loadState] = useAddAllRoomItems();
  const addAllProducts = React.useCallback(() => {
    return runAddAllRoomItems({ roomId });
  }, [roomId, runAddAllRoomItems]);

  const areAllInside = amount === 0;

  if (areAllInside) {
    return null;
  }

  return (
    <Draggable offsetParent={offsetParent} bounds="parent">
      <Root>
        <Typography tag="h2" variant="h4">
          Ihr funktionales Raumkonzept
        </Typography>
        <Summary>
          <Amount>{amount} Produkte</Amount>
          <Total>{formatCurrency(total)}</Total>
        </Summary>

        <Button
          variant="outlined"
          color="bronze"
          onClick={addAllProducts}
          isLoading={loadState.isPending()}
          disabled={areAllInside}
        >
          {loadState.isPending() ? 'Hinzufügen...' : `Gesamtes Paket kaufen`}
        </Button>
      </Root>
    </Draggable>
  );
};
