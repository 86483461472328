import { styled, colors, h1 } from '../../../../../../lib/themes';

export const DeliveryStyled = styled.div`
  margin-bottom: 100px;
`;

export const DeliveryTitle = styled.h1`
  ${h1};
`;

export const Table = styled.table`
  width: 100%;

  font-size: 20px;
  line-height: 1.5;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
  padding-right: 27px;

  &:nth-child(2) {
    font-size: 15px;
    line-height: 1.67;
  }
`;

export const BillingAddress = styled.div`
  color: ${colors.grayDark};
`;
