import React from 'react';

export const BenchTwoSeatWideIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="54" height="42" viewBox="0 0 54 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M51.6 18.125H2.29999C0.999988 18.125 0 19.125 0 20.425V24.725C0 26.025 0.999988 27.025 2.29999 27.025H5L2 40.425C2 40.825 2.20004 41.125 2.60004 41.125H2.70001C3.00001 41.125 3.29999 40.925 3.29999 40.525L6.29999 27.025H47.6L50.6 40.525C50.6 40.825 50.9 41.125 51.2 41.125H51.3C51.7 41.125 51.9 40.725 51.9 40.425L48.9 27.025H51.6C52.9 27.025 53.9 26.025 53.9 24.725V20.425C53.9 19.225 52.9 18.125 51.6 18.125ZM52.6 24.825C52.6 25.425 52.1 25.825 51.6 25.825H2.29999C1.69999 25.825 1.29999 25.325 1.29999 24.825V20.525C1.29999 19.925 1.79999 19.525 2.29999 19.525H51.6C52.2 19.525 52.6 20.025 52.6 20.525V24.825Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 16.625C3.5 15.2443 4.61929 14.125 6 14.125H24C25.3807 14.125 26.5 15.2443 26.5 16.625V18.625C26.5 18.9011 26.2761 19.125 26 19.125C25.7239 19.125 25.5 18.9011 25.5 18.625V16.625C25.5 15.7966 24.8284 15.125 24 15.125H6C5.17157 15.125 4.5 15.7966 4.5 16.625V18.625C4.5 18.9011 4.27614 19.125 4 19.125C3.72386 19.125 3.5 18.9011 3.5 18.625V16.625Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 16.625C27.5 15.2443 28.6193 14.125 30 14.125H48C49.3807 14.125 50.5 15.2443 50.5 16.625V18.625C50.5 18.9011 50.2761 19.125 50 19.125C49.7239 19.125 49.5 18.9011 49.5 18.625V16.625C49.5 15.7966 48.8284 15.125 48 15.125H30C29.1716 15.125 28.5 15.7966 28.5 16.625V18.625C28.5 18.9011 28.2761 19.125 28 19.125C27.7239 19.125 27.5 18.9011 27.5 18.625V16.625Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.375 3.625C1.375 1.62297 2.99797 0 5 0H49C51.002 0 52.625 1.62297 52.625 3.625V18.625H51.375V3.625C51.375 2.31332 50.3117 1.25 49 1.25H5C3.68832 1.25 2.625 2.31332 2.625 3.625V18.625H1.375V3.625Z"
      fill="currentColor"
    />
  </svg>
);
