import { styled } from '../../../../lib/themes';

import { PackageCardStyled, PackageCardWrap } from '../../../../lib/components/PackageCard/PackageCard.styled';

export const RoomPackages = styled.div``;

// TODO: Refactor (high coupling)
export const Packages = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
  margin-left: -16px;
  margin-right: -16px;

  ${PackageCardWrap} {
    padding: 0 48px 95px;
    width: 50%;
  }

  ${PackageCardStyled} {
    height: 100%;
  }
`;
