import React from 'react';
import { On } from '../../../../../lib/core/hooks/on';
import { CError } from '../../../../../lib/components/Error';
import { useRoomProducts } from '../FurnitureEditorPage/hooks/products-hooks';
import { RoomPlanMode } from '../../../../../lib/core/api/generated';
import { formatCurrency } from '../../../../../lib/utils/format-number';
import { AllRoomSteps } from '../../../typesUtils';
import { IConfiguratorItemsPagesProps } from '../ConfiguratorItemsPagesProps';
import { FurnitureExpansionPanels } from '../../../components/FurnitureExpansionPanels';
import { ConfiguratorPageFooter } from '../../../components/ConfiguratorPageFooter';
import { Loader } from '../../../../../lib/components/Loader';
import { Container } from '../../../../../lib/components/Container';
import { Jumbotron } from '../../../../../lib/components/Jumbotron/Jumbotron';
import { FlexContainer } from '../../../../../lib/components/FlexContainer';
import { Title } from '../../../../../lib/components/Title';
import { Typography } from '../../../../../lib/themes/typography/Typography';
import { MoodboardSlider } from '../components';
import { useIsInvitedUser } from '../../../../../lib/core/hooks/useIsInvitedUser';

import { ContentItem, ProductOrCost } from '../../../components/ConfiguratorPageFooter/ConfiguratorPageFooter.styled';
import { Page } from './FurnitureViewerPage.styled';

export const FurnitureViewerPage: React.FC<IConfiguratorItemsPagesProps> = ({ room, changeStep, step }) => {
  const isInvitedUser = useIsInvitedUser();
  return On(
    ({ products, roomAmount, roomTotal }) => {
      return (
        <>
          <Page>
            <MoodboardSlider moodboard={room.moodboard} />
            <Container>
              <Jumbotron>
                <FlexContainer css={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 155 }}>
                  <Title>Ihre ausgewählten Produkte</Title>
                  <Typography tag="p" variant="h5" css={{ marginLeft: 40, maxWidth: 540 }}>
                    Lassen Sie das nod studios Team ihre Produktauswahl prüfen und sicherstellen das all ihre
                    Anforderungen getroffen wurden. Hier bitte zum letzten Punkt der Zusammenfassung gehen.
                  </Typography>
                </FlexContainer>
              </Jumbotron>
            </Container>

            <FurnitureExpansionPanels room={room} roomProducts={products} />
          </Page>

          <ConfiguratorPageFooter
            step={step}
            changeStep={changeStep}
            prevStep={
              isInvitedUser
                ? undefined
                : room.planMode === RoomPlanMode.PlanForMe
                ? AllRoomSteps.RoomParams
                : AllRoomSteps.RoomItemsEditor
            }
            nextStep={AllRoomSteps.RoomSummary}
            submitDisabled={roomTotal === 0}
          >
            <ContentItem />
            <ContentItem>
              <ProductOrCost data-hook-value={'TotalProduct'}>{roomAmount} Produkte</ProductOrCost>
              <ProductOrCost data-hook-value={'TotalPrice'}>{formatCurrency(roomTotal)}</ProductOrCost>
            </ContentItem>
            <ContentItem />
          </ConfiguratorPageFooter>
        </>
      );
    },
    () => <Loader />,
    errorState => <CError error={errorState} />,
    useRoomProducts(room.id),
  );
};
