import { h3, h4, styled } from '../../../../../../../lib/themes';

export const Root = styled.div`
  cursor: grab;
  position: absolute;
  height: 239px;
  left: calc((100vw - 1170px) / 2);
  bottom: 64px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 74px rgba(0, 0, 0, 0.25);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  &:active {
    cursor: grabbing;
  }
  h2 {
    font-weight: bold;
  }
`;

export const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Amount = styled.div`
  ${h4};
`;

export const Total = styled.div`
  ${h3};
  margin-left: 20px;
`;
