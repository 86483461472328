import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useOutsideClickHandler } from '../../hooks/useClickOutside';
import { StyledPopover } from './Popover.styled';
import { useEscape } from '../../hooks/useEscape';
import { useBoxSize } from '../../hooks/useBoxSize';

interface IPopoverProps {
  anchorRef: MutableRefObject<any>;
  isOpened: boolean;
  onClose: () => void;
}

export const Popover: React.FC<IPopoverProps> = props => {
  const { children, anchorRef, isOpened, onClose } = props;

  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleClose = useCallback(() => {
    if (ref.current && anchorRef.current) {
      onClose();
    }
  }, [anchorRef, onClose]);

  useOutsideClickHandler([ref, anchorRef], handleClose);

  useEscape(handleClose);

  const [position, setPosition] = useState({
    left: 0,
    top: 0,
  });

  const [isVisible, setIsVisible] = useState(false);
  const [anchorSizes, setAnchorNode] = useBoxSize();
  const [popoverSizes, setPopoverNode] = useBoxSize();

  const setRef = useCallback(
    node => {
      setPopoverNode(node);
      ref.current = node;
    },
    [setPopoverNode],
  );

  useEffect(() => {
    setAnchorNode(anchorRef.current);
  }, [anchorRef, setAnchorNode]);

  useEffect(() => {
    if (anchorSizes.wasCalculated && popoverSizes.wasCalculated) {
      const newPosition = {
        left: anchorSizes.left + anchorSizes.width - popoverSizes.width,
        top: anchorSizes.top + anchorSizes.height,
      };
      if (newPosition.top + popoverSizes.height > window.innerHeight) {
        newPosition.top = newPosition.top - (newPosition.top + popoverSizes.height - window.innerHeight);
      }

      if (newPosition.top < 0) {
        newPosition.top = 0;
      }

      setPosition(newPosition);
      setIsVisible(true);
    }
  }, [isOpened, anchorSizes, popoverSizes]);

  if (isOpened) {
    return (
      <StyledPopover
        css={`
          transform: translate(${position.left}px, ${position.top}px);
          opacity: ${isVisible ? 1 : 0};
        `}
        ref={setRef}
      >
        {children}
      </StyledPopover>
    );
  }

  return null;
};
