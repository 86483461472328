import { colors, styled } from '../../../../lib/themes';

export const Page = styled.div`
  padding: 80px 0;
  height: 100%;
`;

export const UserWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
`;

export const CompanyImg = styled.img`
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid ${colors.grayLighter};
`;

export const UserInfo = styled.div`
  margin-left: 30px;
`;

export const CompanyName = styled.h3`
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
`;

export const UserName = styled.span`
  font-size: 20px;
  line-height: 30px;
  color: ${colors.black};
`;

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
