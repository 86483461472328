import { styled, colors, h2 } from '../../../../lib/themes';

export const Content = styled.div`
  position: absolute;
  top: 190px;
  z-index: 1;
`;

export const RoomName = styled.h2`
  font-weight: bold;
  font-size: 72px;
  line-height: 75px;
  color: ${colors.white};
  text-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
`;

export const StyleName = styled.h3`
  ${h2};
  margin-top: 30px;
  color: ${colors.white};
  text-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
`;
