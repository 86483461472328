import React from 'react';

export const WideArmchairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="52" height="36" viewBox="0 0 52 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43.5 27.3C48.7 23.7 51.9 18.5 51.9 12.7C51.9 11.1 51.1 8 48.1 8C48 8 47.9 8 47.8 8C47.3 0.8 35.2 0 26.1 0C17 0 5.00002 0.8 4.40002 8C4.30002 8 4.19998 8 4.09998 8C1.09998 8 0.299988 11.1 0.299988 12.7C0.299988 18.5 3.60001 23.7 8.70001 27.3L3.59998 34.2C3.39998 34.5 3.40001 34.9 3.70001 35.1C3.80001 35.2 3.99998 35.2 4.09998 35.2C4.29998 35.2 4.49998 35.1 4.59998 34.9L9.79999 27.9C14.2 30.7 19.9 32.3 26 32.3C32.1 32.3 37.8 30.6 42.2 27.9L47.4 34.9C47.5 35.1 47.7 35.2 47.9 35.2C48 35.2 48.2 35.2 48.3 35.1C48.6 34.9 48.6 34.5 48.4 34.2L43.5 27.3ZM26.2 1.29999C39.7 1.29999 46.3 3.59999 46.6 8.29999C46 8.49999 45.5 8.80001 45.1 9.20001C43.8 10.6 44 12.7 44 12.7C44 13.3 43.9 13.8 43.8 14.3H8.59998C8.49998 13.8 8.40002 13.3 8.40002 12.7C8.40002 12.6 8.59999 10.5 7.29999 9.09998C6.89999 8.69998 6.39999 8.40001 5.79999 8.20001C6.09999 3.60001 12.8 1.29999 26.2 1.29999ZM43.4 15.7C41.4 20.7 34.3 24.5 26.2 24.5C18.1 24.5 11 20.7 9 15.7H43.4ZM1.79999 12.8C1.79999 12.7 1.79999 9.40002 4.29999 9.40002C5.19999 9.40002 5.90002 9.70001 6.40002 10.2C7.30002 11.2 7.20001 12.8 7.20001 12.9C7.20001 20 15.9 26 26.3 26C36.7 26 45.4 20 45.4 12.9C45.4 12.9 45.3 11.3 46.2 10.3C46.7 9.79999 47.4 9.5 48.3 9.5C50.7 9.5 50.8 12.8 50.8 12.9C50.8 23 39.8 31.3 26.4 31.3C13 31.3 1.79999 22.9 1.79999 12.8Z"
      fill="currentColor"
    />
  </svg>
);
