import { colors, styled } from '../../../../lib/themes';
import { Container, Button, IButtonProps } from '../../../../lib/components';
import { RoomItemsStep, RoomParamsStep } from '../../typesUtils';

interface IAnimated {
  step: RoomItemsStep | RoomParamsStep;
}

interface IWithLoading {
  isLoading?: boolean;
}

export const Root = styled.footer`
  height: 80px;
  background-color: ${colors.grayLight};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  box-shadow: 0px -4px 13px rgba(0, 0, 0, 0.06);
`;

export const ButtonsWrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 80px;
`;

const stepMap: { [key: string]: number } = {
  [RoomParamsStep.RoomStyle]: 0,
  [RoomParamsStep.RoomParams]: 0,
  [RoomItemsStep.RoomItemsEditor]: 1,
  [RoomItemsStep.RoomItemsViewer]: 1,
  [RoomItemsStep.RoomSummary]: 2,
  // [RoomParamsStep.PlanType]: 0,
};

export const Content = styled.div<IAnimated>`
  width: 100%;
  flex-grow: 1;
  align-self: flex-start;
  transition: margin-top 0.5s;
  margin-top: ${({ step }) => stepMap[step] * -80}px;
`;

export const ContentItem = styled.div<IWithLoading>`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:last-child {
    padding: 0 30px;
  }
  opacity: ${({ isLoading }) => (isLoading ? 0.2 : 1)};
`;

export const Budget = styled.strong`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${colors.gray};
`;

export const ProductOrCost = styled.strong`
  display: flex;
  width: 50%;
  height: 51px;
  text-align: center;
  align-items: center;
  justify-content: center;
  &:first-child {
    border-right: 1px solid ${colors.gray};
  }
`;
