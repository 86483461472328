import React from 'react';
import * as Yup from 'yup';

import { Typography } from '../../themes/typography/Typography';
import { Form, FormButton, FormFooter, FormInput, FormLayout } from '../Form';
import { requiredString } from '../../utils/yup-reductions';

interface IInvitationFormProps {
  onSubmit: (variables: { email: string }) => Promise<void>;
  onClose: () => void;
}

export const InvitationForm: React.FC<IInvitationFormProps> = ({ onSubmit, onClose }) => (
  <Form onSubmit={onSubmit} initialValues={{ email: '' }} validationSchema={validationSchema}>
    <FormLayout>
      <Typography tag="h2" variant="h5">
        Lassen Sie sich das Konzept per Mausklick freigeben
      </Typography>
      <FormInput name="email" label="E-Mail" />
      <FormFooter>
        <FormButton color="bronze" onClick={onClose} isIgnoreValidation={true}>
          Abbrechen
        </FormButton>
        <FormButton type="submit" variant="contained" color="bronze">
          Freigabe
        </FormButton>
      </FormFooter>
    </FormLayout>
  </Form>
);

const validationSchema = Yup.object().shape({
  email: requiredString('Dieses Feld darf nicht leer sein.').email('E-Mail-Adresse falsch'),
});
