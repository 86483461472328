import detectIt from 'detect-it';
import { Subject } from '../core/observable/subject';
import { useSubject } from '../core/observable/use-subject';

export class Device {
  mediaQuerySmall = window.matchMedia('(max-width: 767px)');
  mediaQueryTabletSmall = window.matchMedia('(max-width: 1023px)');
  mediaQueryTablet = window.matchMedia('(max-width: 1199px)');
  mediaQueryDesktop = window.matchMedia('(max-width: 1399px)');

  isSmallScreen: Subject<boolean>;

  isTableScreen: Subject<boolean>;

  isTableSmallScreen: Subject<boolean>;

  isDesktopScreen: Subject<boolean>;

  constructor() {
    this.isSmallScreen = new Subject<boolean>(this.mediaQuerySmall.matches);
    this.isTableSmallScreen = new Subject<boolean>(this.mediaQueryTabletSmall.matches);
    this.isTableScreen = new Subject<boolean>(this.mediaQueryTablet.matches);
    this.isDesktopScreen = new Subject<boolean>(this.mediaQueryDesktop.matches);

    this.mediaQuerySmall.addEventListener('change', () => {
      this.isSmallScreen.next(this.mediaQuerySmall.matches);
    });
    0;
    this.mediaQueryTablet.addEventListener('change', () => {
      this.isTableScreen.next(this.mediaQueryTablet.matches);
    });

    this.mediaQueryTabletSmall.addEventListener('change', () => {
      this.isTableSmallScreen.next(this.mediaQueryTabletSmall.matches);
    });

    this.mediaQueryDesktop.addEventListener('change', () => {
      this.isDesktopScreen.next(this.mediaQueryDesktop.matches);
    });
  }

  public get _isSynthetic(): boolean {
    return Boolean((window as any).callPhantom) || Boolean(window.navigator.webdriver);
  }

  public get _isTouch(): boolean {
    return !this._isSynthetic && detectIt.primaryInput === 'touch';
  }
}

const device: Device = new Device();

export function useIsMobile(): boolean {
  return useSubject(device.isSmallScreen) || device._isTouch;
}

export function useIsPhone() {
  return useSubject(device.isSmallScreen) && device._isTouch;
}

export function useIsTablet() {
  return useSubject(device.isTableScreen);
}

export function useIsTabletSmall() {
  return useSubject(device.isTableSmallScreen);
}

export function useIsDesktop() {
  return useSubject(device.isDesktopScreen);
}
