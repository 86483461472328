import React from 'react';

import { IButtonProps } from '../../Button';
import { StyledNavigationButton, Label, Icon } from './NavigationButton.styled';

export type NavigationButtonDirection = 'back' | 'forward';

export interface INavigationButtonProps extends IButtonProps {
  direction?: NavigationButtonDirection;
  href?: string;
}

export const NavigationButton: React.FC<INavigationButtonProps> = ({ children, direction = 'forward', ...rest }) => {
  return (
    <StyledNavigationButton direction={direction} {...rest} iconEnd={<Icon />}>
      <Label data-hook-button={children}>{children}</Label>
    </StyledNavigationButton>
  );
};
