import { styled } from '../../../themes';

export const StyledFormFieldRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    &:not(:first-child) {
      margin-left: 32px;
    }
  }
`;
