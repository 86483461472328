import { styled, colors, h1, body } from '../../../../lib/themes';

export const StyledMembershipBlock = styled.div`
  height: 380px;

  display: flex;
  align-items: center;

  background-image: url('/img/mocks/style-example/membership-block.png');
  background-size: cover;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 800px;
  color: ${colors.white};
`;

export const Title = styled.h2`
  ${h1};
  margin-bottom: 30px;
`;

export const Description = styled.p`
  ${body};
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
`;
