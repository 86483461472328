import akustik1 from '../../../../../static/img/otherParamsScreen/akkustik_1.png';
import akustik2 from '../../../../../static/img/otherParamsScreen/akkustik_2.png';
import akustik3 from '../../../../../static/img/otherParamsScreen/akkustik_3.png';
import furniture1 from '../../../../../static/img/otherParamsScreen/furniture_1.png';
import furniture2 from '../../../../../static/img/otherParamsScreen/furniture_2.png';
import furniture3 from '../../../../../static/img/otherParamsScreen/furniture_3.png';
import lounge1 from '../../../../../static/img/otherParamsScreen/lounge_1.png';
import lounge2 from '../../../../../static/img/otherParamsScreen/lounge_2.png';
import lounge3 from '../../../../../static/img/otherParamsScreen/lounge_3.png';
import storage1 from '../../../../../static/img/otherParamsScreen/storage_1.png';
import storage2 from '../../../../../static/img/otherParamsScreen/storage_2.png';
import storage3 from '../../../../../static/img/otherParamsScreen/storage_3.png';
import tools1 from '../../../../../static/img/otherParamsScreen/tools_1.png';
import tools2 from '../../../../../static/img/otherParamsScreen/tools_2.png';
import tools3 from '../../../../../static/img/otherParamsScreen/tools_3.png';

export const otherParams = {
  size: {
    title: 'Raumgröße',
    description: '<p>Eine genaue Flächengröße hilft bei der Projektverwaltung.</p>',
  },
  staticWorkstations: {
    title: 'Feste Arbeitsplätze',
    description:
      '<p>Wie viele Mitarbeiter arbeiten auf der geplanten Fläche? Ihre Angabe fügt die richtige Anzahl an Schreibtischen automatisch zu ihrem Konzept.</p>',
  },
  flexibleWorkstations: {
    title: 'Flexible Arbeitsplätze',
    description: '<p>Wie viele Arbeitsplätze sollen variabel sein?</p>',
  },
  soundproofingRequired: {
    title: 'Schallschutz',
    description: `<p>Verbesserter Schallschutz</p>
    <p>Ein Zehntel aller Deutschen wünscht sich Ruheräume für die Arbeit. </p>
    <p>
      In unserem Akustik-Portfolio können Sie genau bestimmen, welches Design den Bedürfnissen Ihrer
      Mitarbeiter entspricht und deren Produktivität erhöht.
    </p>
    <p>
      Der akustische Schallschutz umfasst verschiedene Maßnahmen, um Lärm zu verringern und die Akustik
      zu verbessern. Das sorgt für angenehmeres Arbeiten, beispielsweise in Großraumbüros oder im
      Call-Center. Oft reichen kleine Variationen, um Ablenkung durch Störgeräusche zu vermeiden und die
      Raumakustik spürbar zu verbessern.
    </p>`,
    images: [akustik1, akustik2, akustik3],
  },
  storageRequired: {
    title: 'Stauraum',
    description: `<p>Stauraum für gute Ideen </p>
    <p>
      Eine ordentliche Arbeitsumgebung fördert die Gewissenhaftigkeit unter den Mitarbeitern und wird
      deswegen auch von der Wissenschaft empfohlen. Unterstützen Sie Ihre Mitarbeiter durch eine
      geeignete Umgebung für hohe Produktivität und kreative Ideen.
    </p>
    <p>In unserer Stauraum-Übersicht finden Sie passende Modelle für Ihren Einrichtungsstil.</p>`,
    images: [storage1, storage2, storage3],
  },
  loungeRequired: {
    title: 'Lounge',
    description: `<p>
    Büros sind nicht mehr bloß ein Raum, ein Schreibtisch und ein flexibler Stuhl. Moderne Büros
    umschmeicheln die Mitarbeiter, bieten ihnen Raum zum Wohlfühlen und einen Ort für ein positives
    Gemeinschaftserlebnis. Stöbern Sie durch unser Lounge-Portfolio, um Ihr Büro mit wenig Aufwand
    gemütlicher zu gestalten.
  </p>`,
    images: [lounge1, lounge2, lounge3],
  },
  furnitureTypeId: {
    title: 'Möbelart',
    description: `<p>Verschiende Style und Möglichkeiten stehen zur verfügung.</p>`,
    images: [furniture1, furniture2, furniture3],
  },
  spaceRequired: {
    title: 'Zubehör',
    description: `<p>Zubehör für alle Gelegenheiten</p>
    <p>Accessoires runden ein Bürokonzept ab und bringen individuellen Touch. Im Zubehör-Bereich gibt es Gadgets passend zu Ihrem Einrichtungsstil.</p>
    `,
    images: [tools1, tools2, tools3],
  },
};
