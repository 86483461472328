import React from 'react';

import { RadioGroupField } from './RadioGroupField';

export const furnitureTypes = [
  { value: '6176', label: 'Polster' },
  { value: '6177', label: 'Holz' },
  { value: '6178', label: 'Gemischt' },
];

interface IFurnitureTypesFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  hasFab?: boolean;
  smartTipContent?: React.ReactNode;
  onInfoButtonClick?: (refEl: React.RefObject<any>, smartTipContent: React.ReactNode) => void;
}

export const FurnitureTypesField: React.FC<IFurnitureTypesFieldProps> = ({
  name,
  label,
  disabled = false,
  smartTipContent,
  onInfoButtonClick,
}) => {
  return (
    <RadioGroupField
      name={name}
      title={label}
      items={furnitureTypes}
      disabled={disabled}
      smartTipContent={smartTipContent}
      onInfoButtonClick={onInfoButtonClick}
    />
  );
};
