import { styled, colors } from '../../../../lib/themes';
import { PayPalLogoIcon } from '../../../../lib/icons/PayPalLogoIcon';

export const StyledPaymentPage = styled.div`
  padding: 80px 0 100px;
`;

export const PaymentHeader = styled.div`
  padding-top: 60px;
`;

export const Content = styled.div``;

export const TitleWrapper = styled.div`
  margin-bottom: 60px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 50px;
  width: 100%;
`;

export const TableHead = styled.thead`
  color: ${colors.grayDark};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${colors.grayLightest};
`;

export const TableCell = styled.td`
  padding-top: 18px;
  padding-bottom: 18px;

  &:nth-child(2) {
    text-align: right;
  }
`;

export const PayPalLogoIconStyled = styled(PayPalLogoIcon)`
  margin-top: 6px;
  margin-left: 16px;
`;

export const PaymentFooter = styled.div`
  padding-top: 6px;
  text-align: right;
`;

export const PreviousPrice = styled.p`
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 1.17;
  font-weight: 700;
  text-decoration: line-through;
  color: ${colors.red};
`;

export const FinalPrice = styled.div`
  margin-bottom: 88px;
  font-size: 72px;
  line-height: 1;
  font-weight: 700;
`;

export const SubmitButtonWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-end;

  & > * {
    &:not(:last-child) {
      margin-right: 60px;
    }
  }
`;

export const LinkText = styled.span`
  color: ${colors.bronze};
`;
