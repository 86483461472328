import { styled, colors } from '../../../themes';
import { IconButton } from '../../IconButton';

export const StyledPageHeader = styled.header`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;

  width: 100%;
  height: 80px;
  padding: 0 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${colors.white};
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
`;

export const LeftColumn = styled.div``;

export const CenterColumn = styled.div`
  flex: 1;
`;

export const RightColumn = styled.div``;

export const RightMenuButton = styled(IconButton)``;
