import { styled, colors, applyCssIf, css, h3 } from '../../themes';
import { Button } from '../Button';

interface IHasCloseButton {
  hasCloseButton: boolean;
}

interface StyledCardProp {
  width: string;
  height: string;
  noPadding: boolean;
}

export const StyledCard = styled.div<StyledCardProp>`
  position: relative;

  width: ${p => p.width};
  height: ${p => p.height};
  padding: ${p => (p.noPadding ? 0 : '50px 50px 60px')};

  border-radius: 6px;
  background-color: ${colors.white};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);

  @media (max-width: 600px) {
    border-radius: 0;
    width: 100vw;
  }
`;

export const Title = styled.h3<IHasCloseButton>`
  ${h3};
  margin: 0;
  margin-bottom: 40px;
  color: ${colors.black};

  ${({ hasCloseButton }) =>
    applyCssIf(!hasCloseButton)(css`
      width: 100%;
      text-align: center;
    `)};
`;

export const CloseButton = styled(Button).attrs(props => ({ variant: 'outlined', color: props.color }))`
  position: absolute;
  z-index: 3;
  top: 50px;
  right: 50px;
  min-width: 48px;
  width: 48px;
  height: 48px;
  background-color: transparent;

  padding: 0;
  border-radius: 50%;

  svg {
    margin: 0 !important;
    height: 20px !important;
  }
`;
