import React from 'react';

export const ChairIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="29" height="47" viewBox="0 0 29 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.2 27.2004V24.9004C28.2 24.1004 27.6 23.4004 26.8 23.3004L25.2 7.6004C24.8 3.8004 21.6 0.900391 17.8 0.900391H10.7C6.90001 0.900391 3.69999 3.8004 3.29999 7.6004L1.70001 23.3004C0.900012 23.4004 0.299988 24.1004 0.299988 24.9004V27.2004C0.299988 28.1004 0.999994 28.8004 1.89999 28.8004H2.70001L0.5 45.8004C0.5 46.0004 0.500012 46.2004 0.700012 46.3004C0.800012 46.4004 1.00001 46.5004 1.20001 46.5004H4.20001C4.50001 46.5004 4.79999 46.3004 4.79999 45.9004L7 28.7004H21.4L23.6 45.9004C23.6 46.2004 23.9 46.5004 24.2 46.5004H27.2C27.4 46.5004 27.6 46.4004 27.7 46.3004C27.8 46.2004 27.9 46.0004 27.9 45.8004L25.7 28.8004H26.5C27.5 28.9004 28.2 28.1004 28.2 27.2004ZM4.60001 7.70039C4.90001 4.60039 7.50001 2.20039 10.7 2.20039H17.8C21 2.20039 23.6 4.60039 23.9 7.70039L25.5 23.2004H3L4.60001 7.70039ZM1.60001 27.2004V24.9004C1.60001 24.7004 1.79999 24.6004 1.89999 24.6004H2.29999H26.2H26.6C26.8 24.6004 26.9 24.8004 26.9 24.9004V27.2004C26.9 27.4004 26.7 27.5004 26.6 27.5004H25.1H22.1H6.5H3.5H2C1.7 27.6004 1.60001 27.4004 1.60001 27.2004ZM3.60001 45.3004H1.89999L4 28.8004H5.70001L3.60001 45.3004ZM26.6 45.3004H24.9L22.8 28.8004H24.5L26.6 45.3004Z"
      fill="currentColor"
    />
  </svg>
);
