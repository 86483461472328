import React, { useMemo } from 'react';
import { Typography } from '../../../../../../lib/themes/typography/Typography';
import {
  ImageCardsFormField,
  IImageCard,
} from '../../../../../../lib/components/ImageCardsFormField/ImageCardsFormField';
import { TUrl } from '../../../../../../types';
import { BackButton, TitleWrapper } from '../../RoomStylePage/RoomStylePage.styled';

interface Card {
  id: string;
  cover: TUrl;
  name: string;
  colors?: string[];
}

interface ImageCardsScreenProps {
  onChange: () => any;
  onBack?: () => any;
  imageCardsGenerator: () => IImageCard[];
  title: string;
  fieldName: string;
}

export const ImageCardsScreen: React.FC<ImageCardsScreenProps> = ({
  onChange,
  imageCardsGenerator,
  title,
  fieldName,
  onBack,
}) => {
  const handleOnChange = React.useCallback(() => {
    onChange();
  }, [onChange]);

  const handleBack = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onBack && onBack();
    },
    [onBack],
  );

  const imageCards = useMemo(imageCardsGenerator, [imageCardsGenerator]);

  return (
    <>
      <TitleWrapper>
        {onBack && <BackButton onClick={handleBack} />}
        <Typography
          tag="h1"
          variant="h1"
          css={`
            text-align: center;
            margin: 60px 0 60px;
          `}
        >
          {title}
        </Typography>
      </TitleWrapper>
      <ImageCardsFormField name={fieldName} imageCards={imageCards} onChange={handleOnChange} />
    </>
  );
};
