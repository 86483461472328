import { colors, styled } from '../../../../lib/themes';

export const Root = styled.div`
  background-color: ${colors.grayLight};
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  box-shadow: 0px -4px 13px rgba(0, 0, 0, 0.06);
`;

export const ContentWrapper = styled.div`
  width: 1110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 57px;
  overflow: hidden;
  height: 100px;
`;

export const Title = styled.strong`
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: ${colors.black};
`;
