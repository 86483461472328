import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { On } from '../../../../lib/core/hooks/on';
import { useGetProject } from '../../../../lib/core/repositories/project-repository';
import { Room } from '../../../../lib/core/api/generated';
import { useCurrentAccount } from '../../../../lib/core/repositories/user-repository';
import { useGetAccount } from '../../../../lib/core/repositories/account-repository';
import { AddCard, CError, Container, Page } from '../../../../lib/components';
import { pluralizeRooms } from '../../../../lib/utils/pluralizeRooms';
import { NewRoomCard } from './components/NewRoomCard';
import { RoomCard } from './components/RoomCard';
import { NoMoreAvailableRooms } from './components/NoMoreAvailableRooms';
import { AllRoomSteps } from '../../../configurator/typesUtils';
import { RoomsHeader } from './components/RoomsHeader';
import { Loader } from '../../../../lib/components/Loader';
import { useIsInvitedUser } from '../../../../lib/core/hooks/useIsInvitedUser';

import { Title, SubTitle, CardWrapper, ContainerStyled, TitleWrapper } from './RoomsPage.styled';

import RoomImageSvg from './RoomImage.svg';
import { useDeleteRoom } from '../../../../lib/core/repositories/room-repository';

interface IProjectPageProps {
  projectId: string;
  accountId: string;
}

export const RoomsPage: React.FC<RouteComponentProps<IProjectPageProps>> = ({ match }) => {
  const { accountId, projectId } = match.params;
  const [currentAccount] = useCurrentAccount();
  const roomLimit = currentAccount ? currentAccount.roomLimit : 1;
  const history = useHistory();
  const isInvitedUser = useIsInvitedUser();
  const [deleteRoom] = useDeleteRoom();
  const [isShowNewRoomCard, setIsShowNewRoomCard] = useState(false);

  const handleOnAddClick = () => {
    setIsShowNewRoomCard(true);
  };

  const handleOnNewRoomCardClose = () => {
    setIsShowNewRoomCard(false);
  };

  const afterRoomCreate = React.useCallback(
    (room: Room) => {
      setIsShowNewRoomCard(false);
      history.push(`/accounts/${accountId}/projects/${room.projectId}/rooms/${room.id}/${AllRoomSteps.RoomStyle.step}`);
    },
    [accountId, history],
  );

  return On(
    (project, account) => {
      const { rooms } = project;
      const roomsCount = account.projects.reduce((acc, project) => acc + project.rooms.length, 0);
      const isFirstRoom = rooms.length === 0;
      const availableRoomsAmount = Math.max(roomLimit - roomsCount, 0);

      return (
        <Page>
          <RoomsHeader backHref={`/accounts/${accountId}/projects`} backButtonText={project.name} />
          <Container>
            <TitleWrapper>
              <Title data-hook-title={'ProjectTitle'}>{project.name}</Title>
              <SubTitle>{pluralizeRooms(availableRoomsAmount)} verfügbar</SubTitle>
            </TitleWrapper>
          </Container>
          <ContainerStyled>
            {rooms.length > 0 &&
              rooms.map(room => (
                <CardWrapper key={room.id}>
                  <RoomCard room={room} accountId={accountId} isInvitedUser={isInvitedUser} deleteRoom={deleteRoom} />
                </CardWrapper>
              ))}

            {!isInvitedUser && (
              <>
                {isShowNewRoomCard ? (
                  <CardWrapper>
                    <NewRoomCard
                      projectId={projectId}
                      onClose={handleOnNewRoomCardClose}
                      afterCreateRoom={afterRoomCreate}
                    />
                  </CardWrapper>
                ) : (
                  <CardWrapper data-hook-button="CreateRoom">
                    {roomsCount < roomLimit ? (
                      <AddCard
                        title="Raum erstellen"
                        subtitle={
                          isFirstRoom
                            ? `Ihr Projekt kann aus <br>mehreren Räumen bestehen. <br>Erstellen Sie Ihren ersten Raum.`
                            : undefined
                        }
                        onClick={handleOnAddClick}
                      />
                    ) : (
                      <NoMoreAvailableRooms />
                    )}
                  </CardWrapper>
                )}
                {isFirstRoom && <img src={RoomImageSvg} />}
              </>
            )}
          </ContainerStyled>
        </Page>
      );
    },
    () => <Loader />,
    errorState => <CError error={errorState} />,
    useGetProject({ id: projectId }),
    useGetAccount({ id: accountId }),
  );
};
