import React from 'react';

import { Solution } from '../../hooks/products-hooks';
import { Solutions } from '../Solutions';

import { Root, Wrapper } from './CatalogHeader.styled';
import { Title } from '../../../../../../../lib/components/Title';

interface ILetMePlanCategoriesProps {
  items: Solution[];
  value: Solution;
  onChange: (value: Solution) => unknown;
}

export const CatalogHeader: React.FC<ILetMePlanCategoriesProps> = ({ items, onChange, value }) => {
  return (
    <Wrapper>
      <Title
        css={`
          padding: 60px 0 0 95px;
        `}
      >
        Katalog
      </Title>
      <Root>
        <Solutions items={items} onChange={onChange} value={value} stepMargin={5} />
      </Root>
    </Wrapper>
  );
};
