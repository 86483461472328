module.exports = {
  FeaturedBanner: {
    'Der erste exklusive': 'Der erste exklusive',
    'Online-Design-Berater für': 'Online-Design-Berater für',
    Bürokonzepte: 'Bürokonzepte',
    'ab 89,-': 'ab 89,-',
    'Kostenlos testen': 'Kostenlos testen',
    'Mitglied werden': 'Mitglied werden',
    'Erfahren Sie mehr': 'Erfahren Sie mehr',
  },
};
