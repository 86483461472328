import { css, ThemedCssFunction } from 'styled-components';

const sizes = {
  desktop: 1919,
  tablet: 1199,
  tabletSmall: 1023,
  phone: 767,
  phoneSmall: 375,
};

// (...args: any[]) => any;
interface IMedia {
  desktop: ThemedCssFunction<any>;
  tablet: ThemedCssFunction<any>;
  tabletSmall: ThemedCssFunction<any>;
  phone: ThemedCssFunction<any>;
  phoneSmall: ThemedCssFunction<any>;
}

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (first, ...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(first, ...args)}
    }
  `;

  return acc;
}, {} as IMedia);
