import { colors, styled } from '../../../../lib/themes';

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: ${colors.black};
  margin-left: 16px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
`;

export const CountsTitle = styled.strong`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: ${colors.black};
  width: 198px;
`;
