import React, { forwardRef, RefObject } from 'react';
import { Field, Label, InputStyled, Error } from './Input.styled';

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  ref?: RefObject<HTMLInputElement>;
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const { error, label, name, id = name, ...inputProps } = props;
  const { type = 'text', value } = inputProps;

  const isValid = !Boolean(error);

  return (
    <Field>
      <InputStyled
        {...inputProps}
        id={id}
        type={type}
        isValid={isValid}
        isDefaultPlaceholder={Boolean(!label)}
        ref={ref}
        data-hook-input={label}
        data-hook-value={value}
      />
      {label && <Label htmlFor={id}>{label}</Label>}
      {!isValid && <Error>{error}</Error>}
    </Field>
  );
});

Input.displayName = 'Input';
