import React from 'react';

export const SofaIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.0394 39.9V23.4C58.0394 23 57.7394 22.7 57.4394 22.7H51.4394V16.7C51.4394 16.3 51.1394 16 50.8394 16H13.2394C12.8394 16 12.6394 16.3 12.6394 16.7V22.7H6.63939C6.23939 22.7 6.03939 23 6.03939 23.4V39.9C6.03939 40.3 6.33939 40.6 6.63939 40.6H11.8394L6.13939 47.4C5.93939 47.7 5.93938 48.1 6.23938 48.3C6.33938 48.4 6.53939 48.5 6.63939 48.5C6.83939 48.5 7.03939 48.4 7.13939 48.3L13.5394 40.7H50.5394L56.9394 48.3C57.0394 48.5 57.2394 48.5 57.4394 48.5C57.5394 48.5 57.7394 48.4 57.8394 48.3C58.1394 48.1 58.1394 47.7 57.9394 47.4L52.2394 40.6H57.4394C57.8394 40.6 58.0394 40.3 58.0394 39.9ZM50.1394 32.1H32.6394V17.7C32.6394 17.6 32.6394 17.5 32.6394 17.5H50.1394V32.1ZM13.9394 17.5H31.4394C31.4394 17.6 31.4394 17.6 31.4394 17.7V32.1H13.9394V17.5ZM56.7394 39.3H7.23938V24.1H12.5394V32.8C12.5394 33.2 12.8394 33.5 13.1394 33.5H50.8394C51.2394 33.5 51.4394 33.2 51.4394 32.8V24.1H56.7394V39.3Z"
      fill="currentColor"
    />
  </svg>
);
