import React from 'react';
import { Header } from '../../../../components/Header';
import { HeaderBackButton } from '../../../../../../lib/components/HeaderBackButton';

interface IRoomsHeaderProps {
  backHref: string;
  badgeText?: string;
  backButtonText: string;
}

export const RoomsHeader: React.FC<IRoomsHeaderProps> = ({ backHref, badgeText, backButtonText }) => {
  return (
    <Header>
      <HeaderBackButton backHref={backHref} backButtonText={backButtonText} badgeText={badgeText} />
    </Header>
  );
};
