import React, { useEffect } from 'react';

/**
 * Hook that handles clicks outside of the passed refs
 */
export function useOutsideClickHandler(
  refOrRefs: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[],
  handler: (event?: MouseEvent) => unknown,
) {
  function handleClickOutside(event: MouseEvent) {
    const refs = Array.isArray(refOrRefs) ? refOrRefs : [refOrRefs];
    const isClickedOnAnyRef = refs.find(ref => ref.current && ref.current.contains(event.target as Node));
    if (!isClickedOnAnyRef) {
      handler(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
