import * as React from 'react';

import { Typography } from '../../themes/typography/Typography';

export const BlockTitle: React.FC<any> = ({ children, className = '' }) => (
  <Typography
    tag="h2"
    variant="h1"
    css={`
      margin-bottom: 95px;
      line-height: 1.3;
      text-align: center;
    `}
    className={className}
  >
    {children}
  </Typography>
);
