import React from 'react';

import { FormError } from '../FormError';
import { StyledFormFooter, Buttons } from './FormFooter.styled';

interface IFormFooter {
  className?: string;
}

export const FormFooter: React.FC<IFormFooter> = ({ children, className }) => (
  <StyledFormFooter className={className}>
    <FormError />
    <Buttons>{children}</Buttons>
  </StyledFormFooter>
);
