import React from 'react';

export const EnvelopeSuccessIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="272" height="269" viewBox="0 0 272 269" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="62.475" width="272" height="206.432" fill="url(#pattern0)" />
    <path
      d="M228 124.844C207.76 139.627 167.187 169.34 152 180.474C146.067 168.488 140.334 156.501 132.907 145.407C122.14 129.396 108.093 115.85 91.6938 105.666C76.4506 96.1514 59.948 88.8172 42.6671 83.8774C41.5204 83.5577 20.4404 79.0828 20.4404 79.3758V223.598C20.4404 229.024 22.5981 234.227 26.4389 238.064C30.2796 241.9 35.4888 244.055 40.9204 244.055H231.147C233.837 244.055 236.5 243.526 238.984 242.498C241.469 241.47 243.727 239.963 245.629 238.064C247.53 236.164 249.039 233.909 250.068 231.427C251.097 228.945 251.627 226.285 251.627 223.598V93.4398C251.627 107.517 238.28 117.346 228 124.844Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M121.853 5.61968L20.373 79.4024V206.471H251.626V93.5197C251.625 89.6206 250.711 85.7759 248.956 82.2931C247.201 78.8103 244.654 75.7861 241.52 73.4625L150.146 5.61968C146.056 2.58394 141.096 0.944702 136 0.944702C130.904 0.944702 125.943 2.58394 121.853 5.61968Z"
      fill="#FFDBD5"
    />
    <path
      d="M199.854 233.907L72.1601 233.907C68.2703 233.907 64.5398 232.363 61.7892 229.616C59.0387 226.868 57.4935 223.142 57.4935 219.257L57.4935 34.0273C57.4935 31.1592 58.6341 28.4085 60.6645 26.3804C62.6949 24.3523 65.4487 23.213 68.3201 23.213L203.68 23.213C206.552 23.213 209.305 24.3523 211.336 26.3804C213.366 28.4085 214.507 31.1592 214.507 34.0273L214.507 219.257C214.507 223.142 212.962 226.868 210.211 229.616C207.461 232.363 203.73 233.907 199.84 233.907H199.854Z"
      fill="white"
    />
    <path
      d="M251.627 93.4398C251.627 107.517 238.293 117.346 228.013 124.844C203.453 142.797 148.88 182.752 146.16 184.776V244.082H231.147C236.578 244.082 241.788 241.927 245.628 238.09C249.469 234.254 251.627 229.051 251.627 223.625V93.4398Z"
      fill="#EA7F7D"
    />
    <path
      d="M201.56 101.897H71.6132C71.0389 101.897 70.5732 102.362 70.5732 102.936V106.318C70.5732 106.892 71.0389 107.357 71.6132 107.357H201.56C202.134 107.357 202.6 106.892 202.6 106.318V102.936C202.6 102.362 202.134 101.897 201.56 101.897Z"
      fill="#E7EAEC"
    />
    <path
      d="M168.747 112.818H70.8271C70.2527 112.818 69.7871 113.283 69.7871 113.857V117.239C69.7871 117.813 70.2527 118.278 70.8271 118.278H168.747C169.321 118.278 169.787 117.813 169.787 117.239V113.857C169.787 113.283 169.321 112.818 168.747 112.818Z"
      fill="#E7EAEC"
    />
    <path
      d="M132.814 123.739H70.0537C69.4793 123.739 69.0137 124.204 69.0137 124.777V128.16C69.0137 128.734 69.4793 129.199 70.0537 129.199H132.814C133.388 129.199 133.854 128.734 133.854 128.16V124.777C133.854 124.204 133.388 123.739 132.814 123.739Z"
      fill="#E7EAEC"
    />
    <path
      d="M182.814 142.477H52.8671C52.2928 142.477 51.8271 142.942 51.8271 143.516V146.899C51.8271 147.473 52.2928 147.938 52.8671 147.938H182.814C183.388 147.938 183.854 147.473 183.854 146.899V143.516C183.854 142.942 183.388 142.477 182.814 142.477Z"
      fill="#E7EAEC"
    />
    <path
      d="M168.747 153.398H67.707C67.1326 153.398 66.667 153.863 66.667 154.437V157.82C66.667 158.394 67.1326 158.859 67.707 158.859H168.747C169.321 158.859 169.787 158.394 169.787 157.82V154.437C169.787 153.863 169.321 153.398 168.747 153.398Z"
      fill="#E7EAEC"
    />
    <path
      d="M20.373 79.4024C20.373 79.1094 41.453 83.5844 42.5997 83.904C59.8976 88.8308 76.4185 96.1561 91.6797 105.666C108.079 115.85 122.126 129.396 132.893 145.407C144.68 162.987 152.2 183.058 163.093 201.211C176.56 223.678 198.04 244.055 225.84 244.055H40.853C38.1636 244.055 35.5004 243.526 33.0157 242.498C30.5309 241.47 28.2732 239.963 26.3715 238.064C24.4698 236.164 22.9612 233.909 21.932 231.427C20.9028 228.945 20.373 226.285 20.373 223.598V79.4024Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M136.587 92.4942C153.45 92.4942 167.12 78.8395 167.12 61.9956C167.12 45.1517 153.45 31.4969 136.587 31.4969C119.724 31.4969 106.054 45.1517 106.054 61.9956C106.054 78.8395 119.724 92.4942 136.587 92.4942Z"
      fill="url(#paint2_radial)"
    />
    <g opacity="0.82">
      <path
        opacity="0.82"
        d="M136.586 88.9515C151.491 88.9515 163.573 76.8829 163.573 61.9955C163.573 47.1082 151.491 35.0396 136.586 35.0396C121.682 35.0396 109.6 47.1082 109.6 61.9955C109.6 76.8829 121.682 88.9515 136.586 88.9515Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <path
      opacity="0.9"
      d="M143.16 51.341L134.666 65.112L130.28 59.1055H124.026L132.813 71.0919C133.05 71.4139 133.359 71.6757 133.715 71.8562C134.072 72.0367 134.466 72.1307 134.866 72.1307H134.96C135.375 72.1114 135.779 71.9909 136.137 71.7797C136.495 71.5684 136.796 71.2728 137.013 70.9187L149.013 51.2611L143.16 51.341Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="136" y1="244.055" x2="136" y2="79.3891" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FAA076" />
        <stop offset="0.28" stopColor="#FAA177" stopOpacity="0.99" />
        <stop offset="0.44" stopColor="#F8A37B" stopOpacity="0.96" />
        <stop offset="0.58" stopColor="#F5A681" stopOpacity="0.91" />
        <stop offset="0.7" stopColor="#F1AB8A" stopOpacity="0.84" />
        <stop offset="0.81" stopColor="#ECB196" stopOpacity="0.74" />
        <stop offset="0.91" stopColor="#E6B9A4" stopOpacity="0.63" />
        <stop offset="1" stopColor="#DFC1B3" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="123.106"
        y1="244.055"
        x2="123.106"
        y2="79.3891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAB696" />
        <stop offset="0.28" stopColor="#FAB697" stopOpacity="0.99" />
        <stop offset="0.44" stopColor="#F8B799" stopOpacity="0.96" />
        <stop offset="0.58" stopColor="#F5B99D" stopOpacity="0.91" />
        <stop offset="0.7" stopColor="#F1BCA3" stopOpacity="0.84" />
        <stop offset="0.81" stopColor="#ECBFAB" stopOpacity="0.74" />
        <stop offset="0.91" stopColor="#E6C3B4" stopOpacity="0.63" />
        <stop offset="1" stopColor="#DFC8BE" stopOpacity="0.5" />
      </linearGradient>
      <radialGradient
        id="paint2_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(136.587 61.9956) scale(30.5333 30.4986)"
      >
        <stop stopColor="#6EE8B6" />
        <stop offset="0.4" stopColor="#6EE8B6" stopOpacity="0.99" />
        <stop offset="0.57" stopColor="#6EE8B5" stopOpacity="0.96" />
        <stop offset="0.68" stopColor="#6FE8B3" stopOpacity="0.91" />
        <stop offset="0.78" stopColor="#6FE8B1" stopOpacity="0.83" />
        <stop offset="0.87" stopColor="#70E8AE" stopOpacity="0.74" />
        <stop offset="0.94" stopColor="#71E8AA" stopOpacity="0.62" />
        <stop offset="1" stopColor="#72E8A6" stopOpacity="0.5" />
      </radialGradient>
    </defs>
  </svg>
);
