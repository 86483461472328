import { styled, colors, h1, h4 } from '../../themes';

export const PackageCardWrap = styled.div``;

export const CallToAction = styled.div`
  background: transparent;
  border: 0;
  ${h4};
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.grayDark};
  height: auto;
  justify-content: center;
`;

export const PackageCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 95px;
  min-height: 345px;
  text-align: center;
  cursor: pointer;
  color: ${colors.black};
  background-color: ${colors.white};
  border: 2px solid #dfdfdf;
  transition: all 0.3s;
  border-radius: 10px;

  &:hover {
    border: 2px solid transparent;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    ${CallToAction} {
      color: ${colors.bronze};
    }
  }
`;

export const Info = styled.div``;

export const Title = styled.h3`
  ${h4};
  margin-bottom: 30px;
`;

export const Price = styled.h1`
  margin-bottom: 15px;
  ${h1};
  font-size: 60px;
  line-height: 45px;
`;

export const PreviousPrice = styled.div`
  margin-bottom: 20px;
  ${h4};
  text-decoration: line-through;
  color: ${colors.grayMedium};
`;
