import React from 'react';

import { IContainerProps } from '../Container';
import { StyledFlexContainer } from './FlexContainer.styled';

export type ContainerDirection = 'row' | 'column';

export interface IFlexContainerProps extends IContainerProps {
  direction?: ContainerDirection;
}

export const FlexContainer: React.FC<IFlexContainerProps> = ({ children, direction = 'row', size, className }) => {
  return (
    <StyledFlexContainer size={size} direction={direction} className={className}>
      {children}
    </StyledFlexContainer>
  );
};
