import React from 'react';
// tslint:disable:max-line-length

export const UploadIcon: React.FC<React.HTMLAttributes<SVGElement>> = props => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 12L14 4L22 12" stroke="white" strokeWidth="2" />
    <line
      y1="-1"
      x2="19"
      y2="-1"
      transform="matrix(4.37115e-08 -1 -1 -4.37115e-08 13 24)"
      stroke="white"
      strokeWidth="2"
    />
    <path d="M0 27H28" stroke="white" strokeWidth="2" />
  </svg>
);
